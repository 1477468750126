<template>
    <div style="overflow-x:auto !important">
        <div style="display: grid; grid-template-columns: auto 400px; grid-column-gap: 50px;">
            <div>
                <table style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                    <tr>
                        <td style=" width: calc(100%/5); vertical-align: top">
                            <img src="../../../../public/img/ProductImages/ZAtop/ZAtop-SM160-40B-side.jpg" style="width:100px" />
                        </td>

                        <td style=" width: calc(100%/5); vertical-align: top; padding-top:15px">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5); vertical-align:top">
                            <button v-if="sapProfile==true" @click="ZAtop" class="btn my_btn_standard_blue" v-bind:disabled="showTemplateButton==true||plusManual==true">{{$t('3464')}}</button>
                        </td>
                        <td class="ZAfont_family ZAfont_size" style=" width:calc(100%/5*2); vertical-align:top">
                            <div v-if="motorManualDataSelected!=null && showMotorManualDetails==false">
                                <div v-if="motorManualDataSelected.mN_MOT!=null">
                                    <div class="my_font-weight_600">{{$t('1098')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorManualDataSelected.mtyP2!=null">{{motorManualDataSelected.mtyP2}} | </span>
                                        <span v-if="motorManualDataSelected.mPr1!=null">{{motorManualDataSelected.mPr1}} kW | </span>
                                        <span v-if="motorManualDataSelected.mnr!=null">{{motorManualDataSelected.mnr}} Upm | </span>
                                        <span v-if="motorManualDataSelected.mU1!=null">{{motorManualDataSelected.mU1}} V </span>
                                    </div>
                                </div>
                                <div v-if="motorManualDataSelected.ltr!=null">
                                    <div class="my_font-weight_600">{{$t('411')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorManualDataSelected.td!=null">{{motorManualDataSelected.td}} mm | </span>
                                        <span v-if="motorManualDataSelected.tb!=null">{{motorManualDataSelected.tb}} mm | </span>
                                        <span v-if="motorManualDataSelected.tdrz!=null&&motorManualDataSelected.tcsd!=null">{{motorManualDataSelected.tdrz}}x{{motorManualDataSelected.tcsd}} | </span>
                                        <span v-if="motorManualDataSelected.tauwi!=null">{{motorManualDataSelected.tauwi}}&deg; |</span>
                                        <span v-if="motorManualDataSelected.tauwi==null&&motorManualDataSelected.takwi!=null">{{motorManualDataSelected.takwi}}&deg; | </span>
                                        <span v-if="motorManualDataSelected.tarill!=null">{{motorManualDataSelected.tarill}} | </span>
                                        <span v-if="motorManualDataSelected.tdra!=null">{{motorManualDataSelected.tdra}}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="motorManualDataSelected!=null">
                                <div>
                                    <button v-if="showMotorManualDetails==false" @click="ChangeMotorDetails(true)" class="btn my_btn_details">
                                        <span>&#9660; </span>
                                        <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="motorManualDataSelected!=null">
                        <td colspan="100" class="ZAfont_family ZAfont_size" style="vertical-align: top;">
                            <div>
                                <button v-if="showMotorManualDetails==true" @click="ChangeMotorDetails(false)" class="btn my_btn_details">
                                    <span>&#9650; </span>
                                    <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                </button>
                            </div>
                            <div v-if="showMotorManualDetails==true">
                                <div>
                                    <div v-if="motorManualDataSelected.kid!=null" style="display: grid; grid-template-columns: 1fr 3fr;padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3452')}}:</div>
                                        <div>{{motorManualDataSelected.kid}}</div>
                                        <div></div>
                                    </div>
                                    <!--<div v-if="motorManualDataSelected.onok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
        <div class="my_font-weight_600">{{$t('304')}}:</div>
        <div>{{motorManualDataSelected.projekt}}</div>
    </div>
    <div v-if="motorManualDataSelected.onok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
        <div class="my_font-weight_600">{{$t('3587')}}:</div>
        <div>{{motorManualDataSelected.sanum}}</div>
    </div>-->
                                    <div v-if="motorManualDataSelected.massbl!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3544')}}:</div>
                                        <div>{{motorManualDataSelected.massbl}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.onok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3545')}}:</div>
                                        <div>{{motorManualDataSelected.onok}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.inok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3546')}}:</div>
                                        <div>{{motorManualDataSelected.inok}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.inok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3153')}}:</div>
                                        <div>{{motorManualDataSelected.mvlift}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.inok!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('1055')}}:</div>
                                        <div>{{motorManualDataSelected.mis}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_MOT!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('1098')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.mtyP2!=null">{{motorManualDataSelected.mtyP2}} | </span>
                                            <span v-if="motorManualDataSelected.mPr1!=null">{{motorManualDataSelected.mPr1}} kW | </span>
                                            <span v-if="motorManualDataSelected.mnr!=null">{{motorManualDataSelected.mnr}} Upm | </span>
                                            <span v-if="motorManualDataSelected.mU1!=null">{{motorManualDataSelected.mU1}} V </span>
                                            <span v-if="motorManualDataSelected.mTypK!=null"> | {{motorManualDataSelected.mTypK}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_MOT}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.msart!=null&&motorManualDataSelected.msart.text!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3067')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.msart!=null && motorManualDataSelected.msart.useTextKey==false">{{motorManualDataSelected.msart.textUI}}</span>
                                            <span v-if="motorManualDataSelected.msart!=null && motorManualDataSelected.msart.useTextKey==true">{{$t(motorManualDataSelected.msart.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.msart!=null&&motorManualDataSelected.msart.text!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3455')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.cnZert!=null && motorManualDataSelected.cnZert.useTextKey==false">{{motorManualDataSelected.cnZert.textUI}}</span>
                                            <span v-if="motorManualDataSelected.cnZert!=null && motorManualDataSelected.cnZert.useTextKey==true">{{$t(motorManualDataSelected.cnZert.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mfbtyp!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('298')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.mfbtyp!=null && motorManualDataSelected.mfbtyp.useTextKey==false">{{motorManualDataSelected.mfbtyp.textUI}}</span>
                                            <span v-if="motorManualDataSelected.mfbtyp!=null && motorManualDataSelected.mfbtyp.useTextKey==true">{{$t(motorManualDataSelected.mfbtyp.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.mfbArt!=null"> | {{motorManualDataSelected.mfbArt}}</span>
                                        </div>
                                        <div v-if="motorManualDataSelected.tnfb !=null">{{motorManualDataSelected.tnfb}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzzMot!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">Anbauteil Mantelleitung ML:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnzzMot}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzzMs!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">Anbauteil Mantelleitung MS:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnzzMs}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.ltr!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('411')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.ltr!=null && motorManualDataSelected.ltr.useTextKey==false">{{motorManualDataSelected.ltr.textUI}}</span>
                                            <span v-if="motorManualDataSelected.ltr!=null && motorManualDataSelected.ltr.useTextKey==true">{{$t(motorManualDataSelected.ltr.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.td!=null"> | {{motorManualDataSelected.td}} mm | </span>
                                            <span v-if="motorManualDataSelected.tb!=null">{{motorManualDataSelected.tb}} mm | </span>
                                            <span v-if="motorManualDataSelected.tdrz!=null&&motorManualDataSelected.tcsd!=null">{{motorManualDataSelected.tdrz}}x{{motorManualDataSelected.tcsd}} | </span>
                                            <span v-if="motorManualDataSelected.tauwi!=null">{{motorManualDataSelected.tauwi}} | </span>
                                            <span v-if="motorManualDataSelected.tauwi==null&&motorManualDataSelected.takwi!=null">{{motorManualDataSelected.takwi}} | </span>
                                            <span v-if="motorManualDataSelected.tarill!=null">{{motorManualDataSelected.tarill}} | </span>
                                            <span v-if="motorManualDataSelected.tdra!=null">{{motorManualDataSelected.tdra}}</span>
                                        </div>
                                        <div>
                                            <span v-if="motorManualDataSelected.mN_TS!=null">{{motorManualDataSelected.mN_TS}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzbtr!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('411')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnzbtr}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_PASSFEDER!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3602')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.mN_PASSFEDER}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnabdi!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">Abdichtung:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnabdi}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnhda!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3567')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.lhda!=null && motorManualDataSelected.lhda.useTextKey==false">{{$t('3537')}} {{motorManualDataSelected.lhda.textUI}}</span>
                                            <span v-if="motorManualDataSelected.lhda!=null && motorManualDataSelected.lhda.useTextKey==true">{{$t('3537')}} {{$t(motorManualDataSelected.lhda.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnhda}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mNFARBE!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">Handrad:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.lthd!=null && motorManualDataSelected.lthd.useTextKey==false">{{$t('3537')}} {{motorManualDataSelected.lthd.textUI}}</span>
                                            <span v-if="motorManualDataSelected.lthd!=null && motorManualDataSelected.lthd.useTextKey==true">{{$t('3537')}} {{$t(motorManualDataSelected.lthd.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnhd}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_ZBSEILSCHU!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('3533')}}:</div>
                                        <div v-if="motorManualDataSelected.lseils!=null">
                                            <span v-if="motorManualDataSelected.lseils!=null && motorManualDataSelected.lseils.useTextKey==false">{{motorManualDataSelected.lseils.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.lseils!=null && motorManualDataSelected.lseils.useTextKey==true">{{$t(motorManualDataSelected.lseils.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.fuesse!=null && motorManualDataSelected.fuesse.useTextKey==false">{{$t('1416')}} {{motorManualDataSelected.fuesse.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.fuesse!=null && motorManualDataSelected.fuesse.useTextKey==true">{{$t('1416')}} {{$t(motorManualDataSelected.fuesse.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.seilab!=null && motorManualDataSelected.seilab.useTextKey==false">{{$t('3536')}} {{motorManualDataSelected.seilab.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.seilab!=null && motorManualDataSelected.seilab.useTextKey==true">{{$t('3536')}} {{$t(motorManualDataSelected.seilab.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.einst!=null && motorManualDataSelected.einst.useTextKey==false">{{$t('3537')}} {{motorManualDataSelected.einst.textUI}}</span>
                                            <span v-if="motorManualDataSelected.einst!=null && motorManualDataSelected.einst.useTextKey==true">{{$t('3537')}} {{$t(motorManualDataSelected.einst.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_ZBSEILSCHU}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_SAS" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3538')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.mN_SAS}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_TSAD!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3534')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.ltsabd!=null && motorManualDataSelected.ltsabd.useTextKey==false">{{motorManualDataSelected.ltsabd.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.ltsabd!=null && motorManualDataSelected.ltsabd.useTextKey==true">{{$t(motorManualDataSelected.ltsabd.textKey)}} | </span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_TSAD}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_TSKL!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3543')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.ltklem!=null && motorManualDataSelected.ltklem.useTextKey==false">{{motorManualDataSelected.ltklem.textUI}}</span>
                                            <span v-if="motorManualDataSelected.ltklem!=null && motorManualDataSelected.ltklem.useTextKey==true">{{$t(motorManualDataSelected.ltklem.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.quantityTklem!=0"> | {{motorManualDataSelected.quantityTklem}} {{$t('3078')}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_TSKL}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnruev!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3586')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.quantityRuev!=0">{{motorManualDataSelected.quantityRuev}} {{$t('3078')}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnruev}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BREM!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('1396')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.btyp!=null">{{motorManualDataSelected.btyp}} | </span>
                                            <span v-if="motorManualDataSelected.bcspan!=null">{{motorManualDataSelected.bcspan}} V | </span>
                                            <span v-if="motorManualDataSelected.bhl!=null && motorManualDataSelected.bhl.useTextKey==false">{{motorManualDataSelected.bhl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.bhl!=null && motorManualDataSelected.bhl.useTextKey==true">{{$t(motorManualDataSelected.bhl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.bksich!=null && motorManualDataSelected.bksich.useTextKey==false">{{motorManualDataSelected.bksich.textUI}}</span>
                                            <span v-if="motorManualDataSelected.bksich!=null && motorManualDataSelected.bksich.useTextKey==true">{{$t(motorManualDataSelected.bksich.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_BREM}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnzbb!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3462')}} {{$t('1396')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnzbb}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.bowdenwireBlockNumber!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3442')}}:</div>
                                        <div>
                                            <!--<span v-if="motorManualDataSelected.bowdenwireLength">{{motorManualDataSelected.bowdenwireLength}} m</span>-->
                                            <span v-if="motorManualDataSelected.lspezb!=null && motorManualDataSelected.lspezb.useTextKey==false">{{motorManualDataSelected.lspezb.textUI}}</span>
                                            <span v-if="motorManualDataSelected.lspezb!=null && motorManualDataSelected.lspezb.useTextKey==true">{{$t(motorManualDataSelected.lspezb.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.spezbl!=null"> | {{motorManualDataSelected.spezbl}} m</span>
                                        </div>
                                        <div>{{motorManualDataSelected.bowdenwireBlockNumber}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_ENC!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3075')}}:</div>
                                        <div>{{motorManualDataSelected.gtyp}}</div>
                                        <div>{{motorManualDataSelected.mN_ENC}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_ENCADAPT!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3600')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.mN_ENCADAPT}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlga!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3428')}}:</div>
                                        <div> </div>
                                        <div>{{motorManualDataSelected.tnlga}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlgl!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3436')}}:</div>
                                        <div v-if="motorManualDataSelected.encoderCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llgl!=null && motorManualDataSelected.llgl.useTextKey==false">{{motorManualDataSelected.llgl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llgl!=null && motorManualDataSelected.llgl.useTextKey==true">{{$t(motorManualDataSelected.llgl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sglMaN!=null">{{motorManualDataSelected.sglMaN}} m | </span>
                                            <span>{{motorManualDataSelected.encoderCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnlgl}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlml!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3437')}}:</div>
                                        <div v-if="motorManualDataSelected.motorCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llml!=null && motorManualDataSelected.llml.useTextKey==false">{{motorManualDataSelected.llml.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llml!=null && motorManualDataSelected.llml.useTextKey==true">{{$t(motorManualDataSelected.llml.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.smlMaN!=null">{{motorManualDataSelected.smlMaN}} m | </span>
                                            <span>{{motorManualDataSelected.motorCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnlml}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlmsl!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3438')}}:</div>
                                        <div v-if="motorManualDataSelected.motorProtectionCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llms!=null && motorManualDataSelected.llms.useTextKey==false">{{motorManualDataSelected.llms.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llms!=null && motorManualDataSelected.llms.useTextKey==true">{{$t(motorManualDataSelected.llms.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.smsMaN!=null">{{motorManualDataSelected.smsMaN}} m | </span>
                                            <span>{{motorManualDataSelected.motorProtectionCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnlmsl}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mib1!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">MIb1:</div>
                                        <div>{{motorManualDataSelected.mib1}} A</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlba!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3439')}}:</div>
                                        <div v-if="motorManualDataSelected.brakeCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llba!=null && motorManualDataSelected.llba.useTextKey==false">{{motorManualDataSelected.llba.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llba!=null && motorManualDataSelected.llba.useTextKey==true">{{$t(motorManualDataSelected.llba.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sbaMaN!=null">{{motorManualDataSelected.sbaMaN}} m | </span>
                                            <span>{{motorManualDataSelected.brakeCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnlba}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnlbl!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3440')}}:</div>
                                        <div v-if="motorManualDataSelected.brakeMonitoringCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llbl!=null && motorManualDataSelected.llbl.useTextKey==false">{{motorManualDataSelected.llbl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llbl!=null && motorManualDataSelected.llbl.useTextKey==true">{{$t(motorManualDataSelected.llbl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sblMaN!=null">{{motorManualDataSelected.sblMaN}} m | </span>
                                            <span>{{motorManualDataSelected.brakeMonitoringCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnlbl}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.tnfLei!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3547')}}:</div>
                                        <div v-if="motorManualDataSelected.fbCableTypeDesignation!=null">
                                            <span v-if="motorManualDataSelected.llfbl!=null && motorManualDataSelected.llfbl.useTextKey==false">{{motorManualDataSelected.llfbl.textUI}} | </span>
                                            <span v-if="motorManualDataSelected.llfbl!=null && motorManualDataSelected.llfbl.useTextKey==true">{{$t(motorManualDataSelected.llfbl.textKey)}} | </span>
                                            <span v-if="motorManualDataSelected.sflMaN!=null">{{motorManualDataSelected.sflMaN}} m | </span>
                                            <span>{{motorManualDataSelected.fbCableTypeDesignation}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.tnfLei}}</div>
                                    </div>

                                    <div v-if="motorManualDataSelected.mN_FARBE!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3539')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.farbe!=null && motorManualDataSelected.farbe.useTextKey==false">{{motorManualDataSelected.farbe.textUI}}</span>
                                            <span v-if="motorManualDataSelected.farbe!=null && motorManualDataSelected.farbe.useTextKey==true">{{$t(motorManualDataSelected.farbe.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_FARBE}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.llab01!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3554')}} 1:</div>
                                        <div>
                                            <span>{{motorManualDataSelected.llab01}}</span>
                                            <span v-if ="motorManualDataSelected.x_LSD!=null"> | {{motorManualDataSelected.x_LSD}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_LOGO!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3540')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.logo!=null && motorManualDataSelected.logo.useTextKey==false">{{motorManualDataSelected.logo.textUI}}</span>
                                            <span v-if="motorManualDataSelected.logo!=null && motorManualDataSelected.logo.useTextKey==true">{{$t(motorManualDataSelected.logo.textKey)}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_LOGO}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL1!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.balsP1!=null && motorManualDataSelected.balsP1.useTextKey==false">{{motorManualDataSelected.balsP1.textUI}}</span>
                                            <span v-if="motorManualDataSelected.balsP1!=null && motorManualDataSelected.balsP1.useTextKey==true">{{$t(motorManualDataSelected.balsP1.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.balmE1!=null"> | {{motorManualDataSelected.balmE1}} {{$t('3078')}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_BAL1}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL2!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.balsP2!=null && motorManualDataSelected.balsP2.useTextKey==false">{{motorManualDataSelected.balsP2.textUI}}</span>
                                            <span v-if="motorManualDataSelected.balsP2!=null && motorManualDataSelected.balsP2.useTextKey==true">{{$t(motorManualDataSelected.balsP2.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.balmE2!=null"> | {{motorManualDataSelected.balmE2}} {{$t('3078')}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_BAL2}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.mN_BAL3!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3390')}}:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.balsP3!=null && motorManualDataSelected.balsP3.useTextKey==false">{{motorManualDataSelected.balsP3.textUI}}</span>
                                            <span v-if="motorManualDataSelected.balsP3!=null && motorManualDataSelected.balsP3.useTextKey==true">{{$t(motorManualDataSelected.balsP3.textKey)}}</span>
                                            <span v-if="motorManualDataSelected.balmE3!=null"> | {{motorManualDataSelected.balmE3}} {{$t('3078')}}</span>
                                        </div>
                                        <div>{{motorManualDataSelected.mN_BAL3}}</div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp1!=null&&motorManualDataSelected.verp1.text!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 1:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp1!=null && motorManualDataSelected.verp1.useTextKey==false">{{motorManualDataSelected.verp1.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp1!=null && motorManualDataSelected.verp1.useTextKey==true">{{$t(motorManualDataSelected.verp1.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp2!=null&&motorManualDataSelected.verp2.text!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 2:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp2!=null && motorManualDataSelected.verp2.useTextKey==false">{{motorManualDataSelected.verp2.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp2!=null && motorManualDataSelected.verp2.useTextKey==true">{{$t(motorManualDataSelected.verp2.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.verp3!=null&&motorManualDataSelected.verp3.text!=null" style="display: grid; grid-template-columns: 1fr 3fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3465')}} 3:</div>
                                        <div>
                                            <span v-if="motorManualDataSelected.verp3!=null && motorManualDataSelected.verp3.useTextKey==false">{{motorManualDataSelected.verp3.textUI}}</span>
                                            <span v-if="motorManualDataSelected.verp3!=null && motorManualDataSelected.verp3.useTextKey==true">{{$t(motorManualDataSelected.verp3.textKey)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="motorManualDataSelected.quantity!=null" style="display: grid; grid-template-columns: 1fr 2fr 1fr; padding: 5px">
                                        <div class="my_font-weight_600">{{$t('3550')}}</div>
                                        <div>{{motorManualDataSelected.quantity}} {{$t('3078')}}</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="plusManual==false && showFrame==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/ZAframe/ZAframe-MH/ZAframe-MH4000-frontcreative.jpg" style="width:100px" />
                        </td>

                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="ZAframe" class="btn my_btn_standard_blue" v-bind:disabled="showTemplateButton==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <!--<h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>-->
                        </td>

                        <td style=" width:calc(100%/5)">
                            <!--<h4 class="my_color_00338e my_font-weight_600">{{$t('1025')}}</h4>-->
                        </td>
                    </tr>
                    <tr v-if="plusManual==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/Leitung/L-ML2.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3435')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorCables" class="btn my_btn_standard_blue" v-bind:disabled="showTemplateButton==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="cableSelectedInformation!=null">
                                <div v-if="cableSelectedInformation.encoderConnectionLength!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3428')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.encoderConnectionLength}} m</span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.encoderCableSelected!=null&&cableSelectedInformation.encoderCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3436')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.encoderCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.encoderCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.encoderCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.motorCableSelected!=null&&cableSelectedInformation.motorCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3437')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.motorCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.motorCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.motorCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.motorProtectionCableSelected!=null&&cableSelectedInformation.motorProtectionCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3438')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.motorProtectionCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.motorProtectionCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.motorProtectionCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.brakeCableSelected!=null&&cableSelectedInformation.brakeCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3439')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.brakeCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.brakeCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.brakeCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.brakeMonitoringCableSelected!=null&&cableSelectedInformation.brakeMonitoringCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3439')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.brakeMonitoringCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.brakeMonitoringCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.brakeMonitoringCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="cableSelectedInformation.forcedVentilationCableSelected!=null&&cableSelectedInformation.forcedVentilationCableSelected==true">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3447')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{$t('407')}}:</span>
                                        <span style="margin-left:5px">
                                            <span>{{cableSelectedInformation.forcedVentilationCableTypeDesignation}}</span>
                                        </span>
                                        <span v-if="cableSelectedInformation.forcedVentilationCableLength!=null">
                                            <span style="margin-left:10px">|</span>
                                            <span style="margin-left:10px">{{$t('3431')}}:</span><span style="margin-left:5px">{{cableSelectedInformation.forcedVentilationCableLength}} m</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showMotorSupplies==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ZAbrands/ZAsymbol.svg" style="width:70px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3462')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorSupplies" class="btn my_btn_standard_blue" v-bind:disabled="showTemplateButton==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="motorSuppliesSelected!=null">
                                <div v-if="motorSuppliesSelected.mn_FARBE!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3539')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{motorSuppliesSelected.encoderConnectionLength}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{cableSelected.sglMaN}}</span>
                                    </div>
                                </div>
                                <div v-if="motorSuppliesSelected.mN_BAL1!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3390')}}:</div>
                                    <div style="margin-left:5px">
                                        <span v-if="motorSuppliesSelected.mN_BAL1!=null">{{motorSuppliesSelected.mN_BAL1}}</span>
                                        <span v-if="motorSuppliesSelected.mN_BAL2!=null"> | {{motorSuppliesSelected.mN_BAL2}}</span>
                                        <span v-if="motorSuppliesSelected.mN_BAL3!=null"> | {{motorSuppliesSelected.mN_BAL3}}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="showMotorPackaging==true">
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ZAbrands/ZAsymbol.svg" style="width:70px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3028')}} {{$t('3465')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="MotorPackaging" class="btn my_btn_standard_blue" v-bind:disabled="showTemplateButton==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5*2)">
                            <div v-if="motorPackagingSelected!=null">
                                <div v-if="motorPackagingSelected.verp1!=null">
                                    <div class="my_color_00338e my_fontWeight_600">{{$t('3465')}}:</div>
                                    <div style="margin-left:5px">
                                        <span>{{motorPackagingSelected.verp1}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{motorPackagingSelected.verp2}}</span>
                                        <span style="margin-left:10px">|</span>
                                        <span style="margin-left:10px">{{motorPackagingSelected.verp3}}</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/ZAdyn4C/ZAdyn4C_011-032_ZD4C01M0-front.jpg" style="width:100px" />
                        </td>
                        <td style=" width:calc(100%/5)">
                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3502')}}</h4>
                        </td>
                        <td style=" width:calc(100%/5)">
                            <button v-if="sapProfile==true" @click="ZAdyn" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true||showTemplateButton==true">{{$t('3464')}}</button>
                        </td>


                        <td style=" width:calc(100%/5)" class="ZAfont_family ZAfont_size">

                            <div v-if="Baug_Select != undefined && showInverterDetails==false">
                                <div v-if="Baug_Select != undefined">
                                    <span class=" my_font-weight_600">{{$t('3453')}}:</span>
                                    <span style="margin-left:5px">{{Baug_Select}}</span>
                                </div>
                                <!--<div v-if="Menue_Select != undefined">
                                    <span class="my_color_00338e my_font-weight_600">Menuesprache:</span>
                                    <span style="margin-left:5px">{{Menue_Select}}</span>
                                </div>-->

                            </div>
                            <div v-if="Baug_Select != undefined">
                                <div>
                                    <button v-if="showInverterDetails==false" @click="ChangeInverterDetails(true)" class="btn my_btn_details">
                                        <span>&#9660; </span>
                                        <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                    </button>
                                </div>
                            </div>



                            <!--<div v-if="Baug_Select != undefined" class="my_color_00338e my_font-weight_600">{{$t('305')}}:</div>
                            <div v-if="Baug_Select != undefined">{{Baug_Select}}</div>
                            <div v-if="Menue_Select != undefined">{{Menue_Select}}</div>
                            <div v-if="Bal_Select != undefined">{{Bal_Select}}</div>
                            <div v-if="Par_Select != undefined">{{Par_Select}}</div>
                            <div v-if="Conf_Select != undefined">{{Conf_Select}}</div>
                            <div v-if="Code_Select != undefined">{{Code_Select}}</div>-->
                        </td>


                        <!--<td style=" width:calc(100%/5)">
                            <div v-if="PAD_Select != undefined || MON_Select != undefined || ND_Select != undefined || FEF_Select != undefined || BR_Select != undefined || BRLT_Select != undefined || DCP_Select != undefined || STO_Select != undefined || XOUT_Select != undefined || XIN_Select != undefined || Nachruestsatz_Select != undefined || ZAsbc_Select != undefined" class="my_color_00338e my_font-weight_600">{{$t('3527')}}:</div>
                            <div v-if="PAD_Select != undefined">{{PAD_Select}}</div>
                            <div v-if="MON_Select != undefined">{{MON_Select}}</div>
                            <div v-if="ND_Select != undefined">{{ND_Select}}</div>
                            <div v-if="FEF_Select != undefined">{{FEF_Select}}</div>
                            <div v-if="BR_Select != undefined">{{BR_Select}}</div>
                            <div v-if="BRLT_Select != undefined">{{BRLT_Select}}</div>
                            <div v-if="Nachruestsatz_Select != undefined">{{Nachruestsatz_Select}}</div>
                            <div v-if="DCP_Select != undefined">{{DCP_Select}}</div>
                            <div v-if="STO_Select != undefined">{{STO_Select}}</div>
                            <div v-if="XOUT_Select != undefined">{{XOUT_Select}}</div>
                            <div v-if="XIN_Select != undefined">{{XIN_Select}}</div>
                            <div v-if="ZAsbc_Select != undefined">{{ZAsbc_Select}}</div>
                            <div v-if="ZAsbc_LS_Select != undefined">{{ZAsbc_LS_Select}}</div>
                            <div v-if="ZAsbc_LSBA_Select != undefined">{{ZAsbc_LSBA_Select}}</div>
                            <div v-if="ZAsbc_BAL_Select != undefined">{{ZAsbc_BAL_Select}}</div>
                        </td>-->


                    </tr>
                    <tr v-if="showInverterDetails == true">
                        <td colspan="100" class="ZAfont_family ZAfont_size" style="vertical-align: top;">
                            <div>
                                <button @click="ChangeInverterDetails(false)" class="btn my_btn_details">
                                    <span>&#9650; </span>
                                    <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                </button>
                            </div>
                            <div>
                                <div v-if="Baug_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3453')}}:</div>
                                    <div style="margin-left:5px">{{Baug_Select}}</div>
                                    <div style="margin-left:5px">{{MNFI_Select}}</div>
                                </div>
                                <div v-if="Menue_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3509')}}:</div>
                                    <div style="margin-left:5px">{{Menue_Select}}</div>
                                </div>
                                <div v-if="Bal_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3391')}}:</div>
                                    <div style="margin-left:5px">{{Bal_Select}}</div>
                                    <div style="margin-left:5px">{{MNBAL_Select}}</div>
                                </div>
                                <div v-if="Par_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3510')}}:</div>
                                    <div style="margin-left:5px">{{Par_Select}}</div>
                                </div>
                                <div v-if="Conf_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3511')}}:</div>
                                    <div style="margin-left:5px">{{Conf_Select}}</div>
                                </div>
                                <div v-if="Code_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3512')}}:</div>
                                    <div style="margin-left:5px">{{Code_Select}}</div>
                                </div>
                                <div v-if="PAD_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3513')}}:</div>
                                    <div style="margin-left:5px">{{PAD_Select}}</div>
                                    <div style="margin-left:5px">{{PADMN_Select}}</div>
                                </div>
                                <div v-if="MON_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3514')}}:</div>
                                    <div style="margin-left:5px">{{MON_Select}}</div>
                                    <div style="margin-left:5px">{{MONMN_Select}}</div>
                                </div>
                                <div v-if="ND_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3515')}}:</div>
                                    <div style="margin-left:5px">{{ND_Select}}</div>
                                    <div style="margin-left:5px">{{NDMN_Select}}</div>
                                </div>
                                <div v-if="FEF_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3516')}}:</div>
                                    <div style="margin-left:5px">{{FEF_Select}}</div>
                                    <div style="margin-left:5px">{{FEFMN_Select}}</div>
                                </div>
                                <div v-if="BR_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('388')}}:</div>
                                    <div style="margin-left:5px">{{BR_Select}}</div>
                                    <div style="margin-left:5px">{{BRMN_Select}}</div>
                                </div>
                                <div v-if="BRLT_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3517')}}:</div>
                                    <div style="margin-left:5px">{{BRLT_Select}}</div>
                                    <div style="margin-left:5px">{{BRLTMN_Select}}</div>
                                </div>
                                <div v-if="Nachruestsatz_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3518')}}:</div>
                                    <div style="margin-left:5px">{{Nachruestsatz_Select}}</div>
                                    <div style="margin-left:5px">{{NachruestsatzMN_Select}}</div>
                                </div>
                                <div v-if="DCP_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3519')}}:</div>
                                    <div style="margin-left:5px">{{DCP_Select}}</div>
                                    <div style="margin-left:5px">{{DCPMN_Select}}</div>
                                </div>
                                <div v-if="STO_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3520')}}:</div>
                                    <div style="margin-left:5px">{{STO_Select}}</div>
                                    <div style="margin-left:5px">{{STOMN_Select}}</div>
                                </div>
                                <div v-if="XOUT_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3521')}}:</div>
                                    <div style="margin-left:5px">{{XOUT_Select}}</div>
                                    <div style="margin-left:5px">{{XOUTMN_Select}}</div>
                                </div>
                                <div v-if="XIN_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3522')}}:</div>
                                    <div style="margin-left:5px">{{XIN_Select}}</div>
                                    <div style="margin-left:5px">{{XINMN_Select}}</div>
                                </div>
                                <div v-if="ZAsbc_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3523')}}:</div>
                                    <div style="margin-left:5px">{{ZAsbc_Select}}</div>
                                    <div style="margin-left:5px">{{ZAsbcMN_Select}}</div>
                                </div>
                                <div v-if="ZAsbc_LS_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3524')}}:</div>
                                    <div style="margin-left:5px">{{ZAsbc_LS_Select}}</div>
                                    <div style="margin-left:5px">{{ZAsbc_LSMN_Select}}</div>
                                </div>
                                <div v-if="ZAsbc_LSBA_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3525')}}:</div>
                                    <div style="margin-left:5px">{{ZAsbc_LSBA_Select}}</div>
                                    <div style="margin-left:5px">{{ZAsbc_LSBAMN_Select}}</div>
                                </div>
                                <div v-if="ZAsbc_BAL_Select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3391')}} {{$t('3523')}}:</div>
                                    <div style="margin-left:5px">{{ZAsbc_BAL_Select}}</div>
                                    <div style="margin-left:5px">{{ZAsbc_BALMN_Select}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style=" width:calc(100%/5)">
                            <img src="../../../../public/img/ProductImages/EVAC_3/EVAC3B-front-web.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">{{$t('3500')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="EVAC" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true||showTemplateButton==true">{{$t('3464')}}</button>
                        </td>


                        <td style=" width:calc(100%/5)" class="ZAfont_family ZAfont_size">

                            <div v-if="EVAC_Typ_select != undefined && showEVACDetails==false">
                                <div v-if="EVAC_Typ_select != undefined">
                                    <span class="my_font-weight_600">{{$t('3453')}}:</span>
                                    <span style="margin-left:5px">{{EVAC_Typ_select}}</span>
                                </div>

                            </div>
                            <div v-if="EVAC_Typ_select != undefined">
                                <div>
                                    <button v-if="showEVACDetails==false" @click="ChangeEVACDetails(true)" class="btn my_btn_details">
                                        <span>&#9660; </span>
                                        <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                    </button>
                                </div>
                            </div>




                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>

                    <tr v-if="showEVACDetails == true">
                        <td colspan="100" class="ZAfont_family ZAfont_size" style="vertical-align: top;">
                            <div>

                                <button @click="ChangeEVACDetails(false)" class="btn my_btn_details">
                                    <span>&#9650; </span>
                                    <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                </button>
                            </div>
                            <div>
                                <div v-if="EVAC_Typ_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3453')}}:</div>
                                    <div style="margin-left:5px">{{EVAC_Typ_select}}</div>
                                    <div style="margin-left:5px">{{EVAC_TypMN_select}}</div>
                                </div>
                                <div v-if="EVAC_Batt_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3505')}}:</div>
                                    <div style="margin-left:5px">{{EVAC_Batt_select}}</div>
                                    <div style="margin-left:5px">{{EVAC_BattMN_select}}</div>
                                </div>
                                <div v-if="EVAC_Cable_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3506')}}:</div>
                                    <div style="margin-left:5px">{{EVAC_Cable_select}}</div>
                                    <div style="margin-left:5px">{{EVAC_CableMN_select}}</div>
                                </div>
                                <div v-if="EVAC_Control_Cable_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3507')}}:</div>
                                    <div style="margin-left:5px">{{EVAC_Control_Cable_select}}</div>
                                    <div style="margin-left:5px">{{EVAC_Control_CableMN_select}}</div>
                                </div>
                                <div v-if="EVAC_Bal_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3391')}}:</div>
                                    <div style="margin-left:5px">{{EVAC_Bal_select}}</div>
                                    <div style="margin-left:5px">{{EVAC_BalMN_select}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>



                    <tr>
                        <td style=" width:calc(100%/5)">


                            <img src="../../../../public/img/ProductImages/ZArec/ZArec4C_013_ZDRE01M0-front.jpg" style="width:100px" />

                        </td>

                        <td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">{{$t('361')}}</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="ZArec" class="btn my_btn_standard_blue" v-bind:disabled="plusManual==true||showTemplateButton==true">{{$t('3464')}}</button>
                        </td>

                        <td style=" width:calc(100%/5)" class="ZAfont_family ZAfont_size">

                            <div v-if="ZArec_Typ_select != undefined && showZArecDetails==false">
                                <div v-if="ZArec_Typ_select != undefined">
                                    <span class="my_font-weight_600">{{$t('3453')}}:</span>
                                    <span style="margin-left:5px">{{ZArec_Typ_select}}</span>
                                </div>

                            </div>
                            <div v-if="ZArec_Typ_select != undefined">
                                <div>
                                    <button v-if="showZArecDetails==false" @click="ChangeZArecDetails(true)" class="btn my_btn_details">
                                        <span>&#9660; </span>
                                        <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                    </button>
                                </div>
                            </div>

                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>



                    <tr v-if="showZArecDetails == true">
                        <td colspan="100" class="ZAfont_family ZAfont_size" style="vertical-align: top;">
                            <div>

                                <button @click="ChangeZArecDetails(false)" class="btn my_btn_details">
                                    <span>&#9650; </span>
                                    <span class="my_color_00338e my_font-weight_600" style="padding: 5px;">{{$t('3240')}}</span>
                                </button>
                            </div>
                            <div>
                                <div v-if="ZArec_Typ_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3453')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_Typ_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_TypMN_select}}</div>
                                </div>
                                <div v-if="ZArec_LS_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3588')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_LS_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_LSMN_select}}</div>
                                </div>
                                <div v-if="ZArec_BR_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('388')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_BR_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_BRMN_select}}</div>
                                </div>
                                <div v-if="ZArec_BRLT_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3517')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_BRLT_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_BRLTMN_select}}</div>
                                </div>
                                <div v-if="ZArec_ZApad_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3513')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_ZApad_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_ZApadMN_select}}</div>
                                </div>
                                <div v-if="ZArec_BAL_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3391')}}:</div>
                                    <div style="margin-left:5px">{{ZArec_BAL_select}}</div>
                                    <div style="margin-left:5px">{{ZArec_BALMN_select}}</div>
                                </div>
                                <div v-if="RLD_Typ_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('407')}}:</div>
                                    <div style="margin-left:5px">{{RLD_Typ_select}}</div>
                                    <div style="margin-left:5px">{{RLD_TypMN_select}}</div>
                                </div>
                                <div v-if="RLD_FEF_select != undefined" style="display: grid; grid-template-columns: 1fr 1fr 2fr; padding: 5px">
                                    <div class=" my_font-weight_600">{{$t('3516')}}:</div>
                                    <div style="margin-left:5px">{{RLD_FEF_select}}</div>
                                    <div style="margin-left:5px">{{RLD_FEFMN_select}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>


                    <tr>
                        <td style=" width:calc(100%/5)">


                            <!-- <img src="../../../../public/img/ProductImages" style="width:100px" />-->

                        </td>

                        <!--<td style=" width:calc(100%/5)">

                            <h4 class="my_color_00338e my_font-weight_600">RLD</h4>
                        </td>

                        <td style=" width:calc(100%/5)">

                            <button v-if="sapProfile==true" @click="RLD" class="btn my_btn_standard_blue">{{$t('3298')}}</button>
                        </td>-->

                        <td style=" width:calc(100%/5)">
                        </td>

                        <td style=" width:calc(100%/5)">
                        </td>
                    </tr>

                </table>
            </div>
            <div v-if="plusManual==true" style="margin-left: 0px; magin-rigth:0px">
                <SummaryInformationManual :additional="true"></SummaryInformationManual>
            </div>
            <div v-if="plusManual==false" style="margin-left: 0px; magin-rigth:0px">
                <div v-if="motorManualDataSelected!=null">
                    <button @click="informationAtkonf" class="btn my_btn_standard_blue">{{$t('3177')}} ATKONF</button>
                </div>
                <SummaryInformationManual :additional="false"></SummaryInformationManual>
            </div>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import SummaryInformationManual from '../../configuration/manualSteps/SummaryInformationManual';

    export default {
        name: 'ManualHome',
        components: {
            SummaryInformationManual,
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                },
            },
            ZLM: {
                get() {
                    return this.$store.state.manualHome.ZLM;
                },
            },
            Baug_Select: {
                get() {
                    return this.$store.state.FI.FI_ModelUI_selected;
                },
            },
            MNFI_Select: {
                get() {
                    return this.$store.state.FI.FI_MNFI_selected;
                },
            },
            Menue_Select: {
                get() {
                    return this.$store.state.FI.FI_MenueUI_selected;
                },
            },
            Bal_Select: {
                get() {
                    return this.$store.state.FI.FI_BalUI_selected;
                },
            },
            MNBAL_Select: {
                get() {
                    return this.$store.state.FI.FI_MNBAL_selected;
                },
            },
            Par_Select: {
                get() {
                    return this.$store.state.FI.FI_ParUI_selected;
                },
            },
            Conf_Select: {
                get() {
                    return this.$store.state.FI.FI_ConfUI_selected;
                },
            },
            Code_Select: {
                get() {
                    return this.$store.state.FI.FI_CodeUI_selected;
                },
            },
            PAD_Select: {
                get() {
                    return this.$store.state.FI.FI_PADUI_selected;
                },
            },
            PADMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNPAD_selected;
                },
            },
            MON_Select: {
                get() {
                    return this.$store.state.FI.FI_MONUI_selected;
                },
            },
            MONMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNMON_selected;
                },
            },
            ND_Select: {
                get() {
                    return this.$store.state.FI.FI_NDUI_selected;
                },
            },
            NDMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNND_selected;
                },
            },
            FEF_Select: {
                get() {
                    return this.$store.state.FI.FI_FEFUI_selected;
                },
            },
            FEFMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNFEF_selected;
                },
            },
            BR_Select: {
                get() {
                    return this.$store.state.FI.FI_BRUI_selected;
                },
            },
            BRMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNBR_selected;
                },
            },
            BRLT_Select: {
                get() {
                    return this.$store.state.FI.FI_BRLTUI_selected;
                },
            },
            BRLTMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNBRLT_selected;
                },
            },
            Nachruestsatz_Select: {
                get() {
                    return this.$store.state.FI.FI_NachruestsatzUI_selected;
                },
            },
            NachruestsatzMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNNachruestsatz_selected;
                },
            },
            DCP_Select: {
                get() {
                    return this.$store.state.FI.FI_DCPUI_selected;
                },
            },
            DCPMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNDCP_selected;
                },
            },
            STO_Select: {
                get() {
                    return this.$store.state.FI.FI_STOUI_selected;
                },
            },
            STOMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNSTO_selected;
                },
            },
            XOUT_Select: {
                get() {
                    return this.$store.state.FI.FI_XOUTUI_selected;
                },
            },
            XOUTMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNXOUT_selected;
                },
            },
            XIN_Select: {
                get() {
                    return this.$store.state.FI.FI_XINUI_selected;
                },
            },
            XINMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNXIN_selected;
                },
            },
            ZAsbc_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbcUI_selected;
                },
            },
            ZAsbcMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNZAsbc_selected;
                },
            },
            ZAsbc_LS_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LSUI_selected
                },
            },
            ZAsbc_LSMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNZAsbc_LS_selected
                },
            },
            ZAsbc_LSBA_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_LSBAUI_selected
                },
            },
            ZAsbc_LSBAMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNZAsbc_LSBA_selected
                },
            },
            ZAsbc_BAL_Select: {
                get() {
                    return this.$store.state.FI.FI_ZAsbc_BALUI_selected
                },
            },
            ZAsbc_BALMN_Select: {
                get() {
                    return this.$store.state.FI.FI_MNZAsbc_BAL_selected
                },
            },
            EVAC_Typ_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_TypUI_select;
                },
            },
            EVAC_TypMN_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_MNTyp_select;
                },
            },
            EVAC_Batt_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_BattUI_select;
                },
            },
            EVAC_BattMN_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_MNBatt_select;
                },
            },
            EVAC_Cable_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_CableUI_select;
                },
            },
            EVAC_CableMN_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_MNCable_select;
                },
            },
            EVAC_Control_Cable_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_Control_CableUI_select;
                },
            },
            EVAC_Control_CableMN_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_MNControl_Cable_select;
                },
            },
            EVAC_Bal_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_BalUI_select;
                },
            },
            EVAC_BalMN_select: {
                get() {
                    return this.$store.state.EVAC.EVAC_MNBal_select;
                },
            },
            ZArec_Typ_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_TypUI_select;
                },
            },
            ZArec_TypMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNTyp_select;
                },
            },
            ZArec_LS_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_LSUI_select;
                },
            },
            ZArec_LSMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNLS_select;
                },
            },
            ZArec_BR_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BRUI_select;
                },
            },
            ZArec_BRMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNBR_select;
                },
            },
            ZArec_BRLT_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BRLTUI_select;
                },
            },
            ZArec_BRLTMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNBRLT_select;
                },
            },
            ZArec_ZApad_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_ZApadUI_select;
                },
            },
            ZArec_ZApadMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNZApad_select;
                },
            },
            ZArec_BAL_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_BALUI_select;
                },
            },
            ZArec_BALMN_select: {
                get() {
                    return this.$store.state.ZArec.ZArec_MNBAL_select;
                },
            },
            RLD_Typ_select: {
                get() {
                    return this.$store.state.ZArec.RLD_TypUI_select;
                },
            },
            RLD_TypMN_select: {
                get() {
                    return this.$store.state.ZArec.RLD_MNTyp_select;
                },
            },
            RLD_FEF_select: {
                get() {
                    return this.$store.state.ZArec.RLD_FEFUI_select;
                },
            },
            RLD_FEFMN_select: {
                get() {
                    return this.$store.state.ZArec.RLD_MNFEF_select;
                },
            },

            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                },
            },
            showFrame: {
                get() {
                    return false;
                },
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            cableSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('cables')) {
                        return this.$store.state.summary.manualHomeSummary.cables;

                    }
                    else {
                        return null;
                    }
                },
            },
            cableSelectedInformation: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('cablesInformation')) {
                        return this.$store.state.summary.manualHomeSummary.cablesInformation;

                    }
                    else {
                        return null;
                    }
                }
            },
            motorSuppliesSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('suppliesMotorManual')) {
                        return this.$store.state.summary.manualHomeSummary.suppliesMotorManual;

                    }
                    else {
                        return null;
                    }
                },
            },
            motorPackagingSelected: {
                get() {
                    if (this.$store.state.summary.manualHomeSummary != null && this.$store.state.summary.manualHomeSummary.hasOwnProperty('packagingMotorManual')) {
                        return this.$store.state.summary.manualHomeSummary.packagingMotorManual;

                    }
                    else {
                        return null;
                    }
                },
            },
            motorManualDataSelected: {
                get() {
                    if (this.$store.state.inputsManual.motorManualData != null && this.$store.state.inputsManual.motorManualData.hasOwnProperty('mN_MOT')) {
                        return this.$store.state.inputsManual.motorManualData;
                    }
                    else {
                        return null;
                    }
                }
            },
            showMotorManualDetails: {
                get() {
                    return this.$store.state.inputsManual.showMotorDetails;
                }
            },
            showMotorSupplies: {
                get() {
                    if (this.plusManual == true && this.cableSelected != null) {
                        return true;
                    }
                    return false;
                },
            },
            showMotorPackaging: {
                get() {
                    if (this.plusManual == true && this.motorSuppliesSelected != null) {
                        return true;
                    }
                    return false;
                },
            },
            showInverterDetails: {
                get() {
                    return this.$store.state.manualHome.showInverterDetails;
                }
            },
            showEVACDetails: {
                get() {
                    return this.$store.state.manualHome.showEVACDetails;
                }
            },
            showZArecDetails: {
                get() {
                    return this.$store.state.manualHome.showZArecDetails;
                }
            },
            showTemplateButton: {
                get() {
                    return this.$store.state.manualHome.showTemplateButton;
                }
            },
        },
        created() {
            this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', false);
            if (this.plusManual == true) {
                this.$store.commit('configurations/MANU_configuration', false);
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);
                this.$store.dispatch('summary/select_databaseRecords_for_manualHome_storeAction')

            }
            else {
                this.$store.commit('configurations/change_sap_storeMutation', true);

                if (this.$store.state.configurations.MANUAL == false) {
                    this.$store.commit('configurations/MANU_configuration', true);
                }
                this.$store.dispatch('manualHome/check_ZLM_storeAction');
                this.$store.dispatch('manualHome/check_configuration_Exist_storeAction');
            }
        },

        methods: {
            ZAtop() {
                this.ChangeMotorDetails(false);
                this.$store.dispatch('configurations/change_elevatorMotor_Manual_storeAction', true);
                router.push('../manualStep/inputsManual');
            },

            ZAframe() {

            },

            ZAdyn() {

                this.$store.dispatch('configurations/MANU', true)
                this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
                this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');
                //this.$store.dispatch('controlTechnologies/get_FI_Type_storeAction')

                this.$store.dispatch('FI/get_FI_Type_storeAction');

                router.push('../manualStep/FI');
            },

            EVAC() {
                console.log('EVAC')
                this.$store.dispatch('EVAC/get_EVAC_Typ_storeAction');

                router.push('../manualStep/EVAC');
            },

            ZArec() {
                console.log('ZArec')

                this.$store.dispatch('ZArec/get_ZArec_Typ_storeAction');
                this.$store.dispatch('ZArec/get_RLD_Typ_storeAction');

                router.push('../manualStep/ZArec');
            },

            RLD() {
                router.push('../manualStep/RLD');
            },
            MotorCables() {
                router.push('../manualStep/cables');
            },
            MotorSupplies() {
                router.push('../manualStep/suppliesMotorManual')
            },
            MotorPackaging() {
                router.push('../manualStep/packagingMotorManual')
            },
            informationAtkonf() {
                this.$store.dispatch('inputsManual/get_informationAtkonf_storeAction')
            },
            ChangeMotorDetails(value) {
                this.$store.commit('inputsManual/change_showMotorDetails_storeMutation', value)
            },
            ChangeInverterDetails(value) {
                this.$store.commit('manualHome/change_showInverterDetails_storeMutation', value)
            },
            ChangeEVACDetails(value) {
                this.$store.commit('manualHome/change_showEVACDetails_storeMutation', value)
            },
            ChangeZArecDetails(value) {
                this.$store.commit('manualHome/change_showZArecDetails_storeMutation', value)
            }

        },
    }
</script>


<style>
    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
