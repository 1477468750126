import axios from 'axios';
import router from "@/router";

let state = {
    EVAC_Typ_select: null,
    EVAC_TypUI_select: null,
    EVAC_MNTyp_select: null,
    EVAC_Batt_select: null,
    EVAC_BattUI_select: null,
    EVAC_MNBatt_select: null,
    EVAC_Cable_select: null,
    EVAC_CableUI_select: null,
    EVAC_MNCable_select: null,
    EVAC_Control_Cable_select: null,
    EVAC_Control_CableUI_select: null,
    EVAC_MNControl_Cable_select: null,
    EVAC_Bal_select: null,
    EVAC_BalUI_select: null,
    EVAC_MNBal_select: null,
    EVAC_Typ_list: [],
    EVAC_TypUI_list: [],
    EVAC_MNTyp_list: [],
    EVAC_Batt_list: [],
    EVAC_Cable_list: [],
    EVAC_Control_Cable_list: [],
    EVAC_Bal_list: [],
    FI_fuer_suche_verwenden: true,
}

const actions = {
    async get_EVAC_Typ_storeAction({ commit, rootState }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { ID: rootState.FI.FI_MNFI_selected, FIberuecksichtigen: rootState.EVAC.FI_fuer_suche_verwenden };
        await axios({

            method: 'post',
            url: '/api/EVAC/get_EVAC_Typ',
            data: {
                data_FU: data_FU,
            },

            
        })
            .then((response) => {
                console.log('1', response)
                commit('get_EVAC_Typ_storeMutation', response.data)
                //debugger;
                if (state.EVAC_Typ_select != null) {
                    for (let i = 0; i < state.EVAC_Typ_list.length; i++) {
                        state.EVAC_Typ_list[i].selected = false;

                        if (state.EVAC_Typ_list[i].text.text == state.EVAC_Typ_select) {
                            state.EVAC_Typ_list[i].selected = true;
                        }
                    }
                }


            })
            .catch(() => { })
    },
    async get_EVAC_Batt_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { EVAC: state.EVAC_Typ_select };
        await axios({

            method: 'post',
            url: '/api/EVAC/get_EVAC_Batt',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {
                console.log('1', response)
                commit('get_EVAC_Batt_storeMutation', response.data)



            })
            .catch(() => { })
    },
    async get_EVAC_Bal_storeAction({ commit, rootState }) {
        const data_FU = { lNG: rootState.manualHome.LNG };
        //console.log('get_FI_Type_storeAction')
        
        await axios({

            method: 'post',
            url: '/api/EVAC/get_EVAC_Bal',
            data: {
                data_FU: data_FU,
            }


        })
            .then((response) => {
                
                commit('get_EVAC_Bal_storeMutation', response.data)

                const firstIndex_whichMeetConditions_Test = state.EVAC_Bal_list.findIndex(i => i.selected == true)
                commit('change_EVAC_Bal_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions_Test].text.text);
                commit('change_EVAC_BalUI_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions_Test].text.textUI);
                commit('change_EVAC_MNBal_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions_Test].id)


            })
            .catch(() => { })
    },
    async get_EVAC_Cable_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        
        await axios({

            method: 'post',
            url: '/api/EVAC/get_EVAC_Cable',
            


        })
            .then((response) => {

                commit('get_EVAC_Cable_storeMutation', response.data)



            })
            .catch(() => { })
    },
    async get_EVAC_Control_Cable_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        const data_FU = { EVAC: state.EVAC_Typ_select };
        await axios({

            method: 'post',
            url: '/api/EVAC/get_EVAC_Control_Cable',
            data: {
                data_FU: data_FU,
            },


        })
            .then((response) => {

                commit('get_EVAC_Control_Cable_storeMutation', response.data)



            })
            .catch(() => { })
    },

    async insert_data({ rootState }) {

        
        const data_Insert_EVAC = {
            Typ: state.EVAC_Typ_select,
            MN_Typ: state.EVAC_MNTyp_select,
            Batt: state.EVAC_Batt_select,
            MN_Batt: state.EVAC_MNBatt_select,
            Cable: state.EVAC_Cable_select,
            MN_Cable: state.EVAC_MNCable_select,
            ControlCable: state.EVAC_Control_Cable_select,
            MN_ControlCable: state.EVAC_MNControl_Cable_select,
            Bal: state.EVAC_Bal_select,
            MN_Bal: state.EVAC_MNBal_select,
            

        };

       

        await axios({
            method: 'post',
            url: '/api/EVAC/insert_data',

            data: {
                data_Insert_EVAC: data_Insert_EVAC,
                zLM: rootState.manualHome.ZLM,
            }

        })
            .then((response) => {

                //commit('change_FI_ZLM_storeMutation', response.data)
                this.commit('manualHome/change_ZLM_storeMutation', response.data)
                
            })
            .catch(() => { })
    },

    change_EVAC_Typ_selection_storeAction({ commit, dispatch }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/
        
        

        if (value.selected == true) {
            commit('change_EVAC_Typ_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_EVAC_Typ_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.EVAC_Typ_list.findIndex(i => (i.selected == true));
        
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_EVAC_Typ_selection_storeMutation', state.EVAC_Typ_list[firstIndex_whichMeetConditions].text.text);
            commit('change_EVAC_TypUI_selection_storeMutation', state.EVAC_Typ_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_EVAC_MNTyp_selection_storeMutation', state.EVAC_Typ_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_EVAC_Typ_selection_storeMutation', null);
            commit('change_EVAC_TypUI_selection_storeMutation', null);
            commit('change_EVAC_MNTyp_selection_storeMutation', null);
        }

        state.EVAC_Bal_select = null;
        state.EVAC_Batt_select = null;
        state.EVAC_Cable_select = null;
        state.EVAC_Control_Cable_select = null;
        
        dispatch('get_EVAC_Batt_storeAction');
        dispatch('get_EVAC_Bal_storeAction');
        dispatch('get_EVAC_Cable_storeAction');
        dispatch('get_EVAC_Control_Cable_storeAction');
       

    },  
    change_EVAC_Batt_selection_storeAction({ commit }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/
        if (value.selected == true) {
            commit('change_EVAC_Batt_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_EVAC_Batt_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.EVAC_Batt_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_EVAC_Batt_selection_storeMutation', state.EVAC_Batt_list[firstIndex_whichMeetConditions].text.text);
            commit('change_EVAC_BattUI_selection_storeMutation', state.EVAC_Batt_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_EVAC_MNBatt_selection_storeMutation', state.EVAC_Batt_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_EVAC_Batt_selection_storeMutation', null);
            commit('change_EVAC_BattUI_selection_storeMutation', null);
            commit('change_EVAC_MNBatt_selection_storeMutation', null);
        }


        //dispatch('get_EVAC_Batt_storeAction');
        //dispatch('get_EVAC_Bal_storeAction');
        //dispatch('get_EVAC_Cable_storeAction')
        //dispatch('get_EVAC_Control_Cable_storeAction')


    },  
    change_EVAC_Cable_selection_storeAction({ commit }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/
        
        if (value.selected == true) {
            commit('change_EVAC_Cable_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_EVAC_Cable_checkboxSelection_storeMutation', value.text.text);
        }
 
        const firstIndex_whichMeetConditions = state.EVAC_Cable_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_EVAC_Cable_selection_storeMutation', state.EVAC_Cable_list[firstIndex_whichMeetConditions].text.text);
            commit('change_EVAC_CableUI_selection_storeMutation', state.EVAC_Cable_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_EVAC_MNCable_selection_storeMutation', state.EVAC_Cable_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_EVAC_Cable_selection_storeMutation', null);
            commit('change_EVAC_CableUI_selection_storeMutation', null);
            commit('change_EVAC_MNCable_selection_storeMutation', null);
        }

    }, 
    change_EVAC_Control_Cable_selection_storeAction({ commit }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/

        if (value.selected == true) {
            commit('change_EVAC_Control_Cable_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_EVAC_Control_Cable_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.EVAC_Control_Cable_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_EVAC_Control_Cable_selection_storeMutation', state.EVAC_Control_Cable_list[firstIndex_whichMeetConditions].text.text);
            commit('change_EVAC_Control_CableUI_selection_storeMutation', state.EVAC_Control_Cable_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_EVAC_MNControl_Cable_selection_storeMutation', state.EVAC_Control_Cable_list[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_EVAC_Control_Cable_selection_storeMutation', null);
            commit('change_EVAC_Control_CableUI_selection_storeMutation', null);
            commit('change_EVAC_MNControl_Cable_selection_storeMutation', null);
        }

    },  
    change_EVAC_Bal_selection_storeAction({ commit, rootState }, value) {
        /*console.log('change_FI_Type_selection_storeAction', value);*/

        if (value.selected == true) {
            commit('change_EVAC_Bal_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_EVAC_Bal_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.EVAC_Bal_list.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_EVAC_Bal_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions].text.text);
            commit('change_EVAC_BalUI_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions].text.textUI);
            commit('change_EVAC_MNBal_selection_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions].id);

            //if (rootState.manualHome.LNG == null) {
            //    this.commit('manualHome/change_LNG_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions].text.text);
            //}

            if (rootState.manualHome.LNG == null) {
                this.commit('manualHome/change_LNG_storeMutation', state.EVAC_Bal_list[firstIndex_whichMeetConditions].text.textUI.toLowerCase());
            }

        }
        else {
            commit('change_EVAC_Bal_selection_storeMutation', null);
            commit('change_EVAC_BalUI_selection_storeMutation', null);
            commit('change_EVAC_MNBal_selection_storeMutation', null);
        }

    }, 
    change_inputs_EVAC_toDefault_storeAction({ commit }) {

        commit('change_EVAC_toDefault_selection_storeMutation');
    },
    change_FI_fuer_suche_verwenden_selection_storeAction({ commit, dispatch }, value) {
       
        commit('change_FI_fuer_suche_verwenden_selection_storeMutation', value)
        dispatch('get_EVAC_Typ_storeAction')
    },
}


const mutations = {
    get_EVAC_Typ_storeMutation(state, payload) {
        state.EVAC_Typ_list = payload;
    },
   change_EVAC_Typ_selection_storeMutation(state, payload) {
       state.EVAC_Typ_select = payload;
    },
    change_EVAC_TypUI_selection_storeMutation(state, payload) {
        state.EVAC_TypUI_select = payload;
    },
    change_EVAC_MNTyp_selection_storeMutation(state, payload) {
        state.EVAC_MNTyp_select = payload;
    },
    get_EVAC_Batt_storeMutation(state, payload) {
        state.EVAC_Batt_list = payload;
    },
    change_EVAC_Batt_selection_storeMutation(state, payload) {
        state.EVAC_Batt_select = payload;
    },
    change_EVAC_BattUI_selection_storeMutation(state, payload) {
        state.EVAC_BattUI_select = payload;
    },
    change_EVAC_MNBatt_selection_storeMutation(state, payload) {
        state.EVAC_MNBatt_select = payload;
    },
    get_EVAC_Bal_storeMutation(state, payload) {
        state.EVAC_Bal_list = payload;
    },
    change_EVAC_Bal_selection_storeMutation(state, payload) {
        state.EVAC_Bal_select = payload;
    },
    change_EVAC_BalUI_selection_storeMutation(state, payload) {
        state.EVAC_BalUI_select = payload;
    },
    change_EVAC_MNBal_selection_storeMutation(state, payload) {
        state.EVAC_MNBal_select = payload;
    },
    get_EVAC_Cable_storeMutation(state, payload) {
        state.EVAC_Cable_list = payload;
    },
    change_EVAC_Cable_selection_storeMutation(state, payload) {
        state.EVAC_Cable_select = payload;
    },
    change_EVAC_CableUI_selection_storeMutation(state, payload) {
        state.EVAC_CableUI_select = payload;
    },
    change_EVAC_MNCable_selection_storeMutation(state, payload) {
        state.EVAC_MNCable_select = payload;
    },
    get_EVAC_Control_Cable_storeMutation(state, payload) {
        state.EVAC_Control_Cable_list = payload
    },
    change_EVAC_Control_Cable_selection_storeMutation(state, payload) {
        state.EVAC_Control_Cable_select = payload;
    },
    change_EVAC_Control_CableUI_selection_storeMutation(state, payload) {
        state.EVAC_Control_CableUI_select = payload;
    },
    change_EVAC_MNControl_Cable_selection_storeMutation(state, payload) {
        state.EVAC_MNControl_Cable_select = payload;
    },
    change_EVAC_toDefault_selection_storeMutation(state) {
        state.EVAC_Typ_select = null;
        state.EVAC_TypUI_select = null;
        state.EVAC_MNTyp_select = null;
        state.EVAC_Batt_select = null;
        state.EVAC_BattUI_select = null;
        state.EVAC_MNBatt_select = null;
        state.EVAC_Bal_select = null;
        state.EVAC_BalUI_select = null; 
        state.EVAC_MNBal_select = null;
        state.EVAC_Cable_select = null;
        state.EVAC_CableUI_select = null;
        state.EVAC_MNCable_select = null;
        state.EVAC_Control_Cable_select = null;
        state.EVAC_Control_CableUI_select = null;
        state.EVAC_MNControl_Cable_select = null;

        //for (let i = 0; i < state.EVAC_Typ_list.length; i++) {
        //    state.EVAC_Typ_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_TypUI_list.length; i++) {
        //    state.EVAC_TypUI_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_MNTyp_list.length; i++) {
        //    state.EVAC_MNTyp_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_Batt_list.length; i++) {
        //    state.EVAC_Batt_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_Cable_list.length; i++) {
        //    state.EVAC_Cable_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_Control_Cable_list.length; i++) {
        //    state.EVAC_Control_Cable_list[i].selected = false;
        //}
        //for (let i = 0; i < state.EVAC_Bal_list.length; i++) {
        //    state.EVAC_Bal_list[i].selected = false;
        //}
        state.EVAC_Typ_list = [];
        state.EVAC_TypUI_list = [];
        state.EVAC_MNTyp_list = [];
        state.EVAC_Batt_list = [];
        state.EVAC_Cable_list = [];
        state.EVAC_Control_Cable_list = [];
        state.EVAC_Bal_list = [];
    },
    change_FI_fuer_suche_verwenden_selection_storeMutation(state, payload) {
        state.FI_fuer_suche_verwenden = payload
    },
    change_EVAC_Typ_checkboxSelection_storeMutation(state, payload) {
        /*debugger;*/
        for (let i = 0; i < state.EVAC_Typ_list.length; i++) {
            state.EVAC_Typ_list[i].selected = false;

            if (state.EVAC_Typ_list[i].text.text == payload) {
                state.EVAC_Typ_list[i].selected = true;
            }
        }
    },
    change_EVAC_Batt_checkboxSelection_storeMutation(state, payload) {
        /*debugger;*/
        for (let i = 0; i < state.EVAC_Batt_list.length; i++) {
            state.EVAC_Batt_list[i].selected = false;

            if (state.EVAC_Batt_list[i].text.text == payload) {
                state.EVAC_Batt_list[i].selected = true;
            }
        }
    },
    change_EVAC_Cable_checkboxSelection_storeMutation(state, payload) {
        /*debugger;*/
        for (let i = 0; i < state.EVAC_Cable_list.length; i++) {
            state.EVAC_Cable_list[i].selected = false;

            if (state.EVAC_Cable_list[i].text.text == payload) {
                state.EVAC_Cable_list[i].selected = true;
            }
        }
    },
    change_EVAC_Control_Cable_checkboxSelection_storeMutation(state, payload) {
        /*debugger;*/
        for (let i = 0; i < state.EVAC_Control_Cable_list.length; i++) {
            state.EVAC_Control_Cable_list[i].selected = false;

            if (state.EVAC_Control_Cable_list[i].text.text == payload) {
                state.EVAC_Control_Cable_list[i].selected = true;
            }
        }
    },
    change_EVAC_Bal_checkboxSelection_storeMutation(state, payload) {
        /*debugger;*/
        for (let i = 0; i < state.EVAC_Bal_list.length; i++) {
            state.EVAC_Bal_list[i].selected = false;

            if (state.EVAC_Bal_list[i].text.text == payload) {
                state.EVAC_Bal_list[i].selected = true;
            }
        }
    },

}
export const EVAC = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}