import axios from 'axios';
import router from "@/router";

let state = {
    summary: {},
    ZLM: null,
    LNG: null,
    showInverterDetails: false,
    showEVACDetails: false,
    showZArecDetails: false,

    showTemplateButton: false,
    zlwNumber: null,
    showModalZlw: false,
    /*LNG: '10. German'*/
}

const actions = {
    change_ZLM_storeAction({ commit, dispatch }, value) {
        commit('change_ZLM_storeMutation', value);
        if (state.ZLM != null) {
            sessionStorage.setItem('zlm', state.ZLM);
            dispatch('check_ZLM_Modifiable_storeAction');
        }
        else {
            if (sessionStorage.getItem('zlm')) {
                sessionStorage.removeItem('zlm')
            }
            this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', null);
            commit('change_showTemplateButton_storeMutation', false);
        }

    },
    check_ZLM_storeAction({ commit, dispatch }) {
        if (sessionStorage.getItem('zlm')) {
            commit('change_ZLM_storeMutation', sessionStorage.getItem('zlm'));
            dispatch('check_ZLM_Modifiable_storeAction');
        }
        else {
            commit('change_showTemplateButton_storeMutation', false);
        }
    },

    async check_ZLM_Modifiable_storeAction({ dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        const zlm = state.ZLM;
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_ZLM_Modifiable/' + zlm,
        })
            .then((response) => {
                dispatch('change_showTemplateButton_storeAction', response.data)
            })
            .catch(() => {
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    check_configuration_Exist_storeAction({ commit, dispatch, rootState }) {
        const configurationOverviewsId = sessionStorage.getItem('id');
        this.commit('configurations/change_configurationOverviewsId_storeMutation', configurationOverviewsId)
        if (rootState.configurations.configurationOverviewsId != null) {
            dispatch('get_ZlwDataFromSql_storeAction');
        }
        else {
            dispatch('check_ZLM_Exist_storeAction', state.ZLM);
            //dispatch('get_ZlmDataFromSql_storeAction');
        }
    },
    async check_ZLM_Exist_storeAction({ dispatch }, value) {
        dispatch('change_ZlwDataToDefault_storeAction');
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        const zlm = value;
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_ZLM_Exist/' + zlm,
        })
            .then((response) => {
                var integerZLMNumber = parseInt(response.data);
                if (!isNaN(integerZLMNumber)) {
                    dispatch('change_ZLM_storeAction', integerZLMNumber);
                    dispatch('get_ZlmDataFromSql_storeAction');
                }
                else {
                    dispatch('change_ZLM_storeAction', null);
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            })
            .catch(() => {
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    get_configurationOverviewsId_storeAction() {
        const configurationOverviewsId = sessionStorage.getItem('id');
        commit('change_configurationOverviewsId_storeMutation', configurationOverviewsId)
    },
    change_ZlwNumber_storeAction({ commit }, value) {
        commit('change_ZlwNumber_storeMutation', value);
    },

    change_ZlwDataToDefault_storeAction({ commit, dispatch }) {
        this.dispatch('configurations/remove_configurationOverviewsId_at_sessionStorage_storeAction');
        dispatch('change_ZlwNumber_storeAction', null);
    },

    async check_zlwNumber_storeAction({ dispatch }, value) {
        const zlw = value;
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_zlwNumber/' + zlw,
        })
            .then((response) => {
                dispatch('change_ZLM_storeAction', null);
                this.dispatch('configurations/set_configurationOverviewsId_at_sessionStorage_storeAction', response.data.data.configurationOverviewsId);
                dispatch('change_ZlwNumber_storeAction', response.data.data.zAlift_ID);
                this.dispatch('summary/select_databaseRecords_for_manualHome_storeAction');
                dispatch('change_showModalZlw_storeAction', false);

                dispatch('get_ZlwDataFromSql_storeAction');
            })
            .catch(() => {
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    change_showModalZlw_storeAction({ commit }, value) {
        commit('change_showModalZlw_storeMutation', value);
    },
    async check_zlmTemplate_storeAction({ dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        const zlm = state.ZLM;
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_zlmTemplate/' + zlm,
        })
            .then((response) => {
                var integerZLMNumber = parseInt(response.data);
                if (!isNaN(integerZLMNumber)) {
                    dispatch('change_ZLM_storeAction', integerZLMNumber);
                    dispatch('get_ZlmDataFromSql_storeAction');
                }
                else {
                    dispatch('change_ZLM_storeAction', null);
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            })
            .catch(() => {
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    change_showTemplateButton_storeAction({ commit }, modifiable) {
        if (modifiable == false) {
            commit('change_showTemplateButton_storeMutation', true);
        }
        else {
            commit('change_showTemplateButton_storeMutation', false);
        }
    },

    get_ZlmDataFromSql_storeAction({ dispatch, rootState }) {
        if (rootState.inputsManual.configurationStepsManualId == null && state.ZLM != null) {
            if (sessionStorage.getItem('configurationStepsManualId')) {
                this.commit('inputsManual/change_configurationStepsManualId_storeMutation', sessionStorage.getItem('configurationStepsManualId'));
            }
        }
        if (rootState.inputsManual.configurationStepsManualId != null) {
            this.dispatch('inputsManual/check_configurationMotorManual_storeAction', rootState.inputsManual.configurationStepsManualId);
        }
        this.dispatch('inputsManual/set_inputManual_toDefault_storeAction');
        this.dispatch('driveTechnologiesManual/change_driveTechnologies_toDefault_storeAction');
        this.dispatch('cables/change_cablesManual_toDefault_storeAction');
        this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction')
        this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', null)

        this.dispatch('inputsManual/loadAllDataMotorManual_storeAction');
    },
    get_ZlwDataFromSql_storeAction({ dispatch, rootState }) {
        if (rootState.inputsManual.configurationStepsManualId == null && state.ZLM != null) {
            if (sessionStorage.getItem('configurationStepsManualId')) {
                this.commit('inputsManual/change_configurationStepsManualId_storeMutation', sessionStorage.getItem('configurationStepsManualId'));
            }
        }

        this.dispatch('inputsManual/set_inputManual_toDefault_storeAction');
        this.dispatch('driveTechnologiesManual/change_driveTechnologies_toDefault_storeAction');
        this.dispatch('cables/change_cablesManual_toDefault_storeAction');
        this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction')
        this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', null)

        this.dispatch('inputsManual/loadAllDataMotorManualZlw_storeAction');
    },

}


const mutations = {


    change_ZLM_storeMutation(state, payload) {
        state.ZLM = payload;
    },
    change_LNG_storeMutation(state, payload) {
        state.LNG = payload;
    },
    change_showInverterDetails_storeMutation(state, payload) {
        state.showInverterDetails = payload;
    },
    change_showEVACDetails_storeMutation(state, payload) {
        state.showEVACDetails = payload;
    },
    change_showZArecDetails_storeMutation(state, payload) {
        state.showZArecDetails = payload;
    },
    change_showTemplateButton_storeMutation(state, payload) {
        state.showTemplateButton = payload;
    },
    change_ZlwNumber_storeMutation(state, payload) {
        state.zlwNumber = payload;
    },
    change_showModalZlw_storeMutation(state, payload) {
        state.showModalZlw = payload;
    },
}
export const manualHome = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}