<template>
    <div>
        <div v-bind:class="{configurationStep_navigation_relative: scrollYPosition <= 72, configurationStep_navigation_fixed: scrollYPosition > 72}" style="height:54px; background-color:#ffffff; border-bottom:1px solid #ced2d8">
            <div v-if="MANU == false && plusManual == false && manualConfiguration == false" style="margin-left:calc(4% - 40px); margin-right:4%;">
                <ul class="configurationStep_navigation ZAfont_family">
                    <li @click="change_configurationStep('inputs')" v-bind:class="{active: routeSlug=='inputs', passed: navigation.inputs=='passed'}"><span style="align-self:center">1. {{$t('3023')}}</span></li>
                    <li @click="change_configurationStep('driveTechnologies')" v-bind:class="{active: routeSlug=='driveTechnologies', passed: navigation.driveTechnologies=='passed', disabled: navigation.driveTechnologies=='disabled'}">2. {{$t('3024')}}</li>
                    <li @click="change_configurationStep('controlTechnologies')" v-bind:class="{active: routeSlug=='controlTechnologies', passed: navigation.controlTechnologies=='passed', disabled: navigation.controlTechnologies=='disabled'}">3. {{$t('3025')}}</li>
                    <li v-if="configurationStepFrame==true" @click="change_configurationStep('frames')" v-bind:class="{active: routeSlug=='frames',passed: navigation.frames=='passed', disabled: navigation.frames=='disabled'}">4. {{$t('3255')}} </li>
                    <li style="border-bottom:none"><button @click="change_configurationStep('summary')" class="btn my_btn_standard_blue" :disabled="navigation.summary=='disabled'">{{$t('3026')}}</button></li>
                </ul>
            </div>
            <div v-if="MANU == true && elevatorMotor_Manual == true" style="margin-left:calc(4% - 40px); margin-right:4%;">
                <ul class="configurationStep_navigation ZAfont_family">
                    <li @click="change_configurationStepManual('inputsManual')" v-bind:class="{active: routeSlug=='inputsManual', passed: navigationManual.inputsManual=='passed'}"><span style="align-self:center">1. {{$t('3023')}}</span></li>
                    <li @click="change_configurationStepManual('driveTechnologiesManual')" v-bind:class="{active: routeSlug=='driveTechnologiesManual', passed: navigationManual.driveTechnologiesManual=='passed', disabled: navigationManual.driveTechnologiesManual=='disabled'}">2. {{$t('3024')}}</li>
                    <li @click="change_configurationStepManual('cables')" v-bind:class="{active: routeSlug=='cables', passed: navigationManual.cablesManual=='passed', disabled: navigationManual.cablesManual=='disabled'}">3. {{$t('3435')}}</li>
                    <li @click="change_configurationStepManual('suppliesMotorManual')" v-bind:class="{active: routeSlug=='suppliesMotorManual', passed: navigationManual.suppliesMotorManual=='passed', disabled: navigationManual.suppliesMotorManual=='disabled'}" >4. {{$t('3462')}}</li>
                    <li @click="change_configurationStepManual('packagingMotorManual')" v-bind:class="{active: routeSlug=='packagingMotorManual',passed: navigationManual.packagingMotorManual=='passed', disabled: navigationManual.packagingMotorManual=='disabled'}">5. {{$t('3465')}}</li>
                </ul>
            </div>
        </div>
        <div v-bind:class="{my_layout: scrollYPosition <= 72, my_layout_at_fixed_configurationStep_navigation: scrollYPosition > 72}">
            <Inputs v-if="routeSlug == 'inputs'"></Inputs>
            <DriveTechnologies v-if="routeSlug == 'driveTechnologies'"></DriveTechnologies>
            <ControlTechnologies v-if="routeSlug == 'controlTechnologies'"></ControlTechnologies>
            <Frames v-if="routeSlug == 'frames'"></Frames>
            <Cables v-if="routeSlug == 'cables'"></Cables>
            <ConfigurationSummary v-if="routeSlug == 'summary'"></ConfigurationSummary>
            <ManualHome v-if="routeSlug == 'ManualHome'"></ManualHome>
            <FI v-if="routeSlug == 'FI'"></FI>
            <EVAC v-if="routeSlug == 'EVAC'"></EVAC>
            <ZArec v-if="routeSlug == 'ZArec'"></ZArec>
            <!--<RLD v-if="routeSlug == 'RLD'"></RLD>-->
            <InputsManual v-if="routeSlug == 'inputsManual'"></InputsManual>
            <DriveTechnologiesManual v-if="routeSlug == 'driveTechnologiesManual'"></DriveTechnologiesManual>
            <SuppliesMotorManual v-if="routeSlug == 'suppliesMotorManual'"></SuppliesMotorManual>
            <PackagingMotorManual v-if="routeSlug == 'packagingMotorManual'"></PackagingMotorManual>
        </div>
    </div>
</template>

<script>
    import router from '../../router';
    import Inputs from '../configuration/configurationSteps/Inputs';
    import DriveTechnologies from '../configuration/configurationSteps/DriveTechnologies';
    import ControlTechnologies from '../configuration/configurationSteps/ControlTechnologies';
    import Frames from '../configuration/configurationSteps/Frames'
    import ConfigurationSummary from '../configuration/configurationSteps/Summary';
    import { configurations } from '../../store/configurations.module';
    //import FI from '../configuration/configurationSteps/FI';
    import ManualHome from '../configuration/manualSteps/ManualHome';
    import FI from '../configuration/manualSteps/FI';
    import EVAC from '../configuration/manualSteps/EVAC';
    import ZArec from '../configuration/manualSteps/ZArec';
    /*import RLD from '../configuration/manualSteps/RLD';*/
    import InputsManual from '../configuration/manualSteps/InputsManual';
    import DriveTechnologiesManual from '../configuration/manualSteps/DriveTechnologiesManual';
    import Cables from '../configuration/manualSteps/Cables';
    import SuppliesMotorManual from '../configuration/manualSteps/SuppliesMotorManual';
    import PackagingMotorManual from '../configuration/manualSteps/PackagingMotorManual';

    export default {
        name: 'Configuration',

        components: {
            Inputs,
            DriveTechnologies,
            ControlTechnologies,
            Frames,
            ConfigurationSummary,
            ManualHome,
            FI,
            EVAC,
            ZArec,
           /* RLD,*/
            InputsManual,
            DriveTechnologiesManual,
            Cables,
            SuppliesMotorManual,
            PackagingMotorManual,
        },

        data() {
            return {

                scrollYPosition: 0
            }
        },
        computed: {
            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                },
            },
            MANU: {
                get() {
                    return this.$store.state.configurations.MANUAL;
                },
            },
            elevatorMotor_Manual: {
                get() {
                    return this.$store.state.configurations.elevatorMotor_Manual;
                },
            },
            manualConfiguration: {
                get() {
                    return this.$store.state.configurations.manualConfiguration;
                },
            },
            routeSlug: {
                get() {
                    return this.$route.params.slug;
                },
            },

            navigation: {
                get() {
                    return this.$store.state.configurations.navigation;
                },
            },
            configurationStepFrame: {
                get() {
                    return this.$store.state.configurations.configurationSteps.frame.storedInDatabase;
                },
            },
            disabledNotUsedTab: {
                get() {
                    return "disabled";
                },
            },
            navigationManual: {
                get() {
                    return this.$store.state.configurations.navigationManual;
                },
            },

        },

        created() {
            window.addEventListener('scroll', this.change_scrollYPosition);
        },
        destroyed() {
            window.removeEventListener('scroll', this.change_scrollYPosition);
        },

        methods: {
            change_configurationStep(value) {
                router.push({ params: { slug: value } })
            },
            change_configurationStepManual(value) {
                this.$store.dispatch('configurations/change_configurationStepManual_storeAction', value);
            },

            change_scrollYPosition() {
                this.scrollYPosition = window.scrollY;
            }
        },


    }
</script>

<style>
    .configurationStep_navigation {
        list-style: none;
        display: flex;
        align-items: center;
        height: 54px;
        cursor: pointer;
    }

        .configurationStep_navigation li {
            border-bottom: 4px solid #aac3e1;
            padding: 11px 0;
            margin: 0 20px;
            width: 25%;
            text-align: center;
            font-weight: 500;
            font-size: 1.1rem;
        }

            .configurationStep_navigation li.active {
                border-bottom-color: #00338e !important;
                color: #00338e !important;
            }

            .configurationStep_navigation li.passed {
                border-bottom-color: #2eb85c;
            }

            .configurationStep_navigation li.disabled {
                border-bottom-color: #87888a;
                color: #87888a;
            }

    .configurationStep_navigation_fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
    }

    .configurationStep_navigation_relative {
        position: relative;
    }
</style>
