import axios from 'axios';
import router from '../router';

let state = {

    customerName: 'ZA',

    // motor
    customerNameSelection: [],
    motorTypeSelection: [],
    motorSizeSelection: [],
    motorPackageSelection: [],
    motorZusatzSelection: {},
    motorVoltageSelection: [],
    motorRatedSpeedSelection: [],
    certificationSelection: [],
    preferenceSelection: [],
    forcedVentilationSelection: [],
    temperatureMonitoringSelection: [],
    upgradeSelection: [],

    note: undefined,
    cmd: undefined,
    projekt: undefined,
    sanum: undefined,
    inok: undefined,
    onok: undefined,
    mvlift: undefined,
    mis: undefined,
    customerName: undefined,
    motorType: undefined,
    motorSize: undefined,
    motorPackage: undefined,
    motorZusatz: undefined,
    motorVoltage: undefined,
    motorRatedSpeed: undefined,
    certification: undefined,
    preference: undefined,
    forcedVentilation: undefined,
    temperatureMonitoring: undefined,

    operatingCurrentSplus: { value: undefined, invalid: undefined },

    sM250_TS280: false,

    // Preselection traction sheave
    deliverTractionSheaveSelection: [],
    machiningForHandWheelSelection: [],
    tractionSheave_DiameterAndWidthSelection: [],
    sheaveProfileSelection: [],
    ropeDiameterSelection: [],
    undercutAngleSelection: [],
    vgrooveAngleSelection: [],
    grooveDistanceSelection: [],
    numberOfGroovesSelection: [],

    deliverTractionSheave: undefined,
    showTractionSheaveDetails: true,
    machiningForHandWheel: undefined,
    tractionsheave_DiameterAndWidth: undefined,
    sheaveProfile: undefined,
    ropeDiameter: undefined,
    undercutAngle: undefined,
    vgrooveAngle: undefined,
    grooveDistance: undefined,
    numberOfGrooves: undefined,

    // Rope guard
    customerIdKidSelection: [],
    positionMotorSelection: [],
    ropePullSelection: [],
    adjustabilitySelection: [],
    thirdRopeGuard: undefined,
    tnruev: undefined,
    imagePathRopeGuard: null,
    deliverRopeSelection: [],
    tractionSheaveCoverSelection: {},
    ropeClampSelection: {},
    rescueReturnDeviceSelection: {},
    deliverRopeDisabled: false,

    customerIdKid: undefined,
    positionMotor: undefined,
    ropePull: undefined,
    adjustability: undefined,
    deliverRope: undefined,
    tractionSheaveCover: undefined,
    ropeClamp: undefined,
    rescueReturnDevice: undefined,

    customerIdKid_Copy: undefined,
    positionMotor_Copy: undefined,
    ropePull_Copy: undefined,
    adjustability_Copy: undefined,

    preselectionMotorManual: {},

    motorManualData: {},
    showMotorDetails: true,
    

    configurationStepsManualId: null,

    // SCH-MOD Modul
    schmod_locationProductionSelection: [],
    schmod_ProdApSelection: [],
    schmod_PSGSizeSelection: [],
    schmod_motorIDSelection: [],
    schmod_motorCOSNameSelection: [],
    schmod_motorVoltageSelection: [],
    schmod_inverterTypeSelection: [],
    schmod_cableLengthSelection: [],
    schmod_cableLengthBrakeSelection: [],
    schmod_handWheelSelection: [],
    schmod_handReleaseSelection: [],
    schmod_grooveSelection: [],
    schmod_numberOfGroovesSelection: [],
    schmod_certificationSelection: [],


    schmod_locationProduction: undefined,
    schmod_ProAp: undefined,
    schmod_PSGSize: undefined,
    schmod_motorID: undefined,
    schmod_motorCOSName: undefined,
    schmod_motorVoltage: undefined,
    schmod_inverterType: undefined,
    schmod_cableLength: undefined,
    schmod_cableLengthBrake: undefined,
    schmod_handWheel: undefined,
    schmod_handRelease: undefined,
    schmod_grooveDistance: { value: undefined, invalid: undefined },
    schmod_groove: undefined,
    schmod_numberOfGrooves: undefined,
    schmod_certification: undefined,
    schModDetails: undefined,

    schmod_Valid: false,

    schmodMotordetails: undefined,
    showSCHMOD: false,
}

const actions = {
    set_inputsManual_storeAction({ rootState, dispatch }) {
        dispatch('check_configurationStepsManualId_storeAction');
        this.dispatch('manualHome/check_ZLM_storeAction');

        if (rootState.manualHome.ZLM != null) {
            dispatch('get_configurationStepsId_storeAction', rootState.manualHome.ZLM);
        }
        else {
            dispatch('get_customerNameSelection_storeAction');
        }
    },

    async get_configurationStepsId_storeAction({ rootState, commit, dispatch }, value) {
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_configurationStepsId/' + value,
        })
            .then((response) => {
                if (response.data.data.configurationStepsManualId != null) {
                    dispatch('change_configurationStepsManualId_storeAction', response.data.data.configurationStepsManualId);
                    if (response.data.data.dataAvailableInDatabase) {
                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.inputsManual = 'passed';
                        navigationManual.driveTechnologiesManual = 'passed';
                        navigationManual.cablesManual = 'passed';
                        navigationManual.suppliesMotorManual = 'passed';
                        navigationManual.packagingMotorManual = 'passed';
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                    //dispatch('get_customerNameSelection_storeAction');
                    dispatch('get_allInputData_storeAction', value);
                }
                else {
                    dispatch('change_configurationStepsManualId_storeAction', null);
                    dispatch('get_customerNameSelection_storeAction');
                }
            })
            .catch(() => {
            })
    },

    async get_allInputData_storeAction({ commit }, value) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_allInputData/' + value,
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    // motor
                    commit('change_cmd_storeMutation', response.data.data.cmd);
                    commit('change_note_storeMutation', response.data.data.notiz);
                    commit('change_projekt_storeMutation', response.data.data.projekt);
                    commit('change_sanum_storeMutation', response.data.data.sanum);
                    commit('change_onok_storeMutation', response.data.data.onok);
                    commit('change_inok_storeMutation', response.data.data.inok);
                    commit('change_mvlift_storeMutation', response.data.data.mvlift);
                    commit('change_mis_storeMutation', response.data.data.mis);
                    commit('change_schmod_Valid_storeMuation', response.data.data.schmodValid);

                    if (response.data.data.motordetails.customerNameSelection != null) {
                        commit('change_customerNameSelection_storeMutation', response.data.data.motordetails.customerNameSelection);
                        commit('change_customerName_storeMutation', response.data.data.kid);
                    }

                    if (response.data.data.motordetails.certificationSelection != null) {
                        commit('change_certificationSelection_storeMutation', response.data.data.motordetails.certificationSelection);
                        commit('change_certification_storeMutation', response.data.data.motordetails.cnZert);
                    }
                    if (response.data.data.motordetails.preferenceSelection != null) {
                        commit('change_preferenceSelection_storeMutation', response.data.data.motordetails.preferenceSelection);
                        commit('change_preference_storeMutation', response.data.data.motordetails.praef);
                    }
                    if (response.data.data.motordetails.temperatureMonitoringSelection != null) {
                        commit('change_temperatureMonitoringSelection_storeMutation', response.data.data.motordetails.temperatureMonitoringSelection);
                        commit('change_temperatureMonitoring_storeMutation', response.data.data.motordetails.msart);
                    }

                    if (response.data.data.motordetails.motorTypeSelection != null) {
                        commit('change_motorTypeSelection_storeMutation', response.data.data.motordetails.motorTypeSelection);
                        commit('change_motorType_storeMutation', response.data.data.motordetails.mtyp);
                    }

                    if (response.data.data.motordetails.motorTypeSelection != null) {
                        commit('change_motorTypeSelection_storeMutation', response.data.data.motordetails.motorTypeSelection);
                        commit('change_motorType_storeMutation', response.data.data.motordetails.mtyp);
                    }
                    if (response.data.data.motordetails.motorSizeSelection != null) {
                        commit('change_motorSizeSelection_storeMutation', response.data.data.motordetails.motorSizeSelection);
                        commit('change_motorSize_storeMutation', response.data.data.motordetails.mbg);
                    }
                    if (response.data.data.motordetails.motorPackageSelection != null) {
                        commit('change_motorPackageSelection_storeMutation', response.data.data.motordetails.motorPackageSelection);
                        commit('change_motorPackage_storeMutation', response.data.data.motordetails.mfe);
                    }
                    if (response.data.data.motordetails.motorZusatzSelection != null) {
                        commit('change_motorZusatzSelection_storeMutation', response.data.data.motordetails.motorZusatzSelection);
                        commit('change_motorZusatz_storeMutation', response.data.data.motordetails.mzusatz);
                    }
                    if (response.data.data.motordetails.motorVoltageSelection != null) {
                        commit('change_motorVoltageSelection_storeMutation', response.data.data.motordetails.motorVoltageSelection);
                        commit('change_motorVoltage_storeMutation', response.data.data.motordetails.mU1);
                    }
                    if (response.data.data.motordetails.motorRatedSpeedSelection != null) {
                        commit('change_motorRatedSpeedSelection_storeMutation', response.data.data.motordetails.motorRatedSpeedSelection);
                        commit('change_motorRatedSpeed_storeMutation', response.data.data.motordetails.mnr);
                    }

                    if (response.data.data.motordetails.upgradeSelection != null) {
                        commit('change_get_upgradeSelection_storeMutation', response.data.data.motordetails.upgradeSelection);
                    }

                    if (response.data.data.motordetails.forcedVentilationSelection != null) {
                        commit('change_forcedVentilationSelection_storeMutation', response.data.data.motordetails.forcedVentilationSelection);
                        commit('change_forcedVentilation_storeMutation', response.data.data.motordetails.mfbArt);
                    }

                    // traction sheave
                    commit('change_showTractionSheaveDetails_storeMutation', response.data.data.tractionSheavedetails.showTractionSheaveDetails)
                    if (response.data.data.tractionSheavedetails.deliverTractionSheaveSelection != null) {
                        commit('change_deliverTractionSheaveSelection_storeMutation', response.data.data.tractionSheavedetails.deliverTractionSheaveSelection);
                        commit('change_deliverTractionSheave_storeMutation', response.data.data.tractionSheavedetails.ltr);
                    }
                    if (response.data.data.tractionSheavedetails.machiningForHandWheelSelection != null) {
                        commit('change_machiningForHandWheelSelection_storeMutation', response.data.data.tractionSheavedetails.machiningForHandWheelSelection);
                        commit('change_machiningForHandWheel_storeMutation', response.data.data.tractionSheavedetails.haRa);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveDiameterAndWidthSelection != null) {
                        commit('change_tractionSheave_DiameterAndWidthSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveDiameterAndWidthSelection);
                        commit('change_tractionSheave_DiameterAndWidth_storeMutation', response.data.data.tractionSheavedetails.tD_TB);
                    }

                    if (response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection != null) {
                        commit('change_ropeDiameterSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection);
                        commit('change_ropeDiameter_storeMutation', response.data.data.tractionSheavedetails.tcsd);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection != null) {
                        commit('change_grooveDistanceSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection);
                        commit('change_grooveDistance_storeMutation', response.data.data.tractionSheavedetails.tdra);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection != null) {
                        commit('change_numberOfGroovesSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection);
                        commit('change_numberOfGrooves_storeMutation', response.data.data.tractionSheavedetails.tdrz);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection != null) {
                        commit('change_sheaveProfileSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection);
                        commit('change_sheaveProfile_storeMutation', response.data.data.tractionSheavedetails.tarill);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection != null) {
                        commit('change_undercutAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection);
                        commit('change_undercutAngle_storeMutation', response.data.data.tractionSheavedetails.tauwi);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection != null) {
                        commit('change_vgrooveAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection);
                        commit('change_vgrooveAngle_storeMutation', response.data.data.tractionSheavedetails.takwi);
                    }

                    // rope guard
                    if (response.data.data.ropeGuarddetails.customerNameRopeGuardSelection != null) {
                        commit('change_customerIdKidSelection_storeMutation', response.data.data.ropeGuarddetails.customerNameRopeGuardSelection);
                        commit('change_customerIdKid_storeMutation', response.data.data.ropeGuarddetails.kidsch);
                    }
                    if (response.data.data.ropeGuarddetails.positionMotorSelection != null) {
                        commit('change_positionMotorSelection_storeMutation', response.data.data.ropeGuarddetails.positionMotorSelection);
                        commit('change_positionMotor_storeMutation', response.data.data.ropeGuarddetails.fuesse);
                    }
                    if (response.data.data.ropeGuarddetails.ropePullSelection != null) {
                        commit('change_ropePullSelection_storeMutation', response.data.data.ropeGuarddetails.ropePullSelection);
                        commit('change_ropePull_storeMutation', response.data.data.ropeGuarddetails.seilab);
                    }
                    if (response.data.data.ropeGuarddetails.adjustabilitySelection != null) {
                        commit('change_adjustabilitySelection_storeMutation', response.data.data.ropeGuarddetails.adjustabilitySelection);
                        commit('change_adjustability_storeMutation', response.data.data.ropeGuarddetails.einst);
                    }
                    if (response.data.data.ropeGuarddetails.thirdRopeGuard != null) {
                        commit('change_thirdRopeGuard_storeMutation', response.data.data.ropeGuarddetails.thirdRopeGuard.thirdRopeGuard);
                        commit('change_imagePathRopeGuard_storeMutation', response.data.data.ropeGuarddetails.thirdRopeGuard.imagePathRopeGuard);
                        commit('change_tnruev_storeMutation', response.data.data.ropeGuarddetails.thirdRopeGuard.tnruev);

                    }
                    if (response.data.data.ropeGuarddetails.deliverRopeGuardSelection != null) {
                        commit('change_deliverRopeSelection_storeMutation', response.data.data.ropeGuarddetails.deliverRopeGuardSelection);
                        commit('change_deliverRope_storeMutation', response.data.data.ropeGuarddetails.lseils);
                    }
                    if (response.data.data.ropeGuarddetails.tractionSheaveCoverSelection != null) {
                        commit('change_tractionSheaveCoverSelection_storeMutation', response.data.data.ropeGuarddetails.tractionSheaveCoverSelection);
                        commit('change_tractionSheaveCover_storeMutation', response.data.data.ropeGuarddetails.ltsabd);
                    }
                    if (response.data.data.ropeGuarddetails.ropeClampSelection != null) {
                        commit('change_ropeClampSelection_storeMutation', response.data.data.ropeGuarddetails.ropeClampSelection);
                        commit('change_ropeClamp_storeMutation', response.data.data.ropeGuarddetails.ltklem);
                    }
                    if (response.data.data.ropeGuarddetails.rescueReturnDeviceSelection != null) {
                        commit('change_rescueReturnDeviceSelection_storeMutation', response.data.data.ropeGuarddetails.rescueReturnDeviceSelection);
                        commit('change_rescueReturnDevice_storeMutation', response.data.data.ropeGuarddetails.lruev);
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    change_note_storeAction({ commit }, value) {
        commit('change_note_storeMutation', value);
    },
    change_cmd_storeAction({ commit }, value) {
        commit('change_cmd_storeMutation', value);
    },
    change_projekt_storeAction({ commit }, value) {
        commit('change_projekt_storeMutation', value);
    },
    change_sanum_storeAction({ commit }, value) {
        commit('change_sanum_storeMutation', value);
    },
    change_inok_storeAction({ commit }, value) {
        commit('change_inok_storeMutation', value);
    },
    change_onok_storeAction({ commit }, value) {
        commit('change_onok_storeMutation', value);
    },
    change_mvlift_storeAction({ commit }, value) {
        commit('change_mvlift_storeMutation', value);
    },
    change_mis_storeAction({ commit }, value) {
        commit('change_mis_storeMutation', value);
    },
    async get_customerNameSelection_storeAction({ commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_customerNameSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_customerNameSelection_storeMutation', response.data);
                if (state.customerNameSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.customerNameSelection.findIndex(i => (i.text.text == state.customerName));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_customerName_storeAction', state.customerNameSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.customerNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_customerName_storeAction', state.customerNameSelection[firstIndex_whichMeetConditions]);
                        }
                    }
                }
                dispatch('check_inputBoxes_storeAction');
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_SCHMOD_customerNameSelection_storeAction({ commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_customerNameSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_customerNameSelection_storeMutation', response.data);
                commit('change_customerName_storeMutation', 'SCH-MOD');
                dispatch('get_certificationSelection_storeAction')
                dispatch('get_preferenceSelection_storeAction');
            })
            .catch(() => {
            })
            .then(() => {

            })
    },

    async check_inputBoxes_storeAction({ commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/check_inputBoxes_storeAction',
            data: {
                data: data,
            }
        })
            .then((response) => {
                if (response.data.data != null) {
                    dispatch('change_note_storeAction', response.data.data.note);
                    dispatch('change_cmd_storeAction', response.data.data.cmd);
                    dispatch('change_projekt_storeAction', response.data.data.projekt);
                    dispatch('change_sanum_storeAction', response.data.data.sanum);
                    dispatch('change_inok_storeAction', response.data.data.inok);
                    dispatch('change_onok_storeAction', response.data.data.onok);
                    dispatch('change_mvlift_storeAction', response.data.data.mvlift);
                    dispatch('change_mis_storeAction', response.data.data.mis);

                }
                else {
                    commit('change_note_storeMutation', null);
                    commit('change_cmd_storeMutation', null);
                    commit('change_projekt_storeMutation', null);
                    commit('change_sanum_storeMutation', null);
                    commit('change_inok_storeMutation', null);
                    commit('change_onok_storeMutation', null);
                    commit('change_mvlift_storeMutation', null);
                    commit('change_mis_storeMutation', null);
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    async get_motordetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            kid: state.customerName,
            temperatureMonitoring: state.temperatureMonitoring,
            mLS_Law: state.certification,
            preference: state.preference,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_motordetails',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (response.data.data.motordetails.motorTypeSelection != null) {
                        commit('change_motorTypeSelection_storeMutation', response.data.data.motordetails.motorTypeSelection);
                        const firstIndex_whichMeetConditions = state.motorTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorType_storeAction', state.motorTypeSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorTypeSelection_storeMutation', []);
                    }
                    if (response.data.data.motordetails.motorSizeSelection != null) {
                        commit('change_motorSizeSelection_storeMutation', response.data.data.motordetails.motorSizeSelection);
                        const firstIndex_whichMeetConditions = state.motorSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorSize_storeAction', state.motorSizeSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorSizeSelection_storeMutation', []);
                    }
                    if (response.data.data.motordetails.motorPackageSelection != null) {
                        commit('change_motorPackageSelection_storeMutation', response.data.data.motordetails.motorPackageSelection);
                        const firstIndex_whichMeetConditions = state.motorPackageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorPackage_storeAction', state.motorPackageSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorPackageSelection_storeMutation', []);
                    }
                    if (response.data.data.motordetails.motorZusatzSelection != null) {
                        commit('change_motorZusatzSelection_storeMutation', response.data.data.motordetails.motorZusatzSelection);
                        const firstIndex_whichMeetConditions = state.motorZusatzSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorZusatz_storeAction', state.motorZusatzSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorZusatzSelection_storeMutation', []);
                    }
                    if (response.data.data.motordetails.motorVoltageSelection != null) {
                        commit('change_motorVoltageSelection_storeMutation', response.data.data.motordetails.motorVoltageSelection);
                        const firstIndex_whichMeetConditions = state.motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorVoltage_storeAction', state.motorVoltageSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorVoltageSelection_storeMutation', []);
                    }
                    if (response.data.data.motordetails.motorRatedSpeedSelection != null) {
                        commit('change_motorRatedSpeedSelection_storeMutation', response.data.data.motordetails.motorRatedSpeedSelection);
                        const firstIndex_whichMeetConditions = state.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_motorRatedSpeed_storeAction', state.motorRatedSpeedSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_motorRatedSpeedSelection_storeMutation', []);
                    }

                    //dispatch('get_upgradeSelection_storeAction');
                }
                else {
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_certificationSelection_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_certificationSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_certificationSelection_storeMutation', response.data);
                if (state.certificationSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.certificationSelection.findIndex(i => (i.text.text == state.certification));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_certification_storeAction', state.certificationSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_certification_storeAction', state.certificationSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }

                    }
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_preferenceSelection_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_preferenceSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_preferenceSelection_storeMutation', response.data);
                if (state.preferenceSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.preferenceSelection.findIndex(i => (i.text.text == state.preference));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_preference_storeAction', state.preferenceSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.preferenceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_preference_storeAction', state.preferenceSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }

                    }
                }
                dispatch('get_temperatureMonitoringSelection_storeAction');
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_temperatureMonitoringSelection_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            kid: state.customerName,
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: configurationStepsManualId,
            cmd: state.cmd,
            mLS_Law: state.certification,
            preference: state.preference,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_temperatureMonitoringSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_temperatureMonitoringSelection_storeMutation', response.data);
                if (state.temperatureMonitoringSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.temperatureMonitoringSelection.findIndex(i => (i.text == state.temperatureMonitoring));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_temperatureMonitoring_storeAction', state.temperatureMonitoringSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.temperatureMonitoringSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_temperatureMonitoring_storeAction', state.temperatureMonitoringSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }

                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_upgradeSelection_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        const data = {
            kid: state.customerName,
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_upgradeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                dispatch('change_get_upgradeSelection_storeAction', response.data);
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_forcedVentilationSelection_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        var upgrade = null;
        const firstIndex_whichMeetConditions_upgrade = state.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_upgrade > -1) {
            upgrade = state.upgradeSelection[firstIndex_whichMeetConditions_upgrade].text;
        }

        const data = {
            kid: state.customerName,
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            upgrade: upgrade,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: configurationStepsManualId,
        }
        await axios({
            method: 'post',
            url: '/api/inputsManual/get_forcedVentilationSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_forcedVentilationSelection_storeMutation', response.data);
                if (state.forcedVentilationSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.forcedVentilationSelection.findIndex(i => (i.text.text == state.forcedVentilation));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_forcedVentilation_storeAction', state.forcedVentilationSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.forcedVentilationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_forcedVentilation_storeAction', state.forcedVentilationSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }
                    }
                }

            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    change_forcedVentilationSelection_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_tractionSheave_DiameterAndWidthSelected_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorType_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_motorSizeSelection_storeMutation', []);
        commit('change_motorPackageSelection_storeMutation', []);
        commit('change_motorZusatzSelection_storeMutation', {});
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorSize_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_motorPackageSelection_storeMutation', []);
        commit('change_motorZusatzSelection_storeMutation', {});
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorPackage_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_motorZusatzSelection_storeMutation', {});
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorZusatz_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorVoltage_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_motorRatedSpeed_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_temperatureMonitoring_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_upgradeSelected_setDefaultValues_storeAction({ commit, dispatch }, value) {
        commit('change_forcedVentilationSelection_storeMutation', []);
        commit('change_machiningForHandWheelSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
    },
    change_customerName_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        commit('change_schmod_Valid_storeMuation', false);

        commit('change_customerNameSelected_storeMutation', value.text.text);
        const firstIndex_whichMeetConditions = state.customerNameSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_customerName_storeMutation', state.customerNameSelection[firstIndex_whichMeetConditions].text.text)
            if (state.customerNameSelection[firstIndex_whichMeetConditions].text.text != "SCH-MOD") {
                dispatch('get_certificationSelection_storeAction')
                dispatch('get_preferenceSelection_storeAction');
            }
            else {
                //router.push({ params: { slug: 'psgMenu' } });
            }
        }
        else {
            commit('change_customerName_storeMutation', null)
            resetLoader = true;
        }
        if (resetLoader == true) {
        }

    },
    change_SM250_TS280_storeAction({ commit, dispatch }, value) {
        commit('change_sM250_TS280_storeMutation', value);
        dispatch('set_inputManual_Motor_toDefault2_storeAction');
        dispatch('get_temperatureMonitoringSelection_storeAction');
    },
    change_motorType_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_motorTypeSelected_storeMutation', null);
        }
        else {
            commit('change_motorTypeSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.motorTypeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorType_storeMutation', state.motorTypeSelection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_motorType_storeMutation', null);
            commit('change_motorSize_storeMutation', null);
            commit('change_motorPackage_storeMutation', null);
            commit('change_motorZusatz_storeMutation', null);
            commit('change_motorVoltage_storeMutation', null);
            commit('change_motorRatedSpeed_storeMutation', null);
            resetLoader = true;
        }

        if (state.motorSizeSelection != null && state.motorSizeSelection.length > 0) {
            commit('change_motorSize_Disabled_storeMuation', state.motorType);
            if (state.motorType != null) {
                const firstIndex_whichMeetConditions_motorSize = state.motorSizeSelection.findIndex(i => (i.text.text == state.motorSize && i.disabled == false && i.typ == state.motorType));
                if (firstIndex_whichMeetConditions_motorSize > -1) {
                    dispatch('change_motorSize_storeAction', state.motorSizeSelection[firstIndex_whichMeetConditions_motorSize]);
                }
                else {
                    dispatch('change_motorSize_storeAction', null);
                }
            }
        }
    },
    change_motorSize_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorSizeSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                typ: state.motorType,
                baug: value.text.text,
            }
            commit('change_motorSizeSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.motorSizeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorSize_storeMutation', state.motorSizeSelection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_motorSize_storeMutation', null);
            commit('change_motorPackage_storeMutation', null);
            commit('change_motorZusatz_storeMutation', null);
            commit('change_motorVoltage_storeMutation', null);
            commit('change_motorRatedSpeed_storeMutation', null);
        }

        if (state.motorPackageSelection != null && state.motorPackageSelection.length > 0) {
            const value2 = {
                typ: state.motorType,
                baug: state.motorSize,
            }
            commit('change_motorPackage_Disabled_storeMuation', value2);
            if (state.motorType != null && state.motorSize != null) {
                const firstIndex_whichMeetConditions_motorSize = state.motorPackageSelection.findIndex(i => (i.text.text == state.motorPackage && i.disabled == false && i.typ == state.motorType && i.baug == state.motorSize));
                if (firstIndex_whichMeetConditions_motorSize > -1) {
                    dispatch('change_motorPackage_storeAction', state.motorPackageSelection[firstIndex_whichMeetConditions_motorSize]);
                }
                else {
                    var filterdata = state.motorPackageSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_motorPackage_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_motorPackage_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.motorPackageSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_motorPackage_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_motorPackage_storeAction', null);
                }
            }
        }
    },
    change_motorPackage_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorPackageSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: value.text.text,
            }
            commit('change_motorPackageSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.motorPackageSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorPackage_storeMutation', state.motorPackageSelection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_motorPackage_storeMutation', null);
            commit('change_motorZusatz_storeMutation', null);
            commit('change_motorVoltage_storeMutation', null);
            commit('change_motorRatedSpeed_storeMutation', null);
        }

        if (state.motorZusatzSelection != null && state.motorZusatzSelection.length > 0) {
            const value2 = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: state.motorPackage,
            }
            commit('change_motorZusatz_Disabled_storeMuation', value2);
            if (state.motorType != null && state.motorSize != null && state.motorPackage != null) {
                const firstIndex_whichMeetConditions_motorSize = state.motorZusatzSelection.findIndex(i => (i.text.text == state.motorZusatz &&
                    i.disabled == false &&
                    i.typ == state.motorType &&
                    i.baug == state.motorSize &&
                    i.fe == state.motorPackage));

                if (firstIndex_whichMeetConditions_motorSize > -1) {
                    dispatch('change_motorZusatz_storeAction', state.motorZusatzSelection[firstIndex_whichMeetConditions_motorSize]);
                }
                else {
                    var filterdata = state.motorZusatzSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_motorZusatz_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_motorZusatz_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.motorZusatzSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_motorZusatz_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_motorZusatz_storeAction', null);
                }
            }
        }
    },
    change_motorZusatz_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (state.motorZusatzSelection != null) {

            var motorZusatzNecessary = false;
            if (Array.isArray(state.motorZusatzSelection)) {
                let motorZusatz_Selection = state.motorZusatzSelection.filter((x) => { return (x.disabled == false) });
                if (motorZusatz_Selection != null && motorZusatz_Selection.length > 0) {
                    const firstIndex_whichMeetConditions = motorZusatz_Selection.findIndex(i => (i.text.text == null));
                    if (firstIndex_whichMeetConditions > -1) {
                    }
                    else {
                        motorZusatzNecessary = true;
                    }
                }
            }
            if (motorZusatzNecessary == true) {
                if (value == null) {
                    commit('change_motorZusatzSelected_storeMutation', null);
                }
                else {
                    const valueSelected = {
                        typ: state.motorType,
                        baug: state.motorSize,
                        fe: state.motorPackage,
                        zusatz: value.text.text,
                    }
                    commit('change_motorZusatzSelected_storeMutation', valueSelected);
                }

                const firstIndex_whichMeetConditions = state.motorZusatzSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    commit('change_motorZusatz_storeMutation', state.motorZusatzSelection[firstIndex_whichMeetConditions].text.text)
                }
                else {
                    commit('change_motorZusatz_storeMutation', null);
                    commit('change_motorVoltage_storeMutation', null);
                    commit('change_motorRatedSpeed_storeMutation', null);
                    resetLoader = true;
                }
            }
            else {
                if (value == null) {
                    commit('change_motorZusatzSelected_storeMutation', null);
                }
                else {
                    const valueSelected2 = {
                        typ: state.motorType,
                        baug: state.motorSize,
                        fe: state.motorPackage,
                        zusatz: value.text.text,
                    }
                    commit('change_motorZusatzOptionalSelected_storeMutation', valueSelected2);
                }

                const firstIndex_whichMeetConditions = state.motorZusatzSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    commit('change_motorZusatz_storeMutation', state.motorZusatzSelection[firstIndex_whichMeetConditions].text.text)
                }
                else {
                    commit('change_motorZusatz_storeMutation', null);
                    commit('change_motorVoltage_storeMutation', null);
                    commit('change_motorRatedSpeed_storeMutation', null);
                    resetLoader = true;
                }

            }
        }
        else {
            commit('change_motorZusatz_storeMutation', null)
            resetLoader = true;
        }

        if (state.motorVoltageSelection != null && state.motorVoltageSelection.length > 0) {
            const value2 = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: state.motorPackage,
                zusatz: state.motorZusatz,
            }
            commit('change_motorVoltage_Disabled_storeMuation', value2);
            const firstIndex_whichMeetConditions_motorSize = state.motorVoltageSelection.findIndex(i => (i.value == state.motorVoltage &&
                i.disabled == false &&
                i.typ == state.motorType &&
                i.baug == state.motorSize &&
                i.fe == state.motorPackage &&
                i.zusatz == state.motorZusatz));

            if (firstIndex_whichMeetConditions_motorSize > -1) {
                dispatch('change_motorVoltage_storeAction', state.motorVoltageSelection[firstIndex_whichMeetConditions_motorSize]);
            }
            else {
                var filterdata = state.motorVoltageSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_motorVoltage_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_motorVoltage_storeAction', null);
                }
            }
        }

    },
    change_motorVoltage_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_motorVoltageSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: state.motorPackage,
                zusatz: state.motorZusatz,
                u1: value.value
            }
            commit('change_motorVoltageSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.motorVoltageSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorVoltage_storeMutation', state.motorVoltageSelection[firstIndex_whichMeetConditions].value)
        }
        else {
            commit('change_motorVoltage_storeMutation', null);
            commit('change_motorRatedSpeed_storeMutation', null);
            resetLoader = true;
        }

        if (state.motorRatedSpeedSelection != null && state.motorRatedSpeedSelection.length > 0) {
            const value2 = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: state.motorPackage,
                zusatz: state.motorZusatz,
                u1: state.motorVoltage,
            }
            commit('change_motorRatedSpeed_Disabled_storeMuation', value2);
            if (state.motorType != null && state.motorSize != null && state.motorPackage != null) {
                const firstIndex_whichMeetConditions_motorSize = state.motorRatedSpeedSelection.findIndex(i => (i.value == state.motorRatedSpeed &&
                    i.disabled == false &&
                    i.typ == state.motorType &&
                    i.baug == state.motorSize &&
                    i.fe == state.motorPackage &&
                    i.zusatz == state.motorZusatz &&
                    i.u1 == state.motorVoltage));

                if (firstIndex_whichMeetConditions_motorSize > -1) {
                    dispatch('change_motorRatedSpeed_storeAction', state.motorRatedSpeedSelection[firstIndex_whichMeetConditions_motorSize]);
                }
                else {
                    var filterdata = state.motorRatedSpeedSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_motorRatedSpeed_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_motorRatedSpeed_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.motorRatedSpeedSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_motorRatedSpeed_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_motorRatedSpeed_storeAction', null);
                }
            }
        }
    },
    change_motorRatedSpeed_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_motorRatedSpeedSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                typ: state.motorType,
                baug: state.motorSize,
                fe: state.motorPackage,
                zusatz: state.motorZusatz,
                u1: state.motorVoltage,
                nr: value.value,
            }
            commit('change_motorRatedSpeedSelected_storeMutation', valueSelected);
        }
        const firstIndex_whichMeetConditions = state.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            if (state.motorRatedSpeed != state.motorRatedSpeedSelection[firstIndex_whichMeetConditions].value || (state.upgradeSelection != null && state.customerName != "SCH-MOD") || (state.upgradeSelection.length < 1 && state.customerName != "SCH-MOD")) {
                commit('change_motorRatedSpeed_storeMutation', state.motorRatedSpeedSelection[firstIndex_whichMeetConditions].value)
                dispatch('get_upgradeSelection_storeAction');
            }
        }
        else {
            commit('change_motorRatedSpeed_storeMutation', null)
            resetLoader = true;
        }

    },
    change_certification_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_certificationSelected_storeMutation', null);
            resetLoader = true;
        }
        else {
            commit('change_certificationSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.certificationSelection.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_certification_storeMutation', state.certificationSelection[firstIndex_whichMeetConditions].text.text)
                dispatch('get_temperatureMonitoringSelection_storeAction');
            }
            else {
                commit('change_certification_storeMutation', null)
                resetLoader == true;
            }
        }
    },
    change_preference_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_preferenceSelected_storeMutation', null);
            resetLoader = true;
        }
        else {
            commit('change_preferenceSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.preferenceSelection.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_preference_storeMutation', state.preferenceSelection[firstIndex_whichMeetConditions].text.text)
                dispatch('get_temperatureMonitoringSelection_storeAction');
            }
            else {
                commit('change_preference_storeMutation', null)
                resetLoader = true;
            }
        }

    },
    change_temperatureMonitoring_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        dispatch('change_temperatureMonitoring_setDefaultValues_storeAction')
        commit('change_temperatureMonitoringSelected_storeMutation', value.text);
        const firstIndex_whichMeetConditions = state.temperatureMonitoringSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_temperatureMonitoring_storeMutation', state.temperatureMonitoringSelection[firstIndex_whichMeetConditions].text)
            if (state.customerName != "SCH-MOD") {
                dispatch('get_motordetails_storeAction');
            }
            else {
                dispatch('get_SCHMOD_Motordetails_storeAction');
            }
        }
        else {
            commit('change_temperatureMonitoring_storeMutation', null)
            resetLoader = true;
        }
    },
    change_get_upgradeSelection_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        commit('change_get_upgradeSelection_storeMutation', value);
        const firstIndex_whichMeetConditions = state.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('get_forcedVentilationSelection_storeAction');
        }
        else {
            resetLoader = true;
        }
    },
    change_upgradeSelected_storeAction({ commit, dispatch }, value) {
        dispatch('change_upgradeSelected_setDefaultValues_storeAction')
        if (value.selected == true) {
            commit('change_upgradeSelected_storeMutation', 'IP21')
        }
        else {
            commit('change_upgradeSelected_storeMutation', value.text)
        }
        dispatch('get_forcedVentilationSelection_storeAction');
    },
    change_forcedVentilationSelection_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        dispatch('change_forcedVentilationSelection_setDefaultValues_storeAction')
        commit('change_forcedVentilationSelection_storeMutation', value);
        const firstIndex_whichMeetConditions = state.forcedVentilationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilation_storeMutation', state.forcedVentilationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_deliverTractionSheaveSelection_storeAction');
            dispatch('get_machiningForHandWheelSelection_storeAction');
        }
        else {
            commit('change_forcedVentilation_storeMutation', null)
            resetLoader = true;
        }
    },
    change_forcedVentilation_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        commit('change_forcedVentilationSelected_storeMutation', value);
        const firstIndex_whichMeetConditions = state.forcedVentilationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilation_storeMutation', state.forcedVentilationSelection[firstIndex_whichMeetConditions].text.text)
            if (state.customerName != "SCH-MOD") {
                dispatch('get_deliverTractionSheaveSelection_storeAction');
                dispatch('get_machiningForHandWheelSelection_storeAction');
            }
            else {
                dispatch('get_deliverTractionSheaveSelection_storeAction');
                dispatch('get_machiningForHandWheelSelection_storeAction');
            }
        }
        else {
            commit('change_forcedVentilation_storeMutation', null)
            resetLoader = true;
        }
    },
    change_operatingCurrentSplus_storeAction({ commit }, value) {
        if (value != null && value.trim() != '') {
            const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
            valueAndValueValidationCriteria.value = value;
            valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.decimal;
            valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = 2;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;

            const operatingCurrentSplus_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

            commit('change_operatingCurrentSplus_storeMutation', operatingCurrentSplus_ValidationResult);
        }
        else {
            commit('change_operatingCurrentSplus_storeMutation', { value: null, invalid: null });
        }
    },

    // Preselection traction sheave
    async get_deliverTractionSheaveSelection_storeAction({ commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_deliverTractionSheaveSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_deliverTractionSheaveSelection_storeMutation', response.data);
                if (state.deliverTractionSheaveSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.deliverTractionSheaveSelection.findIndex(i => (i.text.code == state.deliverTractionSheave));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_deliverTractionSheave_storeAction', state.deliverTractionSheaveSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.deliverTractionSheaveSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_deliverTractionSheave_storeAction', state.deliverTractionSheaveSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_machiningForHandWheelSelection_storeAction({ commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_machiningForHandWheelSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_machiningForHandWheelSelection_storeMutation', response.data);
                if (state.machiningForHandWheelSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.machiningForHandWheelSelection.findIndex(i => (i.text.code == state.machiningForHandWheel));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_machiningForHandWheel_storeAction', state.machiningForHandWheelSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.machiningForHandWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_machiningForHandWheel_storeAction', state.machiningForHandWheelSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                        }

                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_tractionSheave_DiameterAndWidthSelection_storeAction({ dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        var upgrade = null;
        const firstIndex_whichMeetConditions_upgrade = state.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_upgrade > -1) {
            upgrade = state.upgradeSelection[firstIndex_whichMeetConditions_upgrade].text;
        }
        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            upgrade: upgrade,
            sM250_TS280: state.sM250_TS280,
            kid: state.customerName,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_tractionSheave_DiameterAndWidthSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                dispatch('change_tractionSheave_DiameterAndWidthSelection_storeAction', response.data);
                if (state.tractionSheave_DiameterAndWidthSelection.length > 0 && state.tractionsheave_DiameterAndWidth != null && state.tractionsheave_DiameterAndWidth.hasOwnProperty('diameter') && state.tractionsheave_DiameterAndWidth.hasOwnProperty('width')) {
                    const firstIndex_whichMeetConditions = state.tractionSheave_DiameterAndWidthSelection.findIndex(i => (i.diameter == state.tractionsheave_DiameterAndWidth.diameter && i.width == state.tractionsheave_DiameterAndWidth.width));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_tractionSheave_DiameterAndWidth_storeAction', state.tractionSheave_DiameterAndWidthSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                    }

                }
                else {
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_tractionSheavedetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            motorLink: state.tractionsheave_DiameterAndWidth.motorLink,
            kid: state.customerName,
            lTHD: state.machiningForHandWheel,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_tractionSheavedetails',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection != null) {
                        commit('change_ropeDiameterSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection);
                        const firstIndex_whichMeetConditions = state.ropeDiameterSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_ropeDiameter_storeAction', state.ropeDiameterSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_ropeDiameterSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection != null) {
                        commit('change_grooveDistanceSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection);
                        const firstIndex_whichMeetConditions = state.grooveDistanceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_grooveDistance_storeAction', state.grooveDistanceSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_grooveDistanceSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection != null) {
                        commit('change_numberOfGroovesSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection);
                        const firstIndex_whichMeetConditions = state.numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_numberOfGrooves_storeAction', state.numberOfGroovesSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_numberOfGroovesSelection_storeMutation', []);
                    }

                    if (response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection != null) {
                        commit('change_sheaveProfileSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection);
                        const firstIndex_whichMeetConditions = state.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_sheaveProfile_storeAction', state.sheaveProfileSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_sheaveProfileSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection != null) {
                        commit('change_undercutAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection);
                        const firstIndex_whichMeetConditions = state.undercutAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_undercutAngle_storeAction', state.undercutAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_undercutAngleSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection != null) {
                        commit('change_vgrooveAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection);
                        const firstIndex_whichMeetConditions = state.vgrooveAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_vgrooveAngle_storeAction', state.vgrooveAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_vgrooveAngleSelection_storeMutation', []);
                    }

                    //dispatch('get_upgradeSelection_storeAction');
                }
                else {
                }
            })
            .catch(() => {
            })
            .then(() => {
            })

    },

    change_allElements_tractionSheave_setDefaultValues_storeAction({ commit }) {
        commit('change_deliverTractionSheaveSelection_storeMutation', []);
        commit('change_machiningForHandWheelSelection_storeMutation', []);
        commit('change_tractionSheave_DiameterAndWidthSelection_storeMutation', []);
        commit('change_sheaveProfileSelection_storeMutation', []);
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_ropeClampSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        commit('change_undercutAngleSelection_storeMutation', []);
        commit('change_vgrooveAngleSelection_storeMutation', []);
    },
    change_tractionSheave_deliver_setDefaultValues_storeAction({ commit }) {
        commit('change_machiningForHandWheelSelection_storeMutation', []);
        commit('change_sheaveProfileSelection_storeMutation', []);
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_ropeClampSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        commit('change_undercutAngleSelection_storeMutation', []);
        commit('change_vgrooveAngleSelection_storeMutation', []);
    },
    change_tractionSheave_DiameterAndWidth_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_sheaveProfileSelected_storeMutation', []);

        commit('change_undercutAngleSelection_storeMutation', []);
        commit('change_vgrooveAngleSelection_storeMutation', []);
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);

        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_sheaveProfile_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_undercutAngleSelection_storeMutation', []);
        commit('change_vgrooveAngleSelection_storeMutation', []);

        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);

        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_ropeDiameter_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_grooveDistance_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_numberOfGroovesSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_numberOfGrooves_setDefaultValues_storeAction({ commit, dispatch }) {
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_undercutAngle_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_vgrooveAngle_setDefaultValues_storeAction({ commit, dispatch }) {
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },
    change_deliverTractionSheaveSelection_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        commit('change_deliverTractionSheaveSelection_storeMutation', value);
        const firstIndex_whichMeetConditions = state.deliverTractionSheaveSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('change_deliverTractionSheave_storeAction', state.deliverTractionSheaveSelection[firstIndex_whichMeetConditions]);
        }
        else {
            resetLoader = true;
        }
    },
    change_deliverTractionSheave_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');

        if (value == null) {
            commit('change_deliverTractionSheaveSelected_storeMutation', null);
        }
        else {
            commit('change_deliverTractionSheaveSelected_storeMutation', value.text.text);
        }

        var showTractionSheaveDetails = true;
        dispatch('get_tractionSheave_DiameterAndWidthSelection_storeAction');
        const firstIndex_whichMeetConditions = state.deliverTractionSheaveSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_deliverTractionSheave_storeMutation', state.deliverTractionSheaveSelection[firstIndex_whichMeetConditions].text.code)
            if (state.deliverTractionSheaveSelection[firstIndex_whichMeetConditions].text.code == 0) {
                showTractionSheaveDetails = false;
                dispatch('change_tractionSheave_deliver_setDefaultValues_storeAction')
            }
        }
        else {
            commit('change_deliverTractionSheave_storeMutation', null);
            resetLoader = true;
        }

        commit('change_showTractionSheaveDetails_storeMutation', showTractionSheaveDetails);
    },
    change_machiningForHandWheel_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_machiningForHandWheelSelected_storeMutation', null);
        }
        else {
            commit('change_machiningForHandWheelSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.machiningForHandWheelSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_machiningForHandWheel_storeMutation', state.machiningForHandWheelSelection[firstIndex_whichMeetConditions].text.code)
        }
        else {
            commit('change_machiningForHandWheel_storeMutation', null)
            resetLoader = true;
        }
    },
    change_tractionSheave_DiameterAndWidthSelection_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        commit('change_tractionSheave_DiameterAndWidthSelection_storeMutation', value);
        const firstIndex_whichMeetConditions = state.tractionSheave_DiameterAndWidthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            dispatch('change_tractionSheave_DiameterAndWidth_storeAction', state.tractionSheave_DiameterAndWidthSelection[firstIndex_whichMeetConditions])
        }
        else {
            resetLoader = true;
        }
    },
    change_tractionSheave_DiameterAndWidth_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        dispatch('change_tractionSheave_DiameterAndWidth_setDefaultValues_storeAction');
        commit('change_tractionSheave_DiameterAndWidthSelected_storeMutation', value);
        const firstIndex_whichMeetConditions = state.tractionSheave_DiameterAndWidthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_tractionSheave_DiameterAndWidth_storeMutation', state.tractionSheave_DiameterAndWidthSelection[firstIndex_whichMeetConditions])
            if (state.showTractionSheaveDetails == true) {
                if (state.customerName != "SCH-MOD") {
                    dispatch('get_tractionSheavedetails_storeAction');
                }
                else {
                    dispatch('get_SCHMOD_tractionSheavedetails_storeAction');
                }
            }
            else {
                dispatch('get_customerIdKidSelection_storeAction');
            }
        }
        else {
            commit('change_tractionSheave_DiameterAndWidth_storeMutation', null)
            resetLoader = true;
        }
    },
    change_sheaveProfile_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        //dispatch('change_sheaveProfile_setDefaultValues_storeAction');
        if (value == null) {
            commit('change_sheaveProfileSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                arill: value.text.text,
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
            }
            commit('change_sheaveProfileSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.sheaveProfileSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_sheaveProfile_storeMutation', state.sheaveProfileSelection[firstIndex_whichMeetConditions].text.text)
            //dispatch('get_tractionSheaveAngleSelection_storeAction')
        }
        else {
            commit('change_sheaveProfile_storeMutation', null)
            resetLoader = true;
        }

        if (state.undercutAngleSelection != null && state.undercutAngleSelection.length > 0) {
            const value2 = {
                arill: state.sheaveProfile,
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
            }
            commit('change_undercutAngle_Disabled_storeMuation', value2);
            if (state.sheaveProfile != null && state.ropeDiameter != null && state.grooveDistance != null && state.numberOfGrooves != null) {
                const firstIndex_whichMeetConditions_data = state.undercutAngleSelection.findIndex(i => (i.value == state.undercutAngle &&
                    i.disabled == false &&
                    i.arill == state.sheaveProfile &&
                    i.sd == state.ropeDiameter &&
                    i.ra == state.grooveDistance &&
                    i.rz == state.numberOfGrooves));

                if (firstIndex_whichMeetConditions_data > -1) {
                    dispatch('change_undercutAngle_storeAction', state.undercutAngleSelection[firstIndex_whichMeetConditions_data]);
                }
                else {
                    dispatch('change_undercutAngle_storeAction', null);
                }
            }
        }

    },
    change_ropeDiameter_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_ropeDiameterSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                sd: value.value,
            }
            commit('change_ropeDiameterSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.ropeDiameterSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ropeDiameter_storeMutation', state.ropeDiameterSelection[firstIndex_whichMeetConditions].value)
        }
        else {
            commit('change_ropeDiameter_storeMutation', null)
            resetLoader = true;
        }

        if (state.grooveDistanceSelection != null && state.grooveDistanceSelection.length > 0) {
            const value2 = {
                sd: state.ropeDiameter,
            }
            commit('change_grooveDistance_Disabled_storeMuation', value2);
            if (state.ropeDiameter != null && state.grooveDistance != null) {
                const firstIndex_whichMeetConditions_data = state.grooveDistanceSelection.findIndex(i => (i.value == state.grooveDistance &&
                    i.disabled == false &&
                    i.sd == state.ropeDiameter));

                if (firstIndex_whichMeetConditions_data > -1) {
                    dispatch('change_grooveDistance_storeAction', state.grooveDistanceSelection[firstIndex_whichMeetConditions_data]);
                }
                else {
                    var filterdata = state.grooveDistanceSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_grooveDistance_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_grooveDistance_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.grooveDistanceSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_grooveDistance_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_grooveDistance_storeAction', null);
                }
            }
        }
    },
    change_undercutAngle_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_undercutAngleSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                arill: state.sheaveProfile,
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
                uwi: value.value,
            }
            commit('change_undercutAngleSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.undercutAngleSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_undercutAngle_storeMutation', state.undercutAngleSelection[firstIndex_whichMeetConditions].value)
        }
        else {
            commit('change_undercutAngle_storeMutation', null)
            resetLoader = true;
        }

        if (state.vgrooveAngleSelection != null && state.vgrooveAngleSelection.length > 0) {
            const value2 = {
                arill: state.sheaveProfile,
                uwi: state.undercutAngle,
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
            }
            commit('change_vgrooveAngle_Disabled_storeMuation', value2);
            if (state.sheaveProfile != null && state.ropeDiameter != null && state.grooveDistance != null && state.numberOfGrooves != null) {
                const firstIndex_whichMeetConditions_data = state.vgrooveAngleSelection.findIndex(i => (i.value == state.vgrooveAngle &&
                    i.disabled == false &&
                    i.arill == state.sheaveProfile &&
                    i.sd == state.ropeDiameter &&
                    i.ra == state.grooveDistance &&
                    i.rz == state.numberOfGrooves &&
                    i.uwi == state.undercutAngle));

                if (firstIndex_whichMeetConditions_data > -1) {
                    dispatch('change_vgrooveAngle_storeAction', state.vgrooveAngleSelection[firstIndex_whichMeetConditions_data]);
                }
                else {
                    var filterdata = state.vgrooveAngleSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_vgrooveAngle_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_vgrooveAngle_storeAction', null);
                    }
                }

            }
            else {
                var filterdata = state.vgrooveAngleSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_vgrooveAngle_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_vgrooveAngle_storeAction', null);
                }
            }
        }
    },
    change_vgrooveAngle_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_vgrooveAngleSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                arill: state.sheaveProfile,
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
                uwi: state.undercutAngle,
                kwi: value.value,
            }
            commit('change_vgrooveAngleSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.vgrooveAngleSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_vgrooveAngle_storeMutation', state.vgrooveAngleSelection[firstIndex_whichMeetConditions].value)
        }
        else {
            commit('change_vgrooveAngle_storeMutation', null)
            resetLoader = true;
        }
    },
    change_grooveDistance_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;

        if (value == null) {
            commit('change_grooveDistanceSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                sd: state.ropeDiameter,
                ra: value.value,
            }
            commit('change_grooveDistanceSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.grooveDistanceSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_grooveDistance_storeMutation', state.grooveDistanceSelection[firstIndex_whichMeetConditions].value)
        }
        else {
            commit('change_grooveDistance_storeMutation', null)
            resetLoader = true;
        }

        if (state.numberOfGroovesSelection != null && state.numberOfGroovesSelection.length > 0) {
            const value2 = {
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
            }
            commit('change_numberOfGrooves_Disabled_storeMuation', value2);
            if (state.ropeDiameter != null && state.grooveDistance != null && state.numberOfGrooves != null) {
                const firstIndex_whichMeetConditions_data = state.numberOfGroovesSelection.findIndex(i => (i.value == state.numberOfGrooves &&
                    i.disabled == false &&
                    i.sd == state.ropeDiameter &&
                    i.ra == state.grooveDistance));

                if (firstIndex_whichMeetConditions_data > -1) {
                    dispatch('change_numberOfGrooves_storeAction', state.numberOfGroovesSelection[firstIndex_whichMeetConditions_data]);
                }
                else {
                    var filterdata = state.numberOfGroovesSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_numberOfGrooves_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_numberOfGrooves_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.numberOfGroovesSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_numberOfGrooves_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_numberOfGrooves_storeAction', null);
                }
            }
        }
    },
    change_numberOfGrooves_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_numberOfGroovesSelected_storeMutation', null);
        }
        else {
            const valueSelected = {
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: value.value,
            }
            commit('change_numberOfGroovesSelected_storeMutation', valueSelected);
        }

        const firstIndex_whichMeetConditions = state.numberOfGroovesSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_numberOfGrooves_storeMutation', state.numberOfGroovesSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_customerIdKidSelection_storeAction');
        }
        else {
            commit('change_numberOfGrooves_storeMutation', null)
            resetLoader = true;
        }

        if (state.sheaveProfileSelection != null && state.sheaveProfileSelection.length > 0) {
            const value2 = {
                sd: state.ropeDiameter,
                ra: state.grooveDistance,
                rz: state.numberOfGrooves,
            }
            commit('change_sheaveProfile_Disabled_storeMuation', value2);
            if (state.sheaveProfile != null && state.ropeDiameter != null && state.grooveDistance != null && state.numberOfGrooves != null) {
                const firstIndex_whichMeetConditions_data = state.sheaveProfileSelection.findIndex(i => (i.text.text == state.sheaveProfile &&
                    i.disabled == false &&
                    i.sd == state.ropeDiameter &&
                    i.ra == state.grooveDistance &&
                    i.rz == state.numberOfGrooves));

                if (firstIndex_whichMeetConditions_data > -1) {
                    dispatch('change_sheaveProfile_storeAction', state.sheaveProfileSelection[firstIndex_whichMeetConditions_data]);
                }
                else {
                    var filterdata = state.sheaveProfileSelection.filter(i => (i.disabled == false));
                    if (filterdata != null && filterdata.length == 1) {
                        dispatch('change_sheaveProfile_storeAction', filterdata[0]);
                    }
                    else {
                        dispatch('change_sheaveProfile_storeAction', null);
                    }
                }
            }
            else {
                var filterdata = state.sheaveProfileSelection.filter(i => (i.disabled == false));
                if (filterdata != null && filterdata.length == 1) {
                    dispatch('change_sheaveProfile_storeAction', filterdata[0]);
                }
                else {
                    dispatch('change_sheaveProfile_storeAction', null);
                }
            }
        }
    },

    // rope guard
    async get_customerIdKidSelection_storeAction({ commit, dispatch }) {
        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            kid: state.customerName,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_customerIdKidSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_customerIdKidSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.customerIdKidSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_customerIdKid_storeAction', state.customerIdKidSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.customerIdKidSelection.length > 0) {
                        dispatch('change_customerIdKid_storeAction', state.customerIdKidSelection[0]);
                    }
                    else {
                    }

                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_positionMotorSelection_storeAction({ commit, dispatch }) {
        var kid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { kid = state.customerIdKid.text.text; }
        var deliverRope = null;
        if (state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) { deliverRope = state.deliverRope.text.code; }
        var deliverCover = null;
        if (state.tractionSheaveCover != null && state.tractionSheaveCover.hasOwnProperty('text')) { deliverCover = state.tractionSheaveCover.text.code; }


        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            kid: kid,
            deliverRope: deliverRope,
            deliverCover: deliverCover,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_positionMotorSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_positionMotorSelection_storeMutation', response.data);
                if ((state.deliverRope != null && state.deliverRope.text.code != 0 || state.tractionSheaveCover != null && state.tractionSheaveCover.text.code != 0) && state.positionMotorSelection.length > 0 && state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) {
                    var firstIndex_whichMeetConditions = state.positionMotorSelection.findIndex(i => (i.text.text == state.positionMotor.text.text));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_positionMotor_storeAction', state.positionMotorSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.positionMotorSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_positionMotor_storeAction', state.positionMotorSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.positionMotorSelection.length > 0) {
                                dispatch('change_positionMotor_storeAction', state.positionMotorSelection[0]);
                            }
                            else {
                                dispatch('change_positionMotor_storeAction', null);
                            }
                        }
                    }
                }
                else {
                    firstIndex_whichMeetConditions = state.positionMotorSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_positionMotor_storeAction', state.positionMotorSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.positionMotorSelection.length > 0) {
                            dispatch('change_positionMotor_storeAction', state.positionMotorSelection[0]);
                        }
                        else {
                            dispatch('change_positionMotor_storeAction', null);
                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_ropePullSelection_storeAction({ commit, dispatch }) {
        var kid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { kid = state.customerIdKid.text.text; }
        var motorPosition = null;
        if (state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) { motorPosition = state.positionMotor.text.text; }
        var deliverRope = null;
        if (state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) { deliverRope = state.deliverRope.text.code; }
        var deliverCover = null;
        if (state.tractionSheaveCover != null && state.tractionSheaveCover.hasOwnProperty('text')) { deliverCover = state.tractionSheaveCover.text.code; }


        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            kid: kid,
            motorPosition: motorPosition,
            deliverRope: deliverRope,
            deliverCover: deliverCover,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_ropePullSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_ropePullSelection_storeMutation', response.data);
                if ((state.deliverRope != null && state.deliverRope.text.code != 0 || state.tractionSheaveCover != null && state.tractionSheaveCover.text.code != 0) && state.ropePullSelection.length > 0 && state.ropePull != null && state.ropePull.hasOwnProperty('text')) {
                    var firstIndex_whichMeetConditions = state.ropePullSelection.findIndex(i => (i.text.text == state.ropePull.text.text));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_ropePull_storeAction', state.ropePullSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.ropePullSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_ropePull_storeAction', state.ropePullSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.ropePullSelection.length > 0) {
                                dispatch('change_ropePull_storeAction', state.ropePullSelection[0]);
                            }
                            else {
                                dispatch('change_ropePull_storeAction', null);
                            }
                        }
                    }
                }
                else {
                    firstIndex_whichMeetConditions = state.ropePullSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_ropePull_storeAction', state.ropePullSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.ropePullSelection.length > 0) {
                            dispatch('change_ropePull_storeAction', state.ropePullSelection[0]);
                        }
                        else {
                            dispatch('change_ropePull_storeAction', null);
                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_adjustabilitySelection_storeAction({ commit, dispatch }) {
        var kid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { kid = state.customerIdKid.text.text; }
        var motorPosition = null;
        if (state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) { motorPosition = state.positionMotor.text.text; }
        var ropePull = null;
        if (state.ropePull != null && state.ropePull.hasOwnProperty('text')) { ropePull = state.ropePull.text.text; }
        var deliverRope = null;
        if (state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) { deliverRope = state.deliverRope.text.code; }
        var deliverCover = null;
        if (state.tractionSheaveCover != null && state.tractionSheaveCover.hasOwnProperty('text')) { deliverCover = state.tractionSheaveCover.text.code; }

        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            kid: kid,
            motorPosition: motorPosition,
            ropePull: ropePull,
            deliverRope: deliverRope,
            deliverCover: deliverCover,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_adjustabilitySelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_adjustabilitySelection_storeMutation', response.data);
                if ((state.deliverRope != null && state.deliverRope.text.code != 0 || state.tractionSheaveCover != null && state.tractionSheaveCover.text.code != 0) && state.adjustabilitySelection.length > 0 && state.adjustability != null && state.adjustability.hasOwnProperty('text')) {
                    var firstIndex_whichMeetConditions = state.adjustabilitySelection.findIndex(i => (i.text.text == state.adjustability.text.text));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_adjustability_storeAction', state.adjustabilitySelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.adjustabilitySelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_adjustability_storeAction', state.adjustabilitySelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.adjustabilitySelection.length > 0) {
                                dispatch('change_adjustability_storeAction', state.adjustabilitySelection[0]);
                            }
                            else {
                                dispatch('change_adjustability_storeAction', null);
                            }
                        }
                    }
                }
                else {
                    firstIndex_whichMeetConditions = state.adjustabilitySelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_adjustability_storeAction', state.adjustabilitySelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.adjustabilitySelection.length > 0) {
                            dispatch('change_adjustability_storeAction', state.adjustabilitySelection[0]);
                        }
                        else {
                            dispatch('change_adjustability_storeAction', null);
                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_thirdRopeGuard_storeAction({ commit, dispatch }) {
        var kid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { kid = state.customerIdKid.text.text; }
        var motorPosition = null;
        if (state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) { motorPosition = state.positionMotor.text.text; }
        var ropePull = null;
        if (state.ropePull != null && state.ropePull.hasOwnProperty('text')) { ropePull = state.ropePull.text.text; }
        var adjustability = null;
        if (state.adjustability != null && state.adjustability.hasOwnProperty('text')) { adjustability = state.adjustability.text.text; }


        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            kid: kid,
            motorPosition: motorPosition,
            ropePull: ropePull,
            adjustability: adjustability,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_thirdRopeGuard',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_thirdRopeGuard_storeMutation', response.data.thirdRopeGuard);
                commit('change_imagePathRopeGuard_storeMutation', response.data.imagePathRopeGuard);
                commit('change_tnruev_storeMutation', response.data.tnruev);
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_deliverRopeSelection_storeAction({ commit, dispatch }) {
        const data = {
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_deliverRopeSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_deliverRopeSelection_storeMutation', response.data);
                if (state.deliverRopeSelection.length > 0 && state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) {
                    var firstIndex_whichMeetConditions = state.deliverRopeSelection.findIndex(i => (i.text.code == state.deliverRope.text.code));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_deliverRope_storeAction', state.deliverRopeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.deliverRopeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_deliverRope_storeAction', state.deliverRopeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.deliverRopeSelection.length > 0) {
                                dispatch('change_deliverRope_storeAction', state.deliverRopeSelection[0]);
                            }
                            else {
                                dispatch('change_deliverRope_storeAction', null);
                            }
                        }
                    }
                }
                else {
                    firstIndex_whichMeetConditions = state.deliverRopeSelection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_deliverRope_storeAction', state.deliverRopeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.deliverRopeSelection.length > 0) {
                            //dispatch('change_deliverRope_storeAction', state.deliverRopeSelection[0]);

                        }
                        else {
                            dispatch('change_deliverRope_storeAction', null);

                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_tractionSheaveCoverSelection_storeAction({ commit, dispatch }) {
        var kid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { kid = state.customerIdKid.text.text; }
        var motorPosition = null;
        //if (state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) { motorPosition = state.positionMotor.text.text; }
        //var ropePull = null;
        //if (state.ropePull != null && state.ropePull.hasOwnProperty('text')) { ropePull = state.ropePull.text.text; }
        //var adjustability = null;
        //if (state.adjustability != null && state.adjustability.hasOwnProperty('text')) { adjustability = state.adjustability.text.text; }

        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            kid: kid,
            sM250_TS280: state.sM250_TS280,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        //motorPosition: motorPosition,
        //    ropePull: ropePull,
        //        adjustability: adjustability,

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_tractionSheaveCoverSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_tractionSheaveCoverSelection_storeMutation', response.data);
                if (state.tractionSheaveCoverSelection != null && state.tractionSheaveCoverSelection.hasOwnProperty('selection') && state.tractionSheaveCoverSelection.selection.length > 0 && state.tractionSheaveCover != null && state.tractionSheaveCover.hasOwnProperty('text') && state.tractionSheaveCoverSelection.disabled == false) {
                    var firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.text.code == state.tractionSheaveCover.text.code));
                    if (state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions].text.code != 0 && state.tractionSheaveCoverSelection.additionalCover == false && state.deliverRope.text.code != 0) {
                        firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.tractionSheaveCoverSelection.length > 0) {
                                dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[0]);
                            }
                            else {
                                dispatch('change_tractionSheaveCover_storeAction', null);
                            }
                        }
                    }
                    else {
                        firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.text.code == state.tractionSheaveCover.text.code));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions]);
                            }
                            else {
                                if (state.tractionSheaveCoverSelection.selection.length > 0) {
                                    dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[0]);
                                }
                                else {
                                    dispatch('change_tractionSheaveCover_storeAction', null);
                                }
                            }
                        }

                    }
                }
                else {
                    var firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.tractionSheaveCoverSelection.selection.length > 0) {
                            dispatch('change_tractionSheaveCover_storeAction', state.tractionSheaveCoverSelection.selection[0]);
                        }
                        else {
                            dispatch('change_tractionSheaveCover_storeAction', null);
                        }
                    }

                }
            })
            .catch(() => {
            })
            .then(() => {

            })
    },
    async get_ropeClampSelection_storeAction({ commit, dispatch }) {
        const data = {
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_ropeClampSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_ropeClampSelection_storeMutation', response.data);
                if (state.ropeClamp != null && state.ropeClamp.hasOwnProperty('text') && state.ropeClampSelection.selection.length > 0 && state.ropeClampSelection.disabled == false) {
                    var firstIndex_whichMeetConditions = state.ropeClampSelection.selection.findIndex(i => (i.text.code == state.ropeClamp.text.code));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_ropeClamp_storeAction', state.ropeClampSelection.selection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.ropeClampSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_ropeClamp_storeAction', state.ropeClampSelection.selection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.ropeClampSelection.length > 0) {
                                dispatch('change_ropeClamp_storeAction', state.ropeClampSelection.selection[0]);
                            }
                            else {
                                dispatch('change_ropeClamp_storeAction', null);

                            }
                        }
                    }
                }
                else {
                    var firstIndex_whichMeetConditions = state.ropeClampSelection.selection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_ropeClamp_storeAction', state.ropeClampSelection.selection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.ropeClampSelection.length > 0) {
                            dispatch('change_ropeClamp_storeAction', state.ropeClampSelection.selection[0]);
                        }
                        else {
                            dispatch('change_ropeClamp_storeAction', null);

                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },
    async get_rescueReturnDeviceSelection_storeAction({ commit, dispatch }) {
        var deliverRopeClamp = null;
        if (state.ropeClamp != null && state.ropeClamp.hasOwnProperty('text')) { deliverRopeClamp = state.ropeClamp.text.code; }
        const data = {
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            deliverRopeClamp: deliverRopeClamp,
            configurationStepsManualId: state.configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_rescueReturnDeviceSelection',
            data: {
                data: data,
            },
        })
            .then((response) => {
                commit('change_rescueReturnDeviceSelection_storeMutation', response.data);
                if (state.rescueReturnDevice != null && state.rescueReturnDevice.hasOwnProperty('text') && state.rescueReturnDeviceSelection.selection.length > 0 && state.rescueReturnDeviceSelection.disabled == false) {
                    var firstIndex_whichMeetConditions = state.rescueReturnDeviceSelection.selection.findIndex(i => (i.text.code == state.rescueReturnDevice.text.code));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_rescueReturnDevice_storeAction', state.rescueReturnDeviceSelection.selection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.rescueReturnDeviceSelection.selection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_rescueReturnDevice_storeAction', state.rescueReturnDeviceSelection.selection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            if (state.rescueReturnDeviceSelection.length > 0) {
                                dispatch('change_rescueReturnDevice_storeAction', state.rescueReturnDeviceSelection.selection[0]);
                            }
                            else {
                                dispatch('change_rescueReturnDevice_storeAction', null);

                            }
                        }
                    }
                }
                else {
                    var firstIndex_whichMeetConditions = state.rescueReturnDeviceSelection.selection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_rescueReturnDevice_storeAction', state.rescueReturnDeviceSelection.selection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.rescueReturnDeviceSelection.length > 0) {
                            dispatch('change_rescueReturnDevice_storeAction', state.rescueReturnDeviceSelection.selection[0]);
                        }
                        else {
                            dispatch('change_rescueReturnDevice_storeAction', null);

                        }
                    }
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    change_allElements_ropeGuard_setDefaultValues_storeAction({ commit }) {
        commit('change_customerIdKidSelection_storeMutation', []);
        commit('change_positionMotorSelection_storeMutation', []);
        commit('change_ropePullSelection_storeMutation', []);
        commit('change_adjustabilitySelection_storeMutation', []);
        commit('change_deliverRopeSelection_storeMutation', []);
        commit('change_tractionSheaveCoverSelection_storeMutation', []);
        commit('change_ropeClampSelection_storeMutation', []);
        //    commit('change_customerIdKid_storeMutation', null);
        //    commit('change_positionMotor_storeMutation', null);
        //    commit('change_ropePull_storeMutation', null);
        //    commit('change_adjustability_storeMutation', null);
        //    commit('change_deliverRope_storeMutation', null);
        //    commit('change_tractionSheaveCover_storeMutation', null);
        //    commit('change_ropeClamp_storeMutation', null);
        //    commit('change_deliverRopeDisabled_storeMutation', null);
    },
    change_customerIdKid_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_customerIdKidSelected_storeMutation', null);
        }
        else {
            commit('change_customerIdKidSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.customerIdKidSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            var getDeliverRopeOrGetTractionSheaveCover = false;
            commit('change_customerIdKid_storeMutation', state.customerIdKidSelection[firstIndex_whichMeetConditions]);
            if (state.deliverRopeSelection == null || state.deliverRopeSelection.length == 0) {
                dispatch('get_deliverRopeSelection_storeAction');
                getDeliverRopeOrGetTractionSheaveCover = true;
            }
            if (state.tractionSheaveCoverSelection == null || state.tractionSheaveCoverSelection.hasOwnProperty('selection') == false || state.tractionSheaveCoverSelection.selection.length == 0) {
                dispatch('get_tractionSheaveCoverSelection_storeAction');
                getDeliverRopeOrGetTractionSheaveCover = true;
            }
            if (getDeliverRopeOrGetTractionSheaveCover == true) {
                resetLoader = true;
            }
            //    dispatch('get_positionMotorSelection_storeAction');
        }
        else {
            commit('change_customerIdKid_storeMutation', null);
            resetLoader = true;
        }
    },
    change_positionMotor_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_positionMotorSelected_storeMutation', null);
        }
        else {
            commit('change_positionMotorSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.positionMotorSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_positionMotor_storeMutation', state.positionMotorSelection[firstIndex_whichMeetConditions]);
            dispatch('get_ropePullSelection_storeAction');
        }
        else {
            commit('change_positionMotor_storeMutation', null);
            resetLoader = true;
        }
    },
    change_ropePull_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_ropePullSelected_storeMutation', null);
        }
        else {
            commit('change_ropePullSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.ropePullSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ropePull_storeMutation', state.ropePullSelection[firstIndex_whichMeetConditions]);
            dispatch('get_adjustabilitySelection_storeAction');
        }
        else {
            commit('change_ropePull_storeMutation', null);
            resetLoader = true;
        }
    },
    change_adjustability_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_adjustabilitySelected_storeMutation', null);
        }
        else {
            commit('change_adjustabilitySelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.adjustabilitySelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_adjustability_storeMutation', state.adjustabilitySelection[firstIndex_whichMeetConditions]);
            dispatch('get_thirdRopeGuard_storeAction');
            //if (state.deliverRopeSelection == null || state.deliverRopeSelection.length == 0) {
            //    dispatch('get_deliverRopeSelection_storeAction');
            //}
            //if (state.tractionSheaveCoverSelection == null || state.tractionSheaveCoverSelection.hasOwnProperty('selection') == false || state.tractionSheaveCoverSelection.selection.length == 0) {
            //    dispatch('get_tractionSheaveCoverSelection_storeAction');
            //}
            dispatch('get_ropeClampSelection_storeAction');

        }
        else {
            commit('change_adjustability_storeMutation', null);
            resetLoader = true;
        }
    },
    change_deliverRope_storeAction({ commit, dispatch }, value) {
        var resetLoader = false;
        if (value == null) {
            commit('change_deliverRopeSelected_storeMutation', null);
        }
        else {
            commit('change_deliverRopeSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.deliverRopeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_deliverRope_storeMutation', state.deliverRopeSelection[firstIndex_whichMeetConditions]);
        }
        else {
            commit('change_deliverRope_storeMutation', null)
            resetLoader = true;
        }

        dispatch('get_positionMotorSelection_storeAction');
        if ((state.deliverRope == null || state.deliverRope.text.text == null || state.deliverRope.text.text == 'ohne') && (state.tractionSheaveCover == null || state.tractionSheaveCover.text.text == null || state.tractionSheaveCover.text.text == 'ohne')) {
            commit('change_deliverRopeDisabled_storeMutation', true);
            commit('change_positionMotor_Copy_storeMutation', null);
            commit('change_ropePull_Copy_storeMutation', null);
            commit('change_adjustability_Copy_storeMutation', null);

            if (state.tractionSheaveCover == null || state.deliverRope == null) {
                commit('change_positionMotor_storeMutation', null);
                commit('change_ropePull_storeMutation', null);
                commit('change_adjustability_storeMutation', null);
                commit('change_ropeClamp_storeMutation', null);
            }

            commit('change_positionMotorSelection_storeMutation', []);
            commit('change_ropePullSelection_storeMutation', []);
            commit('change_adjustabilitySelection_storeMutation', []);
            commit('change_ropeClampSelection_storeMutation', []);
        }
        else {
            commit('change_deliverRopeDisabled_storeMutation', false);
            if (state.customerIdKid_Copy != null) {
                const firstIndex_whichMeetConditions1 = state.customerIdKidSelection.findIndex(i => (i.text.text == state.customerIdKid_Copy));
                if (firstIndex_whichMeetConditions1 > -1) {
                    commit('change_customerIdKidSelected_storeMutation', state.customerIdKidSelection[firstIndex_whichMeetConditions1].text.text);
                }
            }
            if (state.positionMotor_Copy != null) {
                const firstIndex_whichMeetConditions2 = state.positionMotorSelection.findIndex(i => (i.text.text == state.positionMotor_Copy));
                if (firstIndex_whichMeetConditions2 > -1) {
                    commit('change_positionMotorSelected_storeMutation', state.positionMotorSelection[firstIndex_whichMeetConditions2].text.text);
                }
            }
            if (state.ropePull_Copy != null) {
                const firstIndex_whichMeetConditions3 = state.ropePullSelection.findIndex(i => (i.text.text == state.ropePull_Copy));
                if (firstIndex_whichMeetConditions3 > -1) {
                    commit('change_ropePullSelected_storeMutation', state.ropePullSelection[firstIndex_whichMeetConditions3].text.text);
                }
            }
            if (state.adjustability_Copy != null) {
                const firstIndex_whichMeetConditions4 = state.adjustabilitySelection.findIndex(i => (i.text.text == state.adjustability_Copy));
                if (firstIndex_whichMeetConditions4 > -1) {
                    commit('change_adjustabilitySelected_storeMutation', state.adjustabilitySelection[firstIndex_whichMeetConditions4].text.text);
                }
            }
        }
    },
    change_tractionSheaveCover_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_tractionSheaveCoverSelected_storeMutation', null);
        }
        else {
            commit('change_tractionSheaveCoverSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.tractionSheaveCoverSelection.selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_tractionSheaveCover_storeMutation', state.tractionSheaveCoverSelection.selection[firstIndex_whichMeetConditions]);
        }
        else {
            commit('change_tractionSheaveCover_storeMutation', state.tractionSheaveCoverSelection.selection[0]);
        }

        dispatch('get_positionMotorSelection_storeAction');
        if ((state.deliverRope == null || state.deliverRope.text.text == null || state.deliverRope.text.text == 'ohne') && (state.tractionSheaveCover == null || state.tractionSheaveCover.text.text == null || state.tractionSheaveCover.text.text == 'ohne')) {
            commit('change_deliverRopeDisabled_storeMutation', true);
            commit('change_positionMotor_Copy_storeMutation', null);
            commit('change_ropePull_Copy_storeMutation', null);
            commit('change_adjustability_Copy_storeMutation', null);

            if (state.tractionSheaveCover == null || state.deliverRope == null) {
                commit('change_positionMotor_storeMutation', null);
                commit('change_ropePull_storeMutation', null);
                commit('change_adjustability_storeMutation', null);
                commit('change_ropeClamp_storeMutation', null);
            }

            commit('change_positionMotorSelection_storeMutation', []);
            commit('change_ropePullSelection_storeMutation', []);
            commit('change_adjustabilitySelection_storeMutation', []);
            commit('change_ropeClampSelection_storeMutation', []);
        }
        else {
            commit('change_deliverRopeDisabled_storeMutation', false);
            if (state.customerIdKid_Copy != null) {
                const firstIndex_whichMeetConditions1 = state.customerIdKidSelection.findIndex(i => (i.text.text == state.customerIdKid_Copy));
                if (firstIndex_whichMeetConditions1 > -1) {
                    commit('change_customerIdKidSelected_storeMutation', state.customerIdKidSelection[firstIndex_whichMeetConditions1].text.text);
                }
            }
            if (state.positionMotor_Copy != null) {
                const firstIndex_whichMeetConditions2 = state.positionMotorSelection.findIndex(i => (i.text.text == state.positionMotor_Copy));
                if (firstIndex_whichMeetConditions2 > -1) {
                    commit('change_positionMotorSelected_storeMutation', state.positionMotorSelection[firstIndex_whichMeetConditions2].text.text);
                }
            }
            if (state.ropePull_Copy != null) {
                const firstIndex_whichMeetConditions3 = state.ropePullSelection.findIndex(i => (i.text.text == state.ropePull_Copy));
                if (firstIndex_whichMeetConditions3 > -1) {
                    commit('change_ropePullSelected_storeMutation', state.ropePullSelection[firstIndex_whichMeetConditions3].text.text);
                }
            }
            if (state.adjustability_Copy != null) {
                const firstIndex_whichMeetConditions4 = state.adjustabilitySelection.findIndex(i => (i.text.text == state.adjustability_Copy));
                if (firstIndex_whichMeetConditions4 > -1) {
                    commit('change_adjustabilitySelected_storeMutation', state.adjustabilitySelection[firstIndex_whichMeetConditions4].text.text);
                }
            }
        }
    },
    change_ropeClamp_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_ropeClampSelected_storeMutation', null);
        }
        else {
            commit('change_ropeClampSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.ropeClampSelection.selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_ropeClamp_storeMutation', state.ropeClampSelection.selection[firstIndex_whichMeetConditions]);
            dispatch('get_rescueReturnDeviceSelection_storeAction');
        }
        else {
            commit('change_ropeClamp_storeMutation', state.ropeClampSelection.selection[0]);
        }
    },
    change_rescueReturnDevice_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_rescueReturnDeviceSelected_storeMutation', null);
        }
        else {
            commit('change_rescueReturnDeviceSelected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.rescueReturnDeviceSelection.selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_rescueReturnDevice_storeMutation', state.rescueReturnDeviceSelection.selection[firstIndex_whichMeetConditions]);
        }
        else {
            commit('change_rescueReturnDevice_storeMutation', state.rescueReturnDeviceSelection.selection[0]);
        }
    },




    async insertOrUpdate_preselection_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('cables/change_cablesSelection_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

        var upgrade = null;
        const firstIndex_whichMeetConditions_upgrade = state.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_upgrade > -1) {
            upgrade = state.upgradeSelection[firstIndex_whichMeetConditions_upgrade].text;
        }

        var forcedVentilationWithBrakeRelease = null
        var TNFB = null;
        const firstIndex_whichMeetConditions_forcedVentilationWithBrakeRelease = state.forcedVentilationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_forcedVentilationWithBrakeRelease > -1) {
            forcedVentilationWithBrakeRelease = state.forcedVentilationSelection[firstIndex_whichMeetConditions_forcedVentilationWithBrakeRelease].canBeCombinedWithBrakeRelease.text;
            TNFB = state.forcedVentilationSelection[firstIndex_whichMeetConditions_forcedVentilationWithBrakeRelease].tnfb;
        }
        var forcedVentilation = state.forcedVentilation;
        if (state.forcedVentilation != null && state.forcedVentilation.hasOwnProperty('text')) {
            forcedVentilation = state.forcedVentilation.text.text
        }
        var customerIdKid = null;
        if (state.customerIdKid != null && state.customerIdKid.hasOwnProperty('text')) { customerIdKid = state.customerIdKid.text.text; }
        var positionMotor = null;
        if (state.positionMotor != null && state.positionMotor.hasOwnProperty('text')) { positionMotor = state.positionMotor.text.text; }
        var ropePull = null;
        if (state.ropePull != null && state.ropePull.hasOwnProperty('text')) { ropePull = state.ropePull.text.text; }
        var adjustability = null;
        if (state.adjustability != null && state.adjustability.hasOwnProperty('text')) { adjustability = state.adjustability.text.text; }
        var deliverRope = null;
        if (state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) { deliverRope = state.deliverRope.text.code; }
        var tractionSheaveCover = null;
        if (state.tractionSheaveCover != null && state.tractionSheaveCover.hasOwnProperty('text')) { tractionSheaveCover = state.tractionSheaveCover.text.code; }
        var ropeClamp = null;
        if (state.ropeClamp != null && state.ropeClamp.hasOwnProperty('text')) { ropeClamp = state.ropeClamp.text.code; }
        var lruev = null;
        if (state.rescueReturnDevice != null && state.rescueReturnDevice.hasOwnProperty('text')) { lruev = state.rescueReturnDevice.text.code; }
        var thirdRopeGuard = null;
        if (state.thirdRopeGuard != null && state.thirdRopeGuard.hasOwnProperty('text')) { thirdRopeGuard = state.thirdRopeGuard.text.text; }
        //var undercutAngle = null;
        //var vgrooveAngle = null;
        //if (state.tractionSheaveAngle != null && state.tractionSheaveAngle.hasOwnProperty('undercutAngle') && state.tractionSheaveAngle.hasOwnProperty('vgrooveAngle')) {
        //    undercutAngle = state.tractionSheaveAngle.undercutAngle;
        //    vgrooveAngle = state.tractionSheaveAngle.vgrooveAngle;
        //}
        var diameter = null;
        var width = null;
        if (state.tractionsheave_DiameterAndWidth != null && state.tractionsheave_DiameterAndWidth.hasOwnProperty('diameter') && state.tractionsheave_DiameterAndWidth.hasOwnProperty('width')) {
            if ((state.deliverTractionSheave == null || state.deliverTractionSheave == 0) && (deliverRope == null || deliverRope == 0)) {
            }
            else {
                diameter = state.tractionsheave_DiameterAndWidth.diameter;
                width = state.tractionsheave_DiameterAndWidth.width;
            }
        }
        const preselectionMotorManual = {
            kid: state.customerName,
            mTYP: state.motorType,
            mBG: state.motorSize,
            mFE: state.motorPackage,
            mZUS: state.motorZusatz,
            sMU1: state.motorVoltage,
            mNR: state.motorRatedSpeed,
            mLS_Law: state.certification,
            motor_Preference: state.preference,
            mSART: state.temperatureMonitoring,
            mIP: upgrade,
            motor_ForcedVentilationWithBrakeRelease: forcedVentilationWithBrakeRelease,
            mFBTYP: forcedVentilation,
            tNFB: TNFB,
            lTR: state.deliverTractionSheave,
            lTHD: state.machiningForHandWheel,
            tD: diameter,
            tB: width,
            tARILL: state.sheaveProfile,
            tAUWI: state.undercutAngle,
            tAKWI: state.vgrooveAngle,
            tCSD: state.ropeDiameter,
            tDRA: state.grooveDistance,
            tDRZ: state.numberOfGrooves,
            kIDSCH: customerIdKid,
            fUESSE: positionMotor,
            sEILAB: ropePull,
            eINST: adjustability,
            lSEILS: deliverRope,
            lTSABD: tractionSheaveCover,
            lTKLEM: ropeClamp,
            lRUEV: lruev,
            mN_SAS: thirdRopeGuard,
            nOTIZ: state.note,
            cMD: state.cmd,
            pROJEKT: state.projekt,
            sANUM: state.sanum,
            iNOK: state.inok,
            oNOK: state.onok,
            mvLift: state.mvlift,
            mis: state.mis,
            lTHD: state.machiningForHandWheel,
            sM250_TS280: state.sM250_TS280,
        }
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            preselectionMotorManual: preselectionMotorManual,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/insertOrUpdate_preselection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    dispatch('change_configurationStepsManualId_storeAction', response.data.data.configurationStepsManualId);

                    if (response.data.data.changedDataset == true) {
                        this.dispatch('driveTechnologiesManual/change_driveTechnologies_toDefault_storeAction');
                        this.dispatch('cables/change_cablesManual_toDefault_storeAction');
                        this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.inputsManual = 'passed';
                        navigationManual.driveTechnologiesManual = null;
                        navigationManual.cablesManual = null;
                        navigationManual.suppliesMotorManual = 'disabled';
                        navigationManual.packagingMotorManual = 'disabled';
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                    router.push({ params: { slug: 'driveTechnologiesManual' } });
                }
                else {
                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.inputsManual = null;
                    navigationManual.driveTechnologiesManual = null;
                    navigationManual.cablesManual = 'disabled';
                    navigationManual.suppliesMotorManual = 'disabled';
                    navigationManual.packagingMotorManual = 'disabled';
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => {
            })
            .then(() => {
            })

    },

    async delete_configuration_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/delete_configuration',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('driveTechnologiesManual/change_driveTechnologies_toDefault_storeAction');
                    this.dispatch('cables/change_cablesManual_toDefault_storeAction');
                    this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                    this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.inputsManual = null;
                    navigationManual.driveTechnologiesManual = null;
                    navigationManual.cablesManual = 'disabled';
                    navigationManual.suppliesMotorManual = 'disabled';
                    navigationManual.packagingMotorManual = 'disabled';
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => {
            })
            .then(() => {
            })

    },

    cancelManualMotorConfiguration_storeAction() {
        router.push({ params: { slug: 'ManualHome' } });
    },

    checkIf_inputsManual_areValid_storeAction({ rootState, dispatch, commit }) {
        var numberOf_inputs_whichAreInvalid = 0;
        var deliverRope = null;
        if (state.deliverRope != null && state.deliverRope.hasOwnProperty('text')) { deliverRope = state.deliverRope.text.text; }

        if (state.deliverRope == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (state.customerName == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorType == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorSize == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorPackage == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorVoltage == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorRatedSpeed == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.certification == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.preference == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.temperatureMonitoring == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.deliverTractionSheave == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.deliverTractionSheave != null && state.deliverTractionSheave != 0) {
            if (state.machiningForHandWheel == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.tractionsheave_DiameterAndWidth == null || state.tractionsheave_DiameterAndWidth.diameter == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.tractionsheave_DiameterAndWidth == null || state.tractionsheave_DiameterAndWidth.width == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.sheaveProfile == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.ropeDiameter == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.grooveDistance == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            if (state.numberOfGrooves == null) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
        }
        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('insertOrUpdate_preselection_storeAction');
        }
    },

    async loadAllDataMotorManual_storeAction({ commit, rootState }) {
        const zlm = rootState.manualHome.ZLM; //'2047D9DC-F054-450F-816D-0E2DE60971FC';
        if (zlm != null) {
            await axios({
                method: 'post',
                url: '/api/inputsManual/loadAllDataMotorManual/' + zlm,
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        if (response.data.data.showData == true) {
                            commit('motorManualData_storeMutation', response.data.data);
                        }
                        else {
                            commit('motorManualData_storeMutation', null);
                        }
                    }
                    else {
                        commit('motorManualData_storeMutation', null);
                    }
                })
                .catch(() => {
                })
                .then(() => {
                })
        }
        else {
            commit('motorManualData_storeMutation', null);
        }

    },

    async loadAllDataMotorManualZlw_storeAction({ commit, rootState }) {
        const configurationOverviewsId = rootState.configurations.configurationOverviewsId; 
        if (configurationOverviewsId != null) {
            await axios({
                method: 'post',
                url: '/api/inputsManual/loadAllDataMotorManualZlw/' + configurationOverviewsId,
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        if (response.data.data.showData == true) {
                            commit('motorManualData_storeMutation', response.data.data);
                        }
                        else {
                            commit('motorManualData_storeMutation', null);
                        }
                    }
                    else {
                        commit('motorManualData_storeMutation', null);
                    }
                })
                .catch(() => {
                })
                .then(() => {
                })
        }
        else {
            commit('motorManualData_storeMutation', null);
        }

    },


    async check_configurationMotorManual_storeAction({ dispatch }, value) {
        const configurationStepsManualId = value;
        if (configurationStepsManualId != null) {
            await axios({
                method: 'post',
                url: '/api/inputsManual/checkDeleteConfigurationMotorManual/' + configurationStepsManualId,
            })
                .then((response) => {
                })
                .catch(() => {
                })
                .then(() => {
                    //dispatch('loadAllDataMotorManual_storeAction');
                })
        }
    },
    set_inputManual_toDefault_storeAction({ dispatch }) {
        dispatch('set_inputManual_textInput_toDefault_storeAction');
        dispatch('set_inputManual_Motor_toDefault_storeAction');
        dispatch('set_inputManual_SCHMOD_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
    },

    set_inputManual_textInput_toDefault_storeAction({ commit }) {
        commit('change_note_storeMutation', null);
        commit('change_cmd_storeMutation', null);
        commit('change_projekt_storeMutation', null);
        commit('change_sanum_storeMutation', null);
        commit('change_inok_storeMutation', null);
        commit('change_onok_storeMutation', null);
        commit('change_mvlift_storeMutation', null);
        commit('change_mis_storeMutation', null);
    },

    set_inputManual_Motor_toDefault_storeAction({ commit }) {
        commit('change_customerNameSelection_storeMutation', []);
        commit('change_motorTypeSelection_storeMutation', []);
        commit('change_motorSizeSelection_storeMutation', []);
        commit('change_motorPackageSelection_storeMutation', []);
        commit('change_motorZusatzSelection_storeMutation', {});
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_certificationSelection_storeMutation', []);
        commit('change_preferenceSelection_storeMutation', []);
        commit('change_temperatureMonitoringSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);

        commit('change_customerName_storeMutation', null);
        commit('change_motorType_storeMutation', null);
        commit('change_motorSize_storeMutation', null);
        commit('change_motorPackage_storeMutation', null);
        commit('change_motorZusatz_storeMutation', null);
        commit('change_motorVoltage_storeMutation', null);
        commit('change_motorRatedSpeed_storeMutation', null);
        commit('change_certification_storeMutation', null);
        commit('change_preference_storeMutation', null);
        commit('change_temperatureMonitoring_storeMutation', null);
        commit('change_forcedVentilation_storeMutation', null);
        commit('change_operatingCurrentSplus_storeMutation', { value: null, invalid: null });

    },
    set_inputManual_Motor_toDefault2_storeAction({ commit }) {
        commit('change_motorTypeSelection_storeMutation', []);
        commit('change_motorSizeSelection_storeMutation', []);
        commit('change_motorPackageSelection_storeMutation', []);
        commit('change_motorZusatzSelection_storeMutation', {});
        commit('change_motorVoltageSelection_storeMutation', []);
        commit('change_motorRatedSpeedSelection_storeMutation', []);
        commit('change_temperatureMonitoringSelection_storeMutation', []);
        commit('change_get_upgradeSelection_storeMutation', []);
        commit('change_forcedVentilationSelection_storeMutation', []);

        commit('change_motorType_storeMutation', null);
        commit('change_motorSize_storeMutation', null);
        commit('change_motorPackage_storeMutation', null);
        commit('change_motorZusatz_storeMutation', null);
        commit('change_motorVoltage_storeMutation', null);
        commit('change_motorRatedSpeed_storeMutation', null);
        commit('change_temperatureMonitoring_storeMutation', null);
        commit('change_forcedVentilation_storeMutation', null);
        commit('change_operatingCurrentSplus_storeMutation', { value: null, invalid: null });
    },
    set_inputManual_TractionSheave_toDefault_storeAction({ commit }) {
        commit('change_deliverTractionSheaveSelection_storeMutation', []);
        commit('change_machiningForHandWheelSelection_storeMutation', []);
        commit('change_tractionSheave_DiameterAndWidthSelection_storeMutation', []);
        commit('change_ropeDiameterSelection_storeMutation', []);
        commit('change_grooveDistanceSelection_storeMutation', []);
        commit('change_numberOfGroovesSelection_storeMutation', []);
        commit('change_sheaveProfileSelection_storeMutation', []);
        commit('change_undercutAngleSelection_storeMutation', []);
        commit('change_vgrooveAngleSelection_storeMutation', []);
        commit('change_deliverTractionSheave_storeMutation', null);
        commit('change_showTractionSheaveDetails_storeMutation', null);
        commit('change_machiningForHandWheel_storeMutation', null);
        commit('change_tractionSheave_DiameterAndWidth_storeMutation', null);
        commit('change_ropeDiameter_storeMutation', null);
        commit('change_grooveDistance_storeMutation', null);
        commit('change_numberOfGrooves_storeMutation', null);
        commit('change_sheaveProfile_storeMutation', null);
        commit('change_undercutAngle_storeMutation', null);
        commit('change_vgrooveAngle_storeMutation', null);

    },
    set_inputManual_RopeGuard_toDefault_storeAction({ commit }) {
        commit('change_customerIdKidSelection_storeMutation', []);
        commit('change_positionMotorSelection_storeMutation', []);
        commit('change_ropePullSelection_storeMutation', []);
        commit('change_adjustabilitySelection_storeMutation', []);
        commit('change_thirdRopeGuard_storeMutation', []);
        commit('change_tnruev_storeMutation', null);
        commit('change_imagePathRopeGuard_storeMutation', null);
        commit('change_deliverRopeSelection_storeMutation', []);
        commit('change_tractionSheaveCoverSelection_storeMutation', {});
        commit('change_ropeClampSelection_storeMutation', {});
        commit('change_customerIdKid_storeMutation', null);
        commit('change_positionMotor_storeMutation', null);
        commit('change_ropePull_storeMutation', null);
        commit('change_adjustability_storeMutation', null);
        commit('change_deliverRope_storeMutation', null);
        commit('change_tractionSheaveCover_storeMutation', null);
        commit('change_ropeClamp_storeMutation', null);
        commit('change_deliverRopeDisabled_storeMutation', null);
        commit('change_customerIdKid_Copy_storeMutation', null);
        commit('change_positionMotor_Copy_storeMutation', null);
        commit('change_ropePull_Copy_storeMutation', null);
        commit('change_adjustability_Copy_storeMutation', null);
    },

    change_configurationStepsManualId_storeAction({ commit }, value) {
        commit('change_configurationStepsManualId_storeMutation', value);

        if (state.configurationStepsManualId != null) {
            sessionStorage.setItem('configurationStepsManualId', state.configurationStepsManualId);
        }
        else {
            if (sessionStorage.getItem('configurationStepsManualId')) {
                sessionStorage.removeItem('configurationStepsManualId')
            }
        }
    },

    check_configurationStepsManualId_storeAction({ commit }, value) {
        if (sessionStorage.getItem('configurationStepsManualId')) {
            commit('change_configurationStepsManualId_storeMutation', sessionStorage.getItem('configurationStepsManualId'));

        }
    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.inputsManual == "passed") {
            if (state.customerName != "SCH-MOD") {
                dispatch('delete_configuration_storeAction');
            }
        }
    },

    async get_informationAtkonf_storeAction({ rootState }) {
        const zlm = rootState.manualHome.ZLM;
        if (zlm != null) {
            await axios({
                method: 'post',
                url: '/api/inputsManual/get_informationAtkonf/' + zlm,
            })
                .then((response) => {

                    if (response.data != null) {
                        let blob = new Blob([response.data], { type: 'text/xml' });
                        let url = URL.createObjectURL(blob);
                        window.open(url);
                        URL.revokeObjectURL(url);
                    }
                    else {
                        alert('Keine Daten gefunden!');
                    }
                })
                .catch(() => {
                })
                .then(() => {
                })
        }
    },


    // SCH-MOD Modul
    async set_endPSG_storeAction({ commit, dispatch }) {
        await dispatch('delete_configuration_storeAction');
        await commit('change_customerName_storeMutation', null);
        await commit('change_forcedVentilationSelection_storeMutation', []);
        await dispatch('set_inputManual_toDefault_storeAction');
        await dispatch('set_inputManual_SCHMOD_toDefault_storeAction');
        await commit('set_showSCHMOD_storeMutation', false);
        dispatch('set_inputsManual_storeAction');
        //router.push({ params: { slug: 'inputsManual' } });
    },
    set_schmod_inputsManual_storeAction({ commit, dispatch }, value) {
        dispatch('check_SCHMOD_storeAction', value);
        //dispatch('get_SCHMOD_DefaultAndValuelists_storeAction');
    },

    async check_SCHMOD_Menu_storeAction({ rootState, commit, dispatch }, value) {
        const data = {
            configurationStepsManualId: value,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/check_SCHMOD',
            data: {
                data: data,
            }
        })
            .then((response) => {
                if (response.data == true) {
                    //router.push({ params: { slug: 'psgMenu' } });
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    async check_SCHMOD_storeAction({ rootState, commit, dispatch }, value) {
        const data = {
            configurationStepsManualId: value,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/check_SCHMOD',
            data: {
                data: data,
            }
        })
            .then((response) => {
                if (response.data == true) {
                    commit('set_showSCHMOD_storeMutation', false);
                    dispatch('set_inputManual_Motor_toDefault_storeAction');
                    dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
                    dispatch('set_inputManual_RopeGuard_toDefault_storeAction');
                    dispatch('set_inputManual_SCHMOD_toDefault_storeAction');
                    dispatch('get_SCHMOD_customerNameSelection_storeAction')
                }
                else {
                    commit('set_showSCHMOD_storeMutation', true);
                    dispatch('get_SCHMOD_DefaultAndValuelists_storeAction');
                }
            })
            .catch(() => {
            })
            .then(() => {
            })
    },

    async get_SCHMOD_DefaultAndValuelists_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_DefaultAndValuelists',
        })
            .then((response) => {
                commit('change_SCHMOD_locationProductionSelection_storeMutation', response.data.data.locationProductionSelection);
                if (state.schmod_locationProductionSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_locationProductionSelection.findIndex(i => (i.text.text == state.schmod_locationProduction));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_locationProduction_storeAction', state.schmod_locationProductionSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_locationProductionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_locationProduction_storeAction', state.schmod_locationProductionSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_certificationSelection_storeMutation', response.data.data.certificationSelection);
                if (state.schmod_certificationSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_certificationSelection.findIndex(i => (i.text.text == state.schmod_certification));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_certification_storeAction', state.schmod_certificationSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_certificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_certification_storeAction', state.schmod_certificationSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_ProdApSelection_storeMutation', response.data.data.prodApSelection);
                if (state.schmod_ProdApSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_ProdApSelection.findIndex(i => (i.text.text == state.prodAP));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_ProAp_storeAction', state.schmod_ProdApSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_ProAp_storeAction', state.schmod_ProdApSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_PSGSizeSelection_storeMutation', response.data.data.psgSizeSelection);
                if (state.schmod_PSGSizeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.text.text == state.schmod_PSGSize));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorIDSelection_storeMutation', response.data.data.motorIDSelection);
                if (state.schmod_motorIDSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.text.text == state.schmod_motorID));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorCOSNameSelection_storeMutation', response.data.data.motorCOSNameSelection);
                if (state.schmod_motorCOSNameSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.text.text == state.schmod_motorCOSName));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_motorVoltageSelection_storeMutation', response.data.data.motorVoltageSelection);
                if (state.schmod_motorVoltageSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.value == state.schmod_motorVoltage));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_inverterTypeSelection_storeMutation', response.data.data.inverterTypeSelection);
                if (state.schmod_inverterTypeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.text.text == state.schmod_inverterType));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_cableLengthSelection_storeMutation', response.data.data.cableLengthSelection);
                if (state.schmod_cableLengthSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthSelection.findIndex(i => (i.text.text == state.schmod_cableLength));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLength_storeAction', state.schmod_cableLengthSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLength_storeAction', state.schmod_cableLengthSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', response.data.data.cableLengthBrakeSelection);
                if (state.schmod_cableLengthBrakeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.text.text == state.schmod_cableLengthBrake));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_handWheelSelection_storeMutation', response.data.data.handWheelSelection);
                if (state.schmod_handWheelSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_handWheelSelection.findIndex(i => (i.text.text == state.schmod_handWheel));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_handWheel_storeAction', state.schmod_handWheelSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_handWheelSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_handWheel_storeAction', state.schmod_handWheelSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_handReleaseSelection_storeMutation', response.data.data.handReleaseSelection);
                if (state.schmod_handReleaseSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_handReleaseSelection.findIndex(i => (i.text.text == state.schmod_handRelease));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_handRelease_storeAction', state.schmod_handReleaseSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_handReleaseSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_handRelease_storeAction', state.schmod_handReleaseSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
                commit('change_SCHMOD_grooveSelection_storeMutation', response.data.data.grooveSelection);
                const firstIndex_whichMeetConditions_groove = state.schmod_grooveSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions_groove > -1) {
                    dispatch('change_SCHMOD_grooves_storeAction', state.schmod_grooveSelection[firstIndex_whichMeetConditions_groove]);
                }
                else {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                commit('change_SCHMOD_numberOfGroovesSelection_storeMutation', response.data.data.numberOfGroovesSelection);
                const firstIndex_whichMeetConditions_numberOfGrooves = state.schmod_numberOfGroovesSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions_numberOfGrooves > -1) {
                    dispatch('change_SCHMOD_numberOfGrooves_storeAction', state.schmod_numberOfGroovesSelection[firstIndex_whichMeetConditions_numberOfGrooves]);
                }
                else {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

                commit('change_SchModDetails_storeMuation', response.data.data.schModDetails);

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_locationProductionSelection == null || state.schmod_locationProductionSelection.length == 0) {
                    //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },

    async get_SCHMOD_MotorVoltageSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            locationProduction: state.schmod_locationProduction,
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_MotorVoltageSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_motorVoltageSelection_storeMutation', response.data);
                if (state.schmod_motorVoltageSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.value == state.schmod_motorVoltage));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorVoltage_storeAction', state.schmod_motorVoltageSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_inverterTypeSelection == null || state.schmod_inverterTypeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_PSGSizeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
            voltage: state.schmod_motorVoltage,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_PSGSizeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_PSGSizeSelection_storeMutation', response.data);
                if (state.schmod_PSGSizeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.text.text == state.schmod_PSGSize));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_PSGSizeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_PSGSize_storeAction', state.schmod_PSGSizeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_PSGSizeSelection == null || state.schmod_PSGSizeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_MotorIDSelection_storeAction({ commit, dispatch }) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            const data = {
                prodAp: state.schmod_ProAp,
                voltage: state.schmod_motorVoltage,
                pSG_Size: state.schmod_PSGSize,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_MotorIDSelection',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_SCHMOD_motorIDSelection_storeMutation', response.data);
                    if (state.schmod_motorIDSelection.length > 0) {
                        var firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.text.text == state.schmod_motorID));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            firstIndex_whichMeetConditions = state.schmod_motorIDSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_SCHMOD_motorID_storeAction', state.schmod_motorIDSelection[firstIndex_whichMeetConditions]);
                            }
                            else {
                                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                            }

                        }
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    if (state.schmod_motorIDSelection == null || state.schmod_motorIDSelection.length == 0) {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

                })
        }
    },
    async get_SCHMOD_MotorCOSNameSelection_storeAction({ commit, dispatch }) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            const data = {
                prodAp: state.schmod_ProAp,
                voltage: state.schmod_motorVoltage,
                pSG_Size: state.schmod_PSGSize,
                cosnam: state.schmod_motorID,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_MotorCOSNameSelection',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_SCHMOD_motorCOSNameSelection_storeMutation', response.data);
                    if (state.schmod_motorCOSNameSelection.length > 0) {
                        var firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.text.text == state.schmod_motorCOSName));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            firstIndex_whichMeetConditions = state.schmod_motorCOSNameSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_SCHMOD_motorCosName_storeAction', state.schmod_motorCOSNameSelection[firstIndex_whichMeetConditions]);
                            }
                            else {
                                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                            }

                        }
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    if (state.schmod_motorCOSNameSelection == null || state.schmod_motorCOSNameSelection.length == 0) {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

                })
        }
    },
    async get_SCHMOD_InverterTypeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            locationProduction: state.schmod_locationProduction,
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_InverterTypeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_inverterTypeSelection_storeMutation', response.data);
                if (state.schmod_inverterTypeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.text.text == state.schmod_inverterType));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_inverterTypeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_inverterType_storeAction', state.schmod_inverterTypeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_inverterTypeSelection == null || state.schmod_inverterTypeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_CableLengthBrakeSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_CableLengthBrakeSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', response.data);
                if (state.schmod_cableLengthBrakeSelection.length > 0) {
                    var firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.text.text == state.schmod_cableLengthBrake));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                    }
                    else {
                        firstIndex_whichMeetConditions = state.schmod_cableLengthBrakeSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_SCHMOD_cableLengthBrake_storeAction', state.schmod_cableLengthBrakeSelection[firstIndex_whichMeetConditions]);
                        }
                        else {
                            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                        }

                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_cableLengthBrakeSelection == null || state.schmod_cableLengthBrakeSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },
    async get_SCHMOD_GrooveSelection_storeAction({ commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = {
            prodAp: state.schmod_ProAp,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_GrooveSelection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_SCHMOD_grooveSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.schmod_grooveSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_SCHMOD_grooves_storeAction', state.schmod_grooveSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                if (state.schmod_grooveSelection == null || state.schmod_grooveSelection.length == 0) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

            })
    },

    change_SCHMOD_locationProduction_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_locationProduction_storeMutation', value.text.text);
        //dispatch('get_SCHMOD_ProdApSelection_storeAction');
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_ProAp_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_ProAp_storeMutation', value.text.text);
        const firstIndex_whichMeetConditions_prodAp = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_prodAp > -1) {
            dispatch('get_SCHMOD_MotorVoltageSelection_storeAction');
            dispatch('get_SCHMOD_InverterTypeSelection_storeAction');
            dispatch('get_SCHMOD_CableLengthBrakeSelection_storeAction');
            dispatch('get_SCHMOD_GrooveSelection_storeAction');
        }
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    async change_SCHMOD_PSGSize_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNomAndPSGSize = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.psgglo == value.text.text));
            if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                commit('change_SCHMOD_PSGSize_storeMutation', value.text.text);
                await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
                if (state.schmod_motorID != null) {
                    filter_ProApAndUsNomAndPSGSize = filter_ProApAndUsNomAndPSGSize.filter(i => (i.cosnam == state.schmod_motorID));
                    if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorID_storeMutation', null);
                    }
                    await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                }
                else {
                    await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                }
                if (state.schmod_motorCOSName != null) {
                    filter_ProApAndUsNomAndPSGSize = filter_ProApAndUsNomAndPSGSize.filter(i => (i.psgnam == state.schmod_motorCOSName));
                    if (filter_ProApAndUsNomAndPSGSize != null && filter_ProApAndUsNomAndPSGSize.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorCosName_storeMutation', null);
                    }
                }
            }
            else {
                commit('change_SCHMOD_PSGSize_storeMutation', null);
                await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
            }
        }
        else {
            commit('change_SCHMOD_PSGSize_storeMutation', null);

            await dispatch('get_SCHMOD_MotorIDSelection_storeAction')
            await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    async change_SCHMOD_motorID_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNom = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.cosnam == value.text.text));
            if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                commit('change_SCHMOD_motorID_storeMutation', value.text.text);
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
                const filter_PSGSize = structuredClone(filter_ProApAndUsNom)
                if (state.schmod_motorCOSName != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgnam == state.schmod_motorCOSName));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        commit('change_SCHMOD_motorCosName_storeMutation', null);
                    }
                }
                if (state.schmod_PSGSize != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgglo == state.schmod_PSGSize));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                        }
                        else {
                            if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                                commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                            }
                            else {
                                commit('change_SCHMOD_PSGSize_storeMutation', null);
                            }
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                        }
                    }
                }
            }
            else {
                commit('change_SCHMOD_motorID_storeMutation', null);
                await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
            }
        }
        else {
            commit('change_SCHMOD_motorID_storeMutation', null);
            await dispatch('get_SCHMOD_MotorCOSNameSelection_storeAction')
        }

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_motorCosName_storeAction({ commit, dispatch }, value) {
        if (state.schmod_ProAp != null && state.schmod_motorVoltage != null && value != null && value.hasOwnProperty('text') && value.text.text != null) {
            var filter_ProApAndUsNom = state.schModDetails.filter(i => (i.prodap == state.schmod_ProAp && i.usNom == state.schmod_motorVoltage && i.psgnam == value.text.text));
            if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                commit('change_SCHMOD_motorCosName_storeMutation', value.text.text);
                const filter_PSGSize = structuredClone(filter_ProApAndUsNom)
                if (state.schmod_motorID != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == state.schmod_motorID));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_motorID_storeMutation', filter_PSGSize[0].cosnam);
                            filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == filter_PSGSize[0].cosnam));
                        }
                        else {
                            commit('change_SCHMOD_motorID_storeMutation', null);
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_motorID_storeMutation', filter_ProApAndUsNom[0].cosnam);
                        filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.cosnam == filter_ProApAndUsNom[0].cosnam));
                    }
                    else {
                        commit('change_SCHMOD_motorID_storeMutation', null);
                    }
                }
                if (state.schmod_PSGSize != null) {
                    filter_ProApAndUsNom = filter_ProApAndUsNom.filter(i => (i.psgglo == state.schmod_PSGSize));
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                    }
                    else {
                        if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                        }
                        else {
                            if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                                commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                            }
                            else {
                                commit('change_SCHMOD_PSGSize_storeMutation', null);
                            }
                        }
                    }
                }
                else {
                    if (filter_ProApAndUsNom != null && filter_ProApAndUsNom.length > 0) {
                        commit('change_SCHMOD_PSGSize_storeMutation', filter_ProApAndUsNom[0].psgglo);
                    }
                    else {
                        if (filter_PSGSize != null && filter_PSGSize.length > 0) {
                            commit('change_SCHMOD_PSGSize_storeMutation', filter_PSGSize[0].psgglo);
                        }
                    }
                }
            }
            else {
                commit('change_SCHMOD_motorCosName_storeMutation', null);
            }
        }
        else {
            commit('change_SCHMOD_motorCosName_storeMutation', null);
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_motorVoltage_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_motorVoltage_storeMutation', value.value);
        //const firstIndex_whichMeetConditions_prodAp = state.schmod_ProdApSelection.findIndex(i => (i.selected == true));
        //const firstIndex_whichMeetConditions_voltage = state.schmod_motorVoltageSelection.findIndex(i => (i.selected == true));
        //if (firstIndex_whichMeetConditions_prodAp > -1 && firstIndex_whichMeetConditions_voltage > -1) {
        //    dispatch('get_SCHMOD_PSGSizeSelection_storeAction')
        //}
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);
        dispatch('get_SCHMOD_PSGSizeSelection_storeAction');

        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_inverterType_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_inverterType_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_cableLength_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_cableLength_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_cableLengthBrake_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_cableLengthBrake_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_handWheel_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_handWheel_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_handRelease_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_handRelease_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_grooveDistance_storeAction({ commit, dispatch }, value) {
        if (value != null && value.trim() != '') {
            const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
            valueAndValueValidationCriteria.value = value;
            valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
            valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 20;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThan;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 300;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.lessThanOrEqualTo;
            const schmod_grooveDistance_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);

            commit('change_SCHMOD_grooveDistance_storeMutation', schmod_grooveDistance_ValidationResult);
        }
        else {
            commit('change_SCHMOD_grooveDistance_storeMutation', { value: null, invalid: null });
        }
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_grooves_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_groove_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_numberOfGrooves_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_numberOfGrooves_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },
    change_SCHMOD_certification_storeAction({ commit, dispatch }, value) {
        commit('change_SCHMOD_certification_storeMutation', value.text.text);
        dispatch('checkIf_SCHMOD_inputsManual_areValid_storeAction');
    },

    async insertOrUpdate_schmod_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('cables/change_cablesSelection_toDefault_storeAction');
        this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        var schmodGrooveDistance = null;
        if (state.schmod_grooveDistance != null && state.schmod_grooveDistance.invalid == false) {
            schmodGrooveDistance = state.schmod_grooveDistance.value;
        }

        var schmodMIb1 = null;
        if (state.operatingCurrentSplus != null && state.operatingCurrentSplus.invalid == false) {
            schmodMIb1 = state.operatingCurrentSplus.value;
        }

        const pSG_Variante = {
            produktionsort: state.schmod_locationProduction,
            zertifizierung: state.schmod_certification,
            prodAP: state.schmod_ProAp,
            voltage: state.schmod_motorVoltage,
            pSG_GlobalID_BG: state.schmod_PSGSize,
            motorID: state.schmod_motorID,
            pSGNAM: state.schmod_motorCOSName,
            pSG_Reglerart: state.schmod_inverterType,
            pSG_Kabellaenge: state.schmod_cableLength,
            pSG_KabellaengeBremse: state.schmod_cableLengthBrake,
            pSG_Handradanbau: state.schmod_handWheel,
            pSG_Handlueftung: state.schmod_handRelease,
            rillenabstand: schmodGrooveDistance,
            rillenform: state.schmod_groove,
            pSG_No_of_rope_Grooves: state.schmod_numberOfGrooves,
            mIb1: schmodMIb1,
        }

        dispatch('set_inputManual_Motor_toDefault_storeAction');
        dispatch('set_inputManual_TractionSheave_toDefault_storeAction');
        dispatch('set_inputManual_RopeGuard_toDefault_storeAction');

        const configurationStepsManualId = state.configurationStepsManualId;

        const data = {
            pSG_Variante: pSG_Variante,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/insertOrUpdate_schmod',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    //this.commit('configurations/change_configurationOverviewsManualId_storeMutation', response.data.data.configurationOverviewsManualId)
                    dispatch('change_configurationStepsManualId_storeAction', response.data.data.configurationStepsManualId);

                    //dispatch('set_inputsManual_storeAction');
                    dispatch('set_inputManual_SCHMOD_toDefault_storeAction');

                    dispatch('get_SCHMOD_customerNameSelection_storeAction')

                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },


    async get_SCHMOD_Motordetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        if (configurationStepsManualId != null) {
            const data = {
                kid: state.customerName,
                temperatureMonitoring: state.temperatureMonitoring,
                mLS_Law: state.certification,
                preference: state.preference,
                sM250_TS280: state.sM250_TS280,
                configurationStepsManualId: configurationStepsManualId,
            }

            await axios({
                method: 'post',
                url: '/api/inputsManual/get_SCHMOD_Motordetails',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        if (response.data.data.motordetails.motorTypeSelection != null) {
                            commit('change_motorTypeSelection_storeMutation', response.data.data.motordetails.motorTypeSelection);
                            const firstIndex_whichMeetConditions = state.motorTypeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorType_storeAction', state.motorTypeSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorTypeSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorSizeSelection != null) {
                            commit('change_motorSizeSelection_storeMutation', response.data.data.motordetails.motorSizeSelection);
                            const firstIndex_whichMeetConditions = state.motorSizeSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorSize_storeAction', state.motorSizeSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorSizeSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorPackageSelection != null) {
                            commit('change_motorPackageSelection_storeMutation', response.data.data.motordetails.motorPackageSelection);
                            const firstIndex_whichMeetConditions = state.motorPackageSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorPackage_storeAction', state.motorPackageSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorPackageSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorZusatzSelection != null) {
                            commit('change_motorZusatzSelection_storeMutation', response.data.data.motordetails.motorZusatzSelection);
                            const firstIndex_whichMeetConditions = state.motorZusatzSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorZusatz_storeAction', state.motorZusatzSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorZusatzSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorVoltageSelection != null) {
                            commit('change_motorVoltageSelection_storeMutation', response.data.data.motordetails.motorVoltageSelection);
                            const firstIndex_whichMeetConditions = state.motorVoltageSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorVoltage_storeAction', state.motorVoltageSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorVoltageSelection_storeMutation', []);
                        }
                        if (response.data.data.motordetails.motorRatedSpeedSelection != null) {
                            commit('change_motorRatedSpeedSelection_storeMutation', response.data.data.motordetails.motorRatedSpeedSelection);
                            const firstIndex_whichMeetConditions = state.motorRatedSpeedSelection.findIndex(i => (i.selected == true));
                            if (firstIndex_whichMeetConditions > -1) {
                                dispatch('change_motorRatedSpeed_storeAction', state.motorRatedSpeedSelection[firstIndex_whichMeetConditions]);
                            }
                        } else {
                            commit('change_motorRatedSpeedSelection_storeMutation', []);
                        }

                        //dispatch('get_upgradeSelection_storeAction');
                    }
                    else {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
                .then(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                })
        }
        else {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
    },

    set_inputManual_SCHMOD_toDefault_storeAction({ commit }) {
        //commit('change_SCHMOD_onok_storeMutation', null);
        //commit('change_SCHMOD_inok_storeMutation', null);
        commit('change_SCHMOD_locationProductionSelection_storeMutation', []);
        commit('change_SCHMOD_ProdApSelection_storeMutation', []);
        commit('change_SCHMOD_PSGSizeSelection_storeMutation', []);
        commit('change_SCHMOD_motorIDSelection_storeMutation', []);
        commit('change_SCHMOD_motorCOSNameSelection_storeMutation', []);
        commit('change_SCHMOD_motorVoltageSelection_storeMutation', []);
        commit('change_SCHMOD_inverterTypeSelection_storeMutation', []);
        commit('change_SCHMOD_cableLengthSelection_storeMutation', []);
        commit('change_SCHMOD_cableLengthBrakeSelection_storeMutation', []);
        commit('change_SCHMOD_handWheelSelection_storeMutation', []);
        commit('change_SCHMOD_handReleaseSelection_storeMutation', []);
        commit('change_SCHMOD_grooveSelection_storeMutation', []);
        commit('change_SCHMOD_numberOfGroovesSelection_storeMutation', []);
        commit('change_SCHMOD_locationProduction_storeMutation', null);
        commit('change_SCHMOD_ProAp_storeMutation', null);
        commit('change_SCHMOD_PSGSize_storeMutation', null);
        commit('change_SCHMOD_motorID_storeMutation', null);
        commit('change_SCHMOD_motorCosName_storeMutation', null);
        commit('change_SCHMOD_motorVoltage_storeMutation', null);
        commit('change_SCHMOD_inverterType_storeMutation', null);
        commit('change_SCHMOD_cableLength_storeMutation', null);
        commit('change_SCHMOD_cableLengthBrake_storeMutation', null);
        commit('change_SCHMOD_handWheel_storeMutation', null);
        commit('change_SCHMOD_handRelease_storeMutation', null);
        commit('change_SCHMOD_grooveDistance_storeMutation', { value: null, invalid: null });
        commit('change_SCHMOD_groove_storeMutation', null);
        commit('change_SCHMOD_numberOfGrooves_storeMutation', null);

    },


    async get_SCHMOD_tractionSheavedetails_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = state.configurationStepsManualId;
        var upgrade = null;
        const firstIndex_whichMeetConditions_upgrade = state.upgradeSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions_upgrade > -1) {
            upgrade = state.upgradeSelection[firstIndex_whichMeetConditions_upgrade].text;
        }

        const data = {
            motorType: state.motorType,
            motorSize: state.motorSize,
            motorPackage: state.motorPackage,
            motorZusatz: state.motorZusatz,
            motorVoltage: state.motorVoltage,
            motorRatedSpeed: state.motorRatedSpeed,
            temperatureMonitoring: state.temperatureMonitoring,
            upgrade: upgrade,
            tractionSheaveDiameter: state.tractionsheave_DiameterAndWidth.diameter,
            tractionSheaveWidth: state.tractionsheave_DiameterAndWidth.width,
            motorLink: state.tractionsheave_DiameterAndWidth.motorLink,
            kid: state.customerName,
            lTHD: state.machiningForHandWheel,
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/inputsManual/get_SCHMOD_tractionSheavedetails',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection != null) {
                        commit('change_ropeDiameterSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveRopeDiameterSelection);
                        const firstIndex_whichMeetConditions = state.ropeDiameterSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_ropeDiameter_storeAction', state.ropeDiameterSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_ropeDiameterSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection != null) {
                        commit('change_grooveDistanceSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveGrooveDistanceSelection);
                        const firstIndex_whichMeetConditions = state.grooveDistanceSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_grooveDistance_storeAction', state.grooveDistanceSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_grooveDistanceSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection != null) {
                        commit('change_numberOfGroovesSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveNumberOfRopesSelection);
                        const firstIndex_whichMeetConditions = state.numberOfGroovesSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_numberOfGrooves_storeAction', state.numberOfGroovesSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_numberOfGroovesSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection != null) {
                        commit('change_sheaveProfileSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveProfilesSelection);
                        const firstIndex_whichMeetConditions = state.sheaveProfileSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_sheaveProfile_storeAction', state.sheaveProfileSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_sheaveProfileSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection != null) {
                        commit('change_undercutAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveUndercutAngleSelection);
                        const firstIndex_whichMeetConditions = state.undercutAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_undercutAngle_storeAction', state.undercutAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_undercutAngleSelection_storeMutation', []);
                    }
                    if (response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection != null) {
                        commit('change_vgrooveAngleSelection_storeMutation', response.data.data.tractionSheavedetails.tractionSheaveVgrooveAngleSelection);
                        const firstIndex_whichMeetConditions = state.vgrooveAngleSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            dispatch('change_vgrooveAngle_storeAction', state.vgrooveAngleSelection[firstIndex_whichMeetConditions]);
                        }
                    } else {
                        commit('change_vgrooveAngleSelection_storeMutation', []);
                    }

                    //dispatch('get_upgradeSelection_storeAction');
                }
                else {
                }
            })
            .catch(() => {
            })
            .then(() => {
            })

    },


    checkIf_SCHMOD_inputsManual_areValid_storeAction({ rootState, dispatch, commit }) {
        var numberOf_inputs_whichAreInvalid = 0;
        if (state.schmod_locationProduction == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_certification == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_ProAp == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorVoltage == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_PSGSize == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorID == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_motorCOSName == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_inverterType == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_cableLength == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_cableLengthBrake == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_handWheel == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_handRelease == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_groove == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.schmod_numberOfGrooves == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            commit('change_schmod_Valid_storeMuation', false);
        }
        else {
            commit('change_schmod_Valid_storeMuation', true);
        }
    },

}

const mutations = {
    change_sM250_TS280_storeMutation(state, payload) {
        state.sM250_TS280 = payload;
    },
    change_note_storeMutation(state, payload) {
        state.note = payload;
    },
    change_cmd_storeMutation(state, payload) {
        state.cmd = payload;
    },
    change_projekt_storeMutation(state, payload) {
        state.projekt = payload;
    },
    change_sanum_storeMutation(state, payload) {
        state.sanum = payload;
    },
    change_inok_storeMutation(state, payload) {
        state.inok = payload;
    },
    change_onok_storeMutation(state, payload) {
        state.onok = payload;
    },
    change_mvlift_storeMutation(state, payload) {
        state.mvlift = payload;
    },
    change_mis_storeMutation(state, payload) {
        state.mis = payload;
    },
    change_customerNameSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.customerNameSelection = payload;
    },
    change_motorTypeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.motorTypeSelection = payload;
    },
    change_motorSizeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.motorSizeSelection = payload;
    },
    change_motorPackageSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.motorPackageSelection = payload;
    },
    change_motorZusatzSelection_storeMutation(state, payload) {
        state.motorZusatzSelection = payload;
    },
    change_motorVoltageSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.motorVoltageSelection = payload;
    },
    change_motorRatedSpeedSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.motorRatedSpeedSelection = payload;
    },
    change_certificationSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.certificationSelection = payload;
    },
    change_preferenceSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.preferenceSelection = payload;
    },
    change_temperatureMonitoringSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.temperatureMonitoringSelection = payload;
    },
    change_get_upgradeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.upgradeSelection = payload
    },
    change_forcedVentilationSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.forcedVentilationSelection = payload;
    },
    change_customerNameSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.customerNameSelection.length; i++) {
            state.customerNameSelection[i].selected = false;

            if (state.customerNameSelection[i].text.text == payload) {
                state.customerNameSelection[i].selected = true;
            }
        }
    },
    change_motorTypeSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorTypeSelection.length; i++) {
            state.motorTypeSelection[i].selected = false;

            if (state.motorTypeSelection[i].text.text == payload) {
                state.motorTypeSelection[i].selected = true;
            }
        }
    },
    change_motorSizeSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorSizeSelection.length; i++) {
            state.motorSizeSelection[i].selected = false;
            if (payload != null && payload.hasOwnProperty('baug') && state.motorSizeSelection[i].text.text == payload.baug &&
                state.motorSizeSelection[i].disabled == false &&
                state.motorSizeSelection[i].typ == payload.typ) {
                state.motorSizeSelection[i].selected = true;
            }
        }
    },
    change_motorPackageSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorPackageSelection.length; i++) {
            state.motorPackageSelection[i].selected = false;

            if (payload != null && payload.hasOwnProperty('fe') && state.motorPackageSelection[i].text.text == payload.fe &&
                state.motorPackageSelection[i].disabled == false &&
                state.motorPackageSelection[i].typ == payload.typ &&
                state.motorPackageSelection[i].baug == payload.baug) {
                state.motorPackageSelection[i].selected = true;
            }
        }
    },
    change_motorZusatzSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorZusatzSelection.length; i++) {
            state.motorZusatzSelection[i].selected = false;

            if (payload != null && payload.hasOwnProperty('zusatz') && state.motorZusatzSelection[i].text.text == payload.zusatz &&
                state.motorZusatzSelection[i].disabled == false &&
                state.motorZusatzSelection[i].typ == payload.typ &&
                state.motorZusatzSelection[i].baug == payload.baug &&
                state.motorZusatzSelection[i].fe == payload.fe) {
                state.motorZusatzSelection[i].selected = true;
            }
        }
    },
    change_motorZusatzOptionalSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorZusatzSelection.length; i++) {
            if (payload != null && payload.hasOwnProperty('zusatz') && state.motorZusatzSelection[i].text.text == payload.zusatz &&
                state.motorZusatzSelection[i].disabled == false &&
                state.motorZusatzSelection[i].typ == payload.typ &&
                state.motorZusatzSelection[i].baug == payload.baug &&
                state.motorZusatzSelection[i].fe == payload.fe) {
                if (state.motorZusatzSelection[i].selected == true) {
                    state.motorZusatzSelection[i].selected = false;
                }
                else {
                    state.motorZusatzSelection[i].selected = true;
                }
            }
            else {
                state.motorZusatzSelection[i].selected = false;
            }
        }
    },
    change_motorVoltageSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorVoltageSelection.length; i++) {
            state.motorVoltageSelection[i].selected = false;

            if (payload != null && payload.hasOwnProperty('u1') && state.motorVoltageSelection[i].value == payload.u1 &&
                state.motorVoltageSelection[i].disabled == false &&
                state.motorVoltageSelection[i].typ == payload.typ &&
                state.motorVoltageSelection[i].baug == payload.baug &&
                state.motorVoltageSelection[i].fe == payload.fe &&
                state.motorVoltageSelection[i].zusatz == payload.zusatz) {
                state.motorVoltageSelection[i].selected = true;
            }
        }
    },
    change_motorRatedSpeedSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorRatedSpeedSelection.length; i++) {
            state.motorRatedSpeedSelection[i].selected = false;
            if (payload != null && payload.hasOwnProperty('nr') && state.motorRatedSpeedSelection[i].value == payload.nr &&
                state.motorRatedSpeedSelection[i].disabled == false &&
                state.motorRatedSpeedSelection[i].typ == payload.typ &&
                state.motorRatedSpeedSelection[i].baug == payload.baug &&
                state.motorRatedSpeedSelection[i].fe == payload.fe &&
                state.motorRatedSpeedSelection[i].zusatz == payload.zusatz &&
                state.motorRatedSpeedSelection[i].u1 == payload.u1) {
                state.motorRatedSpeedSelection[i].selected = true;
            }
        }
    },
    change_certificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.certificationSelection.length; i++) {
            state.certificationSelection[i].selected = false;

            if (state.certificationSelection[i].text.text == payload) {
                state.certificationSelection[i].selected = true;
            }
        }
    },
    change_preferenceSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.preferenceSelection.length; i++) {
            state.preferenceSelection[i].selected = false;

            if (state.preferenceSelection[i].text.text == payload) {
                state.preferenceSelection[i].selected = true;
            }
        }
    },
    change_temperatureMonitoringSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.temperatureMonitoringSelection.length; i++) {
            state.temperatureMonitoringSelection[i].selected = false;
            if (state.temperatureMonitoringSelection[i].text == payload) {
                state.temperatureMonitoringSelection[i].selected = true;
            }
        }
    },
    change_forcedVentilationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.forcedVentilationSelection.length; i++) {
            state.forcedVentilationSelection[i].selected = false;
            if (payload != null && payload != '' && state.forcedVentilationSelection[i].text.text == payload.text.text) {
                state.forcedVentilationSelection[i].selected = true;
            }
        }
    },
    change_upgradeSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.upgradeSelection.length; i++) {
            state.upgradeSelection[i].selected = false;

            if (state.upgradeSelection[i].text == payload) {
                state.upgradeSelection[i].selected = true;
            }
        }
    },

    change_customerName_storeMutation(state, payload) {
        state.customerName = payload;
    },
    change_motorType_storeMutation(state, payload) {
        state.motorType = payload;
    },
    change_motorSize_storeMutation(state, payload) {
        state.motorSize = payload;
    },
    change_motorPackage_storeMutation(state, payload) {
        state.motorPackage = payload;
    },
    change_motorZusatz_storeMutation(state, payload) {
        state.motorZusatz = payload;
    },
    change_motorVoltage_storeMutation(state, payload) {
        state.motorVoltage = payload;
    },
    change_motorRatedSpeed_storeMutation(state, payload) {
        state.motorRatedSpeed = payload;
    },
    change_certification_storeMutation(state, payload) {
        state.certification = payload;
    },
    change_preference_storeMutation(state, payload) {
        state.preference = payload;
    },
    change_temperatureMonitoring_storeMutation(state, payload) {
        state.temperatureMonitoring = payload;
    },
    change_forcedVentilation_storeMutation(state, payload) {
        state.forcedVentilation = payload;
    },
    change_operatingCurrentSplus_storeMutation(state, payload) {
        state.operatingCurrentSplus = payload;
    },

    change_motorSize_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.motorSizeSelection.length; i++) {
            state.motorSizeSelection[i].disabled = false;
            state.motorSizeSelection[i].selected = false;

            if (state.motorSizeSelection[i].typ != payload) {
                state.motorSizeSelection[i].disabled = true;
            }
        }
    },
    change_motorPackage_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.motorPackageSelection.length; i++) {
            state.motorPackageSelection[i].disabled = false;
            state.motorPackageSelection[i].selected = false;

            if (state.motorPackageSelection[i].typ != payload.typ ||
                state.motorPackageSelection[i].baug != payload.baug) {

                state.motorPackageSelection[i].disabled = true;
            }
        }
    },
    change_motorZusatz_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.motorZusatzSelection.length; i++) {
            state.motorZusatzSelection[i].disabled = false;
            state.motorZusatzSelection[i].selected = false;

            if (state.motorZusatzSelection[i].typ != payload.typ ||
                state.motorZusatzSelection[i].baug != payload.baug ||
                state.motorZusatzSelection[i].fe != payload.fe) {

                state.motorZusatzSelection[i].disabled = true;
            }
        }
    },
    change_motorVoltage_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.motorVoltageSelection.length; i++) {
            state.motorVoltageSelection[i].disabled = false;
            state.motorVoltageSelection[i].selected = false;

            if (state.motorVoltageSelection[i].typ != payload.typ ||
                state.motorVoltageSelection[i].baug != payload.baug ||
                state.motorVoltageSelection[i].fe != payload.fe ||
                state.motorVoltageSelection[i].zusatz != payload.zusatz) {

                state.motorVoltageSelection[i].disabled = true;
            }
        }
    },
    change_motorRatedSpeed_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.motorRatedSpeedSelection.length; i++) {
            state.motorRatedSpeedSelection[i].disabled = false;
            state.motorRatedSpeedSelection[i].selected = false;

            if (state.motorRatedSpeedSelection[i].typ != payload.typ ||
                state.motorRatedSpeedSelection[i].baug != payload.baug ||
                state.motorRatedSpeedSelection[i].fe != payload.fe ||
                state.motorRatedSpeedSelection[i].zusatz != payload.zusatz ||
                state.motorRatedSpeedSelection[i].u1 != payload.u1) {

                state.motorRatedSpeedSelection[i].disabled = true;
            }
        }
    },

    // Preselection traction sheave
    change_deliverTractionSheaveSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.deliverTractionSheaveSelection = payload;
    },
    change_machiningForHandWheelSelection_storeMutation(state, payload) {
        state.machiningForHandWheelSelection = payload;
    },
    change_tractionSheave_DiameterAndWidthSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.tractionSheave_DiameterAndWidthSelection = payload;
    },
    change_ropeDiameterSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.ropeDiameterSelection = payload;
    },
    change_undercutAngleSelection_storeMutation(state, payload) {
        state.undercutAngleSelection = payload;
    },
    change_vgrooveAngleSelection_storeMutation(state, payload) {
        state.vgrooveAngleSelection = payload;
    },
    change_grooveDistanceSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.grooveDistanceSelection = payload;
    },
    change_numberOfGroovesSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.numberOfGroovesSelection = payload;
    },
    change_sheaveProfileSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.sheaveProfileSelection = payload;
    },
    change_deliverTractionSheaveSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.deliverTractionSheaveSelection.length; i++) {
            state.deliverTractionSheaveSelection[i].selected = false;
            if (payload != null && payload != '' && state.deliverTractionSheaveSelection[i].text.text == payload) {
                state.deliverTractionSheaveSelection[i].selected = true;
            }
        }
    },
    change_machiningForHandWheelSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.machiningForHandWheelSelection.length; i++) {
            state.machiningForHandWheelSelection[i].selected = false;
            if (payload != null && payload != '' && state.machiningForHandWheelSelection[i].text.text == payload) {
                state.machiningForHandWheelSelection[i].selected = true;
            }
        }
    },
    change_tractionSheave_DiameterAndWidthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.tractionSheave_DiameterAndWidthSelection.length; i++) {
            state.tractionSheave_DiameterAndWidthSelection[i].selected = false;
            if (payload != null && payload != '' && state.tractionSheave_DiameterAndWidthSelection[i].text == payload.text) {
                state.tractionSheave_DiameterAndWidthSelection[i].selected = true;
            }
        }
    },
    change_ropeDiameterSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.ropeDiameterSelection.length; i++) {
            state.ropeDiameterSelection[i].selected = false;
            if (payload != null && payload != '' && state.ropeDiameterSelection[i].value == payload.sd &&
                state.ropeDiameterSelection[i].disabled == false) {
                state.ropeDiameterSelection[i].selected = true;
            }
        }
    },
    change_undercutAngleSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.undercutAngleSelection.length; i++) {
            state.undercutAngleSelection[i].selected = false;
            if (payload != null && payload != '' && state.undercutAngleSelection[i].value == payload.uwi &&
                state.undercutAngleSelection[i].disabled == false &&
                state.undercutAngleSelection[i].sd == payload.sd &&
                state.undercutAngleSelection[i].ra == payload.ra &&
                state.undercutAngleSelection[i].rz == payload.rz &&
                state.undercutAngleSelection[i].arill == payload.arill) {
                state.undercutAngleSelection[i].selected = true;
            }
        }
    },
    change_vgrooveAngleSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.vgrooveAngleSelection.length; i++) {
            state.vgrooveAngleSelection[i].selected = false;
            if (payload != null && payload != '' && state.vgrooveAngleSelection[i].value == payload.kwi &&
                state.vgrooveAngleSelection[i].disabled == false &&
                state.vgrooveAngleSelection[i].sd == payload.sd &&
                state.vgrooveAngleSelection[i].ra == payload.ra &&
                state.vgrooveAngleSelection[i].rz == payload.rz &&
                state.vgrooveAngleSelection[i].arill == payload.arill &&
                state.vgrooveAngleSelection[i].uwi == payload.uwi) {
                state.vgrooveAngleSelection[i].selected = true;
            }
        }
    },
    change_grooveDistanceSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.grooveDistanceSelection.length; i++) {
            state.grooveDistanceSelection[i].selected = false;
            if (payload != null && payload != '' && state.grooveDistanceSelection[i].value == payload.ra &&
                state.grooveDistanceSelection[i].disabled == false &&
                state.grooveDistanceSelection[i].sd == payload.sd) {
                state.grooveDistanceSelection[i].selected = true;
            }
        }
    },
    change_numberOfGroovesSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.numberOfGroovesSelection.length; i++) {
            state.numberOfGroovesSelection[i].selected = false;
            if (payload != null && payload != '' && state.numberOfGroovesSelection[i].value == payload.rz &&
                state.numberOfGroovesSelection[i].disabled == false &&
                state.numberOfGroovesSelection[i].sd == payload.sd &&
                state.numberOfGroovesSelection[i].ra == payload.ra) {
                state.numberOfGroovesSelection[i].selected = true;
            }
        }
    },
    change_sheaveProfileSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.sheaveProfileSelection.length; i++) {
            state.sheaveProfileSelection[i].selected = false;
            if (payload != null && payload != '' && state.sheaveProfileSelection[i].text.text == payload.arill &&
                state.sheaveProfileSelection[i].sd == payload.sd &&
                state.sheaveProfileSelection[i].ra == payload.ra &&
                state.sheaveProfileSelection[i].rz == payload.rz) {
                state.sheaveProfileSelection[i].selected = true;
            }
        }
    },
    change_deliverTractionSheave_storeMutation(state, payload) {
        state.deliverTractionSheave = payload;
    },
    change_showTractionSheaveDetails_storeMutation(state, payload) {
        state.showTractionSheaveDetails = payload;
    },
    change_machiningForHandWheel_storeMutation(state, payload) {
        state.machiningForHandWheel = payload;
    },
    change_tractionSheave_DiameterAndWidth_storeMutation(state, payload) {
        state.tractionsheave_DiameterAndWidth = payload;
    },
    change_ropeDiameter_storeMutation(state, payload) {
        state.ropeDiameter = payload;
    },
    change_undercutAngle_storeMutation(state, payload) {
        state.undercutAngle = payload;
    },
    change_vgrooveAngle_storeMutation(state, payload) {
        state.vgrooveAngle = payload;
    },
    change_grooveDistance_storeMutation(state, payload) {
        state.grooveDistance = payload;
    },
    change_numberOfGrooves_storeMutation(state, payload) {
        state.numberOfGrooves = payload;
    },
    change_sheaveProfile_storeMutation(state, payload) {
        state.sheaveProfile = payload;
    },
    change_sheaveProfile_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.sheaveProfileSelection.length; i++) {
            state.sheaveProfileSelection[i].disabled = false;
            state.sheaveProfileSelection[i].selected = false;

            if (state.sheaveProfileSelection[i].sd != payload.sd ||
                state.sheaveProfileSelection[i].ra != payload.ra ||
                state.sheaveProfileSelection[i].rz != payload.rz ||
                state.sheaveProfileSelection[i].arill != payload.arill ||
                state.sheaveProfileSelection[i].uwi != payload.uwi ||
                state.sheaveProfileSelection[i].kwi != payload.kwi) {
                state.sheaveProfileSelection[i].disabled = true;
            }
        }
    },
    change_undercutAngle_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.undercutAngleSelection.length; i++) {
            state.undercutAngleSelection[i].disabled = false;
            state.undercutAngleSelection[i].selected = false;

            if (state.undercutAngleSelection[i].sd != payload.sd ||
                state.undercutAngleSelection[i].ra != payload.ra ||
                state.undercutAngleSelection[i].rz != payload.rz ||
                state.undercutAngleSelection[i].arill != payload.arill) {
                state.undercutAngleSelection[i].disabled = true;
            }
        }
    },
    change_vgrooveAngle_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.vgrooveAngleSelection.length; i++) {
            state.vgrooveAngleSelection[i].disabled = false;
            state.vgrooveAngleSelection[i].selected = false;

            if (state.vgrooveAngleSelection[i].sd != payload.sd ||
                state.vgrooveAngleSelection[i].ra != payload.ra ||
                state.vgrooveAngleSelection[i].rz != payload.rz ||
                state.vgrooveAngleSelection[i].arill != payload.arill ||
                state.vgrooveAngleSelection[i].uwi != payload.uwi) {
                state.vgrooveAngleSelection[i].disabled = true;
            }
        }
    },
    change_grooveDistance_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.grooveDistanceSelection.length; i++) {
            state.grooveDistanceSelection[i].disabled = false;
            state.grooveDistanceSelection[i].selected = false;

            if (state.grooveDistanceSelection[i].sd != payload.sd) {
                state.grooveDistanceSelection[i].disabled = true;
            }
        }
    },
    change_numberOfGrooves_Disabled_storeMuation(state, payload) {
        for (let i = 0; i < state.numberOfGroovesSelection.length; i++) {
            state.numberOfGroovesSelection[i].disabled = false;
            state.numberOfGroovesSelection[i].selected = false;

            if (state.numberOfGroovesSelection[i].sd != payload.sd ||
                state.numberOfGroovesSelection[i].ra != payload.ra) {
                state.numberOfGroovesSelection[i].disabled = true;
            }
        }
    },


    // Rope guard
    change_customerIdKidSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.customerIdKidSelection = payload;
    },
    change_positionMotorSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.positionMotorSelection = payload;
    },
    change_ropePullSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.ropePullSelection = payload;
    },
    change_adjustabilitySelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.adjustabilitySelection = payload;
    },
    change_thirdRopeGuard_storeMutation(state, payload) {
        state.thirdRopeGuard = payload;
    },
    change_tnruev_storeMutation(state, payload) {
        state.tnruev = payload;
    },
    change_imagePathRopeGuard_storeMutation(state, payload) {
        state.imagePathRopeGuard = payload;
    },
    change_deliverRopeSelection_storeMutation(state, payload) {
        state.deliverRopeSelection = payload;
    },
    change_tractionSheaveCoverSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.tractionSheaveCoverSelection = payload;
    },
    change_ropeClampSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.ropeClampSelection = payload;
    },
    change_rescueReturnDeviceSelection_storeMutation(state, payload) {
        state.rescueReturnDeviceSelection = payload;
    },


    change_customerIdKidSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.customerIdKidSelection.length; i++) {
            state.customerIdKidSelection[i].selected = false;
            if (payload != null && payload != '' && state.customerIdKidSelection[i].text.text == payload) {
                state.customerIdKidSelection[i].selected = true;
            }
        }
    },
    change_positionMotorSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.positionMotorSelection.length; i++) {
            state.positionMotorSelection[i].selected = false;
            if (payload != null && payload != '' && state.positionMotorSelection[i].text.text == payload) {
                state.positionMotorSelection[i].selected = true;
            }
        }
    },
    change_ropePullSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.ropePullSelection.length; i++) {
            state.ropePullSelection[i].selected = false;
            if (payload != null && payload != '' && state.ropePullSelection[i].text.text == payload) {
                state.ropePullSelection[i].selected = true;
            }
        }
    },
    change_adjustabilitySelected_storeMutation(state, payload) {
        for (let i = 0; i < state.adjustabilitySelection.length; i++) {
            state.adjustabilitySelection[i].selected = false;
            if (payload != null && payload != '' && state.adjustabilitySelection[i].text.text == payload) {
                state.adjustabilitySelection[i].selected = true;
            }
        }
    },
    change_deliverRopeSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.deliverRopeSelection.length; i++) {
            state.deliverRopeSelection[i].selected = false;
            if (payload != null && payload != '' && state.deliverRopeSelection[i].text.text == payload) {
                state.deliverRopeSelection[i].selected = true;
            }
        }
    },
    change_tractionSheaveCoverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.tractionSheaveCoverSelection.selection.length; i++) {
            state.tractionSheaveCoverSelection.selection[i].selected = false;
            if (payload != null && payload != '' && state.tractionSheaveCoverSelection.selection[i].text.text == payload) {
                state.tractionSheaveCoverSelection.selection[i].selected = true;
            }
        }
    },
    change_ropeClampSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.ropeClampSelection.selection.length; i++) {
            state.ropeClampSelection.selection[i].selected = false;
            if (payload != null && payload != '' && state.ropeClampSelection.selection[i].text.text == payload) {
                state.ropeClampSelection.selection[i].selected = true;
            }
        }
    },
    change_rescueReturnDeviceSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.rescueReturnDeviceSelection.selection.length; i++) {
            state.rescueReturnDeviceSelection.selection[i].selected = false;
            if (payload != null && payload != '' && state.rescueReturnDeviceSelection.selection[i].text.text == payload) {
                state.rescueReturnDeviceSelection.selection[i].selected = true;
            }
        }
    },

    change_customerIdKid_storeMutation(state, payload) {
        state.customerIdKid = payload;
    },
    change_positionMotor_storeMutation(state, payload) {
        state.positionMotor = payload;
    },
    change_ropePull_storeMutation(state, payload) {
        state.ropePull = payload;
    },
    change_adjustability_storeMutation(state, payload) {
        state.adjustability = payload;
    },
    change_deliverRope_storeMutation(state, payload) {
        state.deliverRope = payload;
    },
    change_tractionSheaveCover_storeMutation(state, payload) {
        state.tractionSheaveCover = payload;
    },
    change_ropeClamp_storeMutation(state, payload) {
        state.ropeClamp = payload;
    },
    change_rescueReturnDevice_storeMutation(state, payload) {
        state.rescueReturnDevice = payload;
    },
    change_deliverRopeDisabled_storeMutation(state, payload) {
        state.deliverRopeDisabled = payload
    },

    change_customerIdKid_Copy_storeMutation(state, payload) {
        state.customerIdKid_Copy = payload;
    },
    change_positionMotor_Copy_storeMutation(state, payload) {
        state.positionMotor_Copy = payload;
    },
    change_ropePull_Copy_storeMutation(state, payload) {
        state.ropePull_Copy = payload;
    },
    change_adjustability_Copy_storeMutation(state, payload) {
        state.adjustability_Copy = payload;
    },

    change_preselectionMotorManual_storeMutation(state, payload) {
        state.preselectionMotorManual = payload;
    },

    motorManualData_storeMutation(state, payload) {
        state.motorManualData = payload;
    },
    change_showMotorDetails_storeMutation(state, payload) {
        state.showMotorDetails = payload;
    },
    
    change_configurationStepsManualId_storeMutation(state, payload) {
        state.configurationStepsManualId = payload;
    },

    // SCH-MOD Modul
    change_SCHMOD_locationProductionSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_locationProductionSelection = payload;
    },
    change_SCHMOD_ProdApSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_ProdApSelection = payload;
    },
    change_SCHMOD_PSGSizeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_PSGSizeSelection = payload;
    },
    change_SCHMOD_motorIDSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorIDSelection = payload;
    },
    change_SCHMOD_motorCOSNameSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorCOSNameSelection = payload;
    },
    change_SCHMOD_motorVoltageSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_motorVoltageSelection = payload;
    },
    change_SCHMOD_inverterTypeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_inverterTypeSelection = payload;
    },
    change_SCHMOD_cableLengthSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_cableLengthSelection = payload;
    },
    change_SCHMOD_cableLengthBrakeSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_cableLengthBrakeSelection = payload;
    },
    change_SCHMOD_handWheelSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_handWheelSelection = payload;
    },
    change_SCHMOD_handReleaseSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_handReleaseSelection = payload;
    },
    change_SCHMOD_grooveSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_grooveSelection = payload;
    },
    change_SCHMOD_numberOfGroovesSelection_storeMutation(state, payload) {
        //if (payload == null || payload.length == 0) {
        //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}

        state.schmod_numberOfGroovesSelection = payload;
    },
    change_SCHMOD_locationProduction_storeMutation(state, payload) {
        state.schmod_locationProduction = null;
        for (let i = 0; i < state.schmod_locationProductionSelection.length; i++) {
            state.schmod_locationProductionSelection[i].selected = false;

            if (state.schmod_locationProductionSelection[i].text.text == payload) {
                state.schmod_locationProduction = payload;
                state.schmod_locationProductionSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_ProAp_storeMutation(state, payload) {
        state.schmod_ProAp = null;
        for (let i = 0; i < state.schmod_ProdApSelection.length; i++) {
            state.schmod_ProdApSelection[i].selected = false;

            if (state.schmod_ProdApSelection[i].text.text == payload) {
                state.schmod_ProAp = payload;
                state.schmod_ProdApSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_PSGSize_storeMutation(state, payload) {
        state.schmod_PSGSize = null;
        for (let i = 0; i < state.schmod_PSGSizeSelection.length; i++) {
            state.schmod_PSGSizeSelection[i].selected = false;
            if (state.schmod_PSGSizeSelection[i].text.text == payload) {
                state.schmod_PSGSize = payload;
                state.schmod_PSGSizeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorID_storeMutation(state, payload) {
        state.schmod_motorID = null;
        for (let i = 0; i < state.schmod_motorIDSelection.length; i++) {
            state.schmod_motorIDSelection[i].selected = false;

            if (state.schmod_motorIDSelection[i].text.text == payload) {
                state.schmod_motorID = payload;
                state.schmod_motorIDSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorCosName_storeMutation(state, payload) {
        state.schmod_motorCOSName = null;
        for (let i = 0; i < state.schmod_motorCOSNameSelection.length; i++) {
            state.schmod_motorCOSNameSelection[i].selected = false;

            if (state.schmod_motorCOSNameSelection[i].text.text == payload) {
                state.schmod_motorCOSName = payload;
                state.schmod_motorCOSNameSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_motorVoltage_storeMutation(state, payload) {
        state.schmod_motorVoltage = null;
        for (let i = 0; i < state.schmod_motorVoltageSelection.length; i++) {
            state.schmod_motorVoltageSelection[i].selected = false;

            if (state.schmod_motorVoltageSelection[i].value == payload) {
                state.schmod_motorVoltage = payload;
                state.schmod_motorVoltageSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_inverterType_storeMutation(state, payload) {
        state.schmod_inverterType = null;
        for (let i = 0; i < state.schmod_inverterTypeSelection.length; i++) {
            state.schmod_inverterTypeSelection[i].selected = false;

            if (state.schmod_inverterTypeSelection[i].text.text == payload) {
                state.schmod_inverterType = payload;
                state.schmod_inverterTypeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_cableLength_storeMutation(state, payload) {
        state.schmod_cableLength = null;
        for (let i = 0; i < state.schmod_cableLengthSelection.length; i++) {
            state.schmod_cableLengthSelection[i].selected = false;

            if (state.schmod_cableLengthSelection[i].text.text == payload) {
                state.schmod_cableLength = payload;
                state.schmod_cableLengthSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_cableLengthBrake_storeMutation(state, payload) {
        state.schmod_cableLengthBrake = null;
        for (let i = 0; i < state.schmod_cableLengthBrakeSelection.length; i++) {
            state.schmod_cableLengthBrakeSelection[i].selected = false;

            if (state.schmod_cableLengthBrakeSelection[i].text.text == payload) {
                state.schmod_cableLengthBrake = payload;
                state.schmod_cableLengthBrakeSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_handWheel_storeMutation(state, payload) {
        state.schmod_handWheel = null;
        for (let i = 0; i < state.schmod_handWheelSelection.length; i++) {
            state.schmod_handWheelSelection[i].selected = false;

            if (state.schmod_handWheelSelection[i].text.text == payload) {
                state.schmod_handWheel = payload;
                state.schmod_handWheelSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_handRelease_storeMutation(state, payload) {
        state.schmod_handRelease = null;
        for (let i = 0; i < state.schmod_handReleaseSelection.length; i++) {
            state.schmod_handReleaseSelection[i].selected = false;

            if (state.schmod_handReleaseSelection[i].text.text == payload) {
                state.schmod_handRelease = payload;
                state.schmod_handReleaseSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_grooveDistance_storeMutation(state, payload) {
        state.schmod_grooveDistance = payload;
    },
    change_SCHMOD_groove_storeMutation(state, payload) {
        state.schmod_groove = null;
        for (let i = 0; i < state.schmod_grooveSelection.length; i++) {
            state.schmod_grooveSelection[i].selected = false;

            if (state.schmod_grooveSelection[i].text.text == payload) {
                state.schmod_groove = payload;
                state.schmod_grooveSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_numberOfGrooves_storeMutation(state, payload) {
        state.schmod_numberOfGrooves = null;
        for (let i = 0; i < state.schmod_numberOfGroovesSelection.length; i++) {
            state.schmod_numberOfGroovesSelection[i].selected = false;

            if (state.schmod_numberOfGroovesSelection[i].text.text == payload) {
                state.schmod_numberOfGrooves = payload;
                state.schmod_numberOfGroovesSelection[i].selected = true;
            }
        }
    },
    change_SCHMOD_certificationSelection_storeMutation(state, payload) {
        state.schmod_certificationSelection = payload;
    },
    change_SCHMOD_certification_storeMutation(state, payload) {
        for (let i = 0; i < state.schmod_certificationSelection.length; i++) {
            state.schmod_certificationSelection[i].selected = false;

            if (state.schmod_certificationSelection[i].text.text == payload) {
                state.schmod_certification = payload;
                state.schmod_certificationSelection[i].selected = true;
            }
        }
    },
    change_schmod_Valid_storeMuation(state, payload) {
        state.schmod_Valid = payload;
    },
    change_SchModDetails_storeMuation(state, payload) {
        state.schModDetails = payload;
    },
    change_SCHMOD_Motordetails_storeMutation(state, payload) {
        state.schmodMotordetails = payload;
    },
    set_showSCHMOD_storeMutation(state, payload) {
        state.showSCHMOD = payload;
    },


}

export const inputsManual = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}