import axios from 'axios';
import router from "@/router";

let state = {
    sap: false,

    salesOrder: null,
    salesOrderPos: null,
    configurationOverviewsId: undefined,
    configuration_Modifiable: true,
    configuration_CreatedByLoggedInUser: undefined,
    loaded_configuration: {},
    databaseVersion: undefined,
    configurationOverviewsLinkId: undefined,

    note: { value: undefined, invalid: undefined },
    loader_forLoading_note_Shown: false,
    statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews: {},

    shareDisplayShow: false,
    sharedList: [],
    tempZALift_ID: "",

    configurationOverview: [],
    configurationOverviewFilter: [],

    configurationSteps: {
        inputs: { loaded: false, storedInDatabase: false },
        elevatorMotor_Machine: { loaded: false, storedInDatabase: false },
        elevatorMotor_Machine_TractionSheaveSide: { available: undefined, loaded: false, storedInDatabase: false },
        elevatorMotor_Machine_InstallationPosition: { loaded: false, storedInDatabase: false },
        elevatorMotor_TemperatureMonitoring: { loaded: false, storedInDatabase: false },
        elevatorMotor_ForcedVentilation: { available: undefined, loaded: false, storedInDatabase: false },
        elevatorMotor_Brake: { available: undefined, loaded: false, storedInDatabase: false },
        elevatorMotor_Encoder: { loaded: false, storedInDatabase: false },
        frameDeliverOptions: { available: undefined, loaded: false, storedInDatabase: false },
        frame: { available: undefined, loaded: false, storedInDatabase: false },
        pulley: { available: undefined, loaded: false, storedInDatabase: false },
        inverter: { loaded: false, storedInDatabase: false },
        unintendedCarMovementCheck: { storedInDatabase: false },
        brakeResistor: { available: undefined, loaded: false, storedInDatabase: false },
        powerRecuperationUnit: { available: undefined, loaded: false, storedInDatabase: false },
        brakeControl: { available: undefined, loaded: false, storedInDatabase: false },
        energyEfficiencyRating: { loaded: false, storedInDatabase: false },
        tractionChecks: { loaded: false, storedInDatabase: false },
    },

    navigation: {
        inputs: null,
        driveTechnologies: 'disabled',
        controlTechnologies: 'disabled',
        frames: 'disabled',
        summary: 'disabled',
    },

    language: 'de',
    //Test Dimmler
    MANUAL: false,

    configurationNotValid: null,

    accept_CustomerNumber: false,

    elevatorMotor_Manual: false,
    manualConfiguration: false,
    plusManual: false,
    navigationManual: {
        inputsManual: null,
        driveTechnologiesManual: null,
        cablesManual: 'disabled',
        suppliesMotorManual: 'disabled',
        packagingMotorManual: 'disabled',
    },
    loaded_configurationMotorManual: {},
    //configurationOverviewsManualId: null,
}

const actions = {

    async get_sap_storeAction({ commit }) {
        await axios({
            method: 'post',
            url: '/api/configurations/return_accept_SAP'
        })
            .then((response) => {
                if (response.data != null) {
                    commit('change_sap_storeMutation', response.data);
                }
            });
    },

    change_configurationOverview_storeAction({ commit }, value) {
        commit('change_configurationOverview_storeMutation', value)
    },
    change_configurationOverviewFilter_storeAction({ commit }, value) {
        commit('change_configurationOverviewFilter_storeMutation', value)
    },
    change_configurationLastEditedOn_storeAction({ commit }) {
        commit('change_configurationLastEditedOn_storeMutation')
    },
    change_configurationsTable_tempZAliftID_storeAction({ commit }, value) {
        commit('change_configurationsTable_tempZAliftID_storeMutation', value)
    },
    change_configurationsTable_sharedList_storeAction({ commit }, value) {
        commit('change_configurationsTable_shareList_storeMutation', value)
    },
    change_configurationsTable_shareDisplayShow_storeAction({ commit }, value) {
        commit('change_configurationsTable_shareDisplayShow_storeMutation', value)
    },
    //Test Dimmler
    MANU({ commit }, value) {
        commit('MANU_configuration', value);

    },
    change_configurations_salesOrderRelation_storeAction({ commit }, value) {
        commit('change_salesOrder', value);
    },
    change_configurations_toDefault_storeAction({ commit, dispatch }) {
        dispatch('MANU', false)
        dispatch('remove_configurationOverviewsId_at_sessionStorage_storeAction');
        commit('change_configuration_Modifiable_storeMutation', true);
        commit('change_configuration_CreatedByLoggedInUser_storeMutation', null);
        commit('change_loaded_configuration_storeMutation', {});

        commit('change_note_storeMutation', { value: null, invalid: null });
        commit('change_loader_forLoading_note_Shown_storeMutation', false);
        commit('change_statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews_storeMutation', {});

        commit('change_configurationSteps_storeMutation', {
            inputs: { loaded: false, storedInDatabase: false },
            elevatorMotor_Machine: { loaded: false, storedInDatabase: false },
            elevatorMotor_Machine_TractionSheaveSide: { available: undefined, loaded: false, storedInDatabase: false },
            elevatorMotor_Machine_InstallationPosition: { loaded: false, storedInDatabase: false },
            elevatorMotor_TemperatureMonitoring: { loaded: false, storedInDatabase: false },
            elevatorMotor_ForcedVentilation: { available: undefined, loaded: false, storedInDatabase: false },
            elevatorMotor_Brake: { available: undefined, loaded: false, storedInDatabase: false },
            elevatorMotor_Encoder: { loaded: false, storedInDatabase: false },
            frameDeliverOptions: { available: undefined, loaded: false, storedInDatabase: false },
            frame: { available: undefined, loaded: false, storedInDatabase: false },
            pulley: { available: undefined, loaded: false, storedInDatabase: false },
            inverter: { loaded: false, storedInDatabase: false },
            unintendedCarMovementCheck: { storedInDatabase: false },
            brakeResistor: { available: undefined, loaded: false, storedInDatabase: false },
            powerRecuperationUnit: { available: undefined, loaded: false, storedInDatabase: false },
            brakeControl: { available: undefined, loaded: false, storedInDatabase: false },
            energyEfficiencyRating: { loaded: false, storedInDatabase: false },
            tractionChecks: { loaded: false, storedInDatabase: false },
            cables: { available: undefined, loaded: false, storedInDatabase: false },
        });
        commit('change_navigation_storeMutation', {
            inputs: null,
            driveTechnologies: 'disabled',
            controlTechnologies: 'disabled',
            frames: 'disabled',
            cables: 'disabled',
            summary: 'disabled',
        });
    },

    change_configurationOverviewsId_storeAction({ commit }, value) {
        commit('change_configurationOverviewsId_storeMutation', value)
    },
    set_configurationOverviewsId_at_sessionStorage_storeAction({ commit, dispatch }, value) {
        sessionStorage.setItem('id', value)
        commit('change_configurationOverviewsId_storeMutation', value)
        if (value == null) {
            if (sessionStorage.getItem('idManual')) {
                dispatch('remove_configurationOverviewsLinkId_at_sessionStorage_storeAction')
            }
        }
        else {
            commit('change_plusManual_storeMutation', true);
        }
    },
    remove_configurationOverviewsId_at_sessionStorage_storeAction({ commit, dispatch }) {
        sessionStorage.removeItem('id')
        commit('change_configurationOverviewsId_storeMutation', null)
        commit('change_plusManual_storeMutation', false);
        if (sessionStorage.getItem('idManual')) {
            dispatch('remove_configurationOverviewsLinkId_at_sessionStorage_storeAction')
        }
    },

    set_configurationOverviewsLinkId_at_sessionStorage_storeAction({ commit, dispatch }, value) {
        if (value != null) {
            sessionStorage.setItem('idManual', value)
            commit('change_configurationOverviewsLinkId_storeMutation', value)
        }
        else if (sessionStorage.getItem('idManual')) {
            dispatch('remove_configurationOverviewsLinkId_at_sessionStorage_storeAction')
        }
    },
    remove_configurationOverviewsLinkId_at_sessionStorage_storeAction({ commit }) {
        sessionStorage.removeItem('idManual')
        commit('change_configurationOverviewsLinkId_storeMutation', null)
    },


    change_loaded_configuration_storeAction({ commit }, value) {
        commit('change_loaded_configuration_storeMutation', value)
    },

    select_Note_from_databaseRecord_configurationOverviews_storeAction({ rootState, commit }) {
        commit('change_loader_forLoading_note_Shown_storeMutation', true)

        const data = { configurationOverviewsId: rootState.configurations.configurationOverviewsId };

        axios({
            method: 'post',
            url: '/api/configurations/select_Note_from_databaseRecord_configurationOverviews',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                commit('change_statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews_storeMutation', response.data.status)

                if (response.data.status.statusCode.startsWith('2')) {
                    commit('change_note_storeMutation', { value: response.data.data.note, invalid: false })
                    commit('change_configuration_CreatedByLoggedInUser_storeMutation', response.data.data.configuration_CreatedByLoggedInUser)
                }
            })
            .catch(() => { })
            .then(() => {
                commit('change_loader_forLoading_note_Shown_storeMutation', false)
            })
    },
    change_note_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.string;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const note_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        this.dispatch('inputs/checkIf_changesAt_inputs_changes_configurationFlow_storeAction');
        commit('change_note_storeMutation', note_ValidationResult)

        if (state.configurationSteps.inputs.storedInDatabase == true) {
            if (note_ValidationResult.invalid == false) {
                dispatch('update_databaseRecord_at_configurationOverviews_bySetting_Note_storeAction', note_ValidationResult.value)
            }
            else {
                dispatch('update_databaseRecord_at_configurationOverviews_bySetting_Note_storeAction', null)
            }
        }

    },
    async update_databaseRecord_at_configurationOverviews_bySetting_Note_storeAction({ rootState }, value) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };
        data.note = value;

        await axios({
            method: 'post',
            url: '/api/configurations/update_databaseRecord_at_configurationOverviews_bySetting_Note',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
    },

    change_configuration_Modifiable_storeAction({ commit }, value) {
        commit('change_configuration_Modifiable_storeMutation', value);
    },
    async update_databaseRecord_at_configurationOverviews_bySetting_Configuration_Modifiable_storeAction({ rootState, dispatch }) {
        dispatch('change_configuration_Modifiable_storeAction', false)

        const data = { configurationOverviewsId: rootState.configurations.configurationOverviewsId };

        await axios({
            method: 'post',
            url: '/api/configurations/update_databaseRecord_at_configurationOverviews_bySetting_Configuration_Modifiable',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
    },

    change_configurationSteps_storeAction({ commit, dispatch }, value) {
        if ((JSON.stringify(state.configurationSteps) === JSON.stringify(value)) == false) {
            dispatch('checkIf_data_haveToBeChanged_toDefault_storeAction', value)
            commit('change_configurationSteps_storeMutation', value)
            dispatch('change_Properties_at_navigation_Object_storeAction')
        }
    },
    change_configurationSteps_additionalComponents_storeAction({ commit, dispatch }, value) {
        if ((JSON.stringify(state.configurationSteps) === JSON.stringify(value)) == false) {
            dispatch('checkIf_data_haveToBeChanged_toDefault_storeAction', value)
            commit('change_configurationSteps_storeMutation', value)
        }
    },
    checkIf_data_haveToBeChanged_toDefault_storeAction({ }, value) {
        if ((value.elevatorMotor_Machine.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Machine_toDefault_storeAction')
        }
        if ((value.elevatorMotor_Machine_TractionSheaveSide.loaded == false) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Machine_TractionSheaveSide_toDefault_storeAction')
        }
        if ((value.elevatorMotor_Machine_InstallationPosition.loaded == false) && (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Machine_InstallationPosition_toDefault_storeAction')
        }
        if ((value.elevatorMotor_TemperatureMonitoring.loaded == false) && (state.configurationSteps.elevatorMotor_TemperatureMonitoring.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_TemperatureMonitoring_toDefault_storeAction')
        }
        if ((value.elevatorMotor_ForcedVentilation.loaded == false) && (state.configurationSteps.elevatorMotor_ForcedVentilation.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_ForcedVentilation_toDefault_storeAction')
        }
        if ((value.elevatorMotor_Brake.loaded == false) && (state.configurationSteps.elevatorMotor_Brake.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Brake_toDefault_storeAction')
        }
        if ((value.elevatorMotor_Encoder.loaded == false) && (state.configurationSteps.elevatorMotor_Encoder.loaded == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Encoder_toDefault_storeAction')
        }
        if ((value.frameDeliverOptions.loaded == false) && (state.configurationSteps.frameDeliverOptions.loaded == true)) {
            this.dispatch('frames/change_frameDeliverOptions_toDefault_storeAction')
        }
        if ((value.pulley.loaded == false) && (state.configurationSteps.pulley.loaded == true)) {
            this.dispatch('frames/change_pulley_toDefault_storeAction')
        }
        if ((value.inverter.loaded == false) && (state.configurationSteps.inverter.loaded == true)) {
            this.dispatch('controlTechnologies/change_inverter_toDefault_storeAction')
        }
        if ((value.brakeResistor.loaded == false) && (state.configurationSteps.brakeResistor.loaded == true)) {
            this.dispatch('controlTechnologies/change_brakeResistor_toDefault_storeAction')
        }
        if ((value.powerRecuperationUnit.loaded == false) && (state.configurationSteps.powerRecuperationUnit.loaded == true)) {
            this.dispatch('controlTechnologies/change_powerRecuperationUnit_toDefault_storeAction')
        }
        if ((value.brakeControl.loaded == false) && (state.configurationSteps.brakeControl.loaded == true)) {
            this.dispatch('controlTechnologies/change_brakeControl_toDefault_storeAction')
        }
        if ((value.energyEfficiencyRating.loaded == false) && (state.configurationSteps.energyEfficiencyRating.loaded == true)) {
            this.dispatch('controlTechnologies/change_energyEfficiencyRating_toDefault_storeAction')
        }
    },
    change_Properties_at_navigation_Object_storeAction({ rootState, commit }) {
        var navigation = Object.assign({}, state.navigation)

        var thirdPartyInverter_Selected = true;
        if (rootState.controlTechnologies.inverter_Data.hasOwnProperty('thirdPartyInverter')) {
            thirdPartyInverter_Selected = rootState.controlTechnologies.inverter_Data.thirdPartyInverter.selected;
        }

        if ((state.configurationSteps.inputs.storedInDatabase == true) &&
            (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) &&
            (state.configurationSteps.tractionChecks.storedInDatabase == true) &&
            ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true)) &&
            (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) &&
            (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) &&
            ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == false) || (state.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true)) &&
            ((state.configurationSteps.elevatorMotor_Brake.available == false) || (state.configurationSteps.elevatorMotor_Brake.storedInDatabase == true)) &&
            (state.configurationSteps.elevatorMotor_Encoder.storedInDatabase == true) &&
            ((state.configurationSteps.inverter.storedInDatabase == true) && ((thirdPartyInverter_Selected == true) || ((thirdPartyInverter_Selected == false) && (((state.configurationSteps.brakeResistor.storedInDatabase == true) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == false)) || ((state.configurationSteps.brakeResistor.storedInDatabase == false) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == true))))))
        ) {
            navigation.inputs = 'passed';
            navigation.driveTechnologies = 'passed';
            navigation.controlTechnologies = 'passed';
            navigation.summary = null;

            if (state.configurationSteps.frame.storedInDatabase == true) {
                navigation.frames = 'frames';
            }
            else {
                navigation.frames = 'disabled';
            }
            if (state.configurationSteps.frameDeliverOptions.storedInDatabase == true || state.configurationSteps.pulley.storedInDatabase == true) {
                navigation.frames = 'passed';
            }

            if (state.configurationSteps.frame.storedInDatabase == true && navigation.frames != 'passed') {
                navigation.summary = 'disabled';
            }

        }
        else if ((state.configurationSteps.inputs.storedInDatabase == true) &&
            (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) &&
            (state.configurationSteps.tractionChecks.storedInDatabase == true) &&
            ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true)) &&
            (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) &&
            (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) &&
            ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == false) || (state.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true)) &&
            ((state.configurationSteps.elevatorMotor_Brake.available == false) || (state.configurationSteps.elevatorMotor_Brake.storedInDatabase == true)) &&
            (state.configurationSteps.elevatorMotor_Encoder.storedInDatabase == true)) {

            navigation.inputs = 'passed';
            navigation.driveTechnologies = 'passed';
            navigation.controlTechnologies = null;
            navigation.summary = 'disabled';

            if (state.configurationSteps.frame.storedInDatabase == true) {
                navigation.frames = 'frames';
            }
            else {
                navigation.frames = 'disabled';
            }
            if (state.configurationSteps.frameDeliverOptions.storedInDatabase == true || state.configurationSteps.pulley.storedInDatabase == true) {
                navigation.frames = 'passed';
            }
        }
        else if (state.configurationSteps.inputs.storedInDatabase == true) {
            navigation.inputs = 'passed';
            navigation.driveTechnologies = null;
            navigation.controlTechnologies = 'disabled';
            navigation.frames = 'disabled';
            navigation.summary = 'disabled';
        }
        else {
            navigation.inputs = null;
            navigation.driveTechnologies = 'disabled';
            navigation.controlTechnologies = 'disabled';
            navigation.frames = 'disabled';
            navigation.summary = 'disabled';
        }
        commit('change_navigation_storeMutation', navigation)
    },

    async check_toDos_at_inputs_storeAction({ dispatch, commit }) {
        if (state.configurationSteps.inputs.loaded == false) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
            await this.dispatch('inputs/get_elevatorSystem_construction_suspensionMeans_elevatorMotor_TractionSheave_ValueListsAndSelections_Informations_storeAction')

            if (state.configurationSteps.inputs.loaded == true) {
                if (state.configurationSteps.inputs.storedInDatabase == true) {
                    if (((state.loaded_configuration.hasOwnProperty('elevatorSystem') == true) && (state.loaded_configuration.elevatorSystem != null) && (state.loaded_configuration.hasOwnProperty('construction') == true) && (state.loaded_configuration.construction != null) && (state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null) && (state.loaded_configuration.hasOwnProperty('elevatorMotor_TractionSheave') == true) && (state.loaded_configuration.elevatorMotor_TractionSheave != null)) == false) {
                        await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'inputs' });
                    }

                    if ((state.loaded_configuration.hasOwnProperty('elevatorSystem') == true) && (state.loaded_configuration.elevatorSystem != null) && (state.loaded_configuration.hasOwnProperty('construction') == true) && (state.loaded_configuration.construction != null) && (state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null) && (state.loaded_configuration.hasOwnProperty('elevatorMotor_TractionSheave') == true) && (state.loaded_configuration.elevatorMotor_TractionSheave != null)) {
                        this.dispatch('inputs/set_inputs_storeAction', state.loaded_configuration);
                    }

                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
                else {
                    this.dispatch('inputs/change_locationOfMachine_toDefault_storeAction')
                    this.dispatch('inputs/set_defaultValues_at_inputs_storeAction');

                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            }
        }

        if ((state.configurationSteps.inputs.loaded == true) && (state.configurationSteps.inputs.storedInDatabase == true)) {
            dispatch('select_Note_from_databaseRecord_configurationOverviews_storeAction');
            this.dispatch('inputs/todos_input_IfConfigurationNotValid_storeAction');
        }

        commit('change_configurationNotValid_storeMutation', null)
    },
    async check_toDos_at_driveTechnologies_storeAction({ rootState, dispatch, commit }) {
        if (state.configurationSteps.elevatorMotor_Machine.loaded == false) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Frame_toDefault_storeAction')
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
            await this.dispatch('driveTechnologies/get_elevatorMotor_Machine_Informations_storeAction')
            if (state.configurationSteps.elevatorMotor_Machine.loaded == true) {
                if (state.configurationSteps.inputs.loaded == false) {
                    if (((state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null)) == false) {
                        await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'wrappingAngle' });
                    }
                    if ((state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null)) {
                        this.dispatch('inputs/change_wrappingAngle_Data_storeAction', { wrappingAngle: state.loaded_configuration.suspensionMeans.wrappingAngle, wrappingAngle_Calculated: state.loaded_configuration.suspensionMeans.wrappingAngleCalculated })
                    }
                }
                if (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) {
                    if (((state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null) && (state.loaded_configuration.hasOwnProperty('elevatorMotor_TractionSheave') == true) && (state.loaded_configuration.elevatorMotor_TractionSheave != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.tractionCheck) == true) && (state.loaded_configuration.tractionCheck != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) == false) {
                        await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                    }
                    if ((state.loaded_configuration.hasOwnProperty('elevatorSystem') == true) && (state.loaded_configuration.elevatorSystem != null) && (state.loaded_configuration.hasOwnProperty('suspensionMeans') == true) && (state.loaded_configuration.suspensionMeans != null) && (state.loaded_configuration.hasOwnProperty('elevatorMotor_TractionSheave') == true) && (state.loaded_configuration.elevatorMotor_TractionSheave != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.tractionCheck) == true) && (state.loaded_configuration.tractionCheck != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) {
                        const firstIndex_whichMeetConditions = rootState.driveTechnologies.elevatorMotor_Machine_Data.elevatorMotor_Machine_Selection.findIndex(i => ((i.elevatorMotor_Machine.blockNumberMachine == state.loaded_configuration.elevatorMotor_Machine.blockNumberMachine) && (i.elevatorMotor_Machine.blockNumberGear == state.loaded_configuration.elevatorMotor_Machine.blockNumberGear) && (i.elevatorMotor_TractionSheave.blockNumber == state.loaded_configuration.elevatorMotor_TractionSheave.blockNumber)));
                        if (firstIndex_whichMeetConditions > -1) {
                            this.dispatch('driveTechnologies/add_elevatorSystem_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, elevatorSystem: state.loaded_configuration.elevatorSystem });
                            this.dispatch('driveTechnologies/add_suspensionMeans_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, suspensionMeans: state.loaded_configuration.suspensionMeans });
                            this.dispatch('driveTechnologies/change_tractionCheck_Data_at_elevatorMotor_TractionSheave_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, elevatorMotor_TractionSheave: state.loaded_configuration.elevatorMotor_TractionSheave });
                            this.dispatch('driveTechnologies/add_tractionCheck_Object_atElementOf_elevatorMotor_Machine_Selection_storeAction', { index: firstIndex_whichMeetConditions, tractionCheck: state.loaded_configuration.tractionCheck });

                            this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Machine_Selection_storeAction', firstIndex_whichMeetConditions);
                            this.dispatch('driveTechnologies/change_elevatorMotor_Machine_Selection_Shown_storeAction', false)
                            if ((state.loaded_configuration.hasOwnProperty('frame') == true) && (state.loaded_configuration.frame != null)) {
                                const frame1 = { frame: state.loaded_configuration.frame };
                                this.commit('driveTechnologies/change_frame_Data_at_ElementOf_elevatorMotor_Machine_Selection_storeMutation', { index: firstIndex_whichMeetConditions, frame: frame1 })
                            }
                        }
                        else {
                            // error
                        }
                    }
                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            }
        }
        if ((state.configurationSteps.elevatorMotor_Machine.loaded == true) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true)) {
            this.dispatch('driveTechnologies/change_elevatorMotor_Machine_Selection_Shown_storeAction', false)

            if ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true)) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_Machine_TractionSheaveSide_Informations_storeAction');

                if ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.loaded == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true)) {
                    if (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection.findIndex(i => i.elevatorMotor_Machine_TractionSheaveSide.text == state.loaded_configuration.elevatorMotor_Machine.tractionSheaveSide);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeAction', firstIndex_whichMeetCondition);
                            }
                            else {
                                // error
                            }
                        }
                    }
                    else {
                        this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Machine_TractionSheaveSide_Selection_storeAction', 0)
                        await this.dispatch('driveTechnologies/update_databaseRecord_at_elevatorMotor_Machines_bySetting_TractionSheaveSide_storeAction', rootState.driveTechnologies.elevatorMotor_Machine_TractionSheaveSide_Data.elevatorMotor_Machine_TractionSheaveSide_Selection[0].elevatorMotor_Machine_TractionSheaveSide.text)
                    }
                }
            }
            if ((state.configurationSteps.elevatorMotor_Machine_InstallationPosition.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true)))) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_Machine_InstallationPosition_Informations_storeAction');

                if (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.loaded == true) {
                    if (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection.findIndex(i => i.elevatorMotor_Machine_InstallationPosition.text == state.loaded_configuration.elevatorMotor_Machine.installationPosition);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeAction', firstIndex_whichMeetCondition);
                            }
                            else {
                                // error
                            }
                        }
                    }
                    else {
                        this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Machine_InstallationPosition_Selection_storeAction', 0)
                        await this.dispatch('driveTechnologies/update_databaseRecord_at_elevatorMotor_Machines_bySetting_InstallationPosition_storeAction', rootState.driveTechnologies.elevatorMotor_Machine_InstallationPosition_Data.elevatorMotor_Machine_InstallationPosition_Selection[0].elevatorMotor_Machine_InstallationPosition.text)
                    }
                }
            }
            if ((state.configurationSteps.elevatorMotor_TemperatureMonitoring.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true))) && (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true)) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_TemperatureMonitoring_Informations_storeAction');

                if (state.configurationSteps.elevatorMotor_TemperatureMonitoring.loaded == true) {
                    if (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_TemperatureMonitoring) == true) && (state.loaded_configuration.elevatorMotor_TemperatureMonitoring != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_TemperatureMonitoring) == true) && (state.loaded_configuration.elevatorMotor_TemperatureMonitoring != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_TemperatureMonitoring_Data.elevatorMotor_TemperatureMonitoring_Selection.findIndex(i => i.elevatorMotor_TemperatureMonitoring.typeDesignation == state.loaded_configuration.elevatorMotor_TemperatureMonitoring.typeDesignation);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_TemperatureMonitoring_Selection_storeAction', firstIndex_whichMeetCondition);
                            }
                            else {
                                // error
                            }
                        }
                    }
                    else {
                        await this.dispatch('driveTechnologies/checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_TemperatureMonitoringByPT100_AvailableAndTrue_storeAction')
                    }
                }
            }
            if ((state.configurationSteps.elevatorMotor_ForcedVentilation.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true))) && (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) && (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true)) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_ForcedVentilation_Informations_storeAction');

                if ((state.configurationSteps.elevatorMotor_ForcedVentilation.loaded == true) && (state.configurationSteps.elevatorMotor_ForcedVentilation.available == true)) {
                    if (state.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_ForcedVentilation) == true) && (state.loaded_configuration.elevatorMotor_ForcedVentilation != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_ForcedVentilation) == true) && (state.loaded_configuration.elevatorMotor_ForcedVentilation != null)) {
                            this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_ForcedVentilation_storeAction', state.loaded_configuration.elevatorMotor_ForcedVentilation.selected)
                        }
                    }
                    else {
                        await this.dispatch('driveTechnologies/checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_ForcedVentilation_AvailableAndTrue_storeAction')
                    }
                }
            }
            if ((state.configurationSteps.elevatorMotor_Brake.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true))) && (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) && (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == false) || ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == true) && (state.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true)))) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_Brake_Informations_storeAction');

                if ((state.configurationSteps.elevatorMotor_Brake.loaded == true) && (state.configurationSteps.elevatorMotor_Brake.available == true)) {
                    if (state.configurationSteps.elevatorMotor_Brake.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Brake) == true) && (state.loaded_configuration.elevatorMotor_Brake != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Brake) == true) && (state.loaded_configuration.elevatorMotor_Brake != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Selection.findIndex(i => i.elevatorMotor_Brake.blockNumber == state.loaded_configuration.elevatorMotor_Brake.blockNumber);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Brake_Selection_storeAction', firstIndex_whichMeetCondition)

                                const firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Voltage_Selection.findIndex(i => i.nominalVoltage == state.loaded_configuration.elevatorMotor_Brake.nominalVoltage);
                                if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue > -1) {
                                    this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Brake_Voltage_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Voltage_Selection_mustBeChangedToTrue);
                                }
                                else {
                                    // error
                                }

                                const firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_MechanicalRelease_Selection.findIndex(i => i.text == state.loaded_configuration.elevatorMotor_Brake.mechanicalRelease);
                                if (firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue > -1) {
                                    this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Brake_MechanicalRelease_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_MechanicalRelease_Selection_mustBeChangedToTrue);
                                }
                                else {
                                    // error
                                }

                                const firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_ReleaseMonitoring_Selection.findIndex(i => i.text == state.loaded_configuration.elevatorMotor_Brake.releaseMonitoring);
                                if (firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue > -1) {
                                    this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Brake_ReleaseMonitoring_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_ReleaseMonitoring_Selection_mustBeChangedToTrue);
                                }
                                else {
                                    // error
                                }

                                const firstIndexWhere_Selected_at_elevatorMotor_Brake_Connection_Selection_mustBeChangedToTrue = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Connection_Selection.findIndex(i => i.blockNumber == state.loaded_configuration.elevatorMotor_Brake.brakeConnectionBlockNumber);
                                if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Connection_Selection_mustBeChangedToTrue > -1) {
                                    this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Brake_Connection_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Connection_Selection_mustBeChangedToTrue);
                                }
                                else {
                                    // error
                                }

                                const firstIndexWhere_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_mustBeChangedToTrue = rootState.driveTechnologies.elevatorMotor_Brake_Data.elevatorMotor_Brake_Bowdenwire_Selection.findIndex(i => i.blockNumber == state.loaded_configuration.elevatorMotor_Brake.bowdenwireBlockNumber && i.deliver.text == state.loaded_configuration.elevatorMotor_Brake.bowdenwireDeliver && (state.loaded_configuration.elevatorMotor_Brake.bowdenwireLength == null || i.length == state.loaded_configuration.elevatorMotor_Brake.bowdenwireLength));
                                if (firstIndexWhere_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_mustBeChangedToTrue > -1) {
                                    this.dispatch('driveTechnologies/change_Selected_atElementOf_elevatorMotor_Brake_Bowdenwire_Selection_storeAction', firstIndexWhere_Selected_at_elevatorMotor_Brake_Bowdenwire_Selection_mustBeChangedToTrue);
                                }
                                else {
                                    // error
                                }

                            }
                            else {
                                // error
                            }
                        }
                    }
                    else {
                        await this.dispatch('driveTechnologies/checkIf_elevatorMotor_Machine_FilterCriterion_elevatorMotor_Brake_MechanicalRelease_AvailableAndTrue_storeAction');
                    }
                }
            }
            if ((state.configurationSteps.elevatorMotor_Encoder.loaded == false) && (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == false) || ((state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.available == true) && (state.configurationSteps.elevatorMotor_Machine_TractionSheaveSide.storedInDatabase == true))) && (state.configurationSteps.elevatorMotor_Machine_InstallationPosition.storedInDatabase == true) && (state.configurationSteps.elevatorMotor_TemperatureMonitoring.storedInDatabase == true) && ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == false) || ((state.configurationSteps.elevatorMotor_ForcedVentilation.available == true) && (state.configurationSteps.elevatorMotor_ForcedVentilation.storedInDatabase == true))) && ((state.configurationSteps.elevatorMotor_Brake.available == false) || ((state.configurationSteps.elevatorMotor_Brake.available == true) && (state.configurationSteps.elevatorMotor_Brake.storedInDatabase == true)))) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('driveTechnologies/get_elevatorMotor_Encoder_Informations_storeAction');

                if (state.configurationSteps.elevatorMotor_Encoder.loaded == true) {
                    if (state.configurationSteps.elevatorMotor_Encoder.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Encoder) == true) && (state.loaded_configuration.elevatorMotor_Encoder != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'driveTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Encoder) == true) && (state.loaded_configuration.elevatorMotor_Encoder != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == state.loaded_configuration.elevatorMotor_Encoder.typeDesignation);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', firstIndex_whichMeetCondition);
                            }
                            else {
                                // error
                            }
                        }
                    }
                    else {
                        await this.dispatch('driveTechnologies/checkIf_userPreference_elevatorMotor_Encoder_SetAndPossible_storeAction')
                    }
                }
            }
        }

        if (state.navigation.driveTechnologies == 'passed') {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
    },
    async check_toDos_at_controlTechnologies_storeAction({ rootState, dispatch }) {
        if (state.configurationSteps.inverter.loaded == false) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            if (state.configurationSteps.elevatorMotor_Encoder.loaded == false) {
                await this.dispatch('driveTechnologies/get_elevatorMotor_Encoder_Informations_storeAction');

                if (state.configurationSteps.elevatorMotor_Encoder.loaded == true) {

                    if (state.configurationSteps.elevatorMotor_Encoder.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Encoder) == true) && (state.loaded_configuration.elevatorMotor_Encoder != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Encoder) == true) && (state.loaded_configuration.elevatorMotor_Encoder != null)) {
                            const firstIndex_whichMeetCondition = rootState.driveTechnologies.elevatorMotor_Encoder_Data.elevatorMotor_Encoder_Selection.findIndex(i => i.elevatorMotor_Encoder.typeDesignation == state.loaded_configuration.elevatorMotor_Encoder.typeDesignation);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('driveTechnologies/change_Selected_at_elevatorMotor_Encoder_Selection_storeAction', firstIndex_whichMeetCondition);
                            }
                            else {
                                // error
                            }
                        }
                    }
                }
            }

            await this.dispatch('controlTechnologies/get_inverter_Informations_storeAction')

            if (state.configurationSteps.inverter.loaded == true) {
                if (state.configurationSteps.inverter.storedInDatabase == true) {
                    await this.dispatch('controlTechnologies/change_Selected_atElementOf_inverter_Selection_basedOn_databaseRecord_storeAction')
                }
                else {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                }
            }
        }
        if ((state.configurationSteps.inverter.loaded == true) && (state.configurationSteps.inverter.storedInDatabase == true)) {
            this.dispatch('controlTechnologies/change_inverter_Selection_Shown_storeAction', false);

            if ((state.configurationSteps.brakeResistor.loaded == false) || (state.configurationSteps.powerRecuperationUnit.loaded == false)) {
                var brakeResistor_Loaded = state.configurationSteps.brakeResistor.loaded;
                var powerRecuperationUnit_Loaded = state.configurationSteps.powerRecuperationUnit.loaded;

                if (state.configurationSteps.brakeResistor.loaded == false) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                    await this.dispatch('controlTechnologies/get_brakeResistor_Informations_storeAction');
                }
                if (state.configurationSteps.powerRecuperationUnit.loaded == false) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                    await this.dispatch('controlTechnologies/get_powerRecuperationUnit_Informations_storeAction');
                }

                if ((state.configurationSteps.brakeResistor.available == true) && (state.configurationSteps.powerRecuperationUnit.available == true)) {
                    if ((state.configurationSteps.brakeResistor.storedInDatabase == false) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == false)) {
                        await this.dispatch('controlTechnologies/checkIf_userPreference_for_productTypeForConversionOfRegenerativeEnergy_Set_storeAction');
                    }
                    else if ((state.configurationSteps.brakeResistor.storedInDatabase == true) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == false)) {
                        if (brakeResistor_Loaded == false) {
                            if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeResistor) == true) && (state.loaded_configuration.brakeResistor != null)) == false) {
                                await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                            }

                            if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeResistor) == true) && (state.loaded_configuration.brakeResistor != null)) {
                                const firstIndex_whichMeetCondition = rootState.controlTechnologies.brakeResistor_Data.brakeResistor_Selection.findIndex(i => i.brakeResistor.blockNumber == state.loaded_configuration.brakeResistor.blockNumber);
                                if (firstIndex_whichMeetCondition > -1) {
                                    this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'brake resistor')
                                    this.dispatch('controlTechnologies/change_Selected_atElementOf_brakeResistor_Selection_storeAction', firstIndex_whichMeetCondition)
                                }
                                else {
                                    // error
                                }
                            }
                        }
                    }
                    else if ((state.configurationSteps.brakeResistor.storedInDatabase == false) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == true)) {
                        if (powerRecuperationUnit_Loaded == false) {
                            if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.powerRecuperationUnit) == true) && (state.loaded_configuration.powerRecuperationUnit != null)) == false) {
                                await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                            }

                            if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.powerRecuperationUnit) == true) && (state.loaded_configuration.powerRecuperationUnit != null)) {
                                const firstIndex_whichMeetCondition = rootState.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.powerRecuperationUnit.blockNumber == state.loaded_configuration.powerRecuperationUnit.blockNumber);
                                if (firstIndex_whichMeetCondition > -1) {
                                    this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'power recuperation unit')
                                    this.dispatch('controlTechnologies/change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction', firstIndex_whichMeetCondition)
                                    this.dispatch('controlTechnologies/change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction', firstIndex_whichMeetCondition)
                                }
                                else {
                                    // error
                                }
                            }
                        }
                    }
                    else {
                        // error
                    }
                }
                else if ((state.configurationSteps.brakeResistor.available == true) && (state.configurationSteps.powerRecuperationUnit.available == false)) {
                    if (state.configurationSteps.brakeResistor.storedInDatabase == true) {
                        if (brakeResistor_Loaded == false) {
                            if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeResistor) == true) && (state.loaded_configuration.brakeResistor != null)) == false) {
                                await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                            }

                            if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeResistor) == true) && (state.loaded_configuration.brakeResistor != null)) {
                                const firstIndex_whichMeetCondition = rootState.controlTechnologies.brakeResistor_Data.brakeResistor_Selection.findIndex(i => i.brakeResistor.blockNumber == state.loaded_configuration.brakeResistor.blockNumber);
                                if (firstIndex_whichMeetCondition > -1) {
                                    this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'brake resistor')
                                    this.dispatch('controlTechnologies/change_Selected_atElementOf_brakeResistor_Selection_storeAction', firstIndex_whichMeetCondition)
                                }
                                else {
                                    // error
                                }
                            }
                        }
                    }
                    else {
                        this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'brake resistor')
                        this.dispatch('controlTechnologies/change_Selected_atElementOf_brakeResistor_Selection_storeAction', 0);
                        await this.dispatch('controlTechnologies/insert_databaseRecord_at_brakeResistors_storeAction', rootState.controlTechnologies.brakeResistor_Data.brakeResistor_Selection[0].brakeResistor);
                    }
                }
                else if ((state.configurationSteps.brakeResistor.available == false) && (state.configurationSteps.powerRecuperationUnit.available == true)) {
                    if (state.configurationSteps.powerRecuperationUnit.storedInDatabase == true) {
                        if (powerRecuperationUnit_Loaded == false) {
                            if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.powerRecuperationUnit) == true) && (state.loaded_configuration.powerRecuperationUnit != null)) == false) {
                                await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                            }

                            if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.powerRecuperationUnit) == true) && (state.loaded_configuration.powerRecuperationUnit != null)) {
                                const firstIndex_whichMeetCondition = rootState.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection.findIndex(i => i.powerRecuperationUnit.blockNumber == state.loaded_configuration.powerRecuperationUnit.blockNumber);
                                if (firstIndex_whichMeetCondition > -1) {
                                    this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'power recuperation unit')
                                    this.dispatch('controlTechnologies/change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction', firstIndex_whichMeetCondition)
                                    this.dispatch('controlTechnologies/change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction', firstIndex_whichMeetCondition)
                                }
                                else {
                                    // error
                                }
                            }
                        }
                    }
                    else {
                        this.dispatch('controlTechnologies/change_productTypeForConversionOfRegenerativeEnergy_storeAction', 'power recuperation unit')
                        this.dispatch('controlTechnologies/change_Selected_atElementOf_powerRecuperationUnit_Selection_storeAction', 0);
                        this.dispatch('controlTechnologies/change_Selected_AtComparisonOfPossibilitiesToConvertRegenerativeEnergy_at_powerRecuperationUnit_Selection_storeAction', 0);
                        await this.dispatch('controlTechnologies/insert_databaseRecord_at_powerRecuperationUnits_storeAction', rootState.controlTechnologies.powerRecuperationUnit_Data.powerRecuperationUnit_Selection[0].powerRecuperationUnit);
                    }
                }
            }

            if (state.configurationSteps.brakeControl.loaded == false) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('controlTechnologies/get_brakeControl_Informations_storeAction');

                if ((state.configurationSteps.brakeControl.loaded == true) && (state.configurationSteps.brakeControl.available == true)) {
                    if (state.configurationSteps.brakeControl.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeControl) == true) && (state.loaded_configuration.brakeControl != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.brakeControl) == true) && (state.loaded_configuration.brakeControl != null)) {
                            const firstIndex_whichMeetCondition = rootState.controlTechnologies.brakeControl_Data.brakeControl_Selection.findIndex(i => i.brakeControl.blockNumber == state.loaded_configuration.brakeControl.blockNumber);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('controlTechnologies/change_Selected_atElementOf_brakeControl_Selection_storeAction', firstIndex_whichMeetCondition)
                            }
                            else {
                                // error
                            }
                        }
                    }
                }
            }

            if ((state.configurationSteps.energyEfficiencyRating.loaded == false) && (((state.configurationSteps.brakeResistor.available == true) && (state.configurationSteps.brakeResistor.storedInDatabase == true)) || ((state.configurationSteps.powerRecuperationUnit.available == true) && (state.configurationSteps.powerRecuperationUnit.storedInDatabase == true)))) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('controlTechnologies/get_energyEfficiencyRating_PermissibleValueRanges_Informations_storeAction')

                if (state.configurationSteps.energyEfficiencyRating.storedInDatabase == true) {
                    if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.energyEfficiencyRating) == true) && (state.loaded_configuration.energyEfficiencyRating != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.inverter) == true) && (state.loaded_configuration.inverter != null)) == false) {
                        await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'controlTechnologies' });
                    }

                    if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.energyEfficiencyRating) == true) && (state.loaded_configuration.energyEfficiencyRating != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.elevatorMotor_Machine) == true) && (state.loaded_configuration.elevatorMotor_Machine != null) && (state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.inverter) == true) && (state.loaded_configuration.inverter != null)) {
                        this.dispatch('controlTechnologies/set_energyEfficiencyRating_storeAction', state.loaded_configuration);

                        var configurationSteps = Object.assign({}, state.configurationSteps);
                        var configurationSteps_energyEfficiencyRating = Object.assign({}, state.configurationSteps.energyEfficiencyRating);
                        configurationSteps_energyEfficiencyRating.loaded = true;
                        configurationSteps.energyEfficiencyRating = configurationSteps_energyEfficiencyRating;
                        dispatch('change_configurationSteps_storeAction', configurationSteps)
                    }
                }
                else {
                    await this.dispatch('controlTechnologies/checkIf_userPreferences_for_energyEfficiencyRating_Set_storeAction')
                }
            }
        }

        if (state.navigation.controlTechnologies == 'passed') {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
    },

    async check_toDos_at_frames_storeAction({ rootState, dispatch }) {
        if (state.configurationSteps.elevatorMotor_Machine.storedInDatabase == true) {
            //if (state.configurationSteps.frame.loaded == false) {
            //    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
            //    await this.dispatch('frames/get_frame_Informations_storeAction');
            //    if ((state.configurationSteps.frame.loaded == true) && (state.configurationSteps.frame.available == true)) {
            //        if (state.configurationSteps.frame.storedInDatabase == true) {
            //            if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.frame) == true) && (state.loaded_configuration.frames != null)) == false) {
            //                await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'frames' });
            //            }
            //            if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.frame) == true) && (state.loaded_configuration.frames != null)) {
            //                const firstIndex_whichMeetCondition = rootState.frames.frame_Data.frame_Selection.findIndex(i => i.frame.blockNumber == state.loaded_configuration.frames.blockNumber);
            //                if (firstIndex_whichMeetCondition > -1) {
            //                    this.dispatch('frames/change_Selected_atElementOf_frame_Selection_storeAction', firstIndex_whichMeetCondition)
            //                }
            //                else {
            //                    // error
            //                }
            //            }
            //        }
            //    }
            //}
            //if ((state.configurationSteps.frame.loaded == true) && (state.configurationSteps.frame.storedInDatabase == true)) {
            if (state.configurationSteps.frameDeliverOptions.loaded == false) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
                await this.dispatch('frames/get_frameDeliverOptions_Informations_storeAction');
                if ((state.configurationSteps.frameDeliverOptions.loaded == true) && (state.configurationSteps.frameDeliverOptions.available == true)) {
                    if (state.configurationSteps.frameDeliverOptions.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.frameDeliverOptions) == true) && (state.loaded_configuration.frameDeliverOptions != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'frames' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.frameDeliverOptions) == true) && (state.loaded_configuration.frameDeliverOptions != null)) {
                            this.dispatch('frames/change_frameDeliverOptions_Data_storeAction', state.loaded_configuration.frameDeliverOptions)
                        }
                    }
                    else {
                        await this.dispatch('frames/checkIf_changesAt_frameDeliverOptions_changes_configurationFlow_storeAction')
                    }
                }
            }
            //}

            //if (state.configurationSteps.frame.loaded == true && state.configurationSteps.frame.storedInDatabase == true) {

            if (state.configurationSteps.pulley.loaded == false) {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

                await this.dispatch('frames/get_pulley_Informations_storeAction');
                if ((state.configurationSteps.pulley.loaded == true) && (state.configurationSteps.pulley.available == true)) {
                    if (state.configurationSteps.pulley.storedInDatabase == true) {
                        if (((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.pulleys) == true) && (state.loaded_configuration.pulleys != null)) == false) {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: 'frames' });
                        }

                        if ((state.loaded_configuration.hasOwnProperty(this._vm.$configurationSteps.pulley) == true) && (state.loaded_configuration.pulleys != null)) {
                            const firstIndex_whichMeetCondition = rootState.frames.pulley_Data.pulley_Selection.findIndex(i => i.pulley.blockNumber == state.loaded_configuration.pulleys.blockNumber);
                            if (firstIndex_whichMeetCondition > -1) {
                                this.dispatch('frames/change_Selected_atElementOf_pulley_Selection_storeAction', firstIndex_whichMeetCondition)
                            }
                            else {
                                // error
                            }
                        }
                    }
                }
            }

            //}
        }
        //if (state.navigation.frames == 'passed') {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        //}
    },

    async toDos_at_manualHome_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        await this.dispatch('summary/select_databaseRecords_for_summary_storeAction')
        await this.dispatch('summary/select_databaseRecords_for_manualHome_storeAction')
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });

        router.push('../manualStep/ManualHome');
    },


    async selectAndInsert_or_updateAndOrDelete_databaseRecords_storeAction({ dispatch }, value) {
        dispatch('change_loaded_configuration_storeAction', {});
        if (state.configuration_Modifiable == false) {
            await dispatch('insert_databaseRecords_storeAction', value)
        }
        else {
            await dispatch('updateAndOrDelete_databaseRecords_storeAction', value)
        }
    },
    async insert_databaseRecords_storeAction({ rootState, commit, dispatch }, value) {
        var data = Object.assign({}, value);
        data.configurationOverviewsId = state.configurationOverviewsId;
        data.configurationSteps = state.configurationSteps;

        await axios({
            method: 'post',
            url: '/api/configurations/insert_databaseRecords',
            data: {
                data: data,
            }
        })
            .then(async (response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    dispatch('set_configurationOverviewsId_at_sessionStorage_storeAction', response.data.data.configurationOverviewsId);
                    dispatch('change_configuration_Modifiable_storeAction', true)
                    dispatch('checkIf_data_haveToBeChanged_toDefault_storeAction', response.data.data.configurationSteps)
                    commit('change_configurationSteps_storeMutation', response.data.data.configurationSteps);

                    if (router.history.current.params.slug == 'controlTechnologies') {
                        await dispatch('check_toDos_at_driveTechnologies_storeAction');
                    }
                    dispatch('change_Properties_at_navigation_Object_storeAction');
                }
            })
            .catch(() => { })
    },
    async updateAndOrDelete_databaseRecords_storeAction({ dispatch }, value) {
        var data = Object.assign({}, value);
        data.configurationOverviewsId = state.configurationOverviewsId;
        data.configurationSteps = state.configurationSteps;

        await axios({
            method: 'post',
            url: '/api/configurations/updateAndOrDelete_databaseRecords',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    if (response.data.data.configurationSteps.inputs.storedInDatabase == false) {
                        dispatch('remove_configurationOverviewsId_at_sessionStorage_storeAction')
                    }
                    dispatch('change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },

    async check_id_storeAction({ dispatch }, value) {
        const data = { configurationOverviewsId: value };

        await axios({
            method: 'post',
            url: '/api/configurations/check_id',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2') == false) {
                    dispatch('remove_configurationOverviewsId_at_sessionStorage_storeAction')
                }
            })
            .catch(() => { })
    },

    async toDos_after_routeSlug_UpdatedOrEntered_storeAction({ rootState, dispatch }, value) {
        const configurationOverviewsId = sessionStorage.getItem('id');
        if ((configurationOverviewsId != null) && (configurationOverviewsId != 'null') && (configurationOverviewsId != '')) {
            await dispatch('check_id_storeAction', configurationOverviewsId)
            if ((rootState.statusHandling.status.statusInContextOf == 'check_id') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                if (value != 'manualHome') {
                    const data = {
                        configurationOverviewsId: configurationOverviewsId,
                        routeSlug: value,
                    }
                    await dispatch('check_configuration_fromDatabase_storeAction', data);
                }
                else {
                    const data = {
                        configurationOverviewsId: configurationOverviewsId,
                        routeSlug: 'summary',
                    }
                    await dispatch('check_configuration_fromDatabase_storeAction', data);
                }

                if ((rootState.statusHandling.status.statusInContextOf == 'check_configuration_fromDatabase_afterRouteSlug_UpdatedOrEntered') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
                    dispatch('set_configurationOverviewsId_at_sessionStorage_storeAction', configurationOverviewsId)
                    await dispatch('determine_configurationSteps_storeAction')
                    if ((rootState.statusHandling.status.statusInContextOf == 'determine_configurationSteps') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                        if (value == 'summary') {
                            this.dispatch('summary/toDos_at_summary_storeAction');
                        }
                        else {
                            await dispatch('select_databaseRecords_storeAction', { selectDatabaseRecordsFor: value });
                            if ((rootState.statusHandling.status.statusInContextOf == 'select_databaseRecords') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                                switch (value) {
                                    case 'inputs':
                                        dispatch('check_toDos_at_inputs_storeAction')
                                        break;
                                    case 'driveTechnologies':
                                        dispatch('check_toDos_at_driveTechnologies_storeAction')
                                        break;
                                    case 'controlTechnologies':
                                        dispatch('check_toDos_at_controlTechnologies_storeAction')
                                        break;
                                    case 'frames':
                                        dispatch('check_toDos_at_frames_storeAction')
                                        break;                                   
                                    //case 'manualHome':
                                    //    dispatch('toDos_at_manualHome_storeAction')
                                    //    break;
                                    //case 'cables':
                                    //    dispatch('toDos_at_manualHome_storeAction')
                                    //    break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                }
            }
        }
        else {
            if (value == 'inputs') {
                dispatch('check_toDos_at_inputs_storeAction')
            }
            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 3313 }, closingOption: 'to_startPage' }], valueDivergences: null } })
            }
        }
    },

    async loadDatabaseVersion_storeAction({ commit }) {
        await axios({
            method: 'post',
            url: '/api/configurations/loadVersionDatabaseMasterdata',

        })
            .then((response) => {
                commit('loadDataBaseVersion_storeMutation', response.data)
            })
            .catch(() => { })
    },

    async check_configuration_fromDatabase_storeAction({ dispatch, commit }, value) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'configuration_fromDatabase_BeingChecked' });

        this.dispatch('inputs/change_inputs_toDefault_storeAction');
        this.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
        this.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
        this.dispatch('frames/change_frames_toDefault_storeAction');
        this.dispatch('summary/change_summary_toDefault_storeAction');
        dispatch('change_configurations_toDefault_storeAction');


        await axios({
            method: 'post',
            url: '/api/configurations/check_configuration_fromDatabase',
            data: {
                data: value,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                commit('change_configurationNotValid_storeMutation', response.data.data.configurationNotValid)
            })
            .catch(() => { })
    },
    async toDos_after_configuration_fromDatabase_Checked_storeAction({ rootState, dispatch }, value) {
        var databaseRecords_Finalized = false

        if (status.valueDivergences != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'databaseRecords_BeingInsertedOrUpdated' });
            await dispatch('update_databaseRecords_after_configuration_Checked_storeAction', value);
            if ((rootState.statusHandling.status.statusInContextOf == 'update_databaseRecords_after_configuration_Checked') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                databaseRecords_Finalized = true;
            }
        }
        else {
            databaseRecords_Finalized = true;
        }

        if (databaseRecords_Finalized == true) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
            dispatch('set_configurationOverviewsId_at_sessionStorage_storeAction', value.configurationOverviewsId);
            dispatch('change_configuration_Modifiable_storeAction', value.configuration_Modifiable);
            await dispatch('determine_configurationSteps_storeAction')
            if ((rootState.statusHandling.status.statusInContextOf == 'determine_configurationSteps') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                await dispatch('select_databaseRecords_storeAction', value);
            }
        }
    },
    check_configuration_fromFile_storeAction({ commit,dispatch }, value) {
        const fileInformations = value.fileInformations;
        //event.target.value = null;
        const splittedFileName = fileInformations.name.split('.');
        const fileFormat = splittedFileName[splittedFileName.length - 1];
        var reader = new FileReader();
        if ((fileFormat == 'html') || (fileFormat == 'aus')) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'configuration_fromFile_BeingChecked' });

            this.dispatch('inputs/change_inputs_toDefault_storeAction');
            this.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
            this.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
            this.dispatch('frames/change_frames_toDefault_storeAction');
            this.dispatch('summary/change_summary_toDefault_storeAction');
            dispatch('change_configurations_toDefault_storeAction');
            reader.readAsText(fileInformations, 'windows-1252');
            reader.onload = () => {
                const data = {
                    fileContent: reader.result,
                    fileFormat: fileFormat,
                    fileLanguage: value.fileLanguage,
                }

                axios({
                    method: 'post',
                    url: '/api/configurations/check_configuration_fromFile',
                    data: {
                        data: data,
                    }
                })
                    .then((response) => {
                        this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                    })
                    .catch(() => { })
                    .then(() => {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    })
            }
        }
        else if (fileFormat.toLowerCase() == 'xml') {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'configuration_fromFile_BeingChecked' });

            this.dispatch('inputs/change_inputs_toDefault_storeAction');
            this.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
            this.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
            this.dispatch('frames/change_frames_toDefault_storeAction');
            this.dispatch('summary/change_summary_toDefault_storeAction');
            dispatch('change_configurations_toDefault_storeAction');
            reader.readAsText(fileInformations, 'windows-1252');
            reader.onload = () => {
                const data = {
                    fileContent: reader.result,
                    fileFormat: fileFormat,
                    fileLanguage: value.fileLanguage,
                }

                axios({
                    method: 'post',
                    url: '/api/configurations/check_configurationXML_fromFile',
                    data: {
                        data: data,
                    }
                })
                    .then((response) => {
                        this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                        //commit('change_configurationOverviewsManualId_storeMutation', response.data.data.configurationOverviewsManualId);
                        this.dispatch('manualHome/change_ZLM_storeAction', response.data.data.zlm);
                        router.push('../manualStep/ManualHome');
                    })
                    .catch(() => { })
                    .then(() => {
                        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                    })
            }
        }
        else {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { data: {}, status: { statusCode: '7100000', statusInContextOf: 'check_configurationXML_fromFile_storeAction', statusInformations: [{ textKey: 3300, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 3090 }, closingOption: this._vm.$closingOptions.close }] } })
        }
    },

    async insert_databaseRecords_after_configuration_Checked_storeAction({ rootState, dispatch }, value) {
        dispatch('change_loaded_configuration_storeAction', value)

        var data = Object.assign({}, value);
        data.configurationSteps = state.configurationSteps;

        await axios({
            method: 'post',
            url: '/api/configurations/insert_databaseRecords_after_configuration_Checked',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    dispatch('set_configurationOverviewsId_at_sessionStorage_storeAction', response.data.data.configurationOverviewsId);
                    dispatch('change_configurationSteps_storeAction', response.data.data.configurationSteps);
                }
            })
            .catch(() => { })
    },
    async update_databaseRecords_after_configuration_Checked_storeAction({ }, value) {
        var data = Object.assign({}, value);

        await axios({
            method: 'post',
            url: '/api/configurations/update_databaseRecords_after_configuration_Checked',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
            .catch(() => { })
    },

    async determine_configurationSteps_storeAction({ dispatch }) {
        const data = {
            configurationOverviewsId: state.configurationOverviewsId,
            configurationSteps: state.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/configurations/determine_configurationSteps',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    dispatch('change_configurationSteps_storeAction', response.data.data.configurationSteps);

                }
            })
            .catch(() => { })
    },
    async select_databaseRecords_storeAction({ dispatch }, value) {
        var data = Object.assign({}, value);
        data.configurationOverviewsId = state.configurationOverviewsId;
        data.configurationSteps = state.configurationSteps;

        await axios({
            method: 'post',
            url: '/api/configurations/select_databaseRecords',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.startsWith('2')) {
                    dispatch('change_loaded_configuration_storeAction', response.data.data);
                    dispatch('change_configuration_Modifiable_storeAction', response.data.data.configuration_Modifiable);
                }
            })
            .catch(() => { })
    },

    change_selectedLanguage_storeAction({ commit, dispatch }, value) {
        commit('change_selectedLanguage_storeMutation', value)
    },

    async openInputHelpFile_storeAction({ dispatch }, value) {
        await axios({
            method: 'post',
            url: '/api/configurations/openInputHelpFile/' + value,
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data != null || response.data != undefined || response.data != '') {
                    //this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    var newWindow = window.open("", "_blank");
                    newWindow.document.write(response.data);
                    newWindow.document.title = "ZAlift Help";
                }
            })
            .catch(() => { })
    },
    async openDriveTechnologyHelpFile_storeAction({ dispatch }, value) {

        await axios({
            method: 'post',
            url: '/api/configurations/openDriveTechnologyHelpFile/' + value,
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data != null || response.data != undefined || response.data != '') {
                    //this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    var newWindow = window.open("", "_blank");
                    newWindow.document.write(response.data);
                    newWindow.document.title = "ZAlift Help";
                }
            })
            .catch(() => { })
    },
    async openControlTechnologyHelpFile_storeAction({ dispatch }, value) {

        await axios({
            method: 'post',
            url: '/api/configurations/openControlTechnologyHelpFile/' + value,
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data != null || response.data != undefined || response.data != '') {
                    //this.dispatch('configurations/change_configurationSteps_storeAction', response.data.data.configurationSteps);

                    var newWindow = window.open("", "_blank");
                    newWindow.document.write(response.data);
                    newWindow.document.title = "ZAlift Help";
                }
            })
            .catch(() => { })
    },

    todos_configurationNotValid_storeAction({ commit }) {
        if (state.configurationNotValid != null && state.configurationNotValid == true && state.configuration_Modifiable == false) {
            commit('change_navigation_storeMutation', {
                inputs: null,
                driveTechnologies: 'disabled',
                controlTechnologies: 'disabled',
                frames: 'disabled',
                cables: 'disabled',
                summary: 'passed',
            });
        }
    },

    change_accept_CustomerNumber_storeAction({ commit }, value) {
        commit('change_accept_CustomerNumber_storeMutation', value);
    },
    change_elevatorMotor_Manual_storeAction({ commit }, value) {
        commit('change_elevatorMotor_Manual_storeMutation', value);
    },
    change_manualConfiguration_storeAction({ commit }, value) {
        commit('change_manualConfiguration_storeMutation', value)
    },
    change_configurationStepManual_storeAction({ dispatch }, value) {
        if (value == 'driveTechnologiesManual') {
            if (state.navigationManual.driveTechnologiesManual != 'disabled' && state.navigationManual.inputsManual == 'passed') {
                router.push({ params: { slug: value } })
            }
            else {
                this.dispatch('inputsManual/checkIf_inputsManual_areValid_storeAction')
            }
        }
        else if (value == 'cables') {
            if (state.navigationManual.cablesManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual == 'passed') {
                router.push({ params: { slug: value } })
            }
            else if (state.navigationManual.cablesManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual != 'passed') {
                this.dispatch('driveTechnologiesManual/checkIf_driveTechnologiesManual_areValid_storeAction');
            }
            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else if (value == "suppliesMotorManual") {
            if (state.navigationManual.suppliesMotorManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual == 'passed' &&
                state.navigationManual.cablesManual == 'passed') {
                router.push({ params: { slug: value } })
            }
            else if (state.navigationManual.suppliesMotorManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual == 'passed' && state.navigationManual.cablesManual != 'passed') {
                this.dispatch('cables/checkIf_cablesManual_areValid_storeAction');
            }
            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else if (value == "packagingMotorManual") {
            if (state.navigationManual.packagingMotorManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual == 'passed' &&
                state.navigationManual.cablesManual == 'passed' && state.navigationManual.packagingMotorManual == 'passed') {
                router.push({ params: { slug: value } })
            }
            else if (state.navigationManual.packagingMotorManual != 'disabled' && state.navigationManual.inputsManual == 'passed' && state.navigationManual.driveTechnologiesManual == 'passed' && state.navigationManual.cablesManual == 'passed' && state.navigationManual.suppliesMotorManual != 'passed') {
                this.dispatch('suppliesMotorManual/checkIf_suppliesMotorManual_areValid_storeAction');
            }

            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7300000', statusInContextOf: '', statusInformations: [{ textKey: 3311, textValues: null }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }], valueDivergences: null } })
            }
        }
        else {
            router.push({ params: { slug: value } })
        }
    },
}

const mutations = {
    change_plusManual_storeMutation(state, payload) {
        state.plusManual = payload;
    },
    change_configurationOverview_storeMutation(state, payload) {
        state.configurationOverview = payload;
    },
    change_configurationOverviewFilter_storeMutation(state, payload) {
        state.configurationOverviewFilter = payload;
    },
    change_configurationLastEditedOn_storeMutation(state) {
        state.configurationOverviewFilter.forEach(i => {
            i.configuration_LastEditedOn = (new Date(i.configuration_LastEditedOn)).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
        })
    },
    change_configurationsTable_tempZAliftID_storeMutation(state, payload) {
        state.tempZALift_ID = payload;
    },
    change_configurationsTable_shareList_storeMutation(state, payload) {
        state.sharedList = payload;
    },
    change_configurationsTable_shareDisplayShow_storeMutation(state, payload) {
        state.shareDisplayShow = payload;
    },
    //Test Dimmler
    MANU_configuration(state, payload) {
        state.MANUAL = payload;
    },
    change_salesOrder(state, payload) {
        state.salesOrder = payload.salesOrder;
        state.salesOrderPos = payload.pos;
    },
    loadDataBaseVersion_storeMutation(state, payload) {
        state.databaseVersion = payload;
    },
    change_configurationOverviewsId_storeMutation(state, payload) {
        state.configurationOverviewsId = payload;
    },
    change_configurationOverviewsLinkId_storeMutation(state, payload) {
        state.configurationOverviewsLinkId = payload;
    },
    change_configuration_Modifiable_storeMutation(state, payload) {
        state.configuration_Modifiable = payload;
    },
    change_configuration_CreatedByLoggedInUser_storeMutation(state, payload) {
        state.configuration_CreatedByLoggedInUser = payload;
    },
    change_loaded_configuration_storeMutation(state, payload) {
        state.loaded_configuration = Object.assign({}, payload);
    },

    change_note_storeMutation(state, payload) {
        state.note = payload;
    },
    change_loader_forLoading_note_Shown_storeMutation(state, payload) {
        state.loader_forLoading_note_Shown = payload;
    },
    change_statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews_storeMutation(state, payload) {
        state.statusAt_responseOf_select_Note_from_databaseRecord_configurationOverviews = Object.assign({}, payload);
    },

    change_configurationSteps_storeMutation(state, payload) {
        state.configurationSteps = Object.assign({}, payload);
    },
    change_navigation_storeMutation(state, payload) {
        state.navigation = Object.assign({}, payload);
    },
    change_selectedLanguage_storeMutation(state, payload) {
        state.language = payload;
    },
    change_configurationNotValid_storeMutation(state, payload) {
        state.configurationNotValid = payload;
    },
    change_accept_CustomerNumber_storeMutation(state, payload) {
        state.accept_CustomerNumber = payload;
    },
    change_elevatorMotor_Manual_storeMutation(state, payload) {
        state.elevatorMotor_Manual = payload;
    },
    change_manualConfiguration_storeMutation(state, payload) {
        state.manualConfiguration = payload;
    },
    change_navigationManual_storeMutation(state, payload) {
        state.navigationManual = Object.assign({}, payload);
    },
    change_loaded_configurationMotorManual_storeMutation(state, payload) {
        state.loaded_configurationMotorManual = payload;
    },
    change_sap_storeMutation(state, payload) {
        state.sap = payload;
    },

    //change_configurationOverviewsManualId_storeMutation(state, payload) {
    //    state.configurationOverviewsManualId = payload
    //},
}

export const configurations = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}
