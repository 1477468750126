import axios from 'axios';
import router from "@/router";

let state = {
    nominalLoad_MENUE: null,
    nominalLoad_BAL: null,
    nominalLoad_PAR: null,
    nominalLoad_CODE: null,
    nominalLoad_CONFIG: null,
    nominalLoad_Ausfue: null,
    FI_Type_selected: null,
    FI_TypeUI_selected: null,
    FI_Model_selected: null,
    FI_ModelUI_selected: null,
    FI_Conf_selected: null,
    FI_ConfUI_selected: null,
    FI_Par_selected: null,
    FI_ParUI_selected: null,
    FI_Bal_selected: null,
    FI_BalUI_selected: null,
    FI_Menue_selected: null,
    FI_MenueUI_selected: null,
    FI_Code_selected: null,
    FI_CodeUI_selected: null,
    FI_PAD_selected: null,
    FI_PADUI_selected: null,
    FI_MNPAD_selected: null,
    FI_MON_selected: null,
    FI_MONUI_selected: null,
    FI_MNMON_selected: null,
    FI_ND_selected: null,
    FI_NDUI_selected: null,
    FI_MNND_selected: null,
    FI_FEF_selected: null,
    FI_FEFUI_selected: null,
    FI_MNFEF_selected: null,
    FI_BR_selected: null,
    FI_BRUI_selected: null,
    FI_MNBR_selected: null,
    FI_BR_Quantity_selected: null,
    FI_BR_QuantityUI_selected: null,
    FI_BRLT_selected: null,
    FI_BRLTUI_selected: null,
    FI_MNBRLT_selected: null,
    FI_DCP_selected: null,
    FI_DCPUI_selected: null,
    FI_MNDCP_selected: null,
    FI_STO_selected: null,
    FI_STOUI_selected: null,
    FI_MNSTO_selected: null,
    FI_XOUT_selected: null,
    FI_XOUTUI_selected: null,
    FI_MNXOUT_selected: null,
    FI_XIN_selected: null,
    FI_XINUI_selected: null,
    FI_MNXIN_selected: null,
    FI_MNFI_selected: null,
    FI_MNFISTL_selected: null,
    FI_MNFIBGGT_selected: null,
    FI_MNBAL_selected: null,
    FI_Nachruestsatz_selected: null,
    FI_NachruestsatzUI_selected: null,
    FI_MNNachruestsatz_selected: null,
    FI_ZAsbc_selected: null,
    FI_ZAsbcUI_selected: null,
    FI_MNZAsbc_selected: null,
    FI_ZAsbc_LS_selected: null,
    FI_ZAsbc_LSUI_selected: null,
    FI_MNZAsbc_LS_selected: null,
    FI_ZAsbc_LSBA_selected: null,
    FI_ZAsbc_LSBAUI_selected: null,
    FI_MNZAsbc_LSBA_selected: null,
    FI_ZAsbc_BAL_selected: null,
    FI_ZAsbc_BALUI_selected: null,
    FI_MNZAsbc_BAL_selected: null,
    FI_Type_List: [],
    FI_Model_List: [],
    FI_Conf_List: [],
    FI_Par_List: [],
    FI_Bal_List: [],
    FI_Menue_List: [],
    FI_Code_List: [],
    FI_PAD_List: [],
    FI_MON_List: [],
    FI_ND_List: [],
    FI_FEF_List: [],
    FI_BR_List: [],
    FI_BR_Quantity_List:[],
    FI_BRLT_List: [],
    FI_DCP_List: [],
    FI_STO_List: [],
    FI_XOUT_List: [],
    FI_XIN_List: [],
    FI_Nachruestsatz_List: [],
    FI_ZAsbc_List: [],
    FI_ZAsbc_LS_List: [],
    FI_ZAsbc_LSBA_List: [],
    FI_ZAsbc_BAL_List: [],
    ZLM: null,
}

const actions = {
    //get_Test({ dispatch }) {
    //    console.Log('get_Test')
    //},
    async get_FI_Type_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        await axios({
            method: 'post',
            url: '/api/FI/get_FI_Type',

        })
            .then((response) => {
                commit('get_FI_Type_storeMutation', response.data)
                if (state.FI_Type_selected != null) {
                    for (let i = 0; i < state.FI_Type_List.length; i++) {
                        state.FI_Type_List[i].selected = false;

                        if (state.FI_Type_List[i].text.text == state.FI_Type_selected) {
                            state.FI_Type_List[i].selected = true;
                        }
                    }
                }


            })
            .catch(() => { })
    },
    async get_FI_Model_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected };

        /*console.log('get_FI_Type_storeAction', data_FU)*/
        await axios({
            method: 'post',
            url: '/api/FI/get_FI_Model',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {
                
                commit('get_FI_Model_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_Menue_storeAction({ commit, rootState }) {
        const data_FU = { baur: state.FI_Type_selected, lNG: rootState.manualHome.LNG };

       
        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MENUE',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {
                
                commit('get_FI_Menue_storeMutation', response.data)
                const firstIndex_whichMeetConditions_Test = state.FI_Menue_List.findIndex(i => i.selected == true)
                commit('change_FI_Menue_selection_storeMutation', state.FI_Menue_List[firstIndex_whichMeetConditions_Test].text.text);
                commit('change_FI_MenueUI_selection_storeMutation', state.FI_Menue_List[firstIndex_whichMeetConditions_Test].text.textUI);
                

            })
            .catch(() => { })
    },

    async get_FI_BAL_storeAction({ commit, rootState }) {
        const data_FU = { baur: state.FI_Type_selected, lNG: rootState.manualHome.LNG, };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_BAL',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_Bal_storeMutation', response.data)

                const firstIndex_whichMeetConditions_Test = state.FI_Bal_List.findIndex(i => i.selected == true)
                commit('change_FI_Bal_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions_Test].text.text);
                commit('change_FI_BalUI_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions_Test].text.textUI);
                commit('change_FI_MNBAL_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions_Test].id)

            })
            .catch(() => { })
    },
    async get_FI_Conf_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_CONF',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_Conf_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_Par_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_PAR',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_Par_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_Code_storeAction({ commit, dispatch }) {
        const data_FU = { baur: state.FI_Type_selected };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_Code',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_Code_storeMutation', response.data)
                
                /*if (state.FI_TypeUI_selected == "ZAdynpro") {*/
                    
                    const firstIndex_whichMeetConditions_Test = state.FI_Code_List.findIndex(i => i.selected == true)
                    commit('change_FI_Code_selection_storeMutation', state.FI_Code_List[firstIndex_whichMeetConditions_Test].text.text);
                    commit('change_FI_CodeUI_selection_storeMutation', state.FI_Code_List[firstIndex_whichMeetConditions_Test].text.textUI)
                    dispatch('get_FI_PAD_storeAction');

                    dispatch('get_FI_MON_storeAction');
                    dispatch('get_FI_ND_storeAction');
                    dispatch('get_FI_FEF_storeAction');

                    dispatch('get_FI_BR_storeAction');
                    dispatch('get_FI_BRLT_storeAction');
                    dispatch('get_FI_Nachruestsatz_storeAction');
                    dispatch('get_FI_ZAsbc_storeAction');
                    //dispatch('get_FI_ZAsbc_LS_storeAction');
                    //dispatch('get_FI_ZAsbc_LSBA_storeAction');
                    //dispatch('get_FI_ZAsbc_BAL_storeAction');
                    dispatch('get_FI_DCP_storeAction');
                    dispatch('get_FI_STO_storeAction');
                    dispatch('get_FI_XOUT_storeAction');
                    dispatch('get_FI_XIN_storeAction');
                    dispatch('get_FI_MNFI_storeAction');
                    dispatch('get_FI_MNFISTL_storeAction');
                    dispatch('get_FI_MNFIBGGT_storeAction');
                //} 
                

            })
            .catch(() => { })
    },
    async get_FI_PAD_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };
        


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_PAD',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_PAD_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNPAD_storeAction({ commit }) {
        const data_FU = { ZApad: state.FI_PAD_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNPAD',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNPAD_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MON_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MON',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_MON_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNMON_storeAction({ commit }) {
        const data_FU = { ZAmon: state.FI_MON_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNMON',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNMON_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_ND_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_ND',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_ND_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNND_storeAction({ commit }) {
        const data_FU = { ND: state.FI_ND_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNND',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNND_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_FEF_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_FEF',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_FEF_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNFEF_storeAction({ commit }) {
        const data_FU = { FEF: state.FI_FEF_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNFEF',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNFEF_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_BR_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_BR',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_BR_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_BR_Quantity_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_BR_Quantity',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_BR_Quantity_storeMutation', response.data)
                
                if (state.FI_BR_selected != null) {
                    const firstIndex_whichMeetConditions_Test = state.FI_BR_Quantity_List.findIndex(i => i.selected == true)
                    commit('change_FI_BR_Quantity_selection_storeMutation', state.FI_BR_Quantity_List[firstIndex_whichMeetConditions_Test].text.text);
                    commit('change_FI_BR_QuantityUI_selection_storeMutation', state.FI_BR_Quantity_List[firstIndex_whichMeetConditions_Test].text.textUI)
                }
                
            })
            .catch(() => { })
    },

    async get_FI_MNBRLT_storeAction({ commit }) {
        const data_FU = { BRLT: state.FI_BRLT_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNBRLT',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNBRLT_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNFI_storeAction({ commit }) {
        const data_FU = { BAUG: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNFI',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNFI_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNFISTL_storeAction({ commit }) {
        const data_FU = { BAUG: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNFISTL',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNFISTL_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_MNFIBGGT_storeAction({ commit }) {
        const data_FU = { BAUG: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNFIBGGT',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNFIBGGT_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_MNBR_storeAction({ commit }) {
        const data_FU = { BR:state.FI_BR_selected};



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNBR',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('change_FI_MNBR_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
   

    async get_FI_BRLT_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_BRLT',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_BRLT_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_DCP_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_DCP',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_DCP_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_STO_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_STO',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_STO_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_XOUT_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_XOUT',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_XOUT_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_XIN_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_XIN',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_XIN_storeMutation', response.data)


            })
            .catch(() => { })
    },

    async get_FI_Nachruestsatz_storeAction({ commit }) {

        //console.log('get_FI_Type_storeAction')
        await axios({
            method: 'post',
            url: '/api/FI/get_Nachruestsatz_Type',

        })
            .then((response) => {
                
                commit('get_FI_Nachruestsatz_storeMutation', response.data)



            })
            .catch(() => { })
    },
    async get_FI_ZAsbc_storeAction({ commit }) {
        const data_FU = { baur: state.FI_Type_selected, baug: state.FI_Model_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_ZAsbc',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {
               
                commit('get_FI_ZAsbc_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_MNZASBC_storeAction({ commit }) {
        const data_FU = { ZAsbc: state.FI_ZAsbc_selected };



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_MNZASBC',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {
                
                commit('change_FI_MNZAsbc_selection_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_ZAsbc_LS_storeAction({ commit }) {
        



        await axios({
            method: 'post',
            url: '/api/FI/get_FI_ZAsbc_LS',
           

        })
            .then((response) => {

                commit('get_FI_ZAsbc_LS_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_ZAsbc_LSBA_storeAction({ commit }) {




        await axios({
            method: 'post',
            url: '/api/FI/get_FI_ZAsbc_LSBA',


        })
            .then((response) => {

                commit('get_FI_ZAsbc_LSBA_storeMutation', response.data)


            })
            .catch(() => { })
    },
    async get_FI_ZAsbc_BAL_storeAction({ commit, rootState }) {

        const data_FU = { lNG: rootState.manualHome.LNG, };


        await axios({
            method: 'post',
            url: '/api/FI/get_FI_ZAsbc_BAL',
            data: {
                data_FU: data_FU,
            }

        })
            .then((response) => {

                commit('get_FI_ZAsbc_BAL_storeMutation', response.data)
                const firstIndex_whichMeetConditions_Test = state.FI_ZAsbc_BAL_List.findIndex(i => i.selected == true)
                commit('change_FI_ZAsbc_BAL_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions_Test].text.text);
                commit('change_FI_ZAsbc_BALUI_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions_Test].text.textUI);
                commit('change_FI_MNZAsbc_BAL_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions_Test].id)

            })
            .catch(() => { })
    },

    async insert_data({ rootState }) {
        
        const data_Insert_FU = {
            Baureihe: state.FI_Type_selected,
            Baugroesse: state.FI_Model_selected,
            MN_FI: state.FI_MNFI_selected ,
            MN_STL: state.FI_MNFISTL_selected,
            MN_GT: state.FI_MNFIBGGT_selected,
            Betriebsanleitung: state.FI_Bal_selected,
            MN_BAL: state.FI_MNBAL_selected,
            Umschaltcode: state.FI_Code_selected,
            Kundenkonfiguration: state.FI_Conf_selected,
            Menuesprache: state.FI_Menue_selected,
            Motor_und_Anlagedaten: state.FI_Par_selected,
            ZApad: state.FI_PAD_selected,
            MN_PAD: state.FI_MNPAD_selected,
            ZAmon: state.FI_MON_selected,
            MN_MON: state.FI_MNMON_selected,
            Netzdrossel: state.FI_ND_selected,
            MN_ND: state.FI_MNND_selected,
            Funkentstoerfilter: state.FI_FEF_selected,
            MNFEF: state.FI_MNFEF_selected,
            Bremswiderstand: state.FI_BR_selected,
            MN_BR: state.FI_MNBR_selected,
            Bremswiderstandsleitung: state.FI_BRLT_selected,
            BR_Menge: state.FI_BR_Quantity_selected,
            MN_BRLT: state.FI_MNBRLT_selected,
            Nachruestsatz: state.FI_Nachruestsatz_selected,
            MN_Nachruestsatz: state.FI_MNNachruestsatz_selected,
            DCP_Leitung: state.FI_DCP_selected,
            MN_DCP: state.FI_MNDCP_selected,
            STO_Leitung: state.FI_STO_selected,
            MN_STO: state.FI_MNSTO_selected,
            XOUT_Leitung: state.FI_XOUT_selected,
            MN_XOUT: state.FI_MNXOUT_selected,
            XIN_Leitung: state.FI_XIN_selected,
            MN_XIN: state.FI_MNXIN_selected,
            ZAsbc: state.FI_ZAsbc_selected,
            MN_ZAsbc: state.FI_MNZAsbc_selected,
            Leitungssatz_ZAsbc: state.FI_ZAsbc_LS_selected,
            MN_Leitungssatz_ZAsbc: state.FI_MNZAsbc_LS_selected,
            Bremsenansteuerungsleitung_ZAsbc: state.FI_ZAsbc_LSBA_selected,
            MN_Bremsenansteuerungsleitung_ZAsbc: state.FI_MNZAsbc_LSBA_selected,
            Sprache_Betriebsanleitung_ZAsbc: state.FI_ZAsbc_BAL_selected,
            MN_Sprache_Betriebsanleitung_ZAsbc: state.FI_MNZAsbc_BAL_selected,


        };

        
        
        await axios({
            method: 'post',
            url: '/api/FI/insert_data',
            
            data: {
                data_Insert_FU: data_Insert_FU,
                zLM: rootState.manualHome.ZLM,
            }

        })
            .then((response) => {
                
                //commit('change_FI_ZLM_storeMutation', response.data)
                this.commit('manualHome/change_ZLM_storeMutation',response.data)

            })
            .catch(() => { })
    },

    change_FI_Type_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_Type_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Type_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Type_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Type_selection_storeMutation', state.FI_Type_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_TypeUI_selection_storeMutation', state.FI_Type_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_Type_selection_storeMutation', null);
            commit('change_FI_TypeUI_selection_storeMutation', null);
        }

        /*console.log('change_FI_Type_selection_storeAction', value);*/
        //commit('change_FI_Type_selection_storeMutation', value);
        state.FI_Model_selected = null;
        state.FI_MNFI_selected = null;
        state.FI_MNFISTL_selected= null;
        state.FI_MNFIBGGT_selected= null;
        dispatch('get_FI_Model_storeAction');

        //dispatch('get_FI_Menue_storeAction');
        //dispatch('get_FI_BAL_storeAction');
        //dispatch('get_FI_Par_storeAction');
        //dispatch('get_FI_Conf_storeAction');
        //dispatch('get_FI_Code_storeAction');

    },

    change_FI_Model_selection_storeAction({ commit, dispatch }, value) {
        //commit('change_FI_Model_selection_storeMutation', value);
        if (value.selected == true) {
            commit('change_FI_Model_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Model_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Model_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Model_selection_storeMutation', state.FI_Model_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ModelUI_selection_storeMutation', state.FI_Model_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_Model_selection_storeMutation', null);
            commit('change_FI_ModelUI_selection_storeMutation', null);
        }

        state.FI_Bal_selected = null;
        state.FI_BalUI_selected = null;
        state.FI_Menue_selected = null;
        state.FI_MenueUI_selected = null;
        state.FI_Code_selected = null;
        state.FI_CodeUI_selected = null;
        state.FI_Conf_selected = null;
        state.FI_ConfUI_selected = null;
        state.FI_Par_selected = null;
        state.FI_ParUI_selected = null;


        dispatch('get_FI_Menue_storeAction');
        dispatch('get_FI_BAL_storeAction');
        dispatch('get_FI_Par_storeAction');
        dispatch('get_FI_Conf_storeAction');
        dispatch('get_FI_Code_storeAction');

        //dispatch('get_FI_PAD_storeAction');
        
        //dispatch('get_FI_MON_storeAction');
        //dispatch('get_FI_ND_storeAction');
        //dispatch('get_FI_FEF_storeAction');
        
        //dispatch('get_FI_BR_storeAction');
        //dispatch('get_FI_BRLT_storeAction');
        //dispatch('get_FI_Nachruestsatz_storeAction');
        //dispatch('get_FI_ZAsbc_storeAction');
        //dispatch('get_FI_ZAsbc_LS_storeAction');
        //dispatch('get_FI_ZAsbc_LSBA_storeAction');
        //dispatch('get_FI_ZAsbc_BAL_storeAction');
        //dispatch('get_FI_DCP_storeAction');
        //dispatch('get_FI_STO_storeAction');
        //dispatch('get_FI_XOUT_storeAction');
        //dispatch('get_FI_XIN_storeAction');
        //dispatch('get_FI_MNFI_storeAction');
        //dispatch('get_FI_MNFISTL_storeAction');
        //dispatch('get_FI_MNFIBGGT_storeAction');
        commit('change_FI_MNFI_selection_storeMutation');
        commit('change_FI_MNFISTL_selection_storeMutation');
        commit('change_FI_MNFIBGGT_selection_storeMutation');
    //    commit('change_FI_Menue_selection_storeMutation', value);
    //    dispatch('get_FI_BAL_storeAction');
    },

    change_FI_Menue_selection_storeAction({ commit, rootState }, value) {
        if (value.selected == true) {
            commit('change_FI_Menue_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Menue_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Menue_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Menue_selection_storeMutation', state.FI_Menue_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_MenueUI_selection_storeMutation', state.FI_Menue_List[firstIndex_whichMeetConditions].text.textUI);

            //if (rootState.manualHome.LNG == null) {
            //    this.commit('manualHome/change_LNG_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.text);
            //}
            if (rootState.manualHome.LNG == null) {
                this.commit('manualHome/change_LNG_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.textUI.toLowerCase());
            }
        }
        else {
            commit('change_FI_Menue_selection_storeMutation', null);
            commit('change_FI_MenueUI_selection_storeMutation', null);
        }


    },

    change_FI_Bal_selection_storeAction({ commit, rootState }, value) {
        if (value.selected == true) {
            commit('change_FI_Bal_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Bal_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Bal_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Bal_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_BalUI_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNBAL_selection_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].id);
            
            //if (rootState.manualHome.LNG == null) {
            //    this.commit('manualHome/change_LNG_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.text);
            //}
            if (rootState.manualHome.LNG == null) {
                this.commit('manualHome/change_LNG_storeMutation', state.FI_Bal_List[firstIndex_whichMeetConditions].text.textUI.toLowerCase());
            }

        }
        else {
            commit('change_FI_Bal_selection_storeMutation', null);
            commit('change_FI_BalUI_selection_storeMutation', null);
            commit('change_FI_MNBAL_selection_storeMutation', null);
        }
        

    },
    change_FI_Par_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_Par_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Par_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Par_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Par_selection_storeMutation', state.FI_Par_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ParUI_selection_storeMutation', state.FI_Par_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_Par_selection_storeMutation', null);
            commit('change_FI_ParUI_selection_storeMutation', null);
        }


    },
    change_FI_Conf_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_Conf_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Conf_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Conf_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Conf_selection_storeMutation', state.FI_Conf_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ConfUI_selection_storeMutation', state.FI_Conf_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_Conf_selection_storeMutation', null);
            commit('change_FI_ConfUI_selection_storeMutation', null);
        }


    },
    change_FI_Code_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_Code_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Code_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Code_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Code_selection_storeMutation', state.FI_Code_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_CodeUI_selection_storeMutation', state.FI_Code_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_Code_selection_storeMutation', null);
            commit('change_FI_CodeUI_selection_storeMutation', null);
        }

        dispatch('get_FI_PAD_storeAction');

        dispatch('get_FI_MON_storeAction');
        dispatch('get_FI_ND_storeAction');
        dispatch('get_FI_FEF_storeAction');

        dispatch('get_FI_BR_storeAction');
        dispatch('get_FI_BRLT_storeAction');
        dispatch('get_FI_Nachruestsatz_storeAction');
        dispatch('get_FI_ZAsbc_storeAction');
        //dispatch('get_FI_ZAsbc_LS_storeAction');
        //dispatch('get_FI_ZAsbc_LSBA_storeAction');
        //dispatch('get_FI_ZAsbc_BAL_storeAction');
        dispatch('get_FI_DCP_storeAction');
        dispatch('get_FI_STO_storeAction');
        dispatch('get_FI_XOUT_storeAction');
        dispatch('get_FI_XIN_storeAction');
        dispatch('get_FI_MNFI_storeAction');
        dispatch('get_FI_MNFISTL_storeAction');
        dispatch('get_FI_MNFIBGGT_storeAction');
    },


    change_FI_PAD_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_PAD_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_PAD_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_PAD_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_PAD_selection_storeMutation', state.FI_PAD_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_PADUI_selection_storeMutation', state.FI_PAD_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNPAD_storeAction');
            commit('change_FI_MNPAD_selection_storeMutation')
            
        }
        else {
            commit('change_FI_PAD_selection_storeMutation', null);
            commit('change_FI_PADUI_selection_storeMutation', null);
            commit('change_FI_MNPAD_selection_storeMutation', null)

        }



    },

    change_FI_MON_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_MON_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_MON_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_MON_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_MON_selection_storeMutation', state.FI_MON_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_MONUI_selection_storeMutation', state.FI_MON_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNMON_storeAction');
            commit('change_FI_MNMON_selection_storeMutation')

        }
        else {
            commit('change_FI_MON_selection_storeMutation', null);
            commit('change_FI_MONUI_selection_storeMutation', null);
            commit('change_FI_MNMON_selection_storeMutation', null);
        }


    },

    change_FI_ND_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_ND_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_ND_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_ND_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_ND_selection_storeMutation', state.FI_ND_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_NDUI_selection_storeMutation', state.FI_ND_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNND_storeAction')
            commit('change_FI_MNND_selection_storeMutation')
        }
        else {
            commit('change_FI_ND_selection_storeMutation', null);
            commit('change_FI_NDUI_selection_storeMutation', null);
            commit('change_FI_MNND_selection_storeMutation', null);
        }


    },

    change_FI_FEF_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_FEF_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_FEF_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_FEF_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_FEF_selection_storeMutation', state.FI_FEF_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_FEFUI_selection_storeMutation', state.FI_FEF_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNFEF_storeAction');
            commit('change_FI_MNFEF_selection_storeMutation');
        }
        else {
            commit('change_FI_FEF_selection_storeMutation', null);
            commit('change_FI_FEFUI_selection_storeMutation', null);
            commit('change_FI_MNFEF_selection_storeMutation', null);
        }


    },

    change_FI_BR_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_BR_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_BR_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_BR_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_BR_selection_storeMutation', state.FI_BR_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_BRUI_selection_storeMutation', state.FI_BR_List[firstIndex_whichMeetConditions].text.textUI);
        }
        else {
            commit('change_FI_BR_selection_storeMutation', null);
            commit('change_FI_BRUI_selection_storeMutation', null);
            commit('change_FI_MNBR_selection_storeMutation', null);
        }

        dispatch('get_FI_MNBR_storeAction');
        commit('change_FI_BR_Quantity_selection_storeMutation', null)
        commit('change_FI_BR_QuantityUI_selection_storeMutation', null)
        dispatch('get_FI_BR_Quantity_storeAction')
        //commit('change_FI_MNBR_selection_storeMutation', value);
        commit('change_FI_BRLT_selection_storeMutation', null);
        commit('change_FI_MNBRLT_selection_storeMutation', null);
        commit('change_FI_BRLTUI_selection_storeMutation', null);
        commit('change_FI_BRLT_checkboxSelection_storeMutation', null);
    },
    //change_FI_BR_selection_storeAction({ commit, dispatch }, value) {
    //    commit('change_FI_BR_selection_storeMutation', value)
    //    dispatch('get_FI_MNBR_storeAction');
    //    commit('change_FI_MNBR_selection_storeMutation', value)
    //},

    //BR Menge
    change_FI_BR_Quantity_selection_storeAction({ commit}, value) {
        if (value.selected == true) {
            commit('change_FI_BR_Quantity_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_BR_Quantity_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_BR_Quantity_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_BR_Quantity_selection_storeMutation', state.FI_BR_Quantity_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_BR_QuantityUI_selection_storeMutation', state.FI_BR_Quantity_List[firstIndex_whichMeetConditions].text.textUI);
           
        }
        else {
            commit('change_FI_BR_Quantity_selection_storeMutation', null);
            commit('change_FI_BR_QuantityUI_selection_storeMutation', null);
            
        }

    },

    change_FI_BRLT_selection_storeAction({ commit, dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_BRLT_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_BRLT_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_BRLT_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_BRLT_selection_storeMutation', state.FI_BRLT_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_BRLTUI_selection_storeMutation', state.FI_BRLT_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNBRLT_storeAction')
            commit('change_FI_MNBRLT_selection_storeMutation')
        }
        else {
            commit('change_FI_BRLT_selection_storeMutation', null);
            commit('change_FI_BRLTUI_selection_storeMutation', null);
            commit('change_FI_MNBRLT_selection_storeMutation', null);
        }

    },

    change_FI_DCP_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_DCP_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_DCP_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_DCP_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_DCP_selection_storeMutation', state.FI_DCP_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_DCPUI_selection_storeMutation', state.FI_DCP_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNDCP_selection_storeMutation', state.FI_DCP_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_DCP_selection_storeMutation', null);
            commit('change_FI_DCPUI_selection_storeMutation', null);
            commit('change_FI_MNDCP_selection_storeMutation', null);
        }

    },

    change_FI_STO_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_STO_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_STO_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_STO_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_STO_selection_storeMutation', state.FI_STO_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_STOUI_selection_storeMutation', state.FI_STO_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNSTO_selection_storeMutation', state.FI_STO_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_STO_selection_storeMutation', null);
            commit('change_FI_STOUI_selection_storeMutation', null);
            commit('change_FI_MNSTO_selection_storeMutation', null);
        }

    },

    change_FI_XOUT_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_XOUT_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_XOUT_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_XOUT_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_XOUT_selection_storeMutation', state.FI_XOUT_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_XOUTUI_selection_storeMutation', state.FI_XOUT_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNXOUT_selection_storeMutation', state.FI_XOUT_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_XOUT_selection_storeMutation', null);
            commit('change_FI_XOUTUI_selection_storeMutation', null);
            commit('change_FI_MNXOUT_selection_storeMutation', null);
        }

    },

    change_FI_XIN_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_XIN_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_XIN_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_XIN_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_XIN_selection_storeMutation', state.FI_XIN_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_XINUI_selection_storeMutation', state.FI_XIN_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNXIN_selection_storeMutation', state.FI_XIN_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_XIN_selection_storeMutation', null);
            commit('change_FI_XINUI_selection_storeMutation', null);
            commit('change_FI_MNXIN_selection_storeMutation', null);
        }

    },

    change_FI_Nachruestsatz_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_Nachruestsatz_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_Nachruestsatz_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_Nachruestsatz_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_Nachruestsatz_selection_storeMutation', state.FI_Nachruestsatz_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_NachruestsatzUI_selection_storeMutation', state.FI_Nachruestsatz_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNNachruestsatz_selection_storeMutation', state.FI_Nachruestsatz_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_Nachruestsatz_selection_storeMutation', null);
            commit('change_FI_NachruestsatzUI_selection_storeMutation', null);
            commit('change_FI_MNNachruestsatz_selection_storeMutation', null);
        }

    },

    change_FI_ZAsbc_selection_storeAction({ commit,dispatch }, value) {
        if (value.selected == true) {
            commit('change_FI_ZAsbc_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_ZAsbc_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_ZAsbc_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_ZAsbc_selection_storeMutation', state.FI_ZAsbc_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ZAsbcUI_selection_storeMutation', state.FI_ZAsbc_List[firstIndex_whichMeetConditions].text.textUI);
            dispatch('get_FI_MNZASBC_storeAction');
            commit('change_FI_MNZAsbc_selection_storeMutation');
        }
        else {
            commit('change_FI_ZAsbc_selection_storeMutation', null);
            commit('change_FI_ZAsbcUI_selection_storeMutation', null);
            commit('change_FI_MNZAsbc_selection_storeMutation', null);
        }

        dispatch('get_FI_ZAsbc_LS_storeAction');
        dispatch('get_FI_ZAsbc_LSBA_storeAction');
        dispatch('get_FI_ZAsbc_BAL_storeAction');

    },

    change_FI_ZAsbc_LS_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_ZAsbc_LS_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_ZAsbc_LS_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_ZAsbc_LS_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_ZAsbc_LS_selection_storeMutation', state.FI_ZAsbc_LS_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ZAsbc_LSUI_selection_storeMutation', state.FI_ZAsbc_LS_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNZAsbc_LS_selection_storeMutation', state.FI_ZAsbc_LS_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_ZAsbc_LS_selection_storeMutation', null);
            commit('change_FI_ZAsbc_LSUI_selection_storeMutation', null);
            commit('change_FI_MNZAsbc_LS_selection_storeMutation', null);
        }

    },

    change_FI_ZAsbc_LSBA_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_ZAsbc_LSBA_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_ZAsbc_LSBA_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_ZAsbc_LSBA_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_ZAsbc_LSBA_selection_storeMutation', state.FI_ZAsbc_LSBA_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ZAsbc_LSBAUI_selection_storeMutation', state.FI_ZAsbc_LSBA_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNZAsbc_LSBA_selection_storeMutation', state.FI_ZAsbc_LSBA_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_ZAsbc_LSBA_selection_storeMutation', null);
            commit('change_FI_ZAsbc_LSBAUI_selection_storeMutation', null);
            commit('change_FI_MNZAsbc_LSBA_selection_storeMutation', null);
        }

    },

    change_FI_ZAsbc_BAL_selection_storeAction({ commit }, value) {
        if (value.selected == true) {
            commit('change_FI_ZAsbc_BAL_checkboxSelection_storeMutation', null);
        }
        else {
            commit('change_FI_ZAsbc_BAL_checkboxSelection_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.FI_ZAsbc_BAL_List.findIndex(i => (i.selected == true));

        if (firstIndex_whichMeetConditions > -1) {
            commit('change_FI_ZAsbc_BAL_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions].text.text);
            commit('change_FI_ZAsbc_BALUI_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions].text.textUI);
            commit('change_FI_MNZAsbc_BAL_selection_storeMutation', state.FI_ZAsbc_BAL_List[firstIndex_whichMeetConditions].id);
        }
        else {
            commit('change_FI_ZAsbc_BAL_selection_storeMutation', null);
            commit('change_FI_ZAsbc_BALUI_selection_storeMutation', null);
            commit('change_FI_MNZAsbc_BAL_selection_storeMutation', null);
        }

    },


    //change_FI_Menue_selection_storeAction({ commit }, value) {
    //    commit('change_FI_Menue_selection_storeMutation', value);
    //    /*dispatch('get_FI_BAL_storeAction');*/

    //},
    //change_FI_Bal_selection_storeAction({ commit }, value) {
    //    commit('change_FI_Bal_selection_storeMutation', value);
    //    //dispatch('get_FI_Par_storeAction');

    //},

    //change_FI_Par_selection_storeAction({ commit }, value) {
    //    commit('change_FI_Par_selection_storeMutation', value);
    //    //dispatch('get_FI_Conf_storeAction');

    //},
    //change_FI_Conf_selection_storeAction({ commit }, value) {
    //    commit('change_FI_Conf_selection_storeMutation', value);
    //    //dispatch('get_FI_Code_storeAction');

    //},
    //change_FI_Code_selection_storeAction({ commit }, value) {
    //    commit('change_FI_Code_selection_storeMutation', value);
    //    /*dispatch('get_FI_Conf_storeAction');*/

    //},

    //change_FI_Typ_selection_storeAction({ commit }, value) {
    //    if (value.selected == true) {
    //        commit('change_EVAC_Typ_checkboxSelection_storeMutation', null);
    //    }
    //    else {
    //        commit('change_EVAC_Typ_checkboxSelection_storeMutation', value.text.text);
    //    }

    //    const firstIndex_whichMeetConditions = state.EVAC_Typ_list.findIndex(i => (i.selected == true));

    //    if (firstIndex_whichMeetConditions > -1) {
    //        commit('change_EVAC_Typ_selection_storeMutation', state.EVAC_Typ_list[firstIndex_whichMeetConditions].text.text);
    //    }
    //    else {
    //        commit('change_EVAC_Typ_selection_storeMutation', undefined);
    //    }


    //    for (let i = 0; i < state.FI_Type_List.length; i++) {
    //        state.FI_Type_List[i].selected = false;

    //        if (state.FI_Type_List[i].text.text == payload) {
    //            state.FI_Type_List[i].selected = true;
    //        }
    //    }
    //},
    

    change_inputs_FI_toDefault_storeAction({ commit }) {
        
        commit('change_FI_toDefault_selection_storeMutation');
    },

    

}


const mutations = {

    get_FI_Type_storeMutation(state, payload) {
        state.FI_Type_List = payload;
    },
    change_FI_Type_selection_storeMutation(state, payload) {
        state.FI_Type_selected = payload;
    },
    change_FI_TypeUI_selection_storeMutation(state, payload) {
        state.FI_TypeUI_selected = payload;
    },
     get_FI_Model_storeMutation(state, payload) {
        state.FI_Model_List = payload;
    },
    change_FI_Model_selection_storeMutation(state, payload) {
        state.FI_Model_selected = payload;
    },
    change_FI_ModelUI_selection_storeMutation(state, payload) {
        state.FI_ModelUI_selected = payload;
    },
    get_FI_Menue_storeMutation(state, payload) {
        state.FI_Menue_List = payload;
    },
    change_FI_Menue_selection_storeMutation(state, payload) {
        state.FI_Menue_selected = payload;
    },
    change_FI_MenueUI_selection_storeMutation(state, payload) {
        state.FI_MenueUI_selected = payload;
    },
    get_FI_Bal_storeMutation(state, payload) {
        state.FI_Bal_List = payload;
    },
    change_FI_Bal_selection_storeMutation(state, payload) {
        state.FI_Bal_selected = payload;
    },
    change_FI_BalUI_selection_storeMutation(state, payload) {
        state.FI_BalUI_selected = payload;
    },
    change_FI_MNBAL_selection_storeMutation(state, payload) {
        state.FI_MNBAL_selected = payload;
    },
    get_FI_Par_storeMutation(state, payload) {
        state.FI_Par_List = payload;
    },
    change_FI_Par_selection_storeMutation(state, payload) {
        state.FI_Par_selected = payload;
    },
    change_FI_ParUI_selection_storeMutation(state, payload) {
        state.FI_ParUI_selected = payload;
    },
    get_FI_Conf_storeMutation(state, payload) {
        state.FI_Conf_List = payload;
    },
    change_FI_Conf_selection_storeMutation(state, payload) {
        state.FI_Conf_selected = payload;
    },
    change_FI_ConfUI_selection_storeMutation(state, payload) {
        state.FI_ConfUI_selected = payload;
    },
    get_FI_Code_storeMutation(state, payload) {
        state.FI_Code_List = payload;
    },
    change_FI_Code_selection_storeMutation(state, payload) {
        state.FI_Code_selected = payload;
    },
    change_FI_CodeUI_selection_storeMutation(state, payload) {
        state.FI_CodeUI_selected = payload;
    },
    get_FI_PAD_storeMutation(state, payload) {
        state.FI_PAD_List = payload;
    },
    change_FI_PAD_selection_storeMutation(state, payload) {
        state.FI_PAD_selected = payload;
    },
    change_FI_PADUI_selection_storeMutation(state, payload) {
        state.FI_PADUI_selected = payload;
    },
    change_FI_MNPAD_selection_storeMutation(state, payload) {
        state.FI_MNPAD_selected = payload;
    },
    get_FI_MON_storeMutation(state, payload) {
        state.FI_MON_List = payload;
    },
    change_FI_MON_selection_storeMutation(state, payload) {
        state.FI_MON_selected = payload;
    },
    change_FI_MONUI_selection_storeMutation(state, payload) {
        state.FI_MONUI_selected = payload;
    },
    change_FI_MNMON_selection_storeMutation(state, payload) {
        state.FI_MNMON_selected = payload;
    },
    get_FI_ND_storeMutation(state, payload) {
        state.FI_ND_List = payload;
    },
    change_FI_ND_selection_storeMutation(state, payload) {
        state.FI_ND_selected = payload;
    },
    change_FI_NDUI_selection_storeMutation(state, payload) {
        state.FI_NDUI_selected = payload;
    },
    change_FI_MNND_selection_storeMutation(state, payload) {
        state.FI_MNND_selected = payload;
    },
    get_FI_FEF_storeMutation(state, payload) {
        state.FI_FEF_List = payload;
    },
    change_FI_FEF_selection_storeMutation(state, payload) {
        state.FI_FEF_selected = payload;
    },
    change_FI_FEFUI_selection_storeMutation(state, payload) {
        state.FI_FEFUI_selected = payload;
    },
    change_FI_MNFEF_selection_storeMutation(state, payload) {
        state.FI_MNFEF_selected = payload;
    },
    get_FI_BR_storeMutation(state, payload) {
        state.FI_BR_List = payload;
    },
    change_FI_BR_selection_storeMutation(state, payload) {
        state.FI_BR_selected = payload;
    },
    change_FI_BRUI_selection_storeMutation(state, payload) {
        state.FI_BRUI_selected = payload;
    },
    //Menge BR
    get_FI_BR_Quantity_storeMutation(state, payload) {
        state.FI_BR_Quantity_List = payload;
    },
    change_FI_BR_Quantity_selection_storeMutation(state, payload) {
        state.FI_BR_Quantity_selected = payload;
    },
    change_FI_BR_QuantityUI_selection_storeMutation(state, payload) {
        state.FI_BR_QuantityUI_selected = payload;
    },
    get_FI_BRLT_storeMutation(state, payload) {
        state.FI_BRLT_List = payload;
    },
    change_FI_BRLT_selection_storeMutation(state, payload) {
        state.FI_BRLT_selected = payload;
    },
    change_FI_BRLTUI_selection_storeMutation(state, payload) {
        state.FI_BRLTUI_selected = payload;
    },
    change_FI_MNBRLT_selection_storeMutation(state, payload) {
        state.FI_MNBRLT_selected = payload;
    },
    get_FI_DCP_storeMutation(state, payload) {
        state.FI_DCP_List = payload;
    },
    change_FI_DCP_selection_storeMutation(state, payload) {
        state.FI_DCP_selected = payload;
    },
    change_FI_DCPUI_selection_storeMutation(state, payload) {
        state.FI_DCPUI_selected = payload;
    },
    change_FI_MNDCP_selection_storeMutation(state, payload) {
        state.FI_MNDCP_selected = payload;
    },
    get_FI_STO_storeMutation(state, payload) {
        state.FI_STO_List = payload;
    },
    change_FI_STO_selection_storeMutation(state, payload) {
        state.FI_STO_selected = payload;
    },
    change_FI_STOUI_selection_storeMutation(state, payload) {
        state.FI_STOUI_selected = payload;
    },
    change_FI_MNSTO_selection_storeMutation(state, payload) {
        state.FI_MNSTO_selected = payload;
    },
    get_FI_XIN_storeMutation(state, payload) {
        state.FI_XIN_List = payload;
    },
    change_FI_XIN_selection_storeMutation(state, payload) {
        state.FI_XIN_selected = payload;
    },
    change_FI_XINUI_selection_storeMutation(state, payload) {
        state.FI_XINUI_selected = payload;
    },
    change_FI_MNXIN_selection_storeMutation(state, payload) {
        state.FI_MNXIN_selected = payload;
    },
    get_FI_XOUT_storeMutation(state, payload) {
        state.FI_XOUT_List = payload;
    },
    change_FI_XOUT_selection_storeMutation(state, payload) {
        state.FI_XOUT_selected = payload;
    },
    change_FI_XOUTUI_selection_storeMutation(state, payload) {
        state.FI_XOUTUI_selected = payload;
    },
    change_FI_MNXOUT_selection_storeMutation(state, payload) {
        state.FI_MNXOUT_selected = payload;
    },
    change_FI_MNBR_selection_storeMutation(state, payload) {
        state.FI_MNBR_selected = payload;
    },
    change_FI_MNFI_selection_storeMutation(state, payload) {
        state.FI_MNFI_selected = payload;
    },
    change_FI_ZLM_storeMutation(state, payload) {
        state.ZLM = payload;
        
    },
    change_FI_MNFISTL_selection_storeMutation(state, payload) {
        state.FI_MNFISTL_selected = payload;
    },
    change_FI_MNFIBGGT_selection_storeMutation(state, payload) {
        state.FI_MNFIBGGT_selected = payload;
    },
    change_FI_toDefault_selection_storeMutation(state) {
        state.FI_Type_selected = null;
        state.FI_TypeUI_selected = null;
        state.FI_Model_selected = null;
        state.FI_ModelUI_selected = null;
        state.FI_Conf_selected = null;
        state.FI_ConfUI_selected = null;
        state.FI_Par_selected = null;
        state.FI_ParUI_selected = null;
        state.FI_Bal_selected = null;
        state.FI_BalUI_selected = null;
        state.FI_MNBAL_selected = null;
        state.FI_Menue_selected = null;
        state.FI_MenueUI_selected = null;
        state.FI_Code_selected = null;
        state.FI_CodeUI_selected = null;
        state.FI_MNFI_selected = null;
        state.FI_MNFISTL_selected = null;
        state.FI_MNFIBGGT_selected = null;
        state.FI_PAD_selected = null;
        state.FI_PADUI_selected = null;
        state.FI_MNPAD_selected = null;
        state.FI_MON_selected = null;
        state.FI_MONUI_selected = null;
        state.FI_MNMON_selected = null;
        state.FI_ND_selected = null;
        state.FI_NDUI_selected = null;
        state.FI_MNND_selected = null;
        state.FI_FEF_selected = null;
        state.FI_FEFUI_selected = null;
        state.FI_MNFEF_selected = null;
        state.FI_BR_selected = null;
        state.FI_BR_Quantity_selected = null;
        state.FI_BR_QuantityUI_selected = null;
        state.FI_BRUI_selected = null;
        state.FI_MNBR_selected = null;
        state.FI_BRLT_selected = null;
        state.FI_BRLTUI_selected = null;
        state.FI_MNBRLT_selected = null;
        state.FI_DCP_selected = null;
        state.FI_DCPUI_selected = null;
        state.FI_DCPMN_selected = null;
        state.FI_MNDCP_selected = null;
        state.FI_STO_selected = null;
        state.FI_STOUI_selected = null;
        state.FI_MNSTO_selected = null;
        state.FI_XOUT_selected = null;
        state.FI_XOUTUI_selected = null;
        state.FI_MNXOUT_selected = null;
        state.FI_XIN_selected = null;
        state.FI_XINUI_selected = null;
        state.FI_MNXIN_selected = null;
        state.FI_MNBR_selected = null;
        state.FI_MNBRUI_selected = null;
        state.FI_Nachruestsatz_selected = null;
        state.FI_NachruestsatzUI_selected = null;
        state.FI_MNNachruestsatz_selected = null;
        state.FI_ZAsbc_selected = null;
        state.FI_ZAsbcUI_selected = null;
        state.FI_MNZAsbc_selected = null;
        state.FI_ZAsbc_LS_selected = null;
        state.FI_ZAsbc_LSUI_selected = null;
        state.FI_MNZAsbc_LS_selected = null;
        state.FI_ZAsbc_LSBA_selected = null;
        state.FI_ZAsbc_LSBAUI_selected = null;
        state.FI_MNZAsbc_LSBA_selected = null;
        state.FI_ZAsbc_BAL_selected = null;
        state.FI_ZAsbc_BALUI_selected = null;
        state.FI_MNZAsbc_BAL_selected = null;
        
        //for (let i = 0; i < state.FI_Model_List.length; i++) {
        //    state.FI_Model_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Conf_List.length; i++) {
        //    state.FI_Conf_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Par_List.length; i++) {
        //    state.FI_Par_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Bal_List.length; i++) {
        //    state.FI_Bal_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Menue_List.length; i++) {
        //    state.FI_Menue_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Code_List.length; i++) {
        //    state.FI_Code_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_PAD_List.length; i++) {
        //    state.FI_PAD_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_MON_List.length; i++) {
        //    state.FI_MON_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_ND_List.length; i++) {
        //    state.FI_ND_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_FEF_List.length; i++) {
        //    state.FI_FEF_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_BR_List.length; i++) {
        //    state.FI_BR_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_BR_Quantity_List.length; i++) {
        //    state.FI_BR_Quantity_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_BRLT_List.length; i++) {
        //    state.FI_BRLT_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_DCP_List.length; i++) {
        //    state.FI_DCP_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_STO_List.length; i++) {
        //    state.FI_STO_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_XOUT_List.length; i++) {
        //    state.FI_XOUT_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_XIN_List.length; i++) {
        //    state.FI_XIN_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_Nachruestsatz_List.length; i++) {
        //    state.FI_Nachruestsatz_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_ZAsbc_List.length; i++) {
        //    state.FI_ZAsbc_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_ZAsbc_LS_List.length; i++) {
        //    state.FI_ZAsbc_LS_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_ZAsbc_LSBA_List.length; i++) {
        //    state.FI_ZAsbc_LSBA_List[i].selected = false;
        //}
        //for (let i = 0; i < state.FI_ZAsbc_BAL_List.length; i++) {
        //    state.FI_ZAsbc_BAL_List[i].selected = false;
        //}

        state.FI_Model_List = [];
        state.FI_Conf_List = [];
        state.FI_Par_List= [];      
        state.FI_Bal_List = [];
        state.FI_Menue_List = [];     
        state.FI_Code_List = [];      
        state.FI_PAD_List = [];        
        state.FI_MON_List = [];        
        state.FI_ND_List = [];       
        state.FI_FEF_List = [];        
        state.FI_BR_List = [];        
        state.FI_BR_Quantity_List = [];        
        state.FI_BRLT_List = [];      
        state.FI_DCP_List = [];       
        state.FI_STO_List = [];       
        state.FI_XOUT_List = [];       
        state.FI_XIN_List = [];       
        state.FI_Nachruestsatz_List = [];        
        state.FI_ZAsbc_List = [];      
        state.FI_ZAsbc_LS_List = [];       
        state.FI_ZAsbc_LSBA_List = [];        
        state.FI_ZAsbc_BAL_List = [];
        
    },
    get_FI_Nachruestsatz_storeMutation(state, payload) {
        state.FI_Nachruestsatz_List = payload;
    },
    change_FI_Nachruestsatz_selection_storeMutation(state, payload) {
        state.FI_Nachruestsatz_selected = payload;
    },
    change_FI_NachruestsatzUI_selection_storeMutation(state, payload) {
        state.FI_NachruestsatzUI_selected = payload;
    },
    change_FI_MNNachruestsatz_selection_storeMutation(state, payload) {
        state.FI_MNNachruestsatz_selected = payload;
    },
    get_FI_ZAsbc_storeMutation(state, payload) {
        state.FI_ZAsbc_List = payload;
    },
    change_FI_ZAsbc_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_selected = payload;
    },
    change_FI_ZAsbcUI_selection_storeMutation(state, payload) {
        state.FI_ZAsbcUI_selected = payload;
    },
    change_FI_MNZAsbc_selection_storeMutation(state, payload) {
        state.FI_MNZAsbc_selected = payload;
    },
    get_FI_ZAsbc_LS_storeMutation(state, payload) {
        state.FI_ZAsbc_LS_List = payload;
    },
    change_FI_ZAsbc_LS_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_LS_selected = payload;
    },
    change_FI_ZAsbc_LSUI_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_LSUI_selected = payload;
    },
    change_FI_MNZAsbc_LS_selection_storeMutation(state, payload) {
        state.FI_MNZAsbc_LS_selected = payload;
    },
    get_FI_ZAsbc_LSBA_storeMutation(state, payload) {
        state.FI_ZAsbc_LSBA_List = payload;
    },
    change_FI_ZAsbc_LSBA_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_LSBA_selected = payload;
    },
    change_FI_ZAsbc_LSBAUI_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_LSBAUI_selected = payload;
    },
    change_FI_MNZAsbc_LSBA_selection_storeMutation(state, payload) {
        state.FI_MNZAsbc_LSBA_selected = payload;
    },
    get_FI_ZAsbc_BAL_storeMutation(state, payload) {
        state.FI_ZAsbc_BAL_List = payload;
    },
    change_FI_ZAsbc_BAL_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_BAL_selected = payload;
    },
    change_FI_ZAsbc_BALUI_selection_storeMutation(state, payload) {
        state.FI_ZAsbc_BALUI_selected = payload;
    },
    change_FI_MNZAsbc_BAL_selection_storeMutation(state, payload) {
        state.FI_MNZAsbc_BAL_selected = payload;
    },

    change_FI_Type_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Type_List.length; i++) {
                state.FI_Type_List[i].selected = false;

                if (state.FI_Type_List[i].text.text == payload) {
                    state.FI_Type_List[i].selected = true;
                   
                }
            }
    },
    change_FI_Model_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Model_List.length; i++) {
            state.FI_Model_List[i].selected = false;

            if (state.FI_Model_List[i].text.text == payload) {
                state.FI_Model_List[i].selected = true;
            }
        }
    },
    change_FI_Menue_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Menue_List.length; i++) {
            state.FI_Menue_List[i].selected = false;

            if (state.FI_Menue_List[i].text.text == payload) {
                state.FI_Menue_List[i].selected = true;
            }
        }
    },
    change_FI_Bal_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Bal_List.length; i++) {
            state.FI_Bal_List[i].selected = false;

            if (state.FI_Bal_List[i].text.text == payload) {
                state.FI_Bal_List[i].selected = true;
            }
        }
    },
    change_FI_Par_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Par_List.length; i++) {
            state.FI_Par_List[i].selected = false;

            if (state.FI_Par_List[i].text.text == payload) {
                state.FI_Par_List[i].selected = true;
            }
        }
    },
    change_FI_Conf_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Conf_List.length; i++) {
            state.FI_Conf_List[i].selected = false;

            if (state.FI_Conf_List[i].text.text == payload) {
                state.FI_Conf_List[i].selected = true;
            }
        }
    },
    change_FI_Code_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Code_List.length; i++) {
            state.FI_Code_List[i].selected = false;

            if (state.FI_Code_List[i].text.text == payload) {
                state.FI_Code_List[i].selected = true;
            }
        }
    },

    change_FI_PAD_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_PAD_List.length; i++) {
            state.FI_PAD_List[i].selected = false;

            if (state.FI_PAD_List[i].text.text == payload) {
                state.FI_PAD_List[i].selected = true;
            }
        }
    },

    change_FI_MON_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_MON_List.length; i++) {
            state.FI_MON_List[i].selected = false;

            if (state.FI_MON_List[i].text.text == payload) {
                state.FI_MON_List[i].selected = true;
            }
        }
    },

    change_FI_ND_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_ND_List.length; i++) {
            state.FI_ND_List[i].selected = false;

            if (state.FI_ND_List[i].text.text == payload) {
                state.FI_ND_List[i].selected = true;
            }
        }
    },

    change_FI_FEF_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_FEF_List.length; i++) {
            state.FI_FEF_List[i].selected = false;

            if (state.FI_FEF_List[i].text.text == payload) {
                state.FI_FEF_List[i].selected = true;
            }
        }
    },

    change_FI_BR_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_BR_List.length; i++) {
            state.FI_BR_List[i].selected = false;

            if (state.FI_BR_List[i].text.text == payload) {
                state.FI_BR_List[i].selected = true;
            }
        }
    },
    //BR Menge
    change_FI_BR_Quantity_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_BR_Quantity_List.length; i++) {
            state.FI_BR_Quantity_List[i].selected = false;

            if (state.FI_BR_Quantity_List[i].text.text == payload) {
                state.FI_BR_Quantity_List[i].selected = true;
            }
        }
    },

    change_FI_BRLT_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_BRLT_List.length; i++) {
            state.FI_BRLT_List[i].selected = false;

            if (state.FI_BRLT_List[i].text.text == payload) {
                state.FI_BRLT_List[i].selected = true;
            }
        }
    },

    change_FI_DCP_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_DCP_List.length; i++) {
            state.FI_DCP_List[i].selected = false;

            if (state.FI_DCP_List[i].text.text == payload) {
                state.FI_DCP_List[i].selected = true;
            }
        }
    },

    change_FI_STO_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_STO_List.length; i++) {
            state.FI_STO_List[i].selected = false;

            if (state.FI_STO_List[i].text.text == payload) {
                state.FI_STO_List[i].selected = true;
            }
        }
    },

    change_FI_XOUT_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_XOUT_List.length; i++) {
            state.FI_XOUT_List[i].selected = false;

            if (state.FI_XOUT_List[i].text.text == payload) {
                state.FI_XOUT_List[i].selected = true;
            }
        }
    },

    change_FI_XIN_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_XIN_List.length; i++) {
            state.FI_XIN_List[i].selected = false;

            if (state.FI_XIN_List[i].text.text == payload) {
                state.FI_XIN_List[i].selected = true;
            }
        }
    },

    change_FI_Nachruestsatz_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_Nachruestsatz_List.length; i++) {
            state.FI_Nachruestsatz_List[i].selected = false;

            if (state.FI_Nachruestsatz_List[i].text.text == payload) {
                state.FI_Nachruestsatz_List[i].selected = true;
            }
        }
    },

    change_FI_ZAsbc_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_ZAsbc_List.length; i++) {
            state.FI_ZAsbc_List[i].selected = false;

            if (state.FI_ZAsbc_List[i].text.text == payload) {
                state.FI_ZAsbc_List[i].selected = true;
            }
        }
    },

    change_FI_ZAsbc_LS_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_ZAsbc_LS_List.length; i++) {
            state.FI_ZAsbc_LS_List[i].selected = false;

            if (state.FI_ZAsbc_LS_List[i].text.text == payload) {
                state.FI_ZAsbc_LS_List[i].selected = true;
            }
        }
    },

    change_FI_ZAsbc_LSBA_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_ZAsbc_LSBA_List.length; i++) {
            state.FI_ZAsbc_LSBA_List[i].selected = false;

            if (state.FI_ZAsbc_LSBA_List[i].text.text == payload) {
                state.FI_ZAsbc_LSBA_List[i].selected = true;
            }
        }
    },

    change_FI_ZAsbc_BAL_checkboxSelection_storeMutation(state, payload) {
        for (let i = 0; i < state.FI_ZAsbc_BAL_List.length; i++) {
            state.FI_ZAsbc_BAL_List[i].selected = false;

            if (state.FI_ZAsbc_BAL_List[i].text.text == payload) {
                state.FI_ZAsbc_BAL_List[i].selected = true;
            }
        }
    },


}
export const FI = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}