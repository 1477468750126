import axios from 'axios';
import FileSaver from 'file-saver';

let state = {
    summary: {},

    responseOf_check_databaseRecords_byCalculationDll: {},
    //toDos_at_summary_Done: false,
    openTask: undefined,

    fileLanguage: 'de-DE',
    information: {},
    storedInformation: {},

    showInfoButton: false,
    showInfo: false,

    manualHomeSummary: {},
}

const actions = {
    change_priceInformation_storeAction({ commit }, value) {
        commit('change_priceInformation_storeMutation', value);
    },
    change_summary_toDefault_storeAction({ commit }) {
        commit('change_summary_storeMutation', {});
        commit('change_priceInformation_storeMutation', {});
        commit('change_storedInformation_storeMutation', {});
        commit('change_responseOf_check_databaseRecords_byCalculationDll_storeMutation', {});
        commit('change_toDos_at_summary_Done_storeMutation', false);
        commit('change_openTask_storeMutation', null);
        commit('change_fileLanguage_storeMutation', 'de-DE');
        commit('change_manualHome_storeMutation', {})
    },

    async toDos_at_summary_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        await dispatch('select_databaseRecords_for_summary_storeAction')
        if ((rootState.statusHandling.status.statusInContextOf == 'select_databaseRecords_for_summary') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
            await dispatch('check_configuration_byCalculationDll_storeAction')
            if ((rootState.statusHandling.status.statusInContextOf == 'check_configuration_byCalculationDll') && (rootState.statusHandling.status.statusCode.startsWith('2'))) {
                if (rootState.configurations.configuration_Modifiable == true) {
                    await this.dispatch('configurations/update_databaseRecord_at_configurationOverviews_bySetting_Configuration_Modifiable_storeAction');
                }
                if (state.summary.productConfiguratorId_NotEqualToZero == false) {
                    await dispatch('insertOrUpdate_databaseRecord_at_productConfigurator_storeAction');
                }
                if (state.summary.pKstring_Stored == false) {
                    await dispatch('update_databaseRecord_at_configurationOverviews_bySetting_PKstring_storeAction');
                }
                if (state.summary.outputContentOfHtmlFile_Stored == false) {
                    await dispatch('update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile_storeAction');
                }
                if (state.summary.outputContentOfTextFile_Stored == false) {
                    await dispatch('update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile_storeAction');
                }
                //this.dispatch('summary/get_PriceInformation_storeAction', null);
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                //dispatch('change_toDos_at_summary_Done_storeAction', true)
            }
        }

        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },

    async select_databaseRecords_for_summary_storeAction({ rootState, commit, dispatch }) {
        //commit('change_priceInformation_storeMutation', {});
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/select_databaseRecords_for_summary',
            data: {
                data: data,
            }
        })
            .then((response) => {               
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.startsWith('2')) {
                    commit('change_summary_storeMutation', response.data.data);
                }
            })
            .catch(() => { })
    },
    async check_configuration_byCalculationDll_storeAction({ rootState, commit }) {
        const data = { configurationOverviewsId: rootState.configurations.configurationOverviewsId };

        await axios({
            method: 'post',
            url: '/api/summaries/check_configuration_byCalculationDll',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                commit('change_responseOf_check_databaseRecords_byCalculationDll_storeMutation', response.data)
            })
            .catch(() => { })
    },
    async insertOrUpdate_databaseRecord_at_productConfigurator_storeAction({ rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/insertOrUpdate_databaseRecord_at_productConfigurator',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
            .catch(() => { })
    },
    async update_databaseRecord_at_configurationOverviews_bySetting_PKstring_storeAction({ rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/update_databaseRecord_at_configurationOverviews_bySetting_PKstring',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
            .catch(() => { })
    },
    async update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile_storeAction({ rootState, dispatch }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfHtmlFile',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
            .catch(() => { })
    },

    async update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile_storeAction({ rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/update_databaseRecord_at_configurationOverviews_bySetting_OutputContentOfTextFile',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
            })
            .catch(() => { })
    },

    change_toDos_at_summary_Done_storeAction({ commit, dispatch }, value) {
        commit('change_toDos_at_summary_Done_storeMutation', value)
        if (value == true) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            if (state.openTask == 'get_htmlFileContent') {
                commit('change_openTask_storeMutation', null)
                dispatch('get_htmlFileContent_storeAction')
            }
        }
    },

    change_fileLanguage_storeAction({ commit }, value) {
        commit('change_fileLanguage_storeMutation', value)
    },
    get_htmlFileContent_storeAction({ rootState, commit, dispatch }) {
        if (Object.keys(state.responseOf_check_databaseRecords_byCalculationDll).length > 0) {
            if (state.responseOf_check_databaseRecords_byCalculationDll.status.statusCode.startsWith('2')) {
                const data = {
                    configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                    fileLanguage: state.fileLanguage,
                };

                axios({
                    method: 'post',
                    url: '/api/summaries/get_htmlFileContent',
                    data: {
                        data: data,
                    }
                })
                    .then((response) => {
                        this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                        if ((response.data.status.statusCode.startsWith('2')) || (response.data.status.statusCode.startsWith('74')) || (response.data.status.statusCode.startsWith('75'))) {
                            dispatch('download_htmlFile_storeAction', response.data.data)
                        }
                    })
                    .catch(() => { })
            }
            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', this._vm.$status_for_statusCode_7200000);
            }
        }
        else {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: null });
            commit('change_openTask_storeMutation', 'get_htmlFileContent')
        }
    },

    get_txtFileContent_storeAction({ rootState, commit, dispatch }) {
        if (Object.keys(state.responseOf_check_databaseRecords_byCalculationDll).length > 0) {
            if (state.responseOf_check_databaseRecords_byCalculationDll.status.statusCode.startsWith('2')) {
                const data = {
                    configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                    fileLanguage: state.fileLanguage,
                };

                axios({
                    method: 'post',
                    url: '/api/summaries/get_txtFileContent',
                    data: {
                        data: data,
                    }
                })
                    .then((response) => {
                        this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                        if ((response.data.status.statusCode.startsWith('2')) || (response.data.status.statusCode.startsWith('74')) || (response.data.status.statusCode.startsWith('75'))) {
                            dispatch('download_txtFile_storeAction', response.data.data)
                        }
                    })
                    .catch(() => { })
            }
            else {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', this._vm.$status_for_statusCode_7200000);
            }
        }
        else {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: null });
        }
    },

    download_htmlFile_storeAction({ }, value) {


        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
            const winSize = 'width=660,height=620,scrollbars=yes';
            var newWindow = window.open("", "_blank", winSize);
            newWindow.document.write(value.fileContent);
            const fileName = value.fileName.replace('.html', '');
            newWindow.document.title = fileName;
        }
        else {
            let blob = new Blob([value.fileContent], {
                type: "application/xhtml+xml"
            });
            FileSaver.saveAs(blob, value.fileName);
        }
    },

    download_txtFile_storeAction({ }, value) {
        let blob = new Blob([value.fileContent], {
            type: "application/txt"
        });

        FileSaver.saveAs(blob, value.fileName);
    },

    async get_PriceInformation_available_storeAction({ commit }) {

        await axios({
            method: 'post',
            url: '/api/summaries/get_price_information_available',
        })
            .then((response) => {
                commit('change_ShowInfoButton_storMutation', response.data)
            })
            .catch(() => { })
    },

    async get_PriceInformation_storeAction({ commit, dispatch, rootState }, value) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var customerIdFromInput = '';
        var companyCustFromInput = '';
        var erpSystemNumber = '';
        if (value != null && value.indexOf("|") > -1) {
            var i = parseInt(value.split('|')[0])
            if (!Number.isNaN(i)) {
                customerIdFromInput = value.split('|')[1].trim();
                companyCustFromInput = value.split('|')[2].trim();
                erpSystemNumber = value.split('|')[3].trim();
            }
        }
        const data = {
            configurationOverviewId: rootState.configurations.configurationOverviewsId,
            customerID: customerIdFromInput,
            companyCust: companyCustFromInput,
            erpSystemNumber: erpSystemNumber,
        };
        await axios({
            method: 'post',
            url: '/api/summaries/get_price_information',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                commit('change_priceInformation_storeMutation', response.data)
                if (response.data.status.statusCode.startsWith('2')) {
                    if (response.data.data.za_employee == true) {
                        commit('change_ShowInfo_storMutation', true);
                        dispatch('load_storedInformation_storeAction');
                    }
                    else {
                        commit('change_ShowInfo_storMutation', false);
                    }
                }
                else {
                    commit('change_ShowInfo_storMutation', false);
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    change_ShowInfo_storAction({commit }, value) {
        commit('change_ShowInfo_storMutation', value);
    },

    async checkIf_input_UseFrame_Selected_storeAction({ rootState }) {
        if (rootState.configurations.configuration_Modifiable == true) {
            const data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }

            await axios({
                method: 'post',
                url: '/api/summaries/checkIf_input_UseFrame_Selected_storeAction',
                data: {
                    data: data,
                }
            })
                .then(async (response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.data.changeUseFrame == true) {
                        this.commit('inputs/change_useFrame_storeMutation', false);
                        this.commit('driveTechnologies/change_Selected_atEveryElementOf_elevatorMotor_Machine_Selection_frameAvailable_toFalse_storeMutation')
                    }
                })
                .catch(() => { })
        }
    },
    async change_ShowInfo_storeAction({ commit, dispatch }, value) {
        await dispatch('get_PriceInformation_storeAction', value);      
    },

    async select_databaseRecords_for_manualHome_storeAction({ rootState, commit, dispatch }) {
        if (rootState.configurations.configurationOverviewsId != null) {
            //commit('change_priceInformation_storeMutation', {});
            const data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                configurationSteps: rootState.configurations.configurationSteps,
                configurationOverviewsLinkId: rootState.configurations.configurationOverviewsLinkId,
            };

            await axios({
                method: 'post',
                url: '/api/summaries/select_databaseRecords_for_manualHome',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    if (response.data.status.statusCode.startsWith('2')) {

                        commit('change_manualHome_storeMutation', response.data.data);

                        var firstIndex_whichMeetCondition = response.data.data.suffixData.findIndex(i => i.selected == true);
                        if (firstIndex_whichMeetCondition > -1) {
                            this.dispatch('configurations/set_configurationOverviewsLinkId_at_sessionStorage_storeAction', response.data.data.suffixData[firstIndex_whichMeetCondition].configurationOverviewsLinkId);
                            this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', response.data.data.suffixData[firstIndex_whichMeetCondition].configurationStepsManualId);
                        }
                    }
                })
                .catch(() => { })
        }
    },

    change_suffix_storeAction({ rootState, commit, dispatch }, value) {
        if (value != null && value.configurationOverviewsLinkId != rootState.configurations.configurationOverviewsLinkId) {
            this.dispatch('configurations/set_configurationOverviewsLinkId_at_sessionStorage_storeAction', value.configurationOverviewsLinkId);
            dispatch('select_databaseRecords_for_manualHome_storeAction');
        }
        else if (value == null) {
            this.dispatch('configurations/set_configurationOverviewsLinkId_at_sessionStorage_storeAction', null);
            dispatch('select_databaseRecords_for_manualHome_storeAction');
        }
    },

    async set_manualConfigurationModifiable_storeAction({rootState }) {
        const data = {
            configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            configurationSteps: rootState.configurations.configurationSteps,
            configurationOverviewsLinkId: rootState.configurations.configurationOverviewsLinkId,
        };

        await axios({
            method: 'post',
            url: '/api/summaries/set_manualConfigurationModifiable',
            data: {
                data: data,
            }
        })
            .then((response) => {
            })
            .catch(() => { })
    },

    async toDo_EmailSent_storeAction({ rootState }) {
        const configurationOverviewsId = rootState.configurations.configurationOverviewsId;
        await axios({
            method: 'post',
            url: '/api/summaries/insert_EmailSent/' + configurationOverviewsId,
        })
            .then(() => {
            })
            .catch(() => { })
    },

    async checkInformation_storeAction({rootState}) {
        if (state.showInfo == true && state.information != null && state.information.hasOwnProperty('data') && state.information.data != null && state.information.data.hasOwnProperty('price') && state.information.data.price != null) {
            const data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                price: state.information.data.price,
            };

            await axios({
                method: 'post',
                url: '/api/summaries/insert_Information',
                data: {
                    data: data,
                }
            })
                .then(() => {
                })
                .catch(() => { })
        }
        else {
            const data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            };

            await axios({
                method: 'post',
                url: '/api/summaries/delete_Information',
                data: {
                    data: data,
                }
            })
                .then(() => {
                })
                .catch(() => { })
        }
    },
    async load_storedInformation_storeAction({ commit, rootState }) {
            const data = {
                configurationOverviewId: rootState.configurations.configurationOverviewsId,
            };

            await axios({
                method: 'post',
                url: '/api/summaries/load_Information',
                data: {
                    data: data,
                }
            })
                .then((response) => {
                    commit('change_storedInformation_storeMutation', response.data.data);
                })
                .catch(() => { })
    },
}

const mutations = {
    change_priceInformation_storeMutation(state, payload) {
        state.information = Object.assign({}, payload);
    },
    change_storedInformation_storeMutation(state, payload) {
        state.storedInformation = Object.assign({}, payload);
    },

    change_summary_storeMutation(state, payload) {
        state.summary = Object.assign({}, payload);
    },

    change_responseOf_check_databaseRecords_byCalculationDll_storeMutation(state, payload) {
        state.responseOf_check_databaseRecords_byCalculationDll = Object.assign({}, payload);
    },
    change_toDos_at_summary_Done_storeMutation(state, payload) {
        state.toDos_at_summary_Done = payload;
    },
    change_openTask_storeMutation(state, payload) {
        state.openTask = payload;
    },

    change_fileLanguage_storeMutation(state, payload) {
        state.fileLanguage = payload;
    },
    change_ShowInfoButton_storMutation(state, payload) {
        state.showInfoButton = payload;
    },
    change_ShowInfo_storMutation(state, payload) {
        state.showInfo = payload;
    },
    change_manualHome_storeMutation(state, payload) {
        state.manualHomeSummary = payload;
    },
}

export const summary = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}