<template>
    <div style="overflow-x:auto !important">
        <div>

            <!--<div v-if="Baug_Select != undefined">
                <span class="my_color_00338e my_font-weight_600">{{$t('3503')}}</span>
                <span> {{Baug_Select}}</span>
            </div>

            <input v-if="Baug_Select != undefined" type="checkbox" v-model="FI_fuer_suche_verwenden" style="margin-right: 10px" />
            <label v-if="Baug_Select != undefined">{{$t(3504)}}</label>-->

            <div v-if="RLD_Typ_select_Combo == null" class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">ZArec</div>

            <div v-if="RLD_Typ_select_Combo == null" style="display:grid; grid-template-columns:2fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">
                <div style="padding-right:25px;border-right: 1px solid rgba(0, 0, 21, 0.2)">
                    <table  style=" margin-top:10px; width:100%; vertical-align: middle !important;" cellpadding="10">
                        <tr>
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('407')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_Typ_list!=null&&(ZArec_Typ_list.length == 0 ||ZArec_Typ_list.length>=5)" v-model="ZArec_Typ_select_Combo" class="my_select" style="width: 100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_Typ in ZArec_Typ_list" v-bind:value="itemOf_ZArec_Typ">{{itemOf_ZArec_Typ.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_Typ_list!=null&&ZArec_Typ_list.length<5&&ZArec_Typ_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_Typ in ZArec_Typ_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_Typ.selected" @input="ZArec_Typ_select(itemOf_ZArec_Typ)" />
                                        <label v-if="itemOf_ZArec_Typ.text.useTextKey==false" v-bind:value="itemOf_ZArec_Typ" for="itemOf_ZArec_Typ" style="margin-left: 10px">{{itemOf_ZArec_Typ.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_Typ.text.useTextKey==true" v-bind:value="itemOf_ZArec_Typ" for="itemOf_ZArec_Typ" style="margin-left: 10px">{{$t(itemOf_ZArec_Typ.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3588')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_LS_list!=null&&(ZArec_LS_list.length == 0 || ZArec_LS_list.length>=5)" v-model="ZArec_LS_select_Combo" class="my_select" style="width:100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_LS in ZArec_LS_list" v-bind:value="itemOf_ZArec_LS">{{itemOf_ZArec_LS.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_LS_list!=null&&ZArec_LS_list.length<5&&ZArec_LS_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_LS in ZArec_LS_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_LS.selected" @input="ZArec_LS_select(itemOf_ZArec_LS)" />
                                        <label v-if="itemOf_ZArec_LS.text.useTextKey==false" v-bind:value="itemOf_ZArec_LS" for="itemOf_ZArec_LS" style="margin-left: 10px">{{itemOf_ZArec_LS.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_LS.text.useTextKey==true" v-bind:value="itemOf_ZArec_LS" for="itemOf_ZArec_LS" style="margin-left: 10px">{{$t(itemOf_ZArec_LS.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr v-if="ZArec_Typ_select_Combo != null && ZArec_Typ_select_Combo.text.text != 'ZArec4C 013'">
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('388')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_BR_list!=null&&(ZArec_BR_list.length == 0 || ZArec_BR_list.length>=5)" v-model="ZArec_BR_select_Combo" class="my_select" style="width:100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_BR in ZArec_BR_list" v-bind:value="itemOf_ZArec_BR">{{itemOf_ZArec_BR.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_BR_list!=null&&ZArec_BR_list.length<5&&ZArec_BR_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_BR in ZArec_BR_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_BR.selected" @input="ZArec_BR_select(itemOf_ZArec_BR)" />
                                        <label v-if="itemOf_ZArec_BR.text.useTextKey==false" v-bind:value="itemOf_ZArec_BR" for="itemOf_ZArec_BR" style="margin-left: 10px">{{itemOf_ZArec_BR.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_BR.text.useTextKey==true" v-bind:value="itemOf_ZArec_BR" for="itemOf_ZArec_BR" style="margin-left: 10px">{{$t(itemOf_ZArec_BR.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr v-if="ZArec_BR_select_Combo != null && ZArec_BR_select_Combo.text.text != 'BR14-A'">
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3517')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_BRLT_list!=null&&(ZArec_BRLT_list.length == 0 || ZArec_BRLT_list.length>=5)" v-model="ZArec_BRLT_select_Combo" class="my_select" style="width:100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_BRLT in ZArec_BRLT_list" v-bind:value="itemOf_ZArec_BRLT">{{itemOf_ZArec_BRLT.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_BRLT_list!=null&&ZArec_BRLT_list.length<5&&ZArec_BRLT_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_BRLT in ZArec_BRLT_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_BRLT.selected" @input="ZArec_BRLT_select(itemOf_ZArec_BRLT)" />
                                        <label v-if="itemOf_ZArec_BRLT.text.useTextKey==false" v-bind:value="itemOf_ZArec_BRLT" for="itemOf_ZArec_BRLT" style="margin-left: 10px">{{itemOf_ZArec_BRLT.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_BRLT.text.useTextKey==true" v-bind:value="itemOf_ZArec_BRLT" for="itemOf_ZArec_BRLT" style="margin-left: 10px">{{$t(itemOf_ZArec_BRLT.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3513')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_ZApad_list!=null&&(ZArec_ZApad_list == 0 || ZArec_ZApad_list.length>=5)" v-model="ZArec_ZApad_select_Combo" class="my_select" style="width:100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_ZApad in ZArec_ZApad_list" v-bind:value="itemOf_ZArec_ZApad">{{itemOf_ZArec_ZApad.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_ZApad_list!=null&&ZArec_ZApad_list.length<5&&ZArec_ZApad_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_ZApad in ZArec_ZApad_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_ZApad.selected" @input="ZArec_ZApad_select(itemOf_ZArec_ZApad)" />
                                        <label v-if="itemOf_ZArec_ZApad.text.useTextKey==false" v-bind:value="itemOf_ZArec_ZApad" for="itemOf_ZArec_ZApad" style="margin-left: 10px">{{itemOf_ZArec_ZApad.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_ZApad.text.useTextKey==true" v-bind:value="itemOf_ZArec_ZApad" for="itemOf_ZArec_ZApad" style="margin-left: 10px">{{$t(itemOf_ZArec_ZApad.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td style=" width:calc(100%/2)">
                                <div class="ZAfont_family ZAfont_size">
                                    {{$t('3391')}}
                                </div>
                            </td>
                            <td style=" width:calc(100%)">
                                <select v-if="ZArec_BAL_list!=null&&(ZArec_BAL_list.length == 0 || ZArec_BAL_list.length>=5)" v-model="ZArec_BAL_select_Combo" class="my_select" style="width:100%">
                                    <option value="null" hidden>{{$t('3174')}}</option>
                                    <option v-for="itemOf_ZArec_BAL in ZArec_BAL_list" v-bind:value="itemOf_ZArec_BAL">{{itemOf_ZArec_BAL.text.textUI}}</option>
                                </select>

                                <div class="my_inputCheckbox" v-if="ZArec_BAL_list!=null&&ZArec_BAL_list.length<5&&ZArec_BAL_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                                    <div class="my_inputCheckbox_line" v-for="itemOf_ZArec_BAL in ZArec_BAL_list">
                                        <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                        <input type="checkbox" v-bind:checked="itemOf_ZArec_BAL.selected" @input="ZArec_BAL_select(itemOf_ZArec_BAL)" />
                                        <label v-if="itemOf_ZArec_BAL.text.useTextKey==false" v-bind:value="itemOf_ZArec_BAL" for="itemOf_ZArec_BAL" style="margin-left: 10px">{{itemOf_ZArec_BAL.text.textUI}}</label>
                                        <label v-if="itemOf_ZArec_BAL.text.useTextKey==true" v-bind:value="itemOf_ZArec_BAL" for="itemOf_ZArec_BAL" style="margin-left: 10px">{{$t(itemOf_ZArec_BAL.text.textKey)}}</label>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                    <img src="../../../../public/img/ProductImages/ZArec/ZArec4C_013_ZDRE01M0-front.jpg" style="width:calc(100%/3)" />

                </div>

            </div>







        </div>

    <hr />

    <div v-if="ZArec_Typ_select_Combo == null" class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">RLD</div>
    <div style="display:grid; grid-template-columns:2fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">
        <div style="padding-right: 25px; border-right: 1px solid rgba(0, 0, 21, 0.2)">
            <table v-if="ZArec_Typ_select_Combo == null" style=" margin-top: 10px; width: 100%; vertical-align: middle !important;" cellpadding="10">

                <tr>
                    <td style=" width:calc(100%/2)">
                        <div class="ZAfont_family ZAfont_size">
                            {{$t('407')}}
                        </div>
                    </td>
                    <td style=" width:calc(100%)">
                        <select v-if="RLD_Typ_list!=null&&(RLD_Typ_list.length == 0 || RLD_Typ_list.length>=5)" v-model="RLD_Typ_select_Combo" class="my_select" style="width: 100%">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option v-for="itemOf_RLD_Typ in RLD_Typ_list" v-bind:value="itemOf_RLD_Typ">{{itemOf_RLD_Typ.text.textUI}}</option>
                        </select>

                        <div class="my_inputCheckbox" v-if="RLD_Typ_list!=null&&RLD_Typ_list.length<5&&RLD_Typ_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                            <div v-for="itemOf_RLD_Typ in RLD_Typ_list">
                                <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                <input type="checkbox" v-bind:checked="itemOf_RLD_Typ.selected" @input="RLD_Typ_select(itemOf_RLD_Typ)" />
                                <label v-if="itemOf_RLD_Typ.text.useTextKey==false" v-bind:value="itemOf_RLD_Typ" for="itemOf_RLD_Typ" style="margin-left: 10px">{{itemOf_RLD_Typ.text.textUI}}</label>
                                <label v-if="itemOf_RLD_Typ.text.useTextKey==true" v-bind:value="itemOf_RLD_Typ" for="itemOf_RLD_Typ" style="margin-left: 10px">{{$t(itemOf_RLD_Typ.text.textKey)}}</label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="RLD_FEF_list!=null && RLD_FEF_list.length != 0">
                    <td  style=" width:calc(100%/2)">
                        <div class="ZAfont_family ZAfont_size">
                            Funkentstoerfilter
                        </div>
                    </td>
                    <td style=" width:calc(100%)">
                        <select v-if="RLD_FEF_list!=null&&(RLD_FEF_list.length == 0 || RLD_FEF_list.length>=5)" v-model="RLD_FEF_select_Combo" class="my_select" style="width:100%">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option v-for="itemOf_RLD_FEF in RLD_FEF_list" v-bind:value="itemOf_RLD_FEF">{{itemOf_RLD_FEF.text.textUI}}</option>
                        </select>

                        <div class="my_inputCheckbox" v-if="RLD_FEF_list!=null&&RLD_FEF_list.length<5&&RLD_FEF_list.length>0" style="display:grid; grid-template-columns:1fr 1fr; grid-column-gap:10px; margin-top:10px;width:contain">

                            <div v-for="itemOf_RLD_FEF in RLD_FEF_list">
                                <!--<input v-for="itemOf_EVAC_Batt in EVAC_Batt_list" type="radio" id="huey" name="EVAC_Batt" value="itemOf_EVAC_Batt" v-bind:value="itemOf_EVAC_Batt" v-model="EVAC_Batt_select" />-->
                                <input type="checkbox" v-bind:checked="itemOf_RLD_FEF.selected" @input="RLD_FEF_select(itemOf_RLD_FEF)" />
                                <label v-if="itemOf_RLD_FEF.text.useTextKey==false" v-bind:value="itemOf_RLD_FEF" for="itemOf_RLD_FEF" style="margin-left: 10px">{{itemOf_RLD_FEF.text.textUI}}</label>
                                <label v-if="itemOf_RLD_FEF.text.useTextKey==true" v-bind:value="itemOf_RLD_FEF" for="itemOf_RLD_FEF" style="margin-left: 10px">{{$t(itemOf_RLD_FEF.text.textKey)}}</label>
                            </div>
                        </div>

                    </td>
                </tr>
            </table>

        </div>

        <div>

        </div>

    </div>

        <div>
            <button @click="RouteToManualHomeCancel" class="btn my_btn_standard_blue" style="margin-top:40px; margin-right:5px">{{$t('3237')}}</button>
            <button @click="RouteToManualHomeOK" class="btn my_btn_standard_blue" style="margin-top:40px">{{$t('383')}}</button>
        </div>
        

</div>
</template>


<script>
    import router from '../../../router';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import LoaderIcon from '../../loader/LoaderIcon';

    export default {
        name: 'ZArec',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },

        data() {
            return {


            }
        },

        computed: {
            //sapProfile: {
            //    get() {
            //        console.log('test', this.$store.state.configurations.sap)
            //        return this.$store.state.configurations.sap;
            //    },
            //},


            ZArec_Typ_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_Typ_list != null && Array.isArray(this.$store.state.ZArec.ZArec_Typ_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_Typ_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_Typ_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    //return this.$store.state.ZArec.ZArec_Typ_select
                },
                set(value) {
                    this.ZArec_Typ_select(value)
                    //this.$store.dispatch('ZArec/change_ZArec_Typ_selection_storeAction', value);
                }
            },
            ZArec_LS_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_LS_list != null && Array.isArray(this.$store.state.ZArec.ZArec_LS_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_LS_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_LS_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    //return this.$store.state.ZArec.ZArec_LS_select
                },
                set(value) {
                    this.ZArec_LS_select(value)
                    //this.$store.commit('ZArec/change_ZArec_LS_selection_storeMutation', value)
                }
            },
            ZArec_BR_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_BR_list != null && Array.isArray(this.$store.state.ZArec.ZArec_BR_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_BR_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_BR_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    //return this.$store.state.ZArec.ZArec_BR_select
                },
                set(value) {
                    this.ZArec_BR_select(value)
                    //this.$store.dispatch('ZArec/change_ZArec_BR_selection_storeAction', value)
                }
            },
            ZArec_BRLT_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_BRLT_list != null && Array.isArray(this.$store.state.ZArec.ZArec_BRLT_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_BRLT_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_BRLT_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    //return this.$store.state.ZArec.ZArec_BRLT_select
                },
                set(value) {
                    this.ZArec_BRLT_select(value)
                    //this.$store.commit('ZArec/change_ZArec_BRLT_selection_storeMutation', value)
                }
            },
            ZArec_ZApad_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_ZApad_list != null && Array.isArray(this.$store.state.ZArec.ZArec_ZApad_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_ZApad_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_ZApad_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    //return this.$store.state.ZArec.ZArec_ZApad_select
                },
                set(value) {
                    this.ZArec_ZApad_select(value)
                    //this.$store.commit('ZArec/change_ZArec_ZApad_selection_storeMutation', value)
                }
            },
            ZArec_BAL_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.ZArec_BAL_list != null && Array.isArray(this.$store.state.ZArec.ZArec_BAL_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.ZArec_BAL_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.ZArec_BAL_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }


                    /*return this.$store.state.ZArec.ZArec_BAL_select*/
                },
                set(value) {
                    this.ZArec_BAL_select(value)
                    //this.$store.commit('ZArec/change_ZArec_BAL_selection_storeMutation', value)
                },
            },
            RLD_Typ_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.RLD_Typ_list != null && Array.isArray(this.$store.state.ZArec.RLD_Typ_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.RLD_Typ_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.RLD_Typ_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.RLD_Typ_select(value);
                },
            },
            RLD_FEF_select_Combo: {
                get() {
                    if (this.$store.state.ZArec.RLD_FEF_list != null && Array.isArray(this.$store.state.ZArec.RLD_FEF_list)) {
                        const firstIndex_whichMeetConditions = this.$store.state.ZArec.RLD_FEF_list.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.ZArec.RLD_FEF_list[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.RLD_FEF_select(value);
                }
            },

            ZArec_Typ_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_Typ_list
                }
            },
            ZArec_LS_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_LS_list
                }
            },
            ZArec_BR_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_BR_list
                }
            },
            ZArec_BRLT_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_BRLT_list
                }
            },
            ZArec_ZApad_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_ZApad_list
                }
            },
            ZArec_BAL_list: {
                get() {
                    return this.$store.state.ZArec.ZArec_BAL_list
                }
            },
            RLD_Typ_list: {
                get() {
                    debugger
                    return this.$store.state.ZArec.RLD_Typ_list
                }
            },
            RLD_FEF_list: {
                get() {
                    return this.$store.state.ZArec.RLD_FEF_list
                }
            },
            Baug_Select: {
                get() {
                    return this.$store.state.FI.FI_Model_selected;
                },
            },
            FI_fuer_suche_verwenden: {
                get() {
                    return this.$store.state.ZArec.FI_fuer_suche_verwenden;
                },
                set(value) {
                    this.$store.dispatch('ZArec/change_FI_fuer_suche_verwenden_selection_storeAction', value);
                },
            },



            //FI_fuer_suche_verwenden: {
            //    get() {
            //        return this.$store.state.EVAC.FI_fuer_suche_verwenden;
            //    },
            //    set(value) {
            //        this.$store.dispatch('EVAC/change_FI_fuer_suche_verwenden_selection_storeAction', value);
            //    },
            //},

        },



        created() {
            if (this.configurationOverviewsId != null) {
                this.$store.dispatch('configurations/check_toDos_at_inputs_storeAction')
            }
            else {
                this.$store.dispatch('configurations/toDos_after_routeSlug_UpdatedOrEntered_storeAction', 'inputs')
            }
        },

        methods: {

            ZArec_Typ_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_Typ_selection_storeAction', value);

            },
            ZArec_LS_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_LS_selection_storeAction', value);
            },
            ZArec_BR_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_BR_selection_storeAction', value);

            },
            ZArec_BRLT_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_BRLT_selection_storeAction', value);

            },
            ZArec_ZApad_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_ZApad_selection_storeAction', value);

            },
            ZArec_BAL_select(value) {

                this.$store.dispatch('ZArec/change_ZArec_BAL_selection_storeAction', value);

            },
            RLD_Typ_select(value) {
                this.$store.dispatch('ZArec/change_RLD_Typ_selection_storeAction', value);
            },
            RLD_FEF_select(value) {
                this.$store.dispatch('ZArec/change_RLD_FEF_selection_storeAction', value);
            },

            RouteToManualHomeOK() {
                
                this.$store.dispatch('ZArec/insert_data');
                router.push('../manualStep/ManualHome');
            },
            RouteToManualHomeCancel() {
                this.$store.dispatch('ZArec/change_inputs_ZArec_toDefault_storeAction');
                router.push('../manualStep/ManualHome');
            }
        },

        }
</script>


<style>

    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

    .my_inputCheckbox_line {
        margin-left: 3px;
    }
    
    .my_typeOfSuspensionMeans {
        list-style: none;
        background-color: #ffffff;
        padding: 0;
        margin: 0;
    }

        .my_typeOfSuspensionMeans li {
            border: 2px solid #00338e;
            color: #000000;
            display: inline-block;
            padding: 0.3rem;
            text-align: center;
            width: 50%;
        }

        .my_typeOfSuspensionMeans .active {
            background-color: #00338e;
            color: #ffffff;
        }

    .classC {
        margin-top: 78px;
    }

    .input_margin-bottom {
        margin-bottom: 20px;
    }
</style>
