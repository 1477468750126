<template>
    <div style="overflow-x:auto !important">
        <div>
            <div style="overflow:auto">
                <div v-if="encoderConnectionInformation==null" class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3449')}} {{$t('3075')}}</div>
                <div v-if="encoderConnectionInformation!=null" class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3436')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="encoderCableDeliverSelection.length==0 || encoderCableDeliverSelection.length>4">
                        <select v-model="selectedEncoderCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedEncoderCableDeliver==null, my_input_background_color_at_validInput:selectedEncoderCableDeliver!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="encoderCableDeliver in encoderCableDeliverSelection" v-bind:value="encoderCableDeliver">
                                <span v-if="encoderCableDeliver.text.useTextKey == false">{{encoderCableDeliver.text.textUI}}</span>
                                <span v-if="encoderCableDeliver.text.useTextKey == true">{{$t(encoderCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="encoderCableDeliverSelection.length>0 && encoderCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedEncoderCableDeliver==null, my_input_background_color_at_validInput:selectedEncoderCableDeliver!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="encoderCableDeliver in encoderCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_encoderCableDeliver(encoderCableDeliver)" v-bind:checked="encoderCableDeliver.selected" v-bind:disabled="encoderCableDeliver.selected" />
                                    <span v-if="encoderCableDeliver.text.useTextKey == false" style="margin-left:10px">{{encoderCableDeliver.text.textUI}}</span>
                                    <span v-if="encoderCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(encoderCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="encoderCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="encoderCableLengthSelection.length==0 || encoderCableLengthSelection.length>4">
                            <select v-model="selectedEncoderCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: encoderCableLengthSelection.length >0 && selectedEncoderCableLength==null, my_input_background_color_at_validInput: encoderCableLengthSelection.length == 0 ||selectedEncoderCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="encoderCableLength in encoderCableLengthSelection" v-bind:value="encoderCableLength">{{ValueDecimalSeparator(encoderCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="encoderCableLengthSelection.length>0 && encoderCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: encoderCableLengthSelection.length >0 && selectedEncoderCableLength==null, my_input_background_color_at_validInput: encoderCableLengthSelection.length == 0 ||selectedEncoderCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="encoderCableLength in encoderCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_encoderCableLength(encoderCableLength)" v-bind:checked="encoderCableLength.selected" v-bind:disabled="encoderCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(encoderCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="encoderCableTypeOfConnectionOnComponentSideSelection.length==0 || encoderCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedEncoderCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: encoderCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedEncoderCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: encoderCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedEncoderCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="encoderCableTypeOfConnectionOnComponentSide in encoderCableTypeOfConnectionOnComponentSideSelection" v-bind:value="encoderCableTypeOfConnectionOnComponentSide">
                                    <span v-if="encoderCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{encoderCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="encoderCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(encoderCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="encoderCableTypeOfConnectionOnComponentSideSelection.length>0 && encoderCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: encoderCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedEncoderCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: encoderCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedEncoderCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="encoderCableTypeOfConnectionOnComponentSide in encoderCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_encoderCableTypeOfConnectionOnComponentSide(encoderCableTypeOfConnectionOnComponentSide)" v-bind:checked="encoderCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="encoderCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="encoderCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{encoderCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="encoderCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(encoderCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="encoderCableSpecificationSelection.length==0 || encoderCableSpecificationSelection.length>4">
                            <select v-model="selectedEncoderCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: encoderCableSpecificationSelection.length >0 && selectedEncoderCableSpecification==null, my_input_background_color_at_validInput: encoderCableSpecificationSelection.length == 0 ||selectedEncoderCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="encoderCableSpecification in encoderCableDeliverSelection" v-bind:value="encoderCableSpecification">
                                    <span v-if="encoderCableSpecification.text.useTextKey == false">{{encoderCableSpecification.text.textUI}}</span>
                                    <span v-if="encoderCableSpecification.text.useTextKey == true">{{$t(encoderCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="encoderCableSpecificationSelection.length>0 && encoderCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: encoderCableSpecificationSelection.length >0 && selectedEncoderCableSpecification==null, my_input_background_color_at_validInput: encoderCableSpecificationSelection.length == 0 ||selectedEncoderCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="encoderCableSpecification in encoderCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_encoderCableSpecification(encoderCableSpecification)" v-bind:checked="encoderCableSpecification.selected" v-bind:disabled="encoderCableSpecification.selected" />
                                        <span v-if="encoderCableSpecification.text.useTextKey == false" style="margin-left:10px">{{encoderCableSpecification.text.textUI}}</span>
                                        <span v-if="encoderCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(encoderCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="encoderCableNumberOfWiresAndCrossSectionSelection.length==0 || encoderCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedEncoderCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: encoderCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedEncoderCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: encoderCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedEncoderCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="encoderCableNumberOfWiresAndCrossSection in encoderCableDeliverSelection" v-bind:value="encoderCableNumberOfWiresAndCrossSection">
                                    {{encoderCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(encoderCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="encoderCableNumberOfWiresAndCrossSectionSelection.length>0 && encoderCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: encoderCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedEncoderCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: encoderCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedEncoderCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="encoderCableNumberOfWiresAndCrossSection in encoderCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_encoderCableNumberOfWiresAndCrossSection(encoderCableNumberOfWiresAndCrossSection)" v-bind:checked="encoderCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="encoderCableNumberOfWiresAndCrossSection.selected" />
                                        {{encoderCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(encoderCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="encoderCableBlockNumber!=null">
                            <span>{{encoderCableBlockNumber}}</span>
                            <span v-if="encoderCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="encoderCableTypeDesignation.useTextKey==true&&encoderCableTypeDesignation.textKey!=null">{{$t(encoderCableTypeDesignation.textKey)}}</span>
                                <span v-if="encoderCableTypeDesignation.useTextKey==false||encoderCableTypeDesignation.textKey==null">{{$t(encoderCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="encoderConnectionInformation!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3428')}}:</div>
                    </div>
                    <div>
                        <select v-model="selectedEncoderConnection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: encoderCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedEncoderCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: encoderCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedEncoderCableNumberOfWiresAndCrossSection!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="encoderConnection in encoderConnectionInformation" v-bind:value="encoderConnection">
                                <span>{{encoderConnection.blockNumber}}</span>
                                <span v-if="encoderConnection.length!=null"> | {{ValueDecimalSeparator(encoderConnection.length)}} m</span>
                                <span v-if="encoderConnection.typeOfConnection!=null"> | {{encoderConnection.typeOfConnection}}</span>
                            </option>
                        </select>
                    </div>

                    <!--<div>
                        <span>{{encoderConnectionInformation.blockNumber}}</span>
                        <span v-if="encoderConnectionInformation.length!=null"> | {{ValueDecimalSeparator(encoderConnectionInformation.length)}} m</span>
                    </div>-->
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3437')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="motorCableDeliverSelection.length==0 || motorCableDeliverSelection.length>4">
                        <select v-model="selectedMotorCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput:selectedMotorCableDeliver==null, my_input_background_color_at_validInput:selectedMotorCableDeliver!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorCableDeliver in motorCableDeliverSelection" v-bind:value="motorCableDeliver">
                                <span v-if="motorCableDeliver.text.useTextKey == false">{{motorCableDeliver.text.textUI}}</span>
                                <span v-if="motorCableDeliver.text.useTextKey == true">{{$t(motorCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorCableDeliverSelection.length>0 && motorCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput:selectedMotorCableDeliver==null, my_input_background_color_at_validInput:selectedMotorCableDeliver!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorCableDeliver in motorCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorCableDeliver(motorCableDeliver)" v-bind:checked="motorCableDeliver.selected" v-bind:disabled="motorCableDeliver.selected" />
                                    <span v-if="motorCableDeliver.text.useTextKey == false" style="margin-left:10px">{{motorCableDeliver.text.textUI}}</span>
                                    <span v-if="motorCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(motorCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="motorCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="motorCableLengthSelection.length==0 || motorCableLengthSelection.length>4">
                            <select v-model="selectedMotorCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorCableLengthSelection.length >0 && selectedMotorCableLength==null, my_input_background_color_at_validInput: motorCableLengthSelection.length == 0 ||selectedMotorCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorCableLength in motorCableLengthSelection" v-bind:value="motorCableLength">{{ValueDecimalSeparator(motorCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="motorCableLengthSelection.length>0 && motorCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorCableLengthSelection.length >0 && selectedMotorCableLength==null, my_input_background_color_at_validInput: motorCableLengthSelection.length == 0 ||selectedMotorCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorCableLength in motorCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorCableLength(motorCableLength)" v-bind:checked="motorCableLength.selected" v-bind:disabled="motorCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(motorCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="motorCableTypeOfConnectionOnComponentSideSelection.length==0 || motorCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedMotorCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedMotorCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: motorCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedMotorCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorCableTypeOfConnectionOnComponentSide in motorCableTypeOfConnectionOnComponentSideSelection" v-bind:value="motorCableTypeOfConnectionOnComponentSide">
                                    <span v-if="motorCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{motorCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="motorCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(motorCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorCableTypeOfConnectionOnComponentSideSelection.length>0 && motorCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedMotorCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: motorCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedMotorCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorCableTypeOfConnectionOnComponentSide in motorCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorCableTypeOfConnectionOnComponentSide(motorCableTypeOfConnectionOnComponentSide)" v-bind:checked="motorCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="motorCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="motorCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{motorCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="motorCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(motorCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="motorCableSpecificationSelection.length==0 || motorCableSpecificationSelection.length>4">
                            <select v-model="selectedMotorCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorCableSpecificationSelection.length >0 && selectedMotorCableSpecification==null, my_input_background_color_at_validInput: motorCableSpecificationSelection.length == 0 ||selectedMotorCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorCableSpecification in motorCableSpecificationSelection" v-bind:value="motorCableSpecification">
                                    <span v-if="motorCableSpecification.text.useTextKey == false">{{motorCableSpecification.text.textUI}}</span>
                                    <span v-if="motorCableSpecification.text.useTextKey == true">{{$t(motorCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorCableSpecificationSelection.length>0 && motorCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorCableSpecificationSelection.length >0 && selectedMotorCableSpecification==null, my_input_background_color_at_validInput: motorCableSpecificationSelection.length == 0 ||selectedMotorCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorCableSpecification in motorCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorCableSpecification(motorCableSpecification)" v-bind:checked="motorCableSpecification.selected" v-bind:disabled="motorCableSpecification.selected" />
                                        <span v-if="motorCableSpecification.text.useTextKey == false" style="margin-left:10px">{{motorCableSpecification.text.textUI}}</span>
                                        <span v-if="motorCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(motorCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="motorCableNumberOfWiresAndCrossSectionSelection.length==0 || motorCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedMotorCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedMotorCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: motorCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedMotorCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorCableNumberOfWiresAndCrossSection in motorCableDeliverSelection" v-bind:value="motorCableNumberOfWiresAndCrossSection">
                                    {{motorCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(motorCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="motorCableNumberOfWiresAndCrossSectionSelection.length>0 && motorCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedMotorCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: motorCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedMotorCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorCableNumberOfWiresAndCrossSection in motorCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorCableNumberOfWiresAndCrossSection(motorCableNumberOfWiresAndCrossSection)" v-bind:checked="motorCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="motorCableNumberOfWiresAndCrossSection.selected" />
                                        {{motorCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(motorCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="motorCableBlockNumber!=null">
                            <span>{{motorCableBlockNumber}}</span>
                            <span v-if="motorCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="motorCableTypeDesignation.useTextKey==true&&motorCableTypeDesignation.textKey!=null">{{$t(motorCableTypeDesignation.textKey)}}</span>
                                <span v-if="motorCableTypeDesignation.useTextKey==false||motorCableTypeDesignation.textKey==null">{{$t(motorCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3438')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="motorProtectionCableDeliverSelection.length==0 || motorProtectionCableDeliverSelection.length>4">
                        <select v-model="selectedMotorProtectionCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput:selectedMotorProtectionCableDeliver==null, my_input_background_color_at_validInput:selectedMotorProtectionCableDeliver!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="motorProtectionCableDeliver in motorProtectionCableDeliverSelection" v-bind:value="motorProtectionCableDeliver">
                                <span v-if="motorProtectionCableDeliver.text.useTextKey == false">{{motorProtectionCableDeliver.text.textUI}}</span>
                                <span v-if="motorProtectionCableDeliver.text.useTextKey == true">{{$t(motorProtectionCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="motorProtectionCableDeliverSelection.length>0 && motorProtectionCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput:selectedMotorProtectionCableDeliver==null, my_input_background_color_at_validInput:selectedMotorProtectionCableDeliver!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="motorProtectionCableDeliver in motorProtectionCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_motorProtectionCableDeliver(motorProtectionCableDeliver)" v-bind:checked="motorProtectionCableDeliver.selected" v-bind:disabled="motorProtectionCableDeliver.selected" />
                                    <span v-if="motorProtectionCableDeliver.text.useTextKey == false" style="margin-left:10px">{{motorProtectionCableDeliver.text.textUI}}</span>
                                    <span v-if="motorProtectionCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(motorProtectionCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="motorProtectionCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="motorProtectionCableLengthSelection.length==0 || motorProtectionCableLengthSelection.length>4">
                            <select v-model="selectedMotorProtectionCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableLengthSelection.length >0 && selectedMotorProtectionCableLength==null, my_input_background_color_at_validInput: motorProtectionCableLengthSelection.length == 0 ||selectedMotorProtectionCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorProtectionCableLength in motorProtectionCableLengthSelection" v-bind:value="motorProtectionCableLength">{{ValueDecimalSeparator(motorProtectionCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="motorProtectionCableLengthSelection.length>0 && motorProtectionCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableLengthSelection.length >0 && selectedMotorProtectionCableLength==null, my_input_background_color_at_validInput: motorProtectionCableLengthSelection.length == 0 ||selectedMotorProtectionCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorProtectionCableLength in motorProtectionCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorProtectionCableLength(motorProtectionCableLength)" v-bind:checked="motorProtectionCableLength.selected" v-bind:disabled="motorProtectionCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(motorProtectionCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="motorProtectionCableTypeOfConnectionOnComponentSideSelection.length==0 || motorProtectionCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedMotorProtectionCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedMotorProtectionCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: motorProtectionCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedMotorProtectionCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorProtectionCableTypeOfConnectionOnComponentSide in motorProtectionCableTypeOfConnectionOnComponentSideSelection" v-bind:value="motorProtectionCableTypeOfConnectionOnComponentSide">
                                    <span v-if="motorProtectionCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{motorProtectionCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="motorProtectionCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(motorProtectionCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorProtectionCableTypeOfConnectionOnComponentSideSelection.length>0 && motorProtectionCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedMotorProtectionCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: motorProtectionCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedMotorProtectionCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorProtectionCableTypeOfConnectionOnComponentSide in motorProtectionCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorProtectionCableTypeOfConnectionOnComponentSide(motorProtectionCableTypeOfConnectionOnComponentSide)" v-bind:checked="motorProtectionCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="motorProtectionCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="motorProtectionCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{motorProtectionCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="motorProtectionCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(motorProtectionCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="motorProtectionCableSpecificationSelection.length==0 || motorProtectionCableSpecificationSelection.length>4">
                            <select v-model="selectedMotorProtectionCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableSpecificationSelection.length >0 && selectedMotorProtectionCableSpecification==null, my_input_background_color_at_validInput: motorProtectionCableSpecificationSelection.length == 0 ||selectedMotorProtectionCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorProtectionCableSpecification in motorProtectionCableDeliverSelection" v-bind:value="motorProtectionCableSpecification">
                                    <span v-if="motorProtectionCableSpecification.text.useTextKey == false">{{motorProtectionCableSpecification.text.textUI}}</span>
                                    <span v-if="motorProtectionCableSpecification.text.useTextKey == true">{{$t(motorProtectionCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="motorProtectionCableSpecificationSelection.length>0 && motorProtectionCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableSpecificationSelection.length >0 && selectedMotorProtectionCableSpecification==null, my_input_background_color_at_validInput: motorProtectionCableSpecificationSelection.length == 0 ||selectedMotorProtectionCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorProtectionCableSpecification in motorProtectionCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorProtectionCableSpecification(motorProtectionCableSpecification)" v-bind:checked="motorProtectionCableSpecification.selected" v-bind:disabled="motorProtectionCableSpecification.selected" />
                                        <span v-if="motorProtectionCableSpecification.text.useTextKey == false" style="margin-left:10px">{{motorProtectionCableSpecification.text.textUI}}</span>
                                        <span v-if="motorProtectionCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(motorProtectionCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="motorProtectionCableNumberOfWiresAndCrossSectionSelection.length==0 || motorProtectionCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedMotorProtectionCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedMotorProtectionCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: motorProtectionCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedMotorProtectionCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="motorProtectionCableNumberOfWiresAndCrossSection in motorProtectionCableDeliverSelection" v-bind:value="motorProtectionCableNumberOfWiresAndCrossSection">
                                    {{motorProtectionCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(motorProtectionCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="motorProtectionCableNumberOfWiresAndCrossSectionSelection.length>0 && motorProtectionCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: motorProtectionCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedMotorProtectionCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: motorProtectionCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedMotorProtectionCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="motorProtectionCableNumberOfWiresAndCrossSection in motorProtectionCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_motorProtectionNumberOfWiresAndCrossSection(motorProtectionCableNumberOfWiresAndCrossSection)" v-bind:checked="motorProtectionCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="motorProtectionCableNumberOfWiresAndCrossSection.selected" />
                                        {{motorProtectionCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(motorProtectionCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="motorProtectionCableBlockNumber!=null">
                            <span>{{motorProtectionCableBlockNumber}}</span>
                            <span v-if="motorProtectionCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="motorProtectionCableTypeDesignation.useTextKey==true&&motorProtectionCableTypeDesignation.textKey!=null">{{$t(motorProtectionCableTypeDesignation.textKey)}}</span>
                                <span v-if="motorProtectionCableTypeDesignation.useTextKey==false||motorProtectionCableTypeDesignation.textKey==null">{{$t(motorProtectionCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3439')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="brakeCableDeliverSelection.length==0 || brakeCableDeliverSelection.length>4">
                        <select v-model="selectedBrakeCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeCableDeliverSelection.length >0 && selectedBrakeCableDeliver==null, my_input_background_color_at_validInput: brakeCableDeliverSelection.length == 0 ||selectedBrakeCableDeliver!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="brakeCableDeliver in brakeCableDeliverSelection" v-bind:value="brakeCableDeliver">
                                <span v-if="brakeCableDeliver.text.useTextKey == false">{{brakeCableDeliver.text.textUI}}</span>
                                <span v-if="brakeCableDeliver.text.useTextKey == true">{{$t(brakeCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="brakeCableDeliverSelection.length>0 && brakeCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeCableDeliverSelection.length >0 && selectedBrakeCableDeliver==null, my_input_background_color_at_validInput: brakeCableDeliverSelection.length == 0 ||selectedBrakeCableDeliver!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="brakeCableDeliver in brakeCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_brakeCableDeliver(brakeCableDeliver)" v-bind:checked="brakeCableDeliver.selected" v-bind:disabled="brakeCableDeliver.selected" />
                                    <span v-if="brakeCableDeliver.text.useTextKey == false" style="margin-left:10px">{{brakeCableDeliver.text.textUI}}</span>
                                    <span v-if="brakeCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(brakeCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="brakeCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="brakeCableLengthSelection.length==0 || brakeCableLengthSelection.length>4">
                            <select v-model="selectedBrakeCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeCableLengthSelection.length >0 && selectedBrakeCableLength==null, my_input_background_color_at_validInput: brakeCableLengthSelection.length == 0 ||selectedBrakeCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeCableLength in brakeCableLengthSelection" v-bind:value="brakeCableLength">{{ValueDecimalSeparator(brakeCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="brakeCableLengthSelection.length>0 && brakeCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeCableLengthSelection.length >0 && selectedBrakeCableLength==null, my_input_background_color_at_validInput: brakeCableLengthSelection.length == 0 ||selectedBrakeCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeCableLength in brakeCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeCableLength(brakeCableLength)" v-bind:checked="brakeCableLength.selected" v-bind:disabled="brakeCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(brakeCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="brakeCableTypeOfConnectionOnComponentSideSelection.length==0 || brakeCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedBrakeCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedBrakeCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: brakeCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedBrakeCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeCableTypeOfConnectionOnComponentSide in brakeCableTypeOfConnectionOnComponentSideSelection" v-bind:value="brakeCableTypeOfConnectionOnComponentSide">
                                    <span v-if="brakeCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{brakeCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="brakeCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(brakeCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeCableTypeOfConnectionOnComponentSideSelection.length>0 && brakeCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedBrakeCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: brakeCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedBrakeCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeCableTypeOfConnectionOnComponentSide in brakeCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeCableTypeOfConnectionOnComponentSide(brakeCableTypeOfConnectionOnComponentSide)" v-bind:checked="brakeCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="brakeCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="brakeCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{brakeCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="brakeCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(brakeCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="brakeCableSpecificationSelection.length==0 || brakeCableSpecificationSelection.length>4">
                            <select v-model="selectedBrakeCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeCableSpecificationSelection.length >0 && selectedBrakeCableSpecification==null, my_input_background_color_at_validInput: brakeCableSpecificationSelection.length == 0 ||selectedBrakeCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeCableSpecification in brakeCableSpecificationSelection" v-bind:value="brakeCableSpecification">
                                    <span v-if="brakeCableSpecification.text.useTextKey == false">{{brakeCableSpecification.text.textUI}}</span>
                                    <span v-if="brakeCableSpecification.text.useTextKey == true">{{$t(brakeCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeCableSpecificationSelection.length>0 && brakeCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeCableSpecificationSelection.length >0 && selectedBrakeCableSpecification==null, my_input_background_color_at_validInput: brakeCableSpecificationSelection.length == 0 ||selectedBrakeCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeCableSpecification in brakeCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeCableSpecification(brakeCableSpecification)" v-bind:checked="brakeCableSpecification.selected" v-bind:disabled="brakeCableSpecification.selected" />
                                        <span v-if="brakeCableSpecification.text.useTextKey == false" style="margin-left:10px">{{brakeCableSpecification.text.textUI}}</span>
                                        <span v-if="brakeCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(brakeCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="brakeCableNumberOfWiresAndCrossSectionSelection.length==0 || brakeCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedBrakeCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedBrakeCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: brakeCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedBrakeCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeCableNumberOfWiresAndCrossSection in brakeCableDeliverSelection" v-bind:value="brakeCableNumberOfWiresAndCrossSection">
                                    {{brakeCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(brakeCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeCableNumberOfWiresAndCrossSectionSelection.length>0 && brakeCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedBrakeCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: brakeCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedBrakeCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeCableNumberOfWiresAndCrossSection in brakeCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeCableNumberOfWiresAndCrossSection(brakeCableNumberOfWiresAndCrossSection)" v-bind:checked="brakeCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="brakeCableNumberOfWiresAndCrossSection.selected" />
                                        {{brakeCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(brakeCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="brakeCableBlockNumber!=null">
                            <span>{{brakeCableBlockNumber}}</span>
                            <span v-if="brakeCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="brakeCableTypeDesignation.useTextKey==true&&brakeCableTypeDesignation.textKey!=null">{{$t(brakeCableTypeDesignation.textKey)}}</span>
                                <span v-if="brakeCableTypeDesignation.useTextKey==false||brakeCableTypeDesignation.textKey==null">{{$t(brakeCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3440')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="brakeMonitoringCableDeliverSelection.length==0 || brakeMonitoringCableDeliverSelection.length>4">
                        <select v-model="selectedBrakeMonitoringCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput:brakeMonitoringCableDeliverSelection==null, my_input_background_color_at_validInput:brakeMonitoringCableDeliverSelection!=null}" :disabled="babl==true">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="brakeMonitoringCableDeliver in brakeMonitoringCableDeliverSelection" v-bind:value="brakeMonitoringCableDeliver">
                                <span v-if="brakeMonitoringCableDeliver.text.useTextKey == false">{{brakeMonitoringCableDeliver.text.textUI}}</span>
                                <span v-if="brakeMonitoringCableDeliver.text.useTextKey == true">{{$t(brakeMonitoringCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="brakeMonitoringCableDeliverSelection.length>0 && brakeMonitoringCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput:brakeMonitoringCableDeliverSelection==null, my_input_background_color_at_validInput:brakeMonitoringCableDeliverSelection!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="brakeMonitoringCableDeliver in brakeMonitoringCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_brakeMonitoringCableDeliver(brakeMonitoringCableDeliver)" v-bind:checked="brakeMonitoringCableDeliver.selected" v-bind:disabled="brakeMonitoringCableDeliver.selected || babl==true" />
                                    <span v-if="brakeMonitoringCableDeliver.text.useTextKey == false" style="margin-left:10px">{{brakeMonitoringCableDeliver.text.textUI}}</span>
                                    <span v-if="brakeMonitoringCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(brakeMonitoringCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="brakeMonitoringCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="brakeMonitoringCableLengthSelection.length==0 || brakeMonitoringCableLengthSelection.length>4">
                            <select v-model="selectedBrakeMonitoringCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableLengthSelection.length >0 && selectedBrakeMonitoringCableLength==null, my_input_background_color_at_validInput: brakeMonitoringCableLengthSelection.length == 0 ||selectedBrakeMonitoringCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeMonitoringCableLength in brakeMonitoringCableLengthSelection" v-bind:value="brakeMonitoringCableLength">{{ValueDecimalSeparator(brakeMonitoringCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="brakeMonitoringCableLengthSelection.length>0 && brakeMonitoringCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableLengthSelection.length >0 && selectedBrakeMonitoringCableLength==null, my_input_background_color_at_validInput: brakeMonitoringCableLengthSelection.length == 0 ||selectedBrakeMonitoringCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeMonitoringCableLength in brakeMonitoringCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeMonitoringCableLength(brakeMonitoringCableLength)" v-bind:checked="brakeMonitoringCableLength.selected" v-bind:disabled="brakeMonitoringCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(brakeMonitoringCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length==0 || brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeMonitoringCableTypeOfConnectionOnComponentSide in brakeMonitoringCableTypeOfConnectionOnComponentSideSelection" v-bind:value="brakeMonitoringCableTypeOfConnectionOnComponentSide">
                                    <span v-if="brakeMonitoringCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{brakeMonitoringCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="brakeMonitoringCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(brakeMonitoringCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length>0 && brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeMonitoringCableTypeOfConnectionOnComponentSide in brakeMonitoringCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeMonitoringCableTypeOfConnectionOnComponentSide(brakeMonitoringCableTypeOfConnectionOnComponentSide)" v-bind:checked="brakeMonitoringCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="brakeMonitoringCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="brakeMonitoringCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{brakeMonitoringCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="brakeMonitoringCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(brakeMonitoringCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="brakeMonitoringCableSpecificationSelection.length==0 || brakeMonitoringCableSpecificationSelection.length>4">
                            <select v-model="selectedBrakeMonitoringCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableSpecificationSelection.length >0 && selectedBrakeMonitoringCableSpecification==null, my_input_background_color_at_validInput: brakeMonitoringCableSpecificationSelection.length == 0 ||selectedBrakeMonitoringCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeMonitoringCableSpecification in brakeMonitoringCableDeliverSelection" v-bind:value="brakeMonitoringCableSpecification">
                                    <span v-if="brakeMonitoringCableSpecification.text.useTextKey == false">{{brakeMonitoringCableSpecification.text.textUI}}</span>
                                    <span v-if="brakeMonitoringCableSpecification.text.useTextKey == true">{{$t(brakeMonitoringCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeMonitoringCableSpecificationSelection.length>0 && brakeMonitoringCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableSpecificationSelection.length >0 && selectedBrakeMonitoringCableSpecification==null, my_input_background_color_at_validInput: brakeMonitoringCableSpecificationSelection.length == 0 ||selectedBrakeMonitoringCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeMonitoringCableSpecification in brakeMonitoringCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeMonitoringCableSpecification(brakeMonitoringCableSpecification)" v-bind:checked="brakeMonitoringCableSpecification.selected" v-bind:disabled="brakeMonitoringCableSpecification.selected" />
                                        <span v-if="brakeMonitoringCableSpecification.text.useTextKey == false" style="margin-left:10px">{{brakeMonitoringCableSpecification.text.textUI}}</span>
                                        <span v-if="brakeMonitoringCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(brakeMonitoringCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length==0 || brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedBrakeMonitoringCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedBrakeMonitoringCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedBrakeMonitoringCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="brakeMonitoringCableNumberOfWiresAndCrossSection in brakeMonitoringCableDeliverSelection" v-bind:value="brakeMonitoringCableNumberOfWiresAndCrossSection">
                                    {{brakeMonitoringCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(brakeMonitoringCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length>0 && brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedBrakeMonitoringCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedBrakeMonitoringCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="brakeMonitoringCableNumberOfWiresAndCrossSection in brakeMonitoringCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_brakeMonitoringCableNumberOfWiresAndCrossSection(brakeMonitoringCableNumberOfWiresAndCrossSection)" v-bind:checked="brakeMonitoringCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="brakeMonitoringCableNumberOfWiresAndCrossSection.selected" />
                                        {{brakeMonitoringCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(brakeMonitoringCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="brakeMonitoringCableBlockNumber!=null">
                            <span>{{brakeMonitoringCableBlockNumber}}</span>
                            <span v-if="brakeMonitoringCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="brakeMonitoringCableTypeDesignation.useTextKey==true&&brakeMonitoringCableTypeDesignation.textKey!=null">{{$t(brakeMonitoringCableTypeDesignation.textKey)}}</span>
                                <span v-if="brakeMonitoringCableTypeDesignation.useTextKey==false||brakeMonitoringCableTypeDesignation.textKey==null">{{$t(brakeMonitoringCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">{{$t('3547')}}</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3450')}}:</div>
                    </div>
                    <div v-if="forcedVentilationCableDeliverSelection.length==0 || forcedVentilationCableDeliverSelection.length>4">
                        <select v-model="selectedForcedVentilationCableDeliver" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput:selectedForcedVentilationCableDeliver==null, my_input_background_color_at_validInput:selectedForcedVentilationCableDeliver!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="forcedVentilationCableDeliver in forcedVentilationCableDeliverSelection" v-bind:value="forcedVentilationCableDeliver" v-bind:disabled="forcedVentilationCableDeliverSelection.length==0">
                                <span v-if="forcedVentilationCableDeliver.text.useTextKey == false">{{forcedVentilationCableDeliver.text.textUI}}</span>
                                <span v-if="forcedVentilationCableDeliver.text.useTextKey == true">{{$t(forcedVentilationCableDeliver.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="forcedVentilationCableDeliverSelection.length>0 && forcedVentilationCableDeliverSelection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput:selectedForcedVentilationCableDeliver==null, my_input_background_color_at_validInput:selectedForcedVentilationCableDeliver!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="forcedVentilationCableDeliver in forcedVentilationCableDeliverSelection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilationCableDeliver(forcedVentilationCableDeliver)" v-bind:checked="forcedVentilationCableDeliver.selected" v-bind:disabled="forcedVentilationCableDeliver.selected" />
                                    <span v-if="forcedVentilationCableDeliver.text.useTextKey == false" style="margin-left:10px">{{forcedVentilationCableDeliver.text.textUI}}</span>
                                    <span v-if="forcedVentilationCableDeliver.text.useTextKey == true" style="margin-left:10px">{{$t(forcedVentilationCableDeliver.text.textKey)}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="forcedVentilationCablesShowDetails==true">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3431')}}:</div>
                        </div>
                        <div v-if="forcedVentilationCableLengthSelection.length==0 || forcedVentilationCableLengthSelection.length>4">
                            <select v-model="selectedForcedVentilationCableLength" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableLengthSelection.length >0 && selectedForcedVentilationCableLength==null, my_input_background_color_at_validInput: forcedVentilationCableLengthSelection.length == 0 ||selectedForcedVentilationCableLength!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="forcedVentilationCableLength in forcedVentilationCableLengthSelection" v-bind:value="forcedVentilationCableLength">{{ValueDecimalSeparator(forcedVentilationCableLength.value)}}</option>
                            </select>
                        </div>
                        <div v-if="forcedVentilationCableLengthSelection.length>0 && forcedVentilationCableLengthSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableLengthSelection.length >0 && selectedForcedVentilationCableLength==null, my_input_background_color_at_validInput: forcedVentilationCableLengthSelection.length == 0 ||selectedForcedVentilationCableLength!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="forcedVentilationCableLength in forcedVentilationCableLengthSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilationCableLength(forcedVentilationCableLength)" v-bind:checked="forcedVentilationCableLength.selected" v-bind:disabled="forcedVentilationCableLength.selected" />
                                        <span style="margin-left:10px">{{ValueDecimalSeparator(forcedVentilationCableLength.value)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3426')}}:</div>
                        </div>
                        <div v-if="forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length==0 || forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length>4">
                            <select v-model="selectedForcedVentilationCableTypeOfConnectionOnComponentSide" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedForcedVentilationCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedForcedVentilationCableTypeOfConnectionOnComponentSide!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="forcedVentilationCableTypeOfConnectionOnComponentSide in forcedVentilationCableTypeOfConnectionOnComponentSideSelection" v-bind:value="forcedVentilationCableTypeOfConnectionOnComponentSide">
                                    <span v-if="forcedVentilationCableTypeOfConnectionOnComponentSide.text.useTextKey == false">{{forcedVentilationCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                    <span v-if="forcedVentilationCableTypeOfConnectionOnComponentSide.text.useTextKey == true">{{$t(forcedVentilationCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length>0 && forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length >0 && selectedForcedVentilationCableTypeOfConnectionOnComponentSide==null, my_input_background_color_at_validInput: forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length == 0 ||selectedForcedVentilationCableTypeOfConnectionOnComponentSide!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="forcedVentilationCableTypeOfConnectionOnComponentSide in forcedVentilationCableTypeOfConnectionOnComponentSideSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilationCableTypeOfConnectionOnComponentSide(forcedVentilationCableTypeOfConnectionOnComponentSide)" v-bind:checked="forcedVentilationCableTypeOfConnectionOnComponentSide.selected" v-bind:disabled="forcedVentilationCableTypeOfConnectionOnComponentSide.selected" />
                                        <span v-if="forcedVentilationCableTypeOfConnectionOnComponentSide.text.useTextKey == false" style="margin-left:10px">{{forcedVentilationCableTypeOfConnectionOnComponentSide.text.textUI}}</span>
                                        <span v-if="forcedVentilationCableTypeOfConnectionOnComponentSide.text.useTextKey == true" style="margin-left:10px">{{$t(forcedVentilationCableTypeOfConnectionOnComponentSide.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3422')}}:</div>
                        </div>
                        <div v-if="forcedVentilationCableSpecificationSelection.length==0 || forcedVentilationCableSpecificationSelection.length>4">
                            <select v-model="selectedForcedVentilationCableSpecification" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableSpecificationSelection.length >0 && selectedForcedVentilationCableSpecification==null, my_input_background_color_at_validInput: forcedVentilationCableSpecificationSelection.length == 0 ||selectedForcedVentilationCableSpecification!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="forcedVentilationCableSpecification in forcedVentilationCableSpecificationSelection" v-bind:value="forcedVentilationCableSpecification">
                                    <span v-if="forcedVentilationCableSpecification.text.useTextKey == false">{{forcedVentilationCableSpecification.text.textUI}}</span>
                                    <span v-if="forcedVentilationCableSpecification.text.useTextKey == true">{{$t(forcedVentilationCableSpecification.text.textKey)}}</span>
                                </option>
                            </select>
                        </div>
                        <div v-if="forcedVentilationCableSpecificationSelection.length>0 && forcedVentilationCableSpecificationSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableSpecificationSelection.length >0 && selectedForcedVentilationCableSpecification==null, my_input_background_color_at_validInput: forcedVentilationCableSpecificationSelection.length == 0 ||selectedForcedVentilationCableSpecification!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="forcedVentilationCableSpecification in forcedVentilationCableSpecificationSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilationCableSpecification(forcedVentilationCableSpecification)" v-bind:checked="forcedVentilationCableSpecification.selected" v-bind:disabled="forcedVentilationCableSpecification.selected" />
                                        <span v-if="forcedVentilationCableSpecification.text.useTextKey == false" style="margin-left:10px">{{forcedVentilationCableSpecification.text.textUI}}</span>
                                        <span v-if="forcedVentilationCableSpecification.text.useTextKey == true" style="margin-left:10px">{{$t(forcedVentilationCableSpecification.text.textKey)}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3466')}}:</div>
                        </div>
                        <div v-if="forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length==0 || forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length>4">
                            <select v-model="selectedForcedVentilationCableNumberOfWiresAndCrossSection" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedForcedVentilationCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedForcedVentilationCableNumberOfWiresAndCrossSection!=null}">
                                <option value="null" hidden>{{$t('3174')}}</option>
                                <option value="" hidden>{{$t('3174')}}</option>
                                <option v-for="forcedVentilationCableNumberOfWiresAndCrossSection in forcedVentilationCableDeliverSelection" v-bind:value="forcedVentilationCableNumberOfWiresAndCrossSection">
                                    {{forcedVentilationCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(forcedVentilationCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                </option>
                            </select>
                        </div>
                        <div v-if="forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length>0 && motorCableNumberOfWiresAndCrossSectionSelection.length<=4">
                            <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length >0 && selectedForcedVentilationCableNumberOfWiresAndCrossSection==null, my_input_background_color_at_validInput: forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length == 0 ||selectedForcedVentilationCableNumberOfWiresAndCrossSection!=null}">
                                <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                    <li v-for="forcedVentilationCableNumberOfWiresAndCrossSection in forcedVentilationCableNumberOfWiresAndCrossSectionSelection">
                                        <input type="checkbox" @input="change_Selected_atElementOf_forcedVentilationCableNumberOfWiresAndCrossSection(forcedVentilationCableNumberOfWiresAndCrossSection)" v-bind:checked="forcedVentilationCableNumberOfWiresAndCrossSection.selected" v-bind:disabled="forcedVentilationCableNumberOfWiresAndCrossSection.selected" />
                                        {{forcedVentilationCableNumberOfWiresAndCrossSection.numberOfWires}} x {{ValueDecimalSeparator(forcedVentilationCableNumberOfWiresAndCrossSection.crossSection)}} mm²
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                        <div>
                            <div class="ZAfont_family ZAfont_size">{{$t('3427')}}:</div>
                        </div>
                        <div v-if="forcedVentilationCableBlockNumber!=null">
                            <span>{{forcedVentilationCableBlockNumber}}</span>
                            <span v-if="forcedVentilationCableTypeDesignation!=null">
                                <span> | </span>
                                <span v-if="forcedVentilationCableTypeDesignation.useTextKey==true&&forcedVentilationCableTypeDesignation.textKey!=null">{{$t(forcedVentilationCableTypeDesignation.textKey)}}</span>
                                <span v-if="forcedVentilationCableTypeDesignation.useTextKey==false||forcedVentilationCableTypeDesignation.textKey==null">{{$t(forcedVentilationCableTypeDesignation.textUI)}}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="my_btn_nextConfigurationStep_position">
                <button v-if="plusManual==true" @click="backTo()" class="btn my_btn_nextConfigurationStep" style="margin-right:5px">{{$t('3237')}}</button>
                <button v-if="plusManual==false" @click="furtherToManualHome()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('3366')}}</button>
                <button v-if="plusManual==false" @click="furtherToDriveTechnologiesManual()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('1018')}}</button>
                <button @click="furtherTo()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import router from '../../../router';
    import Images from '../../configuration/Images';
    export default {
        name: 'Cables',
        components: {
            Images,
        },
        data() {
            return {
                cablesModalShown: false,
                cableHeadline: '',
                imagePath_Cable: '',
                imagePath_Cable2: '',
                imagePath_EncoderConnectionCable: '/Leitung/L-GA.jpg',
            }
        },
        created() {
            if (this.$store.state.configurations.plusManual == true && this.$store.state.inputsManual.configurationStepsManualId == null) {
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', true);
                this.$store.dispatch('cables/get_cableInformationsManual_storeAction');
            }
            else if (this.$store.state.configurations.plusManual == true && this.$store.state.inputsManual.configurationStepsManualId != null) {
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', true);
                this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', false);

                if (this.$store.state.inputsManual.configurationStepsManualId == null) {
                    router.push({ params: { slug: 'inputsManual' } })
                }
                else {
                    this.$store.dispatch('cables/get_cableInformationsManual_storeAction');
                }
            }
            else {
                if (this.$store.state.inputsManual.configurationStepsManualId == null) {
                    router.push({ params: { slug: 'inputsManual' } })
                }
                else {
                    if (this.$store.state.configurations.MANUAL == false) {
                        this.$store.commit('configurations/MANU_configuration', true);
                        this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
                    }
                    this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false)
                    this.$store.dispatch('cables/get_cableInformationsManual_storeAction');
                }
            }
        },
        computed: {
            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                }
            },

            // encoder connection
            encoderConnectionInformation: {
                get() {
                    if (this.$store.state.cables.encoderConnectionInformation != null && this.$store.state.cables.encoderConnectionInformation.hasOwnProperty('necessary') && this.$store.state.cables.encoderConnectionInformation.hasOwnProperty('details')) {
                        if (this.$store.state.cables.encoderConnectionInformation.necessary == true) {
                            return this.$store.state.cables.encoderConnectionInformation.details;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
            },

            selectedEncoderConnection: {
                get() {
                    if (this.$store.state.cables.encoderConnectionInformation != null && this.$store.state.cables.encoderConnectionInformation.hasOwnProperty('details') && Array.isArray(this.$store.state.cables.encoderConnectionInformation.details)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderConnectionInformation.details.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderConnectionInformation.details[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_encoderConnectionCableBlockNumber(value);
                }
            },
            // encoder data
            encoderCableDeliverSelection: {
                get() {
                    return this.$store.state.cables.encoderCableDeliverSelection;
                },
            },
            selectedEncoderCableDeliver: {
                get() {
                    if (this.$store.state.cables.encoderCableDeliverSelection != null && Array.isArray(this.$store.state.cables.encoderCableDeliverSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableDeliverSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableDeliverSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },

                set(value) {
                    this.change_Selected_atElementOf_encoderCableDeliver(value);
                }
            },
            encoderCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.encoderCableDeliverSelection != null && Array.isArray(this.$store.state.cables.encoderCableDeliverSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableDeliverSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableDeliverSelection[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            encoderCableLengthSelection: {
                get() {
                    return this.$store.state.cables.encoderCableLengthSelection;
                },
            },
            selectedEncoderCableLength: {
                get() {
                    if (this.$store.state.cables.encoderCableLengthSelection != null && Array.isArray(this.$store.state.cables.encoderCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_encoderCableLength(value);
                }
            },
            encoderCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.encoderCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedEncoderCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.encoderCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.encoderCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_encoderCableTypeOfConnectionOnComponentSide(value);
                }
            },
            encoderCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.encoderCableSpecificationSelection;
                },
            },
            selectedEncoderCableSpecification: {
                get() {
                    if (this.$store.state.cables.encoderCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.encoderCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_encoderCableSpecification(value);
                }
            },
            encoderCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.encoderCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedEncoderCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.encoderCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.encoderCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.encoderCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.encoderCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_encoderCableNumberOfWiresAndCrossSection(value);
                }
            },
            encoderCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.encoderCableBlockNumber != null && this.$store.state.cables.encoderCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.encoderCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            encoderCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.encoderCableBlockNumber != null && this.$store.state.cables.encoderCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.encoderCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },

            // motor data
            motorCableDeliverSelection: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorCable_DeliverOption_Valuelist')) {
                        return this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedMotorCableDeliver: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorCableDeliver(value);
                }
            },
            motorCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            motorCableLengthSelection: {
                get() {
                    return this.$store.state.cables.motorCableLengthSelection;
                },
            },
            selectedMotorCableLength: {
                get() {
                    if (this.$store.state.cables.motorCableLengthSelection != null && Array.isArray(this.$store.state.cables.motorCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorCableLength(value);
                }
            },
            motorCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.motorCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedMotorCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.motorCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.motorCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorCableTypeOfConnectionOnComponentSide(value);
                }
            },
            motorCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.motorCableSpecificationSelection;
                },
            },
            selectedMotorCableSpecification: {
                get() {
                    if (this.$store.state.cables.motorCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.motorCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorCableSpecification(value);
                }
            },
            motorCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.motorCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedMotorCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.motorCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.motorCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorCableNumberOfWiresAndCrossSection(value);
                }
            },
            motorCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.motorCableBlockNumber != null && this.$store.state.cables.motorCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.motorCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            motorCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.motorCableBlockNumber != null && this.$store.state.cables.motorCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.motorCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },

            // motor protection data
            motorProtectionCableDeliverSelection: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorProtectionCable_DeliverOption_Valuelist')) {
                        return this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedMotorProtectionCableDeliver: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorProtectionCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorProtectionCableDeliver(value);
                }
            },
            motorProtectionCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('motorProtectionCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            motorProtectionCableLengthSelection: {
                get() {
                    return this.$store.state.cables.motorProtectionCableLengthSelection;
                },
            },
            selectedMotorProtectionCableLength: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableLengthSelection != null && Array.isArray(this.$store.state.cables.motorProtectionCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorProtectionCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorProtectionCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorProtectionCableLength(value);
                }
            },
            motorProtectionCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.motorProtectionCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedMotorProtectionCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.motorProtectionCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorProtectionCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorProtectionCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorProtectionCableTypeOfConnectionOnComponentSide(value);
                }
            },
            motorProtectionCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.motorProtectionCableSpecificationSelection;
                },
            },
            selectedMotorProtectionCableSpecification: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.motorProtectionCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorProtectionCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorProtectionCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorProtectionCableSpecification(value);
                }
            },
            motorProtectionCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.motorProtectionCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedMotorProtectionCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.motorProtectionCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.motorProtectionCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.motorProtectionCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_motorProtectionCableNumberOfWiresAndCrossSection(value);
                }
            },
            motorProtectionCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableBlockNumber != null && this.$store.state.cables.motorProtectionCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.motorProtectionCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            motorProtectionCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.motorProtectionCableBlockNumber != null && this.$store.state.cables.motorProtectionCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.motorProtectionCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },

            // brake data
            brakeCableDeliverSelection: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeCable_DeliverOption_Valuelist')) {
                        return this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedBrakeCableDeliver: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeCableDeliver(value);
                }
            },
            brakeCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            brakeCableLengthSelection: {
                get() {
                    return this.$store.state.cables.brakeCableLengthSelection;
                },
            },
            selectedBrakeCableLength: {
                get() {
                    if (this.$store.state.cables.brakeCableLengthSelection != null && Array.isArray(this.$store.state.cables.brakeCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeCableLength(value);
                }
            },
            brakeCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.brakeCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedBrakeCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.brakeCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.brakeCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeCableTypeOfConnectionOnComponentSide(value);
                }
            },
            brakeCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.brakeCableSpecificationSelection;
                },
            },
            selectedBrakeCableSpecification: {
                get() {
                    if (this.$store.state.cables.brakeCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.brakeCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeCableSpecification(value);
                }
            },
            brakeCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.brakeCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedBrakeCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.brakeCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.brakeCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeCableNumberOfWiresAndCrossSection(value);
                }
            },
            brakeCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.brakeCableBlockNumber != null && this.$store.state.cables.brakeCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.brakeCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            brakeCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.brakeCableBlockNumber != null && this.$store.state.cables.brakeCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.brakeCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },
            babl: {
                get() {
                    return this.$store.state.cables.babl;
                },
            },

            // brake monitoring data
            brakeMonitoringCableDeliverSelection: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeMonitoringCable_DeliverOption_Valuelist')) {
                        return this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedBrakeMonitoringCableDeliver: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeMonitoringCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeMonitoringCableDeliver(value);
                }

            },
            brakeMonitoringCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('brakeMonitoringCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            brakeMonitoringCableLengthSelection: {
                get() {
                    return this.$store.state.cables.brakeMonitoringCableLengthSelection;
                },
            },
            selectedBrakeMonitoringCableLength: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableLengthSelection != null && Array.isArray(this.$store.state.cables.brakeMonitoringCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeMonitoringCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeMonitoringCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeMonitoringCableLength(value);
                }
            },
            brakeMonitoringCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedBrakeMonitoringCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeMonitoringCableTypeOfConnectionOnComponentSide(value);
                }
            },
            brakeMonitoringCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.brakeMonitoringCableSpecificationSelection;
                },
            },
            selectedBrakeMonitoringCableSpecification: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.brakeMonitoringCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeMonitoringCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeMonitoringCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeMonitoringCableSpecification(value);
                }
            },
            brakeMonitoringCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedBrakeMonitoringCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_brakeMonitoringCableNumberOfWiresAndCrossSection(value);
                }
            },
            brakeMonitoringCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableBlockNumber != null && this.$store.state.cables.brakeMonitoringCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.brakeMonitoringCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            brakeMonitoringCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.brakeMonitoringCableBlockNumber != null && this.$store.state.cables.brakeMonitoringCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.brakeMonitoringCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },
            // forced ventilation cable
            forcedVentilationCableDeliverSelection: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('forcedVentilationCable_DeliverOption_Valuelist')) {
                        return this.$store.state.cables.cables_Data.forcedVentilationCable_DeliverOption_Valuelist;
                    }
                    else {
                        return [];
                    }
                },
            },
            selectedForcedVentilationCableDeliver: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('forcedVentilationCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilationCableDeliver(value);
                }
            },
            forcedVentilationCablesShowDetails: {
                get() {
                    if (this.$store.state.cables.cables_Data != null && this.$store.state.cables.cables_Data.hasOwnProperty('forcedVentilationCable_DeliverOption_Valuelist') && Array.isArray(this.$store.state.cables.cables_Data.motorCable_DeliverOption_Valuelist)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails;
                        }
                    }
                    return false;
                },
            },
            forcedVentilationCableLengthSelection: {
                get() {
                    return this.$store.state.cables.forcedVentilationCableLengthSelection;
                },
            },
            selectedForcedVentilationCableLength: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableLengthSelection != null && Array.isArray(this.$store.state.cables.forcedVentilationCableLengthSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.forcedVentilationCableLengthSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.forcedVentilationCableLengthSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilationCableLength(value);
                }
            },
            forcedVentilationCableTypeOfConnectionOnComponentSideSelection: {
                get() {
                    return this.$store.state.cables.forcedVentilationCableTypeOfConnectionOnComponentSideSelection;
                },
            },
            selectedForcedVentilationCableTypeOfConnectionOnComponentSide: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableTypeOfConnectionOnComponentSideSelection != null && Array.isArray(this.$store.state.cables.forcedVentilationCableTypeOfConnectionOnComponentSideSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilationCableTypeOfConnectionOnComponentSide(value);
                }
            },
            forcedVentilationCableSpecificationSelection: {
                get() {
                    return this.$store.state.cables.forcedVentilationCableSpecificationSelection;
                },
            },
            selectedForcedVentilationCableSpecification: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableSpecificationSelection != null && Array.isArray(this.$store.state.cables.forcedVentilationCableSpecificationSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.forcedVentilationCableSpecificationSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.forcedVentilationCableSpecificationSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilationCableSpecification(value);
                }
            },
            forcedVentilationCableNumberOfWiresAndCrossSectionSelection: {
                get() {
                    return this.$store.state.cables.forcedVentilationCableNumberOfWiresAndCrossSectionSelection;
                },
            },
            selectedForcedVentilationCableNumberOfWiresAndCrossSection: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableNumberOfWiresAndCrossSectionSelection != null && Array.isArray(this.$store.state.cables.forcedVentilationCableNumberOfWiresAndCrossSectionSelection)) {
                        const firstIndex_whichMeetConditions = this.$store.state.cables.forcedVentilationCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                        if (firstIndex_whichMeetConditions > -1) {
                            return this.$store.state.cables.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions];
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        return null;
                    }
                },
                set(value) {
                    this.change_Selected_atElementOf_forcedVentilationCableNumberOfWiresAndCrossSection(value);
                }
            },
            forcedVentilationCableBlockNumber: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableBlockNumber != null && this.$store.state.cables.forcedVentilationCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.forcedVentilationCableBlockNumber.blockNumber;
                    }
                    else {
                        return null;
                    }
                },
            },
            forcedVentilationCableTypeDesignation: {
                get() {
                    if (this.$store.state.cables.forcedVentilationCableBlockNumber != null && this.$store.state.cables.forcedVentilationCableBlockNumber.hasOwnProperty('blockNumber')) {
                        return this.$store.state.cables.forcedVentilationCableBlockNumber.typeDesignation;
                    }
                    else {
                        return null;
                    }
                },
            },

        },
        methods: {
            ValueDecimalSeparator(value) {
                if (value != null) {
                    const localDecimal = { value: value, country: this.$store.state.configurations.language, }
                    return this.$get_decimalValueToLocaleString(localDecimal);
                }
                return value;
            },

            change_Selected_atElementOf_encoderCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderCableDeliver_storeAction', value);
            },
            change_Selected_atElementOf_motorCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorCableDeliver_storeAction', value);
            },
            change_Selected_atElementOf_motorProtectionCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorProtectionCableDeliver_storeAction', value);
            },
            change_Selected_atElementOf_brakeCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeCableDeliver_storeAction', value);
            },
            change_Selected_atElementOf_brakeMonitoringCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeMonitoringCableDeliver_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilationCableDeliver(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_forcedVentilationCableDeliver_storeAction', value);
            },

            change_Selected_atElementOf_encoderConnectionLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderConnectionLength_storeAction', value);
            },
            change_Selected_atElementOf_encoderConnectionCableBlockNumber(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderConnectionCableBlockNumber_storeAction', value);
            },
            change_Selected_atElementOf_encoderCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderCableLength_storeAction', value);
            },
            change_Selected_atElementOf_motorCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorCableLength_storeAction', value);
            },
            change_Selected_atElementOf_motorProtectionCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorProtectionCableLength_storeAction', value);
            },
            change_Selected_atElementOf_brakeCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeCableLength_storeAction', value);
            },
            change_Selected_atElementOf_brakeMonitoringCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeMonitoringCableLength_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilationCableLength(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_forcedVentilationCableLength_storeAction', value);
            },

            change_Selected_atElementOf_encoderConnectionTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderConnectionTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_encoderCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderCableTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_motorCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorCableTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_motorProtectionCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorProtectionCableTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_brakeCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeCableTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_brakeMonitoringCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilationCableTypeOfConnectionOnComponentSide(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeAction', value);
            },

            change_Selected_atElementOf_encoderCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderCableSpecification_storeAction', value);
            },
            change_Selected_atElementOf_motorCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorCableSpecification_storeAction', value);
            },
            change_Selected_atElementOf_motorProtectionCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorProtectionCableSpecification_storeAction', value);
            },
            change_Selected_atElementOf_brakeCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeCableSpecification_storeAction', value);
            },
            change_Selected_atElementOf_brakeMonitoringCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeMonitoringCableSpecification_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilationCableSpecification(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_forcedVentilationCableSpecification_storeAction', value);
            },

            change_Selected_atElementOf_encoderCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_encoderCableNumberOfWiresAndCrossSection_storeAction', value);
            },
            change_Selected_atElementOf_motorCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorCableNumberOfWiresAndCrossSection_storeAction', value);
            },
            change_Selected_atElementOf_motorProtectionCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_motorProtectionCableNumberOfWiresAndCrossSection_storeAction', value);
            },
            change_Selected_atElementOf_brakeCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeCableNumberOfWiresAndCrossSection_storeAction', value);
            },
            change_Selected_atElementOf_brakeMonitoringCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeAction', value);
            },
            change_Selected_atElementOf_forcedVentilationCableNumberOfWiresAndCrossSection(value) {
                this.$store.dispatch('cables/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('cables/change_forcedVentilationCableNumberOfWiresAndCrossSection_storeAction', value);
            },

            furtherTo() {
                this.$store.dispatch('cables/checkIf_cablesManual_areValid_storeAction');
            },
            backTo() {
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);
                router.push('../manualStep/ManualHome');
            },
            furtherToDriveTechnologiesManual() {
                router.push({ params: { slug: 'driveTechnologiesManual' } })
            },
            furtherToManualHome() {
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },
        },
    }
</script>

<style>
    .my_inputCheckbox2 {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox2 li {
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

    .my_inputCheckbox {
        border: 1px solid #ced2d8;
        list-style: none;
        padding-top: 5px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: 0;
    }

        .my_inputCheckbox li {
            display: inline-block;
            padding: 3px;
            /*margin-bottom: 3px;*/
        }

            .my_inputCheckbox li:nth-child(odd) {
                margin-right: 10px;
                width: calc(50% - 10px);
            }

            .my_inputCheckbox li:nth-child(even) {
                margin-left: 10px;
                width: calc(50% - 10px);
            }
</style>
