import axios from 'axios';
import router from '../router';

let state = {
    quantity: { value: undefined, invalid: undefined },
    quantityTklem: { value: undefined, invalid: undefined },
    quantityRuev: { value: undefined, invalid: undefined },
    quantityTklemDisabled: true,
    quantityRuevDisabled: true,
    quantityTklemPermissibleValues: {},
    quantityRuevPermissibleValues: {},
    packageId: undefined,
    machineWeight: undefined,
    packageStandard: undefined,
    packageSeaFreigth: undefined,
    collectivePackaging: undefined,

    packageInfo1Selection: [],
    packageInfo2Selection: [],
    packageInfo3Selection: [],
    selectedPackageInfo1: null,
    selectedPackageInfo2: null,
    selectedPackageInfo3: null,

}

const actions = {
    change_packagingMotor_toDefault_storeAction({ commit, dispatch }) {
        commit('change_quantity_storeMutation', { value: undefined, invalid: undefined });
        commit('change_quantityTklem_storeMutation', { value: undefined, invalid: undefined });
        commit('change_quantityRuev_storeMutation', { value: undefined, invalid: undefined });
        commit('change_quantityTklemDisabled_storeMutation', true);
        commit('change_quantityRuevDisabled_storeMutation', true);
        commit('change_quantityTklemPermissibleValue_storeMutation', {});
        commit('change_quantityRuevPermissibleValue_storeMutation', {});
        commit('change_packageId_storeMutation', null);
        commit('change_machineWeight_storeMutation', null);
        commit('change_packageStandard_storeMutation', null);
        commit('change_packageSeaFreigth_storeMutation', null);
        commit('change_collectivePackaging_storeMutation', null);

        commit('change_packageInfo1Selection_storeMutation', []);
        commit('change_packageInfo2Selection_storeMutation', []);
        commit('change_packageInfo3Selection_storeMutation', []);
        commit('change_selectedPackageInfo1_storeMutation', null);
        commit('change_selectedPackageInfo2_storeMutation', null);
        commit('change_selectedPackageInfo3_storeMutation', null);

    },

    async get_packagingInformation_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const data = { configurationStepsManualId: rootState.inputsManual.configurationStepsManualId };

        await axios({
            method: 'post',
            url: '/api/packagingMotorManual/get_packagingInformations',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                    commit('change_quantityTklemDisabled_storeMutation', response.data.data.quantityTklemDisabled);
                    commit('change_quantityRuevDisabled_storeMutation', response.data.data.quantityRuevDisabled);
                    commit('change_quantityTklemPermissibleValue_storeMutation', response.data.data.quantityTklemPermissibleValueRange);
                    commit('change_quantityRuevPermissibleValue_storeMutation', response.data.data.quantityRuevPermissibleValueRange);

                    dispatch('change_quantityTklem_storeAction', response.data.data.quantityTklem);
                    dispatch('change_quantityRuev_storeAction', response.data.data.quantityRuev);

                    dispatch('change_quantity_storeAction', response.data.data.quantity);
                    commit('change_packageId_storeMutation', response.data.data.packageId);
                    commit('change_machineWeight_storeMutation', response.data.data.machineWeight);
                    commit('change_packageStandard_storeMutation', response.data.data.packageStandardWeight);
                    commit('change_packageSeaFreigth_storeMutation', response.data.data.packageSeaFreigthWeight);
                    commit('change_collectivePackaging_storeMutation', response.data.data.collectivePackaging);

                    commit('change_packageInfo1Selection_storeMutation', response.data.data.packageInformation1Selection);
                    const firstIndex_whichMeetConditions = state.packageInfo1Selection.findIndex(i => (i.selected == true));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_selectedPackageInfo1_storeAction', state.packageInfo1Selection[firstIndex_whichMeetConditions]);
                    }
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })


    },
    async get_packagingInfo2Selection_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
            quantity: state.quantity.value,
            collectivePackaging: state.collectivePackaging,
        }

        await axios({
            method: 'post',
            url: '/api/packagingMotorManual/get_packagingInfo2Selection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_packageInfo2Selection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.packageInfo2Selection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_selectedPackageInfo2_storeAction', state.packageInfo2Selection[firstIndex_whichMeetConditions]);
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })


    },
    async get_packagingInfo3Selection_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
            quantity: state.quantity.value,
            selectedPackageInfo1: state.selectedPackageInfo1,
            selectedPackageInfo2: state.selectedPackageInfo2,
        }
        await axios({
            method: 'post',
            url: '/api/packagingMotorManual/get_packagingInfo3Selection',
            data: {
                data: data,
            }
        })
            .then((response) => {
                commit('change_packageInfo3Selection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.packageInfo3Selection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_selectedPackageInfo3_storeAction', state.packageInfo3Selection[firstIndex_whichMeetConditions]);
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })


    },
    checkIf_packagingInfo2Selection_storeAction({ dispatch }) {
        if (state.quantity.value != null && state.collectivePackaging != null) {
            dispatch('get_packagingInfo2Selection_storeAction')
        }
    },
    checkIf_packagingInfo3Selection_storeAction({ dispatch }) {
        if (state.selectedPackageInfo1 != null && state.selectedPackageInfo2 != null && state.quantity.value != null) {
            dispatch('get_packagingInfo3Selection_storeAction')
        }
    },

    change_quantity_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
        valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
        valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        const quantity_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        commit('change_quantity_storeMutation', quantity_ValidationResult);

        dispatch('checkIf_packagingInfo2Selection_storeAction')
    },
    change_quantityTklem_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        if (state.quantityTklemPermissibleValues != null && state.quantityTklemPermissibleValues.hasOwnProperty('minimum')
            && state.quantityTklemPermissibleValues.minimum.operator != null && state.quantityTklemPermissibleValues.minimum.value != null) {
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.quantityTklemPermissibleValues.minimum.value;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.quantityTklemPermissibleValues.maximum.value;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        }
        else {
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        }
        const quantity_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        commit('change_quantityTklem_storeMutation', quantity_ValidationResult);

    },
    change_quantityRuev_storeAction({ commit, dispatch }, value) {
        const valueAndValueValidationCriteria = this._vm.$transmit_valueAndValueValidationCriteria;
        valueAndValueValidationCriteria.value = value;
        valueAndValueValidationCriteria.dataType = this._vm.$dataTypes.integer;
        valueAndValueValidationCriteria.permissibleNumberOfDecimalPlaces = null;
        if (state.quantityRuevPermissibleValues != null && state.quantityRuevPermissibleValues.hasOwnProperty('minimum')
            && state.quantityRuevPermissibleValues.minimum.operator != null && state.quantityRuevPermissibleValues.minimum.value != null) {
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = state.quantityRuevPermissibleValues.minimum.value;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = state.quantityRuevPermissibleValues.maximum.value;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = this._vm.$operators.lessThanOrEqualTo;
        }
        else {
            valueAndValueValidationCriteria.permissibleValueRange.minimum.value = 0;
            valueAndValueValidationCriteria.permissibleValueRange.minimum.operator = this._vm.$operators.greaterThanOrEqualTo;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.value = null;
            valueAndValueValidationCriteria.permissibleValueRange.maximum.operator = null;
        }

        const quantity_ValidationResult = this._vm.$checkIf_value_isValid(valueAndValueValidationCriteria);
        commit('change_quantityRuev_storeMutation', quantity_ValidationResult);

    },
    change_packageId_storeAction({ commit }, value) {
        commit('change_packageId_storeMutation', value);
    },
    change_machineWeight_storeAction({ commit }, value) {
        commit('change_machineWeight_storeMutation', value);
    },
    change_packageStandard_storeAction({ commit }, value) {
        commit('change_packageStandard_storeMutation', value);
    },
    change_packageSeaFreigth_storeAction({ commit }, value) {
        commit('change_packageSeaFreigth_storeMutation', value);
    },
    change_collectivePackaging_storeAction({ commit }, value) {
        commit('change_collectivePackaging_storeMutation', value);
    },
    change_selectedPackageInfo1_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_packageInfo1Selected_storeMutation', null);
        }
        else {
            commit('change_packageInfo1Selected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.packageInfo1Selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedPackageInfo1_storeMutation', state.packageInfo1Selection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_selectedPackageInfo1_storeMutation', null)
        }
        dispatch('checkIf_packagingInfo2Selection_storeAction')
    },
    change_selectedPackageInfo2_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_packageInfo2Selected_storeMutation', null);
        }
        else {
            commit('change_packageInfo2Selected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.packageInfo2Selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedPackageInfo2_storeMutation', state.packageInfo2Selection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_selectedPackageInfo2_storeMutation', null)
        }
        dispatch('checkIf_packagingInfo3Selection_storeAction')
    },
    change_selectedPackageInfo3_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_packageInfo3Selected_storeMutation', null);
        }
        else {
            commit('change_packageInfo3Selected_storeMutation', value.text.text);
        }
        const firstIndex_whichMeetConditions = state.packageInfo3Selection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_selectedPackageInfo3_storeMutation', state.packageInfo3Selection[firstIndex_whichMeetConditions].text.text)
        }
        else {
            commit('change_selectedPackageInfo3_storeMutation', null)
        }
    },

    async insertOrUpdate_packagingMotorManual_storeAction({ rootState, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        var packagingMotorManual = {};

        if (state.quantity != null) {
            packagingMotorManual.quantity = state.quantity.value;
        }
        if (state.quantityTklem != null) {
            packagingMotorManual.quantityTklem = state.quantityTklem.value;
        }
        if (state.quantityRuev != null) {
            packagingMotorManual.quantityRuev = state.quantityRuev.value;
        }
        if (state.selectedPackageInfo1 != null) {
            packagingMotorManual.verp1 = state.selectedPackageInfo1;
        }
        if (state.selectedPackageInfo2 != null) {
            packagingMotorManual.verp2 = state.selectedPackageInfo2;
        }
        if (state.selectedPackageInfo3 != null) {
            packagingMotorManual.verp3 = state.selectedPackageInfo3;
        }

        const data = {
            configurationStepsManualId: configurationStepsManualId,
            packagingMotorManual: packagingMotorManual,
        }
        await axios({
            method: 'post',
            url: '/api/configurations/insertOrUpdate_packagingMotorManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (rootState.configurations.plusManual == false) {

                    if (response.data.status.statusCode.substring(0, 1) == '2') {
                        this.commit('configurations/change_elevatorMotor_Manual_storeMutation', false);

                        if (response.data.data.changedDataset == true) {
                            var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                            navigationManual.packagingMotorManual = 'passed';
                            this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                        }
                        router.push({ params: { slug: 'ManualHome' } });
                        this.dispatch('manualHome/change_ZLM_storeAction', response.data.data.zlm);

                    }
                    else {
                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.packagingMotorManual = null;
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                }
            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
                if (rootState.configurations.plusManual == true) {
                    this.dispatch('configurations/change_manualConfiguration_storeAction', false);
                    router.push('../manualStep/ManualHome');
                }
            })
    },
    async delete_configuration_packagingMotorManual_storeAction({ rootState, commit, dispatch }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/delete_configuration_packagingMotorManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.packagingMotorManual = null;
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => { })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })

    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.packagingMotorManual == "passed") {
            dispatch('delete_configuration_packagingMotorManual_storeAction');
        }
    },

    checkIf_packagingMotorManual_areValid_storeAction({ rootState, dispatch }) {
        var numberOf_inputs_whichAreInvalid = 0;

        if (state.quantity == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.selectedPackageInfo1 == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.selectedPackageInfo2 == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.selectedPackageInfo3 == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('insertOrUpdate_packagingMotorManual_storeAction');
        }
    },




}

const mutations = {
    change_quantity_storeMutation(state, payload) {
        state.quantity = payload;
    },
    change_quantityTklem_storeMutation(state, payload) {
        state.quantityTklem = payload;
    },
    change_quantityRuev_storeMutation(state, payload) {
        state.quantityRuev = payload;
    },
    change_quantityTklemDisabled_storeMutation(state, payload) {
        state.quantityTklemDisabled = payload;
    },
    change_quantityRuevDisabled_storeMutation(state, payload) {
        state.quantityRuevDisabled = payload;
    },
    change_quantityTklemPermissibleValue_storeMutation(state, payload) {
        state.quantityTklemPermissibleValues = payload;
    },
    change_quantityRuevPermissibleValue_storeMutation(state, payload) {
        state.quantityRuevPermissibleValues = payload;
    },
    change_packageId_storeMutation(state, payload) {
        state.packageId = payload;
    },
    change_machineWeight_storeMutation(state, payload) {
        state.machineWeight = payload;
    },
    change_packageStandard_storeMutation(state, payload) {
        state.packageStandard = payload;
    },
    change_packageSeaFreigth_storeMutation(state, payload) {
        state.packageSeaFreigth = payload;
    },
    change_collectivePackaging_storeMutation(state, payload) {
        state.collectivePackaging = payload;
    },
    change_packageInfo1Selection_storeMutation(state, payload) {
        state.packageInfo1Selection = payload;
    },
    change_packageInfo2Selection_storeMutation(state, payload) {
        state.packageInfo2Selection = payload;
    },
    change_packageInfo3Selection_storeMutation(state, payload) {
        state.packageInfo3Selection = payload;
    },
    change_packageInfo1Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.packageInfo1Selection.length; i++) {
            state.packageInfo1Selection[i].selected = false;

            if (state.packageInfo1Selection[i].text.text == payload) {
                state.packageInfo1Selection[i].selected = true;
            }
        }
    },
    change_packageInfo2Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.packageInfo2Selection.length; i++) {
            state.packageInfo2Selection[i].selected = false;

            if (state.packageInfo2Selection[i].text.text == payload) {
                state.packageInfo2Selection[i].selected = true;
            }
        }
    },
    change_packageInfo3Selected_storeMutation(state, payload) {
        for (let i = 0; i < state.packageInfo3Selection.length; i++) {
            state.packageInfo3Selection[i].selected = false;

            if (state.packageInfo3Selection[i].text.text == payload) {
                state.packageInfo3Selection[i].selected = true;
            }
        }
    },
    change_selectedPackageInfo1_storeMutation(state, payload) {
        state.selectedPackageInfo1 = payload;
    },
    change_selectedPackageInfo2_storeMutation(state, payload) {
        state.selectedPackageInfo2 = payload;
    },
    change_selectedPackageInfo3_storeMutation(state, payload) {
        state.selectedPackageInfo3 = payload;
    },

}

export const packagingMotorManual = {
    namespaced: true,
    components: {
        axios,
    },
    state,
    actions,
    mutations
}