<template>
    <div style="overflow-x:auto !important">
        <div>
            <div style="overflow:auto">
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('3550')}}:</div>
                    </div>
                    <div>
                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="quantity" v-bind:class="{my_input_border_at_invalidInput: quantity_ValidationResult.invalid==true, my_input_background_color_at_validInput:quantity_ValidationResult.invalid==false}" />
                        <InvalidityReasons v-if="quantity_ValidationResult.invalid==true" v-bind:validationResult="quantity_ValidationResult"></InvalidityReasons>
                    </div>
                </div>
                <div v-if="quantityTklemDisabled!=null&&quantityTklemDisabled==false" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('1327')}} {{$t('3543')}}:</div>
                    </div>
                    <div>
                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="quantityTklem" v-bind:class="{my_input_border_at_invalidInput: quantityTklem_ValidationResult.invalid==true, my_input_background_color_at_validInput:quantityTklem_ValidationResult.invalid==false}" />
                        <InvalidityReasons v-if="quantityTklem_ValidationResult.invalid==true" v-bind:validationResult="quantityTklem_ValidationResult"></InvalidityReasons>
                    </div>
                </div>
                <div v-if="quantityRuevDisabled!=null&&quantityRuevDisabled==false" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">{{$t('1327')}} {{$t('3586')}}:</div>
                    </div>
                    <div>
                        <input class="form-control" v-bind:placeholder="this.$i18n.i18next.t('3173')" v-model="quantityRuev" v-bind:class="{my_input_border_at_invalidInput: quantityRuev_ValidationResult.invalid==true, my_input_background_color_at_validInput:quantityRuev_ValidationResult.invalid==false}" />
                        <InvalidityReasons v-if="quantityRuev_ValidationResult.invalid==true" v-bind:validationResult="quantityRuev_ValidationResult"></InvalidityReasons>
                    </div>
                </div>
                <!--<div v-if="packageId!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>ID:</div>
                    <div>{{packageId}}</div>
                </div>
                <div v-if="machineWeight!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>Antriebs-Gewicht:</div>
                    <div>{{machineWeight}} kg</div>
                </div>
                <div v-if="packageStandard!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>Standard:</div>
                    <div>{{packageStandard}} kg</div>
                </div>
                <div v-if="packageSeaFreigth!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>Seefracht:</div>
                    <div>{{packageSeaFreigth}} kg</div>
                </div>
                <div v-if="collectivePackaging!=null" class="ZAfont_family ZAfont_size" style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>Sammelverpackung m�glich?:</div>
                    <div>{{collectivePackaging}}</div>
                </div>-->
                <hr />
            </div>
            <div style="overflow:auto">
                <div class="my_color_00338e my_fontWeight_600 my_fontSize_1Point5 ZAfont_family">Verpackungsinfo proA-VML</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 30px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">Info 1:</div>
                    </div>
                    <div v-if="packageInfo1Selection.length==0 || packageInfo1Selection.length>4">
                        <select v-model="selectedPackageInfo1" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo1==null, my_input_background_color_at_validInput:selectedPackageInfo1!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="packageInfo in packageInfo1Selection" v-bind:value="packageInfo">
                                <span v-if="packageInfo.text.useTextKey == false">{{packageInfo.text.textUI}}</span>
                                <span v-if="packageInfo.text.useTextKey == true">{{$t(packageInfo.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="packageInfo1Selection.length>0 && packageInfo1Selection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo1==null, my_input_background_color_at_validInput:selectedPackageInfo1!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="packageInfo in packageInfo1Selection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_packageInfo1(packageInfo)" v-bind:checked="packageInfo.selected" v-bind:disabled="packageInfo.selected" />
                                    <span style="margin-left:10px">{{packageInfo.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">Info 2:</div>
                    </div>
                    <div v-if="packageInfo2Selection.length==0 || packageInfo2Selection.length>4">
                        <select v-model="selectedPackageInfo2" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo2==null, my_input_background_color_at_validInput:selectedPackageInfo2!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="packageInfo in packageInfo1Selection" v-bind:value="packageInfo">
                                <span v-if="packageInfo.text.useTextKey == false">{{packageInfo.text.textUI}}</span>
                                <span v-if="packageInfo.text.useTextKey == true">{{$t(packageInfo.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="packageInfo2Selection.length>0 && packageInfo2Selection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo2==null, my_input_background_color_at_validInput:selectedPackageInfo2!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="packageInfo in packageInfo2Selection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_packageInfo2(packageInfo)" v-bind:checked="packageInfo.selected" v-bind:disabled="packageInfo.selected" />
                                    <span style="margin-left:10px">{{packageInfo.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px; margin-top: 20px">
                    <div>
                        <div class="ZAfont_family ZAfont_size">Info 3:</div>
                    </div>
                    <div v-if="packageInfo3Selection.length==0 || packageInfo3Selection.length>4">
                        <select v-model="selectedPackageInfo3" class="my_select" style="width:100%" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo3==null, my_input_background_color_at_validInput:selectedPackageInfo3!=null}">
                            <option value="null" hidden>{{$t('3174')}}</option>
                            <option value="" hidden>{{$t('3174')}}</option>
                            <option v-for="packageInfo in packageInfo3Selection" v-bind:value="packageInfo">
                                <span v-if="packageInfo.text.useTextKey == false">{{packageInfo.text.textUI}}</span>
                                <span v-if="packageInfo.text.useTextKey == true">{{$t(packageInfo.text.textKey)}}</span>
                            </option>
                        </select>
                    </div>
                    <div v-if="packageInfo3Selection.length>0 && packageInfo3Selection.length<=4">
                        <div style="display: grid;" v-bind:class="{my_input_border_at_invalidInput: selectedPackageInfo3==null, my_input_background_color_at_validInput:selectedPackageInfo3!=null}">
                            <ul class="my_inputCheckbox ZAfont_family ZAfont_size">
                                <li v-for="packageInfo in packageInfo3Selection">
                                    <input type="checkbox" @input="change_Selected_atElementOf_packageInfo3(packageInfo)" v-bind:checked="packageInfo.selected" v-bind:disabled="packageInfo.selected" />
                                    <span style="margin-left:10px">{{packageInfo.text.textUI}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <hr />
            </div>
        </div>
        <div class="my_btn_nextConfigurationStep_position">
            <button @click="furtherToManualHome_Cancel()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('3366')}}</button>
            <button v-if="plusManual==false" @click="furtherToSupplies()" class="btn my_btn_nextConfigurationStep" style="margin-right: 5px">{{$t('1018')}}</button>
            <button @click="furtherToManualHome()" class="btn my_btn_nextConfigurationStep">{{$t('1023')}}</button>
        </div>
    </div>
</template>


<script>
    import router from '../../../router';
    import LoaderIcon from '../../loader/LoaderIcon';
    import InvalidInputs from '../../configuration/InvalidInputs'
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Images from '../../configuration/Images';
    import { packagingMotorManual } from '../../../store/packagingMotorManual.module';

    export default {
        name: 'PackagingMotorManual',

        components: {
            InvalidityReasons,
            LoaderIcon,
        },
        created() {
            if (this.$store.state.configurations.plusManual == true) {
                this.$store.dispatch('configurations/change_manualConfiguration_storeAction', true);
                this.$store.dispatch('packagingMotorManual/get_packagingInformation_storeAction');
            }
            else {
                if (this.$store.state.inputsManual.configurationStepsManualId == null) {
                    router.push({ params: { slug: 'inputsManual' } })
                }
                else {
                    this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);
                    if (this.$store.state.configurations.MANUAL == false) {
                        this.$store.commit('configurations/MANU_configuration', true);
                        this.$store.commit('configurations/change_elevatorMotor_Manual_storeMutation', true);
                    }
                    this.$store.dispatch('packagingMotorManual/get_packagingInformation_storeAction')
                }
            }
        },
        computed: {
            plusManual: {
                get() {
                    return this.$store.state.configurations.plusManual;
                }
            },
            quantity: {
                get() {
                    return this.$store.state.packagingMotorManual.quantity.value;
                },
                set(value) {
                    this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('packagingMotorManual/change_quantity_storeAction', value)
                }
            },
            quantity_ValidationResult: {
                get() {
                    return this.$store.state.packagingMotorManual.quantity;
                },
            },
            quantityTklemDisabled: {
                get() {
                   return this.$store.state.packagingMotorManual.quantityTklemDisabled;
                },
            },
            quantityTklem: {
                get() {
                    return this.$store.state.packagingMotorManual.quantityTklem.value;
                },
                set(value) {
                    this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('packagingMotorManual/change_quantityTklem_storeAction', value)
                }
            },
            quantityTklem_ValidationResult: {
                get() {
                    return this.$store.state.packagingMotorManual.quantityTklem;
                },
            },
            quantityRuevDisabled: {
                get() {
                    return this.$store.state.packagingMotorManual.quantityRuevDisabled;
                },
            },
            quantityRuev: {
                get() {
                    return this.$store.state.packagingMotorManual.quantityRuev.value;
                },
                set(value) {
                    this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                    this.$store.dispatch('packagingMotorManual/change_quantityRuev_storeAction', value)
                }
            },
            quantityRuev_ValidationResult: {
                get() {
                    return this.$store.state.packagingMotorManual.quantityRuev;
                },
            },
            packageId: {
                get() {
                    return this.$store.state.packagingMotorManual.packageId;
                }
            },
            machineWeight: {
                get() {
                    return this.$store.state.packagingMotorManual.machineWeight;
                }
            },
            packageStandard: {
                get() {
                    return this.$store.state.packagingMotorManual.packageStandard;
                }
            },
            packageSeaFreigth: {
                get() {
                    return this.$store.state.packagingMotorManual.packageSeaFreigth;
                }
            },
            collectivePackaging: {
                get() {
                    return this.$store.state.packagingMotorManual.collectivePackaging;
                }
            },

            packageInfo1Selection: {
                get() {
                    return this.$store.state.packagingMotorManual.packageInfo1Selection;
                },
                set(value) {
                    this.change_Selected_atElementOf_packageInfo1(value);
                }
            },
            selectedPackageInfo1: {
                get() {
                    return this.$store.state.packagingMotorManual.selectedPackageInfo1;
                }
            },
            packageInfo2Selection: {
                get() {
                    return this.$store.state.packagingMotorManual.packageInfo2Selection;
                },
                set(value) {
                    this.change_Selected_atElementOf_packageInfo2(value);
                }
            },
            selectedPackageInfo2: {
                get() {
                    return this.$store.state.packagingMotorManual.selectedPackageInfo2;
                }
            },
            packageInfo3Selection: {
                get() {
                    return this.$store.state.packagingMotorManual.packageInfo3Selection;
                },
                set(value) {
                    this.change_Selected_atElementOf_packageInfo3(value);
                }
            },
            selectedPackageInfo3: {
                get() {
                    return this.$store.state.packagingMotorManual.selectedPackageInfo3;
                }
            },
        },

        methods: {
            change_Selected_atElementOf_packageInfo1(value) {
                this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('packagingMotorManual/change_selectedPackageInfo1_storeAction', value)
            },
            change_Selected_atElementOf_packageInfo2(value) {
                this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('packagingMotorManual/change_selectedPackageInfo2_storeAction', value)
            },
            change_Selected_atElementOf_packageInfo3(value) {
                this.$store.dispatch('packagingMotorManual/checkIf_changesAt_configurationFlow_storeAction');
                this.$store.dispatch('packagingMotorManual/change_selectedPackageInfo3_storeAction', value)
            },
            furtherToManualHome() {
                this.$store.dispatch('packagingMotorManual/checkIf_packagingMotorManual_areValid_storeAction');
            },
            furtherToSupplies() {
                router.push({ params: { slug: 'suppliesMotorManual' } })
            },
            furtherToManualHome_Cancel() {
                this.$store.dispatch('inputsManual/cancelManualMotorConfiguration_storeAction')
            },
        },
    }
</script>


<style>
</style>