<template>
    <!--<button @click="newConfiguration()" class="btn my_btn_standard" style="width:100%">{{$t('3249')}}</button>-->
    <div class="ZAfont_family" style="border: 2px solid #ced2d8; padding: 15px; margin-top:15px">
        <div>
            <button v-if="sapProfile==true" @click="click_supplies()" class="btn my_btn_standard_blue" style="width:100%;margin-bottom:10px">{{$t('3462')}}</button>
        </div>
        <div style="text-align:right; margin-bottom:10px">
            <div class="my_color_00338e">
                <span style="font-size: 1.2em; font-weight:500">{{$t('3332')}}:</span>
                <span style="font-size: 1.8em; font-weight:900"> {{summary.zAlift_ID}}</span>
            </div>
            <div v-if="summary.userNameCreatedCalculation!=''" class="my_color_00338e">
                <span>{{summary.userNameCreatedCalculation}}</span>
            </div>
            <div v-if="loaded_file_ZAlift_ID!=null" class="ZAfont_size">
                <span>{{$t('3312')}}:</span>
                <span> {{loaded_file_ZAlift_ID}}</span>
            </div>
        </div>
        <hr />
        <div>
            <select v-model="fileLanguage" class="my_select" style="width:100%">
                <option value="de-DE">{{$t('1015')}}</option>
                <option value="en-GB">{{$t('1014')}}</option>
                <option value="es-ES">{{$t('1455')}}</option>
                <option value="it-IT">{{$t('1456')}}</option>
                <option value="pl-PL">{{$t('1457')}}</option>
                <option value="fr-FR">{{$t('1458')}}</option>
                <option value="cs-CZ">{{$t('1459')}}</option>
                <option value="ru-RU">{{$t('1460')}}</option>
                <option value="tr-TR">{{$t('1461')}}</option>
                <option value="pt-PT">{{$t('1462')}}</option>
                <option value="zh">{{$t('1463')}}</option>
            </select>
            <button @click="get_htmlFileContent()" class="btn my_btn_standard" style="margin-top:10px; width:100%"> <span class="fa-solid fa-download"></span><span> {{$t('3463')}}</span></button>
        </div>
        <hr />
        <div v-if="showPriceButton==true">
            <div style="margin-top:10px">
                <input type="checkbox" v-model="accept_terms_pricing" id="accept_terms_pricing" /> <label style="font-weight:600; font-size:0.9rem; margin-bottom:10px" class="ZAfont_family">{{$t('3468')}}</label>
            </div>
            <button v-if="showPriceButton==true" :disabled="!accept_terms_pricing" @click="change_showInfo()" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px">{{$t('3467')}}</button>
        </div>
        <hr />
        <div>
            <input type="checkbox" v-model="accept_terms_of_request" id="accept_terms_of_request" /> <label style="font-weight:600; font-size:0.9rem; margin-bottom:10px" class="ZAfont_family" for="accept_terms_of_request">{{$t('3370')}}<a v-if="$t('3369') =='de'" v-b-modal="'modal-accept_terms_of_request'" href="/pdf/de/Nutzungsbedingungen-DE.pdf" target="_blank">Anfragebedingungen</a><a v-else v-b-modal="'modal-accept_terms_of_request'" href="/pdf/en/Nutzungsbedingungen-EN.pdf" target="_blank"><!--href="javascript:void(0)"-->Terms of Request</a> </label>
        </div>
        <div v-if="accept_request_a_quote==true">
            <input ref="inputCust" v-model="inputCust" list="arrayCustomer" :disabled="!accept_terms_of_request" style="margin-top:10px; width:100%" :placeholder="$t('3357')">
            <datalist id="arrayCustomer" list style="margin-top:10px; width:100%">
                <option v-for="(customer , index) in arrayCustomer" v-if="customer[0].value.value != 1  "> {{index}} | {{customer[0].value}} | {{customer[1].value}} | {{customer[2].value}}</option>
            </datalist>
            <!--<button v-if="sapProfile==false" :disabled="!accept_terms_of_request" @click="request_offer_or_post_order('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3201')}} <i class="fas fa-chevron-right"></i></button>-->
            <button v-if="sapProfile==true" :disabled="!accept_terms_of_request" @click="request_offer_or_post_order_sap('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3372')}} <i class="fas fa-chevron-right"></i></button>
            <!--Test Dimmler Angebot-->
            <button v-if="sapProfile==true" :disabled="!accept_terms_of_request" @click="request_Quotation_or_post_Quotation_sap('A')" class="btn my_btn_standard_blue" style="width:100%;margin-top:10px"> {{$t('3371')}} <i class="fas fa-chevron-right"></i></button>
        </div>
        <hr />
        <div>
            <!-- Button to open the modal -->
            <button :disabled="!accept_terms_of_request||configurationNotValid==true" class="btn my_btn_standard_blue" @click="showModal = true">{{$t('3368')}}</button>

            <!-- Modal component -->
            <modal v-if="showModal" @close="closeModal" :zlw="zAlift_ID" :note="note"></modal>
        </div>
    </div>
</template>

<script>
    import router from '../../../router';
    import axios from 'axios';
    import Images from '../../configuration/Images';
    import InvalidityReasons from '../../dataValidation/InvalidityReasons';
    import Modal from './SummaryEmailModal.vue'
    import { configurations } from '../../../store/configurations.module';
    export default {
        name: 'SummaryInformation',
        components: {
            Images,
            InvalidityReasons,
            Modal,
        },
        data() {
            return {
                accept_terms_of_request: false,
                accept_terms_pricing: false,
                arrayCustomer: [],
                inputCust: '',
                arrCust: [],
                showModal: false,
                scrollYPosition: 0,
            }
        },
        watch: {
            async inputCust(val, oldVal) {
                if (val != oldVal && val.length >= 4 && val.length < 20) {
                    await axios({
                        method: 'post',
                        url: '/api/summaries/search_for_customers/' + this.inputCust
                    })
                        .then((response) => {
                            this.arrayCustomer = response.data.map(f => {
                                return [f[1], f[0], f[2]];
                            })
                        })
                } else {
                    if (val.length < 4) {
                        this.arrayCustomer = [];
                    }
                    ////this.$store.dispatch('summary/change_priceInformation_storeAction', {});
                    if (this.$store.state.summary.showInfoButton != null && this.$store.state.summary.showInfoButton == true && this.$store.state.summary.showInfo != null && this.$store.state.summary.showInfo == true && this.inputCust.indexOf("|") > -1) {
                        this.$store.dispatch('summary/get_PriceInformation_storeAction', this.inputCust);
                    }
                }
            }
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                }
            },
            userProfile: {
                get() {
                    return this.$store.state.account.user.profile
                }
            },
            configurationOverviewsId: {
                get() {
                    return this.$store.state.configurations.configurationOverviewsId;
                },
            },
            showPriceButton: {
                get() {
                    return this.$store.state.summary.showInfoButton;
                    //if (this.$store.state.summary.information != null && this.$store.state.summary.information.hasOwnProperty('data') && this.$store.state.summary.information.data != null && this.$store.state.summary.information.data.za_employee != null) {
                    //    return this.$store.state.summary.information.data.za_employee;
                    //}
                    //else {
                    //    return false;
                    //}
                },
            },
            configurationNotValid: {
                get() {
                    if (this.$store.state.configurations.configurationNotValid != null) {
                        return this.$store.state.configurations.configurationNotValid;
                    }
                    else {
                        return false;
                    }
                },
            },
            accept_request_a_quote: {
                get() {
                    return this.userProfile.memberOf.includes('ZAEmployee');
                },
            },
            loaded_file_ZAlift_ID: {
                get() {
                    if (this.$store.state.configurations.loaded_configuration.hasOwnProperty('zAlift_ID')) {
                        return this.$store.state.configurations.loaded_configuration.zAlift_ID;
                    }
                    else {
                        return null;
                    }

                },
            },

            summary: {
                get() {
                    return this.$store.state.summary.summary;
                }
            },

            zAlift_ID: {
                get() {
                    return this.$store.state.summary.summary.zAlift_ID;
                },
            },
            note: {
                get() {
                    return this.$store.state.summary.summary.note;
                },
            },
            fileLanguage: {
                get() {
                    return this.$store.state.summary.fileLanguage;
                },
                set(value) {
                    this.$store.dispatch('summary/change_fileLanguage_storeAction', value)
                }
            },
        },

        methods: {
            change_scrollYPosision() {
                this.scrollYPosition = window.scrollY;
            },
            closeModal() {
                this.showModal = false;
            },
            async loadCustomers(inputCust) {

                await axios({
                    method: 'post',
                    url: '/api/summaries/search_for_customers/' + inputCust
                })
                    .then((response) => {
                        this.inputCust = "0 | " + response.data[0][1]['value'] + " | " + response.data[0][0]['value'] + " | " + response.data[0][2]['value'];
                    })

            },

            get_htmlFileContent() {
                this.$store.dispatch('summary/get_htmlFileContent_storeAction');
            },
            change_showInfo() {
                this.$store.dispatch('summary/change_ShowInfo_storeAction', this.inputCust);
            },
            click_supplies() {
                this.$store.commit('configurations/change_plusManual_storeMutation', true);
                router.push({ params: { slug: 'ManualHome' } });
            },
            request_offer_or_post_order(type) {
                var i = parseInt(this.inputCust.split('|')[0])
                if (!Number.isNaN(i)) {
                    var customerIdFromInput = this.inputCust.split('|')[1].trim();
                    var companyCustFromInput = this.inputCust.split('|')[3].trim();

                    const data = {
                        'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                        'documentType': type,
                        'customerID': customerIdFromInput,
                        'companyCust': companyCustFromInput,
                        'customerIDCust': '5054871',
                    }

                    axios({
                        method: 'post',
                        url: '/api/summaries/request_offer_or_post_order',
                        data: {
                            'data': data
                        }
                    })
                        .then((response) => {
                            if (type == 'A') {
                                alert("Ihre Angebotsnummer lautet: " + response.data);

                            } else if (type == 'U') {
                                alert("Ihre Auftragsnummer lautet: " + response.data);
                            }
                        })
                }
            },
            request_offer_or_post_order_sap(type) {
                const data = {
                    'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                    'configurationOverviewLinkId': this.$store.state.configurations.configurationOverviewLinkId,
                    'documentType': type,
                    'customerID': '',//customerIdFromInput,
                    'companyCust': '',//companyCustFromInput,
                    'customerIDCust': '5054871',
                    'salesOrder': this.$store.state.configurations.salesOrder,
                    'salesOrderPos': this.$store.state.configurations.salesOrderPos
                }

                axios({
                    method: 'post',
                    url: '/api/summaries/request_offer_or_post_order_sap',
                    data: {
                        'data': data
                    }
                })
                    .then((response) => {
                        if (type == 'A') {
                            alert("Ihre Angebotsnummer lautet: " + response.data);

                        } else if (type == 'U') {
                            alert("Ihre Auftragsnummer lautet: " + response.data);
                        }
                    })
                //}
            },
            request_Quotation_or_post_Quotation_sap(type) {
                const data = {
                    'configurationOverviewId': this.$store.state.configurations.configurationOverviewsId,
                    'configurationOverviewLinkId': this.$store.state.configurations.configurationOverviewLinkId,
                    'documentType': type,
                    'customerID': '',//customerIdFromInput,
                    'companyCust': '',//companyCustFromInput,
                    'customerIDCust': '5054871',
                    'salesOrder': this.$store.state.configurations.salesOrder,
                    'salesOrderPos': this.$store.state.configurations.salesOrderPos
                }

                axios({
                    method: 'post',
                    url: '/api/summaries/request_Quotation_or_post_Quotation_sap',
                    data: {
                        'data': data
                    }
                })
                    .then((response) => {
                        if (type == 'A') {
                            alert("Ihre Angebotsnummer lautet: " + response.data);

                        } else if (type == 'U') {
                            alert("Ihre Auftragsnummer lautet: " + response.data);
                        }
                    })
                //}
            },


        }
    }</script>

<style>
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: scroll;
        /*        height: 120%;*/
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 650px;
    }

    .modal-container {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        max-width: 500px;
        width: 100%;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

        .modal-header h3 {
            margin: 0;
        }

    .modal-body {
        margin-bottom: 20px;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
</style>
