import axios from 'axios';
import router from "@/router";
import { configurations } from './configurations.module';

let state = {
    loadCables: false,

    cables_Data: {},

    /* loader_forLoadingAllContent_at_cable_Shown: false,*/
    babl: false,
    encoderCableDeliverSelection: [],
    encoderCableDeliver: undefined,
    motorCableDeliver: undefined,
    motorProtectionCableDeliver: undefined,
    brakeCableDeliver: undefined,
    brakeMonitoringCableDeliver: undefined,
    forcedVentilationCableDeliver: undefined,

    encoderConnectionInformation: {},
    encoderCableLengthSelection: [],
    encoderCableLength: undefined,
    motorCableLengthSelection: [],
    motorCableLength: undefined,
    motorProtectionCableLengthSelection: [],
    motorProtectionCableLength: undefined,
    brakeCableLengthSelection: [],
    brakeCableLength: undefined,
    brakeMonitoringCableLengthSelection: [],
    brakeMonitoringCableLength: undefined,
    forcedVentilationCableLengthSelection: [],
    forcedVentilationCableLength: undefined,

    encoderCableTypeOfConnectionOnComponentSideSelection: [],
    encoderCableTypeOfConnectionOnComponentSide: undefined,
    motorCableTypeOfConnectionOnComponentSideSelection: [],
    motorCableTypeOfConnectionOnComponentSide: undefined,
    motorProtectionCableTypeOfConnectionOnComponentSideSelection: [],
    motorProtectionCableTypeOfConnectionOnComponentSide: undefined,
    brakeCableTypeOfConnectionOnComponentSideSelection: [],
    brakeCableTypeOfConnectionOnComponentSide: undefined,
    brakeMonitoringCableTypeOfConnectionOnComponentSideSelection: [],
    brakeMonitoringCableTypeOfConnectionOnComponentSide: undefined,
    forcedVentilationCableTypeOfConnectionOnComponentSideSelection: [],
    forcedVentilationCableTypeOfConnectionOnComponentSide: undefined,

    encoderCableSpecificationSelection: [],
    encoderCableSpecification: undefined,
    motorCableSpecificationSelection: [],
    motorCableSpecification: undefined,
    motorProtectionCableSpecificationSelection: [],
    motorProtectionCableSpecification: undefined,
    brakeCableSpecificationSelection: [],
    brakeCableSpecification: undefined,
    brakeMonitoringCableSpecificationSelection: [],
    brakeMonitoringCableSpecification: undefined,
    forcedVentilationCableSpecificationSelection: [],
    forcedVentilationCableSpecification: undefined,

    encoderCableNumberOfWiresAndCrossSectionSelection: [],
    encoderCableNumberOfWiresAndCrossSection: undefined,
    motorCableNumberOfWiresAndCrossSectionSelection: [],
    motorCableNumberOfWiresAndCrossSection: undefined,
    motorProtectionCableNumberOfWiresAndCrossSectionSelection: [],
    motorProtectionCableNumberOfWiresAndCrossSection: undefined,
    brakeCableNumberOfWiresAndCrossSectionSelection: [],
    brakeCableNumberOfWiresAndCrossSection: undefined,
    brakeMonitoringCableNumberOfWiresAndCrossSectionSelection: [],
    brakeMonitoringCableNumberOfWiresAndCrossSection: undefined,
    forcedVentilationCableNumberOfWiresAndCrossSectionSelection: [],
    forcedVentilationCableNumberOfWiresAndCrossSection: undefined,

    encoderConnectionCableBlockNumber: undefined,
    encoderCableBlockNumber: undefined,
    motorCableBlockNumber: undefined,
    motorProtectionCableBlockNumber: undefined,
    brakeCableBlockNumber: undefined,
    brakeMonitoringCableBlockNumber: undefined,
    forcedVentilationCableBlockNumber: undefined,

}

const actions = {
    change_cablesManual_toDefault_storeAction({ commit, dispatch }) {
        commit('change_loadCables_storeMutation', false);
        commit('change_cables_Data_storeMutation', {});

        commit('change_encoderCableDeliverSelection_storeMutation', []);
        commit('change_encoderCableDeliver_storeMutation', null);
        commit('change_motorCableDeliver_storeMutation', null);
        commit('change_motorProtectionCableDeliver_storeMutation', null);
        commit('change_brakeCableDeliver_storeMutation', null);
        commit('change_brakeMonitoringCableDeliver_storeMutation', null);
        commit('change_forcedVentilationCableDeliver_storeMutation', null);

        commit('change_encoderConnectionInformation_storeMutation', {});
        commit('change_encoderCableLengthSelection_storeMutation', []);
        commit('change_encoderCableLength_storeMutation', null);
        commit('change_motorCableLengthSelection_storeMutation', []);
        commit('change_motorCableLength_storeMutation', null);
        commit('change_motorProtectionCableLengthSelection_storeMutation', []);
        commit('change_motorProtectionCableLength_storeMutation', null);
        commit('change_brakeCableLengthSelection_storeMutation', []);
        commit('change_brakeCableLength_storeMutation', null);
        commit('change_brakeMonitoringCableLengthSelection_storeMutation', []);
        commit('change_brakeMonitoringCableLength_storeMutation', null);
        commit('change_forcedVentilationCableLengthSelection_storeMutation', []);
        commit('change_forcedVentilationCableLength_storeMutation', null);

        commit('change_encoderCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_encoderCableTypeOfConnectionOnComponentSide_storeMutation', null);
        commit('change_motorCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_motorCableTypeOfConnectionOnComponentSide_storeMutation', null);
        commit('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeMutation', null);
        commit('change_brakeCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_brakeCableTypeOfConnectionOnComponentSide_storeMutation', null);
        commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeMutation', null);
        commit('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        commit('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeMutation', null);

        commit('change_encoderCableSpecificationSelection_storeMutation', []);
        commit('change_encoderCableSpecification_storeMutation', null);
        commit('change_motorCableSpecificationSelection_storeMutation', []);
        commit('change_motorCableSpecification_storeMutation', null);
        commit('change_motorProtectionCableSpecificationSelection_storeMutation', []);
        commit('change_motorProtectionCableSpecification_storeMutation', null);
        commit('change_brakeCableSpecificationSelection_storeMutation', []);
        commit('change_brakeCableSpecification_storeMutation', null);
        commit('change_brakeMonitoringCableSpecificationSelection_storeMutation', []);
        commit('change_brakeMonitoringCableSpecification_storeMutation', null);
        commit('change_forcedVentilationCableSpecificationSelection_storeMutation', []);
        commit('change_forcedVentilationCableSpecification_storeMutation', null);

        commit('change_encoderCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_encoderCableNumberOfWiresAndCrossSection_storeMutation', null);
        commit('change_motorCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_motorCableNumberOfWiresAndCrossSection_storeMutation', null);
        commit('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_motorProtectionCableNumberOfWiresAndCrossSection_storeMutation', null);
        commit('change_brakeCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_brakeCableNumberOfWiresAndCrossSection_storeMutation', null);
        commit('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeMutation', null);
        commit('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        commit('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeMutation', null);

        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_motorCableBlockNumber_storeMutation', null);
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
    },


    // Manual selection
    async get_cableInformationsManual_storeAction({ commit, dispatch, rootState }) {
        if (rootState.configurations.configurationOverviewsId != null || rootState.inputsManual.configurationStepsManualId != null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });

            var methodName = '';
            if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
                methodName = 'get_cableInformationsConfiguration/' + rootState.configurations.configurationOverviewsId;
            }
            else {
                const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
                methodName = 'get_cableInformationsManual/' + configurationStepsManualId;
            }

            await axios({
                method: 'post',
                url: '/api/cables/' + methodName,
            })
                .then((response) => {
                    this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                    commit('change_loadCables_storeMutation', true);

                    if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                        commit('change_cables_Data_storeMutation', response.data.data);

                        //const firstIndex_whichMeetConditions_encoderCableDeliver = state.cables_Data.encoderCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        //if (firstIndex_whichMeetConditions_encoderCableDeliver > -1) {
                        //    dispatch('change_encoderCableDeliver_storeAction', state.cables_Data.encoderCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_encoderCableDeliver])
                        //}
                        //else {
                        //    dispatch('change_encoderCableDeliver_storeAction', null)
                        //}
                        var firstIndex_whichMeetConditions_motorCableDeliver = state.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorCable_DeliverOption_Valuelist != null && state.cables_Data.motorCable_DeliverOption_Valuelist.hasOwnProperty('text') == true) {
                            const loadMotorCables = state.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorCableDeliverOption));
                            if (loadMotorCables > -1) {
                                firstIndex_whichMeetConditions_motorCableDeliver = loadMotorCables;
                            }
                        }
                        if (firstIndex_whichMeetConditions_motorCableDeliver > -1) {
                            dispatch('change_motorCableDeliver_storeAction', state.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_motorCableDeliver])
                        }
                        else {
                            dispatch('change_motorCableDeliver_storeAction', null)
                        }
                        var firstIndex_whichMeetConditions_motorProtectionCableDeliver = state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorProtectionCable_DeliverOption_Valuelist != null && state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.hasOwnProperty('text') == true) {
                            const loadMotorProtectionCables = state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorProtectionCableDeliverOption));
                            if (loadMotorProtectionCables > -1) {
                                firstIndex_whichMeetConditions_motorProtectionCableDeliver = loadMotorProtectionCables;
                            }
                        }
                        if (firstIndex_whichMeetConditions_motorProtectionCableDeliver > -1) {
                            dispatch('change_motorProtectionCableDeliver_storeAction', state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_motorProtectionCableDeliver])
                        }
                        else {
                            dispatch('change_motorProtectionCableDeliver_storeAction', null)
                        }
                        var firstIndex_whichMeetConditions_brakeCableDeliver = state.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeCable_DeliverOption_Valuelist != null && state.cables_Data.brakeCable_DeliverOption_Valuelist.hasOwnProperty('text') == true) {
                            const loadBrakeCables = state.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.brakeCableDeliverOption));
                            if (loadBrakeCables > -1) {
                                firstIndex_whichMeetConditions_brakeCableDeliver = loadBrakeCables;
                            }
                        }
                        if (firstIndex_whichMeetConditions_brakeCableDeliver > -1) {
                            dispatch('change_brakeCableDeliver_storeAction', state.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_brakeCableDeliver])
                        }
                        else {
                            dispatch('change_brakeCableDeliver_storeAction', null)
                        }
                        var firstIndex_whichMeetConditions_brakeMonitoringCableDeliver = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist != null && state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.hasOwnProperty('text') == true) {
                            const loadBrakeMonitoringCables = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.brakeMonitoringCable_DeliverOption_Valuelist));
                            if (loadBrakeMonitoringCables > -1) {
                                firstIndex_whichMeetConditions_brakeMonitoringCableDeliver = loadBrakeMonitoringCables;
                            }
                        }
                        if (firstIndex_whichMeetConditions_brakeMonitoringCableDeliver > -1) {
                            dispatch('change_brakeMonitoringCableDeliver_storeAction', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_brakeMonitoringCableDeliver])
                        }
                        else {
                            dispatch('change_brakeMonitoringCableDeliver_storeAction', null)
                        }
                        var firstIndex_whichMeetConditions_forcedVentilationCableDeliver = state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                        if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist != null && state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.hasOwnProperty('text') == true) {
                            const loadForcedVentilationCables = state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.forcedVentilationCable_DeliverOption_Valuelist));
                            if (loadForcedVentilationCables > -1) {
                                firstIndex_whichMeetConditions_forcedVentilationCableDeliver = loadForcedVentilationCables;
                            }
                        }
                        if (firstIndex_whichMeetConditions_forcedVentilationCableDeliver > -1) {
                            dispatch('change_forcedVentilationCableDeliver_storeAction', state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_forcedVentilationCableDeliver])
                        }
                        else {
                            dispatch('change_forcedVentilationCableDeliver_storeAction', null)
                        }

                        dispatch('get_encoderCableDeliverSelection_storeAction');
                    }
                })
                .catch(() => {
                    this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
                })
                .then(() => {
                    commit('change_loadCables_storeMutation', false);
                })
        }
    },

    // encoder connection
    async get_encoderConnectionCableInformation_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var encoderCableBlockNumber = null;
        if (state.encoderCableBlockNumber != null && state.encoderCableBlockNumber.hasOwnProperty('blockNumber')) {
            encoderCableBlockNumber = state.encoderCableBlockNumber.blockNumber;
        }
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderConnectionCableInformationConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                encoderCableBlockNumber: encoderCableBlockNumber,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderConnectionCableInformation';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                encoderCableBlockNumber: encoderCableBlockNumber,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_encoderConnectionInformation_storeMutation', response.data.data);
                if (state.encoderConnectionInformation != null && state.encoderConnectionInformation.hasOwnProperty('details') == true) {
                    var firstIndex_whichMeetConditions = state.encoderConnectionInformation.details.findIndex(i => (i.selected == true));
                    //if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.encoderCableLengthSelection != null && state.cables_Data.encoderCableLengthSelection.hasOwnProperty('value') == true) {
                    //    const loadCables = state.cables_Data.encoderCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.encoderCableLength));
                    //    if (loadCables > -1) {
                    //        firstIndex_whichMeetConditions = loadCables;
                    //    }
                    //}
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_encoderConnectionCableBlockNumber_storeAction', state.encoderConnectionInformation.details[firstIndex_whichMeetConditions]);
                    }
                    else {
                        if (state.encoderCableLengthSelection.length > 0) {
                            dispatch('change_encoderConnectionCableBlockNumber_storeAction', state.encoderConnectionInformation[0]);
                        }
                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },

    // encoder cable
    async get_encoderCableDeliverSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};

        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableDeliverSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }
        }
        else {
            methodName = 'get_encoderCableDeliverSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
            }
        }
        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_encoderCableDeliverSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.encoderCableDeliverSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.encoderCableDeliverSelection != null && state.cables_Data.encoderCableDeliverSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.encoderCableDeliverSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.encoderCableDeliverOption));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_encoderCableDeliver_storeAction', state.encoderCableDeliverSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.encoderCableDeliverSelection.length > 0) {
                        dispatch('change_encoderCableDeliver_storeAction', state.encoderCableDeliverSelection[0]);
                    }
                }
                //}
                dispatch('get_encoderConnectionCableInformation_storeAction');
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    async get_encoderCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_encoderCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.encoderCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.encoderCableLengthSelection != null && state.cables_Data.encoderCableLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.encoderCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.encoderCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_encoderCableLength_storeAction', state.encoderCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.encoderCableLengthSelection.length > 0) {
                        dispatch('change_encoderCableLength_storeAction', state.encoderCableLengthSelection[0]);
                    }
                }

                if (state.encoderCableLengthSelection == null || state.encoderCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.encoderCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_encoderCableDeliver_storeAction', state.cables_Data.encoderCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_encoderCableLengthSelection_toDefault_storeAction')
                    }
                }

                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_encoderCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};

        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.encoderCableLength,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.encoderCableLength,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_encoderCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.encoderCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.encoderCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.encoderCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.encoderCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.encoderCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_encoderCableTypeOfConnectionOnComponentSide_storeAction', state.encoderCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.encoderCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_encoderCableTypeOfConnectionOnComponentSide_storeAction', state.encoderCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_encoderCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};

        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_encoderCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.encoderCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.encoderCableSpecificationSelection != null && state.cables_Data.encoderCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.encoderCableSpecificationSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.encoderCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_encoderCableSpecification_storeAction', state.encoderCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.encoderCableSpecificationSelection.length > 0) {
                        dispatch('change_encoderCableSpecification_storeAction', state.encoderCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_encoderCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};

        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                specification: state.encoderCableSpecification,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                specification: state.encoderCableSpecification,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_encoderCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.encoderCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_encoderCableNumberOfWiresAndCrossSection_storeAction', state.encoderCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.encoderCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_encoderCableNumberOfWiresAndCrossSection_storeAction', state.encoderCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_encoderCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};

        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_encoderCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                specification: state.encoderCableSpecification,
                numberOfWires: state.encoderCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.encoderCableNumberOfWiresAndCrossSection.crossSection,
                deliver: state.encoderCableDeliver,
            }
        }
        else {
            methodName = 'get_encoderCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.encoderCableLength,
                typeOfConnectionOnComponentSide: state.encoderCableTypeOfConnectionOnComponentSide,
                specification: state.encoderCableSpecification,
                numberOfWires: state.encoderCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.encoderCableNumberOfWiresAndCrossSection.crossSection,
                deliver: state.encoderCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_encoderCableBlockNumber_storeMutation', response.data.data);
                //}
                dispatch('get_encoderConnectionCableInformation_storeAction');
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    // motor cable
    async get_motorCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                deliver: state.motorCableDeliver,
            }
        }
        else {
            methodName = 'get_motorCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                deliver: state.motorCableDeliver,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_motorCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorCableLengthSelection != null && state.cables_Data.motorCableLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.motorCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.motorCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorCableLength_storeAction', state.motorCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableLengthSelection.length > 0) {
                        dispatch('change_motorCableLength_storeAction', state.motorCableLengthSelection[0]);

                    }
                }
                if (state.motorCableLengthSelection == null || state.motorCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_motorCableDeliver_storeAction', state.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_motorCableLengthSelection_toDefault_storeAction')
                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorCableLength,
            }
        }
        else {
            methodName = 'get_motorCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorCableLength,
            }
        }
        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.motorCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.motorCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorCableTypeOfConnectionOnComponentSide_storeAction', state.motorCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_motorCableTypeOfConnectionOnComponentSide_storeAction', state.motorCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
            }
        }
        else {
            methodName = 'get_motorCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorCableSpecificationSelection != null && state.cables_Data.motorCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.motorCableSpecificationSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorCableSpecification_storeAction', state.motorCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableSpecificationSelection.length > 0) {
                        dispatch('change_motorCableSpecification_storeAction', state.motorCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
                specification: state.motorCableSpecification,
            }
        }
        else {
            methodName = 'get_motorCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
                specification: state.motorCableSpecification,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.motorCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorCableNumberOfWiresAndCrossSection_storeAction', state.motorCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_motorCableNumberOfWiresAndCrossSection_storeAction', state.motorCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
                specification: state.motorCableSpecification,
                numberOfWires: state.motorCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.motorCableNumberOfWiresAndCrossSection.crossSection,
            }
        }
        else {
            methodName = 'get_motorCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorCableLength,
                typeOfConnectionOnComponentSide: state.motorCableTypeOfConnectionOnComponentSide,
                specification: state.motorCableSpecification,
                numberOfWires: state.motorCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.motorCableNumberOfWiresAndCrossSection.crossSection,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_motorCableBlockNumber_storeMutation', response.data.data);
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    // motor protection cable
    async get_motorProtectionCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorProtectionCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }
        }
        else {
            methodName = 'get_motorProtectionCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorProtectionCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorProtectionCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorProtectionCableLengthSelection != null && state.cables_Data.motorProtectionCableLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.motorProtectionCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.motorProtectionCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorProtectionCableLength_storeAction', state.motorProtectionCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorProtectionCableLengthSelection.length > 0) {
                        dispatch('change_motorProtectionCableLength_storeAction', state.motorProtectionCableLengthSelection[0]);
                    }
                }

                if (state.motorProtectionCableLengthSelection == null || state.motorProtectionCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_motorProtectionCableDeliver_storeAction', state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_motorProtectionCableLengthSelection_toDefault_storeAction', null)
                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorProtectionCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorProtectionCableLength,
            }
        }
        else {
            methodName = 'get_motorProtectionCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorProtectionCableLength,
            }

        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorProtectionCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorProtectionCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.motorProtectionCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.motorProtectionCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorProtectionCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }

                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeAction', state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeAction', state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorProtectionCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorProtectionCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
            }
        }
        else {
            methodName = 'get_motorProtectionCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorProtectionCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.motorProtectionCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.motorProtectionCableSpecificationSelection != null && state.cables_Data.motorProtectionCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.motorProtectionCableSpecificationSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.motorProtectionCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorProtectionCableSpecification_storeAction', state.motorProtectionCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorProtectionCableSpecificationSelection.length > 0) {
                        dispatch('change_motorProtectionCableSpecification_storeAction', state.motorProtectionCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorProtectionCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
                specification: state.motorProtectionCableSpecification,
            }
        }
        else {
            methodName = 'get_motorProtectionCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
                specification: state.motorProtectionCableSpecification,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.motorProtectionCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_motorProtectionCableNumberOfWiresAndCrossSection_storeAction', state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorProtectionCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSection_storeAction', state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_motorProtectionCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_motorProtectionCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
                specification: state.motorProtectionCableSpecification,
                numberOfWires: state.motorProtectionCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.motorProtectionCableNumberOfWiresAndCrossSection.crossSection,
            }
        }
        else {
            methodName = 'get_motorProtectionCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.motorProtectionCableLength,
                typeOfConnectionOnComponentSide: state.motorProtectionCableTypeOfConnectionOnComponentSide,
                specification: state.motorProtectionCableSpecification,
                numberOfWires: state.motorProtectionCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.motorProtectionCableNumberOfWiresAndCrossSection.crossSection,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_motorProtectionCableBlockNumber_storeMutation', response.data.data);
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    // brake cable
    async get_brakeCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }
        }
        else {
            methodName = 'get_brakeCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeCableLengthSelection != null && state.cables_Data.brakeCableLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.brakeCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.brakeCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeCableLength_storeAction', state.brakeCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeCableLengthSelection.length > 0) {
                        dispatch('change_brakeCableLength_storeAction', state.brakeCableLengthSelection[0]);
                    }
                }

                if (state.brakeCableLengthSelection == null || state.brakeCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_brakeCableDeliver_storeAction', state.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_brakeCableLengthSelection_toDefault_storeAction', null)
                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeCableLength,
            }
        }
        else {
            methodName = 'get_brakeCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeCableLength,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.brakeCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.brakeCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.brakeCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }

                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeCableTypeOfConnectionOnComponentSide_storeAction', state.brakeCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.motorCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_brakeCableTypeOfConnectionOnComponentSide_storeAction', state.brakeCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
            }
        }
        else {
            methodName = 'get_brakeCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeCableSpecificationSelection != null && state.cables_Data.brakeCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.brakeCableSpecificationSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.brakeCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeCableSpecification_storeAction', state.brakeCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeCableSpecificationSelection.length > 0) {
                        dispatch('change_brakeCableSpecification_storeAction', state.brakeCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
                specification: state.brakeCableSpecification,
            }
        }
        else {
            methodName = 'get_brakeCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
                specification: state.brakeCableSpecification,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.brakeCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeCableNumberOfWiresAndCrossSection_storeAction', state.brakeCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_brakeCableNumberOfWiresAndCrossSection_storeAction', state.brakeCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
                specification: state.brakeCableSpecification,
                numberOfWires: state.brakeCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.brakeCableNumberOfWiresAndCrossSection.crossSection,
            }
        }
        else {
            methodName = 'get_brakeCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeCableLength,
                typeOfConnectionOnComponentSide: state.brakeCableTypeOfConnectionOnComponentSide,
                specification: state.brakeCableSpecification,
                numberOfWires: state.brakeCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.brakeCableNumberOfWiresAndCrossSection.crossSection,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_brakeCableBlockNumber_storeMutation', response.data.data);
                //}

                if (state.brakeCableBlockNumber != null && state.brakeCableBlockNumber.hasOwnProperty('typeDesignation') && state.brakeCableBlockNumber.typeDesignation.text != null && state.brakeCableBlockNumber.typeDesignation.text.substring(0, 5) == "BA/BL") {
                    commit('change_babl_storeMutation', true);
                }
                else {
                    commit('change_babl_storeMutation', false);
                }

                if (state.babl == true) {
                    const firstIndex_whichMeetConditions = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions > -1) {
                        dispatch('change_brakeMonitoringCableDeliver_storeAction', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                    }
                    else {
                        commit('change_brakeMonitoringCableDeliverSelected_storeMutation', null);
                        dispatch('change_brakeMonitoringCableDeliverDetails_toDefault_storeAction');
                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    // brake monitoring cable
    async get_brakeMonitoringCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeMonitoringCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }
        }
        else {
            methodName = 'get_brakeMonitoringCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_brakeMonitoringCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeMonitoringCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeMonitoringCableLengthSelection != null && state.cables_Data.brakeMonitoringCableLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.brakeMonitoringCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.brakeMonitoringCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeMonitoringCableLength_storeAction', state.brakeMonitoringCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeMonitoringCableLengthSelection.length > 0) {
                        dispatch('change_brakeMonitoringCableLength_storeAction', state.brakeMonitoringCableLengthSelection[0]);
                    }
                }

                if (state.brakeMonitoringCableLengthSelection == null || state.brakeMonitoringCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_brakeMonitoringCableDeliver_storeAction', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_brakeMonitoringCableLengthSelection_toDefault_storeAction', null)
                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeMonitoringCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeMonitoringCableLength,
            }
        }
        else {
            methodName = 'get_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeMonitoringCableLength,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.brakeMonitoringCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeAction', state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeAction', state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeMonitoringCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeMonitoringCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
            }
        }
        else {
            methodName = 'get_brakeMonitoringCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeMonitoringCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.brakeMonitoringCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.brakeMonitoringCableSpecificationSelection != null && state.cables_Data.brakeMonitoringCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.brakeMonitoringCableSpecificationSelection.findIndex(i => (i.text == rootState.summary.manualHomeSummary.cables.brakeMonitoringCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeMonitoringCableSpecification_storeAction', state.brakeMonitoringCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeMonitoringCableSpecificationSelection.length > 0) {
                        dispatch('change_brakeMonitoringCableSpecification_storeAction', state.brakeMonitoringCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeMonitoringCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
                specification: state.brakeMonitoringCableSpecification,
            }
        }
        else {
            methodName = 'get_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
                specification: state.brakeMonitoringCableSpecification,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeAction', state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeAction', state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_brakeMonitoringCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_brakeMonitoringCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
                specification: state.brakeMonitoringCableSpecification,
                numberOfWires: state.brakeMonitoringCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.brakeMonitoringCableNumberOfWiresAndCrossSection.crossSection,
            }
        }
        else {
            methodName = 'get_brakeMonitoringCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.brakeMonitoringCableLength,
                typeOfConnectionOnComponentSide: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
                specification: state.brakeMonitoringCableSpecification,
                numberOfWires: state.brakeMonitoringCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.brakeMonitoringCableNumberOfWiresAndCrossSection.crossSection,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_brakeMonitoringCableBlockNumber_storeMutation', response.data.data);
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    // forced ventiltion cable
    async get_forcedVentilationCablesLengthSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_forcedVentilationCableLengthSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
            }
        }
        else {
            methodName = 'get_forcedVentilationCableLengthSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_forcedVentilationCableLengthSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.forcedVentilationCableLengthSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.forcedVentilationCableLengthSelection != null && state.cables_Data.forcedVentilationLengthSelection.hasOwnProperty('value') == true) {
                    const loadCables = state.cables_Data.forcedVentilationCableLengthSelection.findIndex(i => (i.value == rootState.summary.manualHomeSummary.cables.forcedVentilationCableLength));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_forcedVentilationCableLength_storeAction', state.forcedVentilationCableLengthSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.forcedVentilationCableLengthSelection.length > 0) {
                        dispatch('change_forcedVentilationCableLength_storeAction', state.forcedVentilationCableLengthSelection[0]);
                    }
                }

                if (state.forcedVentilationCableLengthSelection == null || state.forcedVentilationCableLengthSelection.length == 0) {
                    const firstIndex_whichMeetConditions_DeliverOptionValuelist = state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                    if (firstIndex_whichMeetConditions_DeliverOptionValuelist > -1) {
                        dispatch('change_forcedVentilationCableDeliver_storeAction', state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions_DeliverOptionValuelist]);
                    }
                    else {
                        dispatch('change_forcedVentilationCableLengthSelection_toDefault_storeAction', null)
                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_forcedVentilationCableTypeOfConnectionOnComponentSideSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.forcedVentilationCableLength,
            }
        }
        else {
            methodName = 'get_forcedVentilationCableTypeOfConnectionOnComponentSideSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.forcedVentilationCableLength,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.forcedVentilationCableTypeOfConnectionOnComponentSideSelection != null && state.cables_Data.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.text.text == rootState.summary.manualHomeSummary.cables.forcedVentilationCableTypeOfConnection));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeAction', state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length > 0) {
                        dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeAction', state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_forcedVentilationCableSpecificationSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_forcedVentilationCableSpecificationSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
            }
        }
        else {
            methodName = 'get_forcedVentilationCableSpecificationSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_forcedVentilationCableSpecificationSelection_storeMutation', response.data);
                var firstIndex_whichMeetConditions = state.forcedVentilationCableSpecificationSelection.findIndex(i => (i.selected == true));
                if (state.loadCables == true && rootState.summary.manualHomeSummary != null && rootState.summary.manualHomeSummary.hasOwnProperty('cables') && state.cables_Data.forcedVentilationCableSpecificationSelection != null && state.cables_Data.forcedVentilationCableSpecificationSelection.hasOwnProperty('text') == true) {
                    const loadCables = state.cables_Data.forcedVentilationCableSpecificationSelection.findIndex(i => (i.text == rootState.summary.manualHomeSummary.cables.forcedVentilationCableSpecification));
                    if (loadCables > -1) {
                        firstIndex_whichMeetConditions = loadCables;
                    }
                }
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_forcedVentilationCableSpecification_storeAction', state.forcedVentilationCableSpecificationSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.forcedVentilationCableSpecificationSelection.length > 0) {
                        dispatch('change_forcedVentilationCableSpecification_storeAction', state.forcedVentilationCableSpecificationSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })
    },
    async get_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeAction({ commit, dispatch, rootState }) {
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: true, reasonWhy_loaderModal_Shown: 'data_BeingLoaded' });
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_forcedVentilationCableNumberOfWiresAndCrossSectionSelectionConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
                specification: state.forcedVentilationCableSpecification,
            }
        }
        else {
            methodName = 'get_forcedVentilationCableNumberOfWiresAndCrossSectionSelection';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationTypeOfConnectionOnComponentSide,
                specification: state.forcedVentilationCableSpecification,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {

                commit('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeMutation', response.data);
                const firstIndex_whichMeetConditions = state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeAction', state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions]);
                }
                else {
                    if (state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length > 0) {
                        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeAction', state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[0]);

                    }
                }
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                //this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },
    async get_forcedVentilationCableBlockNumber_storeAction({ commit, dispatch, rootState }) {
        var methodName = '';
        var data = {};
        if (rootState.configurations.plusManual == true && rootState.inputsManual.configurationStepsManualId == null) {
            methodName = 'get_forcedVentilationCableBlockNumberConfiguration';
            data = {
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
                specification: state.brakeMonitoringCableSpecification,
                numberOfWires: state.forcedVentilationCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.forcedVentilationCableNumberOfWiresAndCrossSection.crossSection,
            }
        }
        else {
            methodName = 'get_forcedVentilationCableBlockNumber';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                length: state.forcedVentilationCableLength,
                typeOfConnectionOnComponentSide: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
                specification: state.forcedVentilationCableSpecification,
                numberOfWires: state.forcedVentilationCableNumberOfWiresAndCrossSection.numberOfWires,
                crossSection: state.forcedVentilationCableNumberOfWiresAndCrossSection.crossSection,
            }
        }

        await axios({
            method: 'post',
            url: '/api/cables/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                //this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                //if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                commit('change_forcedVentilationCableBlockNumber_storeMutation', response.data.data);
                //}
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
    },

    change_cablesSelection_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_motorCableLengthSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableLengthSelection_toDefault_storeAction');
        dispatch('change_brakeCableLengthSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableLengthSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableLengthSelection_toDefault_storeAction');
        commit('change_encoderConnectionInformation_storeMutation', {});
    },

    change_encoderCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_encoderCableLengthSelection_storeMutation', []);
        dispatch('change_encoderCableLength_storeAction', null);

        dispatch('change_encoderCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_encoderCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
    },
    change_motorCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorCableLengthSelection_storeMutation', []);
        dispatch('change_motorCableLength_storeAction', null);

        dispatch('change_motorCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_motorCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorCableBlockNumber_storeMutation', null);
    },
    change_motorProtectionCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorProtectionCableLengthSelection_storeMutation', []);
        dispatch('change_motorProtectionCableLength_storeAction', null);

        dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
    },
    change_brakeCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeCableLengthSelection_storeMutation', []);
        dispatch('change_brakeCableLength_storeAction', null);

        dispatch('change_brakeCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_brakeCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
    },
    change_brakeMonitoringCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeMonitoringCableLengthSelection_storeMutation', []);
        dispatch('change_brakeMonitoringCableLength_storeAction', null);

        dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
    },
    change_forcedVentilationCableLengthSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_forcedVentilationCableLengthSelection_storeMutation', []);
        dispatch('change_forcedVentilationCableLength_storeAction', null);

        dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
    },

    change_encoderCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_encoderCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_encoderCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_encoderCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
    },
    change_motorCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_motorCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_motorCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorCableBlockNumber_storeMutation', null);
    },
    change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_motorProtectionCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
    },
    change_brakeCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_brakeCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_brakeCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
    },
    change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_brakeMonitoringCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
    },
    change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeMutation', []);
        dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeAction', null);

        dispatch('change_forcedVentilationCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
    },

    change_encoderCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_encoderCableSpecificationSelection_storeMutation', []);
        dispatch('change_encoderCableSpecification_storeAction', null);

        dispatch('change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
    },
    change_motorCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorCableSpecificationSelection_storeMutation', []);
        dispatch('change_motorCableSpecification_storeAction', null);

        dispatch('change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorCableBlockNumber_storeMutation', null);
    },
    change_motorProtectionCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorProtectionCableSpecificationSelection_storeMutation', []);
        dispatch('change_motorProtectionCableSpecification_storeAction', null);

        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
    },
    change_brakeCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeCableSpecificationSelection_storeMutation', []);
        dispatch('change_brakeCableSpecification_storeAction', null);

        dispatch('change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
    },
    change_brakeMonitoringCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeMonitoringCableSpecificationSelection_storeMutation', []);
        dispatch('change_brakeMonitoringCableSpecification_storeAction', null);

        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
    },
    change_forcedVentilationCableSpecificationSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_forcedVentilationCableSpecificationSelection_storeMutation', []);
        dispatch('change_forcedVentilationCableSpecification_storeAction', null);

        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
    },

    change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_encoderCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_encoderCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
    },
    change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_motorCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_motorCableBlockNumber_storeMutation', null);
    },
    change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
    },
    change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_brakeCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
    },
    change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
    },
    change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction({ commit, dispatch }) {
        commit('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeMutation', []);
        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeAction', null);

        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
    },

    change_encoderCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_encoderCableLengthSelection_toDefault_storeAction');
        dispatch('change_encoderCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_encoderCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },
    change_motorCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_motorCableLengthSelection_toDefault_storeAction');
        dispatch('change_motorCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_motorCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorCableBlockNumber_storeMutation', null);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },
    change_motorProtectionCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_motorProtectionCableLengthSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },
    change_brakeCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_brakeCableLengthSelection_toDefault_storeAction');
        dispatch('change_brakeCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_brakeCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },
    change_brakeMonitoringCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_brakeMonitoringCableLengthSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },
    change_forcedVentilationCableDeliverDetails_toDefault_storeAction({ commit, dispatch }) {
        dispatch('change_forcedVentilationCableLengthSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableSpecificationSelection_toDefault_storeAction');
        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction');
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
        this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
    },

    change_encoderConnectionCableBlockNumber_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_encoderConnectionCableSelected_storeMutation', null);
        }
        else {
            commit('change_encoderConnectionCableSelected_storeMutation', value.blockNumber);
        }
        if (state.encoderConnectionInformation != null && state.encoderConnectionInformation.hasOwnProperty('details')) {
            const firstIndex_whichMeetConditions = state.encoderConnectionInformation.details.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_encoderConnectionCableBlockNumber_storeMutation', state.encoderConnectionInformation.details[firstIndex_whichMeetConditions].blockNumber)
            }
            else {
                commit('change_encoderConnectionCableBlockNumber_storeMutation', null)
            }
        }

    },

    change_encoderCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_encoderCableDeliverSelected_storeMutation', null);
        }
        else {
            commit('change_encoderCableDeliverSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.encoderCableDeliverSelection.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_encoderCableDeliver_storeMutation', state.encoderCableDeliverSelection[firstIndex_whichMeetConditions].text.code);
                if (state.encoderCableDeliverSelection[firstIndex_whichMeetConditions].showDetails == true) {
                    dispatch('get_encoderCablesLengthSelection_storeAction');
                }
                else {
                    dispatch('change_encoderCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                commit('change_encoderCableDeliver_storeMutation', null);
                dispatch('change_encoderCableDeliverDetails_toDefault_storeAction');
            }
        }
        dispatch('get_encoderConnectionCableInformation_storeAction');
    },
    change_motorCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorCableDeliverSelected_storeMutation', null);
        }
        else {
            commit('change_motorCableDeliverSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.cables_Data.motorCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_motorCableDeliver_storeMutation', state.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                if (state.cables_Data.motorCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails == true) {
                    dispatch('get_motorCablesLengthSelection_storeAction');
                }
                else {
                    dispatch('change_motorCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                commit('change_motorCableDeliver_storeMutation', null);
                dispatch('change_motorCableDeliverDetails_toDefault_storeAction');
            }
        }
    },
    change_motorProtectionCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_motorProtectionCableDeliverSelected_storeMutation', null);
        }
        else {
            commit('change_motorProtectionCableDeliverSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_motorProtectionCableDeliver_storeMutation', state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                if (state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails == true) {
                    dispatch('get_motorProtectionCablesLengthSelection_storeAction');
                }
                else {
                    dispatch('change_motorProtectionCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                commit('change_motorProtectionCableDeliver_storeMutation', null);
                dispatch('change_motorProtectionCableDeliverDetails_toDefault_storeAction');
            }
        }
    },
    change_brakeCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_brakeCableDeliverSelected_storeMutation', null);
        }
        else {
            commit('change_brakeCableDeliverSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.cables_Data.brakeCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_brakeCableDeliver_storeMutation', state.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                if (state.cables_Data.brakeCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails == true) {
                    dispatch('get_brakeCablesLengthSelection_storeAction');
                }
                else {
                    dispatch('change_brakeCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                commit('change_brakeCableDeliver_storeMutation', null);
                dispatch('change_brakeCableDeliverDetails_toDefault_storeAction');
            }
        }
    },
    change_brakeMonitoringCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_brakeMonitoringCableDeliverSelected_storeMutation', null);
        }
        else {
            if (state.babl == false) {
                commit('change_brakeMonitoringCableDeliverSelected_storeMutation', value.text.text);
                const firstIndex_whichMeetConditions = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions > -1) {
                    commit('change_brakeMonitoringCableDeliver_storeMutation', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                    if (state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails == true) {
                        dispatch('get_brakeMonitoringCablesLengthSelection_storeAction');
                    }
                    else {
                        dispatch('change_brakeMonitoringCableDeliverDetails_toDefault_storeAction');
                    }
                }
                else {
                    commit('change_brakeMonitoringCableDeliver_storeMutation', null);
                    dispatch('change_brakeMonitoringCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                const firstIndex_whichMeetConditions2 = state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.findIndex(i => (i.text.code == 0));
                if (firstIndex_whichMeetConditions2 > -1) {
                    commit('change_brakeMonitoringCableDeliverSelected_storeMutation', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions2].text.text)
                    commit('change_brakeMonitoringCableDeliver_storeMutation', state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions2].text.code);
                }
                else {
                    commit('change_brakeMonitoringCableDeliverSelected_storeMutation', null);
                    dispatch('change_brakeMonitoringCableDeliverDetails_toDefault_storeAction');
                }
            }
        }
    },
    change_forcedVentilationCableDeliver_storeAction({ commit, dispatch }, value) {
        if (value == null) {
            commit('change_forcedVentilationCableDeliverSelected_storeMutation', null);
        }
        else {
            commit('change_forcedVentilationCableDeliverSelected_storeMutation', value.text.text);
            const firstIndex_whichMeetConditions = state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions > -1) {
                commit('change_forcedVentilationCableDeliver_storeMutation', state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].text.code);
                if (state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[firstIndex_whichMeetConditions].showDetails == true) {
                    dispatch('get_forcedVentilationCablesLengthSelection_storeAction');
                }
                else {
                    dispatch('change_forcedVentilationCableDeliverDetails_toDefault_storeAction');
                }
            }
            else {
                commit('change_forcedVentilationCableDeliver_storeMutation', null);
                dispatch('change_forcedVentilationCableDeliverDetails_toDefault_storeAction');
            }
        }
    },

    change_encoderCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_encoderCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')
        if (value == null) {
            commit('change_encoderCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_encoderCableLengthSelected_storeMutation', value.value);
        }
        const firstIndex_whichMeetConditions = state.encoderCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_encoderCableLength_storeMutation', state.encoderCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_encoderCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_encoderCableLength_storeMutation', null)
        }

    },
    change_motorCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_motorCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_motorCableLengthSelected_storeMutation', value.value);
        }
        const firstIndex_whichMeetConditions = state.motorCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorCableLength_storeMutation', state.motorCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_motorCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_motorCableLength_storeMutation', null)
        }
    },
    change_motorProtectionCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_motorProtectionCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_motorProtectionCableLengthSelected_storeMutation', value.value);
        }

        const firstIndex_whichMeetConditions = state.motorProtectionCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorProtectionCableLength_storeMutation', state.motorProtectionCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_motorProtectionCableLength_storeMutation', null)
        }

    },
    change_brakeCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_brakeCableLengthSelected_storeMutation', value.value);
        }

        const firstIndex_whichMeetConditions = state.brakeCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeCableLength_storeMutation', state.brakeCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_brakeCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_brakeCableLength_storeMutation', null)
        }

    },
    change_brakeMonitoringCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeMonitoringCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_brakeMonitoringCableLengthSelected_storeMutation', value.value);
        }
        const firstIndex_whichMeetConditions = state.brakeMonitoringCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeMonitoringCableLength_storeMutation', state.brakeMonitoringCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_brakeMonitoringCableLength_storeMutation', null)
        }

    },
    change_forcedVentilationCableLength_storeAction({ commit, dispatch }, value) {
        dispatch('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_forcedVentilationCableLengthSelected_storeMutation', null);
        }
        else {
            commit('change_forcedVentilationCableLengthSelected_storeMutation', value.value);
        }
        const firstIndex_whichMeetConditions = state.forcedVentilationCableLengthSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilationCableLength_storeMutation', state.forcedVentilationCableLengthSelection[firstIndex_whichMeetConditions].value)
            dispatch('get_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeAction');
        }
        else {
            commit('change_forcedVentilationCableLength_storeMutation', null)
        }

    },

    change_encoderCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_encoderCableSpecificationSelection_toDefault_storeAction')
        if (value == null) {
            commit('change_encoderCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_encoderCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.encoderCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_encoderCableTypeOfConnectionOnComponentSide_storeMutation', state.encoderCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_encoderCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_encoderCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },
    change_motorCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorCableSpecificationSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_motorCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_motorCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorCableTypeOfConnectionOnComponentSide_storeMutation', state.motorCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_motorCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_motorCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },
    change_motorProtectionCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorProtectionCableSpecificationSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_motorProtectionCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_motorProtectionCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorProtectionCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeMutation', state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_motorProtectionCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_motorProtectionCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },
    change_brakeCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeCableSpecificationSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_brakeCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.brakeCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeCableTypeOfConnectionOnComponentSide_storeMutation', state.brakeCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_brakeCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_brakeCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },
    change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeMonitoringCableSpecificationSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeMutation', state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_brakeMonitoringCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },
    change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeAction({ commit, dispatch }, value) {
        dispatch('change_forcedVentilationCableSpecificationSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelected_storeMutation', null);
        }
        else {
            commit('change_forcedVentilationCableTypeOfConnectionOnComponentSideSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeMutation', state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_forcedVentilationCableSpecificationSelection_storeAction');
        }
        else {
            commit('change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeMutation', null)
        }

    },

    change_encoderCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_encoderCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')
        if (value == null) {
            commit('change_encoderCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_encoderCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.encoderCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_encoderCableSpecification_storeMutation', state.encoderCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_encoderCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_encoderCableSpecification_storeMutation', null)
        }

    },
    change_motorCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')
        if (value == null) {
            commit('change_motorCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_motorCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorCableSpecification_storeMutation', state.motorCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_motorCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_motorCableSpecification_storeMutation', null)
        }

    },
    change_motorProtectionCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')
        if (value == null) {
            commit('change_motorProtectionCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_motorProtectionCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.motorProtectionCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorProtectionCableSpecification_storeMutation', state.motorProtectionCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_motorProtectionCableSpecification_storeMutation', null)
        }

    },
    change_brakeCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_brakeCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.brakeCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeCableSpecification_storeMutation', state.brakeCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_brakeCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_brakeCableSpecification_storeMutation', null)
        }

    },
    change_brakeMonitoringCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_brakeMonitoringCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_brakeMonitoringCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.brakeMonitoringCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeMonitoringCableSpecification_storeMutation', state.brakeMonitoringCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_brakeMonitoringCableSpecification_storeMutation', null)
        }

    },
    change_forcedVentilationCableSpecification_storeAction({ commit, dispatch }, value) {
        dispatch('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_toDefault_storeAction')

        if (value == null) {
            commit('change_forcedVentilationCableSpecificationSelected_storeMutation', null);
        }
        else {
            commit('change_forcedVentilationCableSpecificationSelected_storeMutation', value.text.text);
        }

        const firstIndex_whichMeetConditions = state.forcedVentilationCableSpecificationSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilationCableSpecification_storeMutation', state.forcedVentilationCableSpecificationSelection[firstIndex_whichMeetConditions].text.text)
            dispatch('get_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeAction')
        }
        else {
            commit('change_forcedVentilationCableSpecification_storeMutation', null)
        }

    },

    change_encoderCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_encoderCableBlockNumber_storeMutation', null);
        commit('change_encoderConnectionCableBlockNumber_storeMutation', null);
        if (value == null) {
            commit('change_encoderCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_encoderCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }

        const firstIndex_whichMeetConditions = state.encoderCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_encoderCableNumberOfWiresAndCrossSection_storeMutation', state.encoderCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_encoderCableBlockNumber_storeAction')
        }
        else {
            commit('change_encoderCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },
    change_motorCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_motorCableBlockNumber_storeMutation', null);
        if (value == null) {
            commit('change_motorCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_motorCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }
        const firstIndex_whichMeetConditions = state.motorCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorCableNumberOfWiresAndCrossSection_storeMutation', state.motorCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_motorCableBlockNumber_storeAction')
        }
        else {
            commit('change_motorCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },
    change_motorProtectionCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_motorProtectionCableBlockNumber_storeMutation', null);
        if (value == null) {
            commit('change_motorProtectionCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_motorProtectionCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }
        const firstIndex_whichMeetConditions = state.motorProtectionCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_motorProtectionCableNumberOfWiresAndCrossSection_storeMutation', state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_motorProtectionCableBlockNumber_storeAction')
        }
        else {
            commit('change_motorProtectionCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },
    change_brakeCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_brakeCableBlockNumber_storeMutation', null);
        commit('change_babl_storeMutation', false);
        if (value == null) {
            commit('change_brakeCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_brakeCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }

        const firstIndex_whichMeetConditions = state.brakeCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeCableNumberOfWiresAndCrossSection_storeMutation', state.brakeCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_brakeCableBlockNumber_storeAction')
        }
        else {
            commit('change_brakeCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },
    change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_brakeMonitoringCableBlockNumber_storeMutation', null);
        if (value == null) {
            commit('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }

        const firstIndex_whichMeetConditions = state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeMutation', state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_brakeMonitoringCableBlockNumber_storeAction')
        }
        else {
            commit('change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },
    change_forcedVentilationCableNumberOfWiresAndCrossSection_storeAction({ commit, dispatch }, value) {
        commit('change_forcedVentilationCableBlockNumber_storeMutation', null);
        if (value == null) {
            commit('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelected_storeMutation', null);
        }
        else {
            commit('change_forcedVentilationCableNumberOfWiresAndCrossSectionSelected_storeMutation', value);
        }

        const firstIndex_whichMeetConditions = state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection.findIndex(i => (i.selected == true));
        if (firstIndex_whichMeetConditions > -1) {
            commit('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeMutation', state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[firstIndex_whichMeetConditions])
            dispatch('get_forcedVentilationCableBlockNumber_storeAction')
        }
        else {
            commit('change_forcedVentilationCableNumberOfWiresAndCrossSection_storeMutation', null)
        }
    },


    async insertOrUpdate_cables_storeAction({ dispatch, rootState }) {
        //this.dispatch('cables/change_cablesSelection_toDefault_storeAction');
        var motorCableSelected = false;
        var motorCableBlockNumber = null;
        var motorCableTypeDesignation = null;
        var motorCableNumberOfWires = null;
        var motorCableCrossSection = null;
        if (state.motorCableBlockNumber != null && state.motorCableBlockNumber.blockNumber != '') {
            motorCableSelected = true;
            motorCableBlockNumber = state.motorCableBlockNumber.blockNumber;
            motorCableTypeDesignation = state.motorCableBlockNumber.typeDesignation.text;
            if (state.motorCableNumberOfWiresAndCrossSection != null && state.motorCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                motorCableNumberOfWires = state.motorCableNumberOfWiresAndCrossSection.numberOfWires;
                motorCableCrossSection = state.motorCableNumberOfWiresAndCrossSection.crossSection;
            }
        }
        var motorProtectionCableSelected = false;
        var motorProtectionCableBlockNumber = null;
        var motorProtectionCableTypeDesignation = null;
        var motorProtectionCableNumberOfWires = null;
        var motorProtectionCableCrossSection = null;
        if (state.motorProtectionCableBlockNumber != null && state.motorProtectionCableBlockNumber.blockNumber != '') {
            motorProtectionCableSelected = true;
            motorProtectionCableBlockNumber = state.motorProtectionCableBlockNumber.blockNumber;
            motorProtectionCableTypeDesignation = state.motorProtectionCableBlockNumber.typeDesignation.text;
            if (state.motorProtectionCableNumberOfWiresAndCrossSection != null && state.motorProtectionCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                motorProtectionCableNumberOfWires = state.motorProtectionCableNumberOfWiresAndCrossSection.numberOfWires;
                motorProtectionCableCrossSection = state.motorProtectionCableNumberOfWiresAndCrossSection.crossSection;
            }
        }
        var encoderCableSelected = false;
        var encoderCableBlockNumber = null;
        var encoderCableTypeDesignation = null;
        var encoderCableNumberOfWires = null;
        var encoderCableCrossSection = null;
        if (state.encoderCableBlockNumber != null && state.encoderCableBlockNumber.blockNumber != '') {
            encoderCableSelected = true;
            encoderCableBlockNumber = state.encoderCableBlockNumber.blockNumber;
            encoderCableTypeDesignation = state.encoderCableBlockNumber.typeDesignation.text;
            if (state.encoderCableNumberOfWiresAndCrossSection != null && state.encoderCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                encoderCableNumberOfWires = state.encoderCableNumberOfWiresAndCrossSection.numberOfWires;
                encoderCableCrossSection = state.encoderCableNumberOfWiresAndCrossSection.crossSection;
            }
        }

        var encoderConnectionSelected = false;
        var encoderConnectionBlockNumber = null;
        var encoderConnectionLength = null;
        var encoderConnectionTypeOfConnection = null;
        if (state.encoderConnectionInformation != null && state.encoderConnectionInformation.hasOwnProperty('details')) {
            var firstIndex_whichMeetConditions_enocderConnectionInformation = state.encoderConnectionInformation.details.findIndex(i => (i.selected == true));
            if (firstIndex_whichMeetConditions_enocderConnectionInformation > -1) {
                encoderConnectionSelected = true;
                encoderConnectionBlockNumber = state.encoderConnectionInformation.details[firstIndex_whichMeetConditions_enocderConnectionInformation].blockNumber;
                encoderConnectionLength = state.encoderConnectionInformation.details[firstIndex_whichMeetConditions_enocderConnectionInformation].length;
                encoderConnectionTypeOfConnection = state.encoderConnectionInformation.details[firstIndex_whichMeetConditions_enocderConnectionInformation].typeOfConnection;
            }
        }
        var brakeCableSelected = false;
        var brakeCableBlockNumber = null;
        var brakeCableTypeDesignation = null;
        var brakeCableNumberOfWires = null;
        var brakeCableCrossSection = null;
        if (state.brakeCableBlockNumber != null && state.brakeCableBlockNumber.blockNumber != '') {
            brakeCableSelected = true;
            brakeCableBlockNumber = state.brakeCableBlockNumber.blockNumber;
            brakeCableTypeDesignation = state.brakeCableBlockNumber.typeDesignation.text;
            if (state.brakeCableNumberOfWiresAndCrossSection != null && state.brakeCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                brakeCableNumberOfWires = state.brakeCableNumberOfWiresAndCrossSection.numberOfWires;
                brakeCableCrossSection = state.brakeCableNumberOfWiresAndCrossSection.crossSection;
            }
        }
        var brakeMonitoringCableSelected = false;
        var brakeMonitoringCableBlockNumber = null;
        var brakeMonitoringCableTypeDesignation = null;
        var brakeMonitoringCableNumberOfWires = null;
        var brakeMonitoringCableCrossSection = null;
        if (state.brakeMonitoringCableBlockNumber != null && state.brakeMonitoringCableBlockNumber.blockNumber != '') {
            brakeMonitoringCableSelected = true;
            brakeMonitoringCableBlockNumber = state.brakeMonitoringCableBlockNumber.blockNumber;
            brakeMonitoringCableTypeDesignation = state.brakeMonitoringCableBlockNumber.typeDesignation.text;
            if (state.brakeMonitoringCableNumberOfWiresAndCrossSection != null && state.brakeMonitoringCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                brakeMonitoringCableNumberOfWires = state.brakeMonitoringCableNumberOfWiresAndCrossSection.numberOfWires;
                brakeMonitoringCableCrossSection = state.brakeMonitoringCableNumberOfWiresAndCrossSection.crossSection;
            }
        }

        var forcedVentilationCableSelected = false;
        var forcedVentilationCableBlockNumber = null;
        var forcedVentilationCableTypeDesignation = null;
        var forcedVentilationCableNumberOfWires = null;
        var forcedVentilationCableCrossSection = null;
        if (state.forcedVentilationCableBlockNumber != null && state.forcedVentilationCableBlockNumber.blockNumber != '') {
            forcedVentilationCableSelected = true;
            forcedVentilationCableBlockNumber = state.forcedVentilationCableBlockNumber.blockNumber;
            forcedVentilationCableTypeDesignation = state.forcedVentilationCableBlockNumber.typeDesignation.text;
            if (state.forcedVentilationCableNumberOfWiresAndCrossSection != null && state.forcedVentilationCableNumberOfWiresAndCrossSection.hasOwnProperty('numberOfWires')) {
                forcedVentilationCableNumberOfWires = state.forcedVentilationCableNumberOfWiresAndCrossSection.numberOfWires;
                forcedVentilationCableCrossSection = state.forcedVentilationCableNumberOfWiresAndCrossSection.crossSection;
            }
        }

        const cablesManual = {
            motorCableNecessary: state.cables_Data.motorCable_Necessary,
            motorCableSelected: motorCableSelected,
            tNLML: motorCableBlockNumber,
            sMLMAN: state.motorCableLength,
            lLML: state.motorCableDeliver,
            sMLP01: state.motorCableTypeOfConnectionOnComponentSide,
            sMLSPE: state.motorCableSpecification,
            motorCableTypeDesignation: motorCableTypeDesignation,
            motorCableNumberOfWires: motorCableNumberOfWires,
            sMLA1: motorCableCrossSection,
            motorProtectionCableNecessary: state.cables_Data.motorProtectionCable_Necessary,
            motorProtectionCableSelected: motorProtectionCableSelected,
            tNLMSL: motorProtectionCableBlockNumber,
            sMSMaN: state.motorProtectionCableLength,
            lLMS: state.motorProtectionCableDeliver,
            sMSP01: state.motorProtectionCableTypeOfConnectionOnComponentSide,
            sMSSPE: state.motorProtectionCableSpecification,
            motorProtectionCableTypeDesignation: motorProtectionCableTypeDesignation,
            motorProtectionCableNumberOfWires: motorProtectionCableNumberOfWires,
            sMSA1: motorProtectionCableCrossSection,
            encoderCableNecessary: state.cables_Data.encoderCable_Necessary,
            encoderCableSelected: encoderCableSelected,
            tNLGL: encoderCableBlockNumber,
            sGLMaN: state.encoderCableLength,
            lLGL: state.encoderCableDeliver,
            sGLP01: state.encoderCableTypeOfConnectionOnComponentSide,
            sGLSPE: state.encoderCableSpecification,
            encoderCableTypeDesignation: encoderCableTypeDesignation,
            encoderCableNumberOfWires: encoderCableNumberOfWires,
            encoderCableCrossSection: encoderCableCrossSection,
            encoderConnectionSelected: encoderConnectionSelected,
            tNLGA: encoderConnectionBlockNumber,
            encoderConnectionLength: encoderConnectionLength,
            encoderConnectionTypeOfConnection: encoderConnectionTypeOfConnection,
            brakeCableNecessary: state.cables_Data.brakeCable_Necessary,
            brakeCableSelected: brakeCableSelected,
            tNLBA: brakeCableBlockNumber,
            sBAMaN: state.brakeCableLength,
            lLBA: state.brakeCableDeliver,
            sBAP01: state.brakeCableTypeOfConnectionOnComponentSide,
            brakeCableSpecification: state.brakeCableSpecification,
            brakeCableTypeDesignation: brakeCableTypeDesignation,
            brakeCableNumberOfWires: brakeCableNumberOfWires,
            brakeCableCrossSection: brakeCableCrossSection,
            brakeMonitoringCableNecessary: state.cables_Data.brakeMonitoringCable_Necessary,
            brakeMonitoringCableSelected: brakeMonitoringCableSelected,
            tNLBL: brakeMonitoringCableBlockNumber,
            sBLMaN: state.brakeMonitoringCableLength,
            lLBL: state.brakeMonitoringCableDeliver,
            sBLP01: state.brakeMonitoringCableTypeOfConnectionOnComponentSide,
            brakeMonitoringCableSpecification: state.brakeMonitoringCableSpecification,
            brakeMonitoringCableTypeDesignation: brakeMonitoringCableTypeDesignation,
            brakeMonitoringCableNumberOfWires: brakeMonitoringCableNumberOfWires,
            brakeMonitoringCableCrossSection: brakeMonitoringCableCrossSection,
            forcedVentilationNecessary: state.cables_Data.forcedVentilationCable_Necessary,
            forcedVentilationCableSelected: forcedVentilationCableSelected,
            tNFLei: forcedVentilationCableBlockNumber,
            sFLMaN: state.forcedVentilationCableLength,
            lLFBL: state.forcedVentilationCableDeliver,
            forcedVentilationCableP01: state.forcedVentilationCableTypeOfConnectionOnComponentSide,
            forcedVentilationCableSpecification: state.forcedVentilationCableSpecification,
            forcedVentilationCableTypeDesignation: forcedVentilationCableTypeDesignation,
            forcedVentilationCableNumberOfWires: forcedVentilationCableNumberOfWires,
            forcedVentilationCableCrossSection: forcedVentilationCableCrossSection
        }

        var data = {};
        var methodName = '';
        if (rootState.configurations.plusManual == true) {
            methodName = 'insertOrUpdate_cablesConfiguration';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            var preselectionMotorManual = null;
            var driveTechnologiesManual = null;

            if (rootState.summary.manualHomeSummary != null) {
                if (rootState.summary.manualHomeSummary.hasOwnProperty('preselectionMotorManual') && rootState.summary.manualHomeSummary.preselectionMotorManual != null && rootState.summary.manualHomeSummary.preselectionMotorManual.mN_MOT != null) {
                    preselectionMotorManual = rootState.summary.manualHomeSummary.preselectionMotorManual;
                }
            }
            if (rootState.summary.manualHomeSummary != null) {
                if (rootState.summary.manualHomeSummary.hasOwnProperty('driveTechnologiesManual') && rootState.summary.manualHomeSummary.driveTechnologiesManual != null && rootState.summary.manualHomeSummary.driveTechnologiesManual.mN_BREM != null) {
                    driveTechnologiesManual = rootState.summary.manualHomeSummary.driveTechnologiesManual;
                }
            }


            data = {
                configurationStepsManualId: configurationStepsManualId,
                configurationOverviewsLinkId: rootState.configurations.configurationOverviewsLinkId,
                configurationOverviewsId: rootState.configurations.configurationOverviewsId,
                cablesManual: cablesManual,
                preselectionMotorManual: preselectionMotorManual,
                driveTechnologiesManual: driveTechnologiesManual,
            }
        }
        else {
            methodName = 'insertOrUpdate_cablesManual';
            const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;
            data = {
                configurationStepsManualId: configurationStepsManualId,
                cablesManual: cablesManual,
            }
        }

        await axios({
            method: 'post',
            url: '/api/configurations/' + methodName,
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);

                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    if (rootState.configurations.plusManual == true) {
                        this.dispatch('inputsManual/change_configurationStepsManualId_storeAction', response.data.data.configurationStepsManualId);
                        this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                        if ((response.data.status.statusCode.substring(0, 1) == '2') || (response.data.status.statusCode.substring(0, 2) == '74') || (response.data.status.statusCode.substring(0, 2) == '75')) {
                            this.dispatch('configurations/set_configurationOverviewsLinkId_at_sessionStorage_storeAction', response.data.data.configurationOverviewsLinkId);
                        }
                    }
                    else {
                        if (response.data.data.changedDataset == true) {
                            this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                            this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                            var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                            navigationManual.cablesManual = 'passed';
                            navigationManual.suppliesMotorManual = null;
                            navigationManual.packagingMotorManual = null;
                            this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                        }
                    }
                }
                else {
                    if (rootState.configurations.plusManual == true) {
                    }
                    else {
                        var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                        navigationManual.cablesManual = null;
                        navigationManual.suppliesMotorManual = null;
                        navigationManual.packagingMotorManual = 'disabled';
                        this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                    }
                }
            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })

            .then(() => {
                if (rootState.configurations.plusManual == true) {
                    this.dispatch('configurations/change_manualConfiguration_storeAction', false);
                    router.push('../manualStep/ManualHome');
                }
                else {
                    router.push({ params: { slug: 'suppliesMotorManual' } });
                }
            })

    },

    async delete_configuration_cablesManual_storeAction({ rootState, commit, dispatch }) {
        const configurationStepsManualId = rootState.inputsManual.configurationStepsManualId;

        const data = {
            configurationStepsManualId: configurationStepsManualId,
        }

        await axios({
            method: 'post',
            url: '/api/configurations/delete_configuration_cablesManual',
            data: {
                data: data,
            }
        })
            .then((response) => {
                this.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data);
                if (response.data.status.statusCode.substring(0, 1) == '2') {
                    this.dispatch('suppliesMotorManual/change_suppliesMotorManual_toDefault_storeAction');
                    this.dispatch('packagingMotorManual/change_packagingMotor_toDefault_storeAction');

                    var navigationManual = Object.assign({}, rootState.configurations.navigationManual)
                    navigationManual.cablesManual = null;
                    navigationManual.suppliesMotorManual = null;
                    navigationManual.packagingMotorManual = 'disabled';
                    this.commit('configurations/change_navigationManual_storeMutation', navigationManual);
                }

            })
            .catch(() => {
                this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
            })
            .then(() => {
            })

    },

    checkIf_changesAt_configurationFlow_storeAction({ rootState, dispatch }) {
        if (rootState.configurations.navigationManual.cablesManual == "passed") {
            dispatch('delete_configuration_cablesManual_storeAction');
        }
    },


    checkIf_cablesManual_areValid_storeAction({ rootState, dispatch }) {
        var numberOf_inputs_whichAreInvalid = 0;
        if (state.cables_Data.encoderCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.encoderCableDeliver == null || (state.encoderCableDeliver != 0 && state.encoderCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.cables_Data.encoderCable_Necessary == true) {
            if (state.encoderConnectionInformation == null || state.encoderConnectionInformation.hasOwnProperty('details') == false) {
                numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
            }
            else {
                const firstIndex_whichMeetConditions = state.encoderConnectionInformation.details.findIndex(i => (i.selected == true));
                if (firstIndex_whichMeetConditions == -1) {
                    numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
                }
                else if (state.encoderConnectionInformation.details[firstIndex_whichMeetConditions].blockNumber == null) {
                    numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
                }
            }
        }
        if (state.cables_Data.motorCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorCableDeliver == null || (state.motorCableDeliver != 0 && state.motorCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.cables_Data.motorProtectionCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.motorProtectionCableDeliver == null || (state.motorProtectionCableDeliver != 0 && state.motorProtectionCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.cables_Data.brakeCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.brakeCableDeliver == null || (state.brakeCableDeliver != 0 && state.brakeCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.cables_Data.brakeMonitoringCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.brakeMonitoringCableDeliver == null || (state.brakeMonitoringCableDeliver != 0 && state.brakeMonitoringCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.cables_Data.forcedVentilationCable_Necessary == null) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }
        if (state.forcedVentilationCableDeliver == null || (state.forcedVentilationCableDeliver != 0 && state.forcedVentilationCableBlockNumber == null)) {
            numberOf_inputs_whichAreInvalid = numberOf_inputs_whichAreInvalid + 1;
        }

        if (numberOf_inputs_whichAreInvalid > 0) {
            this.dispatch('statusHandling/change_dataAndStatus_storeAction', { status: { statusCode: '7200001', statusInContextOf: 'checkIf_inputs_areValid_storeAction', statusInformations: [{ textKey: 1446 }, { textKey: 1449 }], statusModalClosingOptions: [{ displayedDesignation: { textKey: 383 }, closingOption: 'close' }] } })
        }
        else if (numberOf_inputs_whichAreInvalid == 0) {
            dispatch('insertOrUpdate_cables_storeAction');
        }

    },



}



const mutations = {

    // cable data
    change_loadCables_storeMutation(state, payload) {
        state.loadCables = payload;
    },
    change_cables_Data_storeMutation(state, payload) {
        if (payload == null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
        state.cables_Data = payload;
    },

    change_encoderCableDeliverSelection_storeMutation(state, payload) {
        if (payload == null) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }
        state.encoderCableDeliverSelection = payload;
    },

    change_encoderCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderCableDeliverSelection.length; i++) {
            state.encoderCableDeliverSelection[i].selected = false;

            if (state.encoderCableDeliverSelection[i].text.text == payload) {
                state.encoderCableDeliverSelection[i].selected = true;
            }
        }
    },
    change_motorCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cables_Data.motorCable_DeliverOption_Valuelist.length; i++) {
            state.cables_Data.motorCable_DeliverOption_Valuelist[i].selected = false;

            if (state.cables_Data.motorCable_DeliverOption_Valuelist[i].text.text == payload) {
                state.cables_Data.motorCable_DeliverOption_Valuelist[i].selected = true;
            }
        }
    },
    change_motorProtectionCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cables_Data.motorProtectionCable_DeliverOption_Valuelist.length; i++) {
            state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[i].selected = false;

            if (state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[i].text.text == payload) {
                state.cables_Data.motorProtectionCable_DeliverOption_Valuelist[i].selected = true;
            }
        }
    },
    change_brakeCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cables_Data.brakeCable_DeliverOption_Valuelist.length; i++) {
            state.cables_Data.brakeCable_DeliverOption_Valuelist[i].selected = false;

            if (state.cables_Data.brakeCable_DeliverOption_Valuelist[i].text.text == payload) {
                state.cables_Data.brakeCable_DeliverOption_Valuelist[i].selected = true;
            }
        }
    },
    change_brakeMonitoringCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist.length; i++) {
            state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[i].selected = false;

            if (state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[i].text.text == payload) {
                state.cables_Data.brakeMonitoringCable_DeliverOption_Valuelist[i].selected = true;
            }
        }
    },
    change_forcedVentilationCableDeliverSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist.length; i++) {
            state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[i].selected = false;

            if (state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[i].text.text == payload) {
                state.cables_Data.forcedVentilationCable_DeliverOption_Valuelist[i].selected = true;
            }
        }
    },

    change_encoderCableDeliver_storeMutation(state, payload) {
        state.encoderCableDeliver = payload;
    },
    change_motorCableDeliver_storeMutation(state, payload) {
        state.motorCableDeliver = payload;
    },
    change_motorProtectionCableDeliver_storeMutation(state, payload) {
        state.motorProtectionCableDeliver = payload;
    },
    change_brakeCableDeliver_storeMutation(state, payload) {
        state.brakeCableDeliver = payload;
    },
    change_brakeMonitoringCableDeliver_storeMutation(state, payload) {
        state.brakeMonitoringCableDeliver = payload;
    },
    change_forcedVentilationCableDeliver_storeMutation(state, payload) {
        state.forcedVentilationCableDeliver = payload;
    },


    // encoderConnection
    change_encoderConnectionInformation_storeMutation(state, payload) {
        state.encoderConnectionInformation = payload;
    },
    change_encoderConnectionCableSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderConnectionInformation.details.length; i++) {
            state.encoderConnectionInformation.details[i].selected = false;

            if (state.encoderConnectionInformation.details[i].blockNumber == payload) {
                state.encoderConnectionInformation.details[i].selected = true;
            }
        }
    },
    // encoder cable 
    change_encoderCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.encoderCableLengthSelection = payload;
    },
    change_encoderCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderCableLengthSelection.length; i++) {
            state.encoderCableLengthSelection[i].selected = false;

            if (state.encoderCableLengthSelection[i].value == payload) {
                state.encoderCableLengthSelection[i].selected = true;
            }
        }
    },
    change_encoderCableLength_storeMutation(state, payload) {
        state.encoderCableLength = payload;
    },
    // motor cable 
    change_motorCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorCableLengthSelection = payload;
    },
    change_motorCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorCableLengthSelection.length; i++) {
            state.motorCableLengthSelection[i].selected = false;

            if (state.motorCableLengthSelection[i].value == payload) {
                state.motorCableLengthSelection[i].selected = true;
            }
        }
    },
    change_motorCableLength_storeMutation(state, payload) {
        state.motorCableLength = payload;
    },
    // motor protection cable 
    change_motorProtectionCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorProtectionCableLengthSelection = payload;
    },
    change_motorProtectionCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorProtectionCableLengthSelection.length; i++) {
            state.motorProtectionCableLengthSelection[i].selected = false;

            if (state.motorProtectionCableLengthSelection[i].value == payload) {
                state.motorProtectionCableLengthSelection[i].selected = true;
            }
        }
    },
    change_motorProtectionCableLength_storeMutation(state, payload) {
        state.motorProtectionCableLength = payload;
    },
    // brake cable 
    change_brakeCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeCableLengthSelection = payload;
    },
    change_brakeCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeCableLengthSelection.length; i++) {
            state.brakeCableLengthSelection[i].selected = false;

            if (state.brakeCableLengthSelection[i].value == payload) {
                state.brakeCableLengthSelection[i].selected = true;
            }
        }
    },
    change_brakeCableLength_storeMutation(state, payload) {
        state.brakeCableLength = payload;
    },
    // brake monitoring cable 
    change_brakeMonitoringCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeMonitoringCableLengthSelection = payload;
    },
    change_brakeMonitoringCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeMonitoringCableLengthSelection.length; i++) {
            state.brakeMonitoringCableLengthSelection[i].selected = false;

            if (state.brakeMonitoringCableLengthSelection[i].value == payload) {
                state.brakeMonitoringCableLengthSelection[i].selected = true;
            }
        }
    },
    change_brakeMonitoringCableLength_storeMutation(state, payload) {
        state.brakeMonitoringCableLength = payload;
    },
    // forced ventiltion cable
    change_forcedVentilationCableLengthSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.forcedVentilationCableLengthSelection = payload;
    },
    change_forcedVentilationCableLengthSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.forcedVentilationCableLengthSelection.length; i++) {
            state.forcedVentilationCableLengthSelection[i].selected = false;

            if (state.forcedVentilationCableLengthSelection[i].value == payload) {
                state.forcedVentilationCableLengthSelection[i].selected = true;
            }
        }
    },
    change_forcedVentilationCableLength_storeMutation(state, payload) {
        state.forcedVentilationCableLength = payload;
    },

    // encoder cable
    change_encoderCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.encoderCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_encoderCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.encoderCableTypeOfConnectionOnComponentSideSelection[i].selected = false;

            if (state.encoderCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.encoderCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_encoderCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.encoderCableTypeOfConnectionOnComponentSide = payload;
    },
    // motor cable
    change_motorCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_motorCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.motorCableTypeOfConnectionOnComponentSideSelection[i].selected = false;
            if (state.motorCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.motorCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_motorCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.motorCableTypeOfConnectionOnComponentSide = payload;
    },
    // motor protection cable
    change_motorProtectionCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorProtectionCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_motorProtectionCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorProtectionCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[i].selected = false;

            if (state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.motorProtectionCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_motorProtectionCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.motorProtectionCableTypeOfConnectionOnComponentSide = payload;
    },
    // brake cable
    change_brakeCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_brakeCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.brakeCableTypeOfConnectionOnComponentSideSelection[i].selected = false;

            if (state.brakeCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.brakeCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_brakeCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.brakeCableTypeOfConnectionOnComponentSide = payload;
    },
    // brake monitoring cable
    change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_brakeMonitoringCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[i].selected = false;

            if (state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.brakeMonitoringCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_brakeMonitoringCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.brakeMonitoringCableTypeOfConnectionOnComponentSide = payload;
    },
    // forced ventiltion cable
    change_forcedVentilationCableTypeOfConnectionOnComponentSideSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection = payload;
    },
    change_forcedVentilationCableTypeOfConnectionOnComponentSideSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection.length; i++) {
            state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[i].selected = false;

            if (state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[i].text.text == payload) {
                state.forcedVentilationCableTypeOfConnectionOnComponentSideSelection[i].selected = true;
            }
        }
    },
    change_forcedVentilationCableTypeOfConnectionOnComponentSide_storeMutation(state, payload) {
        state.forcedVentilationCableTypeOfConnectionOnComponentSide = payload;
    },

    // encoder cable
    change_encoderCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.encoderCableSpecificationSelection = payload;
    },
    change_encoderCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderCableSpecificationSelection.length; i++) {
            state.encoderCableSpecificationSelection[i].selected = false;

            if (state.encoderCableSpecificationSelection[i].text.text == payload) {
                state.encoderCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_encoderCableSpecification_storeMutation(state, payload) {
        state.encoderCableSpecification = payload;
    },
    // motor cable
    change_motorCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorCableSpecificationSelection = payload;
    },
    change_motorCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorCableSpecificationSelection.length; i++) {
            state.motorCableSpecificationSelection[i].selected = false;
            if (state.motorCableSpecificationSelection[i].text.text == payload) {
                state.motorCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_motorCableSpecification_storeMutation(state, payload) {
        state.motorCableSpecification = payload;
    },
    // motor protection cable
    change_motorProtectionCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorProtectionCableSpecificationSelection = payload;
    },
    change_motorProtectionCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorProtectionCableSpecificationSelection.length; i++) {
            state.motorProtectionCableSpecificationSelection[i].selected = false;

            if (state.motorProtectionCableSpecificationSelection[i].text.text == payload) {
                state.motorProtectionCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_motorProtectionCableSpecification_storeMutation(state, payload) {
        state.motorProtectionCableSpecification = payload;
    },
    // brake cable
    change_brakeCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeCableSpecificationSelection = payload;
    },
    change_brakeCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeCableSpecificationSelection.length; i++) {
            state.brakeCableSpecificationSelection[i].selected = false;

            if (state.brakeCableSpecificationSelection[i].text.text == payload) {
                state.brakeCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_brakeCableSpecification_storeMutation(state, payload) {
        state.brakeCableSpecification = payload;
    },
    // brake monitoring cable
    change_brakeMonitoringCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeMonitoringCableSpecificationSelection = payload;
    },
    change_brakeMonitoringCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeMonitoringCableSpecificationSelection.length; i++) {
            state.brakeMonitoringCableSpecificationSelection[i].selected = false;

            if (state.brakeMonitoringCableSpecificationSelection[i].text.text == payload) {
                state.brakeMonitoringCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_brakeMonitoringCableSpecification_storeMutation(state, payload) {
        state.brakeMonitoringCableSpecification = payload;
    },
    // forced ventiltion
    change_forcedVentilationCableSpecificationSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.forcedVentilationCableSpecificationSelection = payload;
    },
    change_forcedVentilationCableSpecificationSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.forcedVentilationCableSpecificationSelection.length; i++) {
            state.forcedVentilationCableSpecificationSelection[i].selected = false;

            if (state.forcedVentilationCableSpecificationSelection[i].text.text == payload) {
                state.forcedVentilationCableSpecificationSelection[i].selected = true;
            }
        }
    },
    change_forcedVentilationCableSpecification_storeMutation(state, payload) {
        state.forcedVentilationCableSpecification = payload;
    },

    // encoder cable
    change_encoderCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.encoderCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_encoderCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.encoderCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.encoderCableNumberOfWiresAndCrossSectionSelection[i].selected = false;

            if (state.encoderCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.encoderCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.encoderCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_encoderCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.encoderCableNumberOfWiresAndCrossSection = payload;
    },
    // motor cable
    change_motorCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_motorCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.motorCableNumberOfWiresAndCrossSectionSelection[i].selected = false;
            if (state.motorCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.motorCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.motorCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_motorCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.motorCableNumberOfWiresAndCrossSection = payload;
    },
    // motor protection cable
    change_motorProtectionCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.motorProtectionCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_motorProtectionCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.motorProtectionCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[i].selected = false;

            if (state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.motorProtectionCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_motorProtectionCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.motorProtectionCableNumberOfWiresAndCrossSection = payload;
    },
    // brake cable
    change_brakeCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_brakeCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.brakeCableNumberOfWiresAndCrossSectionSelection[i].selected = false;

            if (state.brakeCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.brakeCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.brakeCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_brakeCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.brakeCableNumberOfWiresAndCrossSection = payload;
    },
    // brake monitoring cable
    change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_brakeMonitoringCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[i].selected = false;

            if (state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.brakeMonitoringCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_brakeMonitoringCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.brakeMonitoringCableNumberOfWiresAndCrossSection = payload;
    },
    // forced ventiltion cable
    change_forcedVentilationCableNumberOfWiresAndCrossSectionSelection_storeMutation(state, payload) {
        if (payload == null || payload.length == 0) {
            this.dispatch('container/change_loaderModal_storeAction', { loaderModal_Shown: false, reasonWhy_loaderModal_Shown: null });
        }

        state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection = payload;
    },
    change_forcedVentilationCableNumberOfWiresAndCrossSectionSelected_storeMutation(state, payload) {
        for (let i = 0; i < state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection.length; i++) {
            state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[i].selected = false;

            if (state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[i].numberOfWires == payload.numberOfWires && state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[i].crossSection == payload.crossSection) {
                state.forcedVentilationCableNumberOfWiresAndCrossSectionSelection[i].selected = true;
            }
        }
    },
    change_forcedVentilationCableNumberOfWiresAndCrossSection_storeMutation(state, payload) {
        state.forcedVentilationCableNumberOfWiresAndCrossSection = payload;
    },


    change_encoderConnectionCableBlockNumber_storeMutation(state, payload) {
        state.encoderConnectionCableBlockNumber = payload;
    },
    change_encoderCableBlockNumber_storeMutation(state, payload) {
        state.encoderCableBlockNumber = payload;
    },
    change_motorCableBlockNumber_storeMutation(state, payload) {
        state.motorCableBlockNumber = payload;
    },
    change_motorProtectionCableBlockNumber_storeMutation(state, payload) {
        state.motorProtectionCableBlockNumber = payload;
    },
    change_brakeCableBlockNumber_storeMutation(state, payload) {
        state.brakeCableBlockNumber = payload;
    },
    change_brakeMonitoringCableBlockNumber_storeMutation(state, payload) {
        state.brakeMonitoringCableBlockNumber = payload;
    },
    change_forcedVentilationCableBlockNumber_storeMutation(state, payload) {
        state.forcedVentilationCableBlockNumber = payload;
    },
    change_babl_storeMutation(state, payload) {
        state.babl = payload;
    },
}

export const cables = {
    namespaced: true,
    components: {
        axios
    },
    state,
    actions,
    mutations
}